import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  verified: undefined
};

const verifyChangeEmailSlice = createSlice({
  name: 'verifyChangeEmail',
  initialState,
  reducers: {
    changeEmailVerify: (state) => {
      state.loading = true;
    }
  }
});

export const {
  changeEmailVerify
} = verifyChangeEmailSlice.actions;

export default verifyChangeEmailSlice.reducer;
