import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import temperatureMeasurementIcon from '../../../../../assets/images/svg/temperature-measurement.svg';

const TemperatureMeasurementTooltip = (props) => {
  const { currentWaterTemp } = props;

  return (
    <>
      <img src={temperatureMeasurementIcon} alt="Temperature Measurement Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('temperatureLabel')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {currentWaterTemp || defaultValue}
          °C
        </span>
      </div>
    </>
  );
};

TemperatureMeasurementTooltip.propTypes = {
  currentWaterTemp: PropTypes.number
};

export default TemperatureMeasurementTooltip;
