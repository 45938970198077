/**
 * Generates an array of number in string format with length of 2 characters
 * @param {number} length Length of the sequence (no more than 100)
 * @returns {array} Array of strings
 */
export const generateTimeStringSequence = (length) => {
  const maxLength = length < 100 ? length : 99;
  return [...Array(maxLength).keys()].map((value) => `${value}`.padStart(2, '0'));
};

/**
 * Get index of an hours value in array of hours
 * @param {array} hoursArr Array of strings in format of ['00', '01', '02', ...]
 * @param {string} time Time string in format of 'hh:mm'
 * @returns {number} Index of the hours value in hours array
 */
export const getHoursIndex = (hoursArr, time) => hoursArr.indexOf(time.split(':')[0]);

/**
 * Get index of an minutes value in array of minutes
 * @param {array} minutesArr Array of strings in format of ['00', '01', '02', ...]
 * @param {string} time Time string in format of 'hh:mm'
 * @returns {number} Index of the minutes value in minutes array
 */
export const getMinutesIndex = (minutesArr, time) => minutesArr.indexOf(time.split(':')[1]);
