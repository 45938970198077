import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  sendSuccessfull: false,
  verifyEmail: undefined,
  resendEmail: false,
  changeEmail: false,
  isLoading: false,
  email: undefined
};

const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    sendEmailChange: epicEmptyFunction,
    receiveSignUp: (state, action) => {
      const { email } = action.payload;

      state.sendSuccessfull = true;
      state.email = email;
    },
    signUpSend: (state) => {
      state.isLoading = true;
    },
    reveivedInfoFromServer: (state) => {
      state.isLoading = false;
    },
    changeEmailAction: (state) => {
      state.sendSuccessfull = false;
      state.changeEmail = true;
    },
    sendEmailChangeSucc: (state, action) => {
      const { email, verifyEmail } = action.payload;

      state.verifyEmail = verifyEmail;
      state.sendSuccessfull = true;
      state.email = email;
    },
    resendToEmail: (state) => {
      state.resendEmail = true;
    }
  }
});

export const {
  reveivedInfoFromServer,
  sendEmailChangeSucc,
  changeEmailAction,
  sendEmailChange,
  receiveSignUp,
  resendToEmail,
  signUpSend
} = signUpSlice.actions;

export default signUpSlice.reducer;
