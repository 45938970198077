/* eslint-disable arrow-body-style */
import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  errorMessage: undefined,
  stopMessage: undefined,
  initialValues: {},
  verified: false
};

const signUp2Slice = createSlice({
  name: 'signUp2',
  initialState,
  reducers: {
    signUpVerify: epicEmptyFunction,
    signUp2Send: epicEmptyFunction,
    signUpVerifySucc: (state, action) => {
      state.verified = true;
      state.initialValues = {
        ...action.payload.fields,
        gateway: undefined,
        code: action.payload.verifyCode
      };
    },
    signUpVerifyError: (state, action) => {
      return {
        errorMessage: action.payload.message
      };
    },
    signUpShowStopMessage: (state, action) => {
      return {
        stopMessage: action.payload.message
      };
    }
  }
});

export const {
  signUpVerifyError,
  signUpVerifySucc,
  signUpVerify,
  signUp2Send
} = signUp2Slice.actions;

export default signUp2Slice.reducer;
