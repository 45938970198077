import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import ModalWindow from '../../ModalWindow';
import { CancelButton, PrimaryButton } from '../../../components/UIKit';
import { LongCheckboxArr } from '../../../components';

import { getModalsAddCarChargersSelector } from '../../../redux-store/selectors/modals';
import { ADD_CAR_CHARGERS_WINDOW_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

const formID = 'addCarChargersForm';

const AddCarChargersWindow = (props) => {
  const {
    handleSubmit,
    reset
  } = props;

  const dispatch = useDispatch();
  const modal = useSelector(getModalsAddCarChargersSelector);
  const [checkedFields, setCheckedFields] = useState([]);
  const isDisabled = checkedFields.some((field) => field.isChecked);

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    const carChargers = modal?.data?.carChargers?.map((device) => ({ _id: device._id, name: device.tag?.name || device.device_group, isChecked: false })) || [];
    if (carChargers.length) {
      setCheckedFields(carChargers);
    }
  }, [modal?.opened]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const dataForReq = checkedFields.filter(({ isChecked }) => isChecked).map(({ _id }) => _id);
    handleSubmit([...(modal?.data?.carChargersId || []), ...dataForReq], modal?.data?.id, modal?.data?.deviceType, modal?.data?.rowData);
    reset();
  };

  const handleChange = (value) => {
    setCheckedFields((prev) => {
      const copy = modal?.data?.deviceType === 'Car' ? [...prev].map((el) => ({ ...el, isChecked: false })) : [...prev];
      const indexOfPoint = copy.findIndex((point) => point._id === value);

      copy[indexOfPoint] = {
        ...copy[indexOfPoint],
        isChecked: !copy[indexOfPoint].isChecked
      };
      return copy;
    });
  };

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_CAR_CHARGERS_WINDOW_MODAL_ID }));

  return (
    <ModalWindow customClass="max-width-550" modalID={ADD_CAR_CHARGERS_WINDOW_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addCarCharger')}</h5>
      </div>

      <div>
        <div className="modal-body ">
          <form
            id={formID}
            onSubmit={handleSubmitForm}
            className="m-login__form m-form pop-up-form add-sm-us"
          >
            <LongCheckboxArr
              formId={formID}
              searchPlaceholder="search"
              handleChange={handleChange}
              noDataText="noDevices"
              checkedData={checkedFields}
              isShowSelectAllCheckbox={false}
              selectAllCheckboxLabel="selectAllEndUsers"
            />
          </form>
        </div>
        <div className="modal-footer modal-add-installer-to-oem">
          <div className="ml-auto modal-buttons">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton
              customButtonClass="ml-2 installer-to-oem-btn"
              form={formID}
              i18nLabel="add"
              disabled={!isDisabled}
              isSubmit
              type="plus"
            />
          </div>
        </div>
      </div>

    </ModalWindow>
  );
};

AddCarChargersWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

const form = reduxForm({
  form: formID
})(AddCarChargersWindow);

export default form;
