import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { CLOUD_STATUS_MODAL_ID, openModalWindow } from '../../../containers/ModalWindow/slice';

import { getSignInUserSelector } from '../../../redux-store/selectors/signIn';
import { getCloudStatusMessagesSelector } from '../../../redux-store/selectors/cloudStatus';

import { ReactComponent as CloudStatusIcon } from '../../../assets/images/svg/cloudStatus.svg';
import { ReactComponent as CloudStatusWithMessageIcon } from '../../../assets/images/svg/cloudStatusWithMessage.svg';

import i18n from '../../../i18n';

/**
 * Renders main RenderUserBar
 * @memberof module:RenderUserBar
 */
const RenderUserBar = (props) => {
  const { innerProps, isNewStatus } = props;
  const dispatch = useDispatch();

  const user = useSelector(getSignInUserSelector);
  const cloudStatusInfo = useSelector(getCloudStatusMessagesSelector);

  const { first_name, last_name, role } = user || {};
  const isAdmin = ['root', 'solar_admin'].includes(role.type);

  const openCloudStatusModal = () => dispatch(openModalWindow({ modalID: CLOUD_STATUS_MODAL_ID, data: cloudStatusInfo }));

  return (
    <>
      <span {...innerProps} className="user-name-user-icon">
        <span className="user-name-topbar">{`${first_name} ${last_name}`}</span>
        {' '}
        <span className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--hover-accentik m-btn--icon  m-btn--icon-dashb m-btn--icon-only m-btn--pill">
          <i className="la la-user" />
        </span>
        {' '}
      </span>
      {!isAdmin && (
        <span
          className="cloud-status-icon m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--hover-accentik m-btn--icon  m-btn--icon-dashb m-btn--icon-only m-btn--pill"
          onClick={openCloudStatusModal}
        >
          {isNewStatus ? <CloudStatusWithMessageIcon /> : <CloudStatusIcon />}
        </span>
      )}
      {role.type === 'end_user' && (
        <Link
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-dashb m-btn--icon-only m-btn--pill"
          to="/my-devices/"
          title={i18n.t('settings')}
        >
          <i className="la la-cog la-cog-dashb" />
        </Link>
      )}
    </>
  );
};

RenderUserBar.propTypes = {
  innerProps: PropTypes.instanceOf(Object).isRequired,
  isNewStatus: PropTypes.bool
};

export default RenderUserBar;
