import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { handleErrorDetailed } from '../../api_helper';

import { addNotification } from '../NotificationGenerator/slice';
import { api } from '../../api';
import { receiveCloudStatusMessage, requestCloudStatusMessage } from './slice';
import { MAINTENANCE_MESSAGE_URL } from '../../api/apiUrls';

function getCloudStatusMessageEpic($action) {
  return $action.pipe(
    ofType(requestCloudStatusMessage),
    map((action) => action.payload),
    // eslint-disable-next-line arrow-body-style
    switchMap((params) => {
      return from(api.get(`${MAINTENANCE_MESSAGE_URL}`, { params })).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          if (result?.error) {
            return addNotification({ type: 'error', text: result.message });
          }

          return receiveCloudStatusMessage({ messages: result });
        })
      );
    })
  );
}

export default combineEpics(getCloudStatusMessageEpic);
