import { findLastIndex } from 'lodash';

/**
 * Applies one new rage to the list of activity.
 * Mutates activity object.
 * @param {Function} creatorFn - Function that is responsible for creating ranges.
 * @param {Function} [pusherFn] - Function that is responsible for inserting ranges.
 * @param {Object[]} activity
 * @param {number} minX
 * @param {number} timestamp
 * @param {number} y
 * @param {string} [color]
 * @param {*} state
 * @param {boolean} [isInactive]
 */
const applyRange = (creatorFn, pusherFn, activity, minX, timestamp, y, color, state, isInactive) => {
  const lastRangeIndex = findLastIndex(activity, (range) => range.y === y);
  if (lastRangeIndex !== -1) {
    const lastRange = activity[lastRangeIndex];
    if (lastRange.isInactive === isInactive && lastRange.state === state) {
      lastRange.x2 = timestamp;
    } else {
      pusherFn(
        activity,
        lastRangeIndex,
        creatorFn(
          lastRange.x2,
          timestamp,
          y,
          color,
          state,
          isInactive
        )
      );
    }
  } else {
    const tenSec = 10000;
    if (isInactive || (timestamp - (tenSec * 2)) <= minX) {
      pusherFn(
        activity,
        0,
        creatorFn(
          minX,
          timestamp,
          y,
          undefined,
          undefined,
          isInactive
        )
      );
    } else {
      const x = timestamp - tenSec;
      pusherFn(
        activity,
        0,
        creatorFn(
          minX,
          x,
          y,
          undefined,
          undefined,
          true
        )
      );
      pusherFn(
        activity,
        undefined,
        creatorFn(
          x,
          timestamp,
          y,
          color,
          state
        )
      );
    }
  }
};

export default applyRange;
