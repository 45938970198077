import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  oemsList: undefined
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getOEMsByID: epicEmptyFunction,
    getOEMs: epicEmptyFunction,

    clearOEMs: (state) => {
      state.oemsList = null;
    },
    setOEMs: (state, action) => {
      state.oemsList = action.payload.oems;
    }
  }
});

export const {
  getOEMsByID,
  clearOEMs,
  setOEMs,
  getOEMs
} = usersSlice.actions;

export default usersSlice.reducer;
