import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chart: {},
  activeDevices: {},
  user: undefined
};

const dashboardSlice = createSlice({
  name: 'dashboardV2',
  initialState,
  reducers: {
    setDashboardUser: (state, action) => {
      state.user = action.payload;
    },
    updateChart: (state, action) => {
      state.chart = { ...state.chart, ...action.payload };
    },

    updateActiveDevices: (state, action) => {
      state.activeDevices = action.payload;
    },
    resetState: () => initialState
  }
});

// eslint-disable-next-line no-empty-pattern
export const {
  setDashboardUser,
  updateChart,
  updateActiveDevices,
  resetState
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
