/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { CommonPhoneInput, LanguageSelector, CustomTextInput, CustomSelect, CountrySelector } from '../../../components/ReduxFormFields';
import { CancelButton } from '../../../components/UIKit';
import { AvatarPreviewer } from '../components';
import { BillingAddress } from '../../../components';

import { required, minLength, maxLength, checkValidPhone } from '../../validation';
import { SETTING_PROFILE_FORM, statusList } from '../constants';
import { useZipCodeValidation } from '../../../hooks';
import { PaymentsMeans } from '../../../components/PaymentsMeans';

import i18n from '../../../i18n';

import '../../../App.css';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);
const valueSelector = formValueSelector(SETTING_PROFILE_FORM);

/**
 * Edit profile of user with roleType = 'root | suppor | viewer' - FormSupport
 * @memberof module:SettingProfile
 */
const FormSupport = (props) => {
  const {
    openChangePassword,
    openChangeEmail,
    initialValues,
    handleSubmit,
    userRoleName,
    userRoleType,
    initialize,
    myRoleType,
    submitting,
    pristine,
    goBack,
    first_name,
    last_name,
    myself,
    sendEmailRequest,
    isNeedToShowBillingInfo = false
  } = props;
  const zipValidation = useZipCodeValidation(valueSelector);
  const zipValidationBilling = useZipCodeValidation(valueSelector, 'billingAddress.country');

  useEffect(() => {
    initialize(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleDependencies = !myself && myRoleType === 'viewer' ? { disabled: true } : {};
  const accessRoles = ['root', 'solar_admin', 'property_management'].includes(myRoleType);

  return (
    <form onSubmit={handleSubmit}>
      <div className="nav-settings">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-auto mr-sm-auto">
              <h6 className="set-prof-head">
                {i18n.t('settingsProfile')}
                {!myself && (
                  <>
                    :&nbsp;
                    {i18n.exists(userRoleName || userRoleType) ? i18n.t(userRoleName || userRoleType) : (userRoleName || userRoleType)}
                  </>
                )}
              </h6>
            </div>
            {!roleDependencies.disabled && (
              <div className="col-auto">
                <button
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                  className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-save-chan"
                  type="button"
                >
                  {i18n.t('saveChanges')}
                </button>
              </div>
            )}
            <div className="col-auto cont-for-canc-btn">
              <CancelButton onClickHandler={goBack} customButtonClass="emp-set-btn-canc" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row align-items-md-start justify-content-md-start justify-content-center settings-user-details">
          <div className="col-md-3 col-auto">
            <div className="user-photo">
              <Field
                name="avatar"
                type="file"
                userName={`${first_name} ${last_name}`}
                avatar={initialValues?.avatarURL}
                component={AvatarPreviewer}
                {...roleDependencies}
              />
            </div>
            {isNeedToShowBillingInfo && accessRoles && <PaymentsMeans />}
          </div>
          <div className="col-md-8">
            <div className="row justify-content-between">
              <div className="col-lg-5">
                {!myself && accessRoles && (
                  <div className="form-group m-form__group input-field">
                    <div className="flags-select-label">
                      {i18n.t('status')}
                    </div>
                    <Field
                      name="status"
                      component={CustomSelect}
                      placeholder={i18n.t('selectStatus')}
                      options={statusList()}
                      isSearchable={false}
                      validate={[required]}
                      filterOption={({ value }) => value === 'active' || value === 'deactivated'}
                      {...roleDependencies}
                    />
                  </div>
                )}
                <div className="form-group m-form__group input-field">
                  <Field
                    name="first_name"
                    component={CustomTextInput}
                    label={i18n.t('reqFirstName')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="last_name"
                    component={CustomTextInput}
                    label={i18n.t('reqLastName')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="phone"
                    component={CommonPhoneInput}
                    label={i18n.t('phoneNumber')}
                    className="m-input"
                    autoComplete="off"
                    country={initialValues?.country || 'Switzerland'}
                    phone={initialValues?.phone}
                    validate={[checkValidPhone, required]}
                    isRequired
                    {...roleDependencies}
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field input-field-for-ch-pass">
                  <Field
                    disabled
                    name="old_email"
                    component={CustomTextInput}
                    label={i18n.t('mail')}
                    className="m-input"
                  />

                  {!roleDependencies.disabled ? (
                    <>
                      <button
                        type="button"
                        onClick={sendEmailRequest}
                        className="resend-btn"
                      >
                        {i18n.t('resendEmail')}
                      </button>
                      <button
                        type="button"
                        onClick={openChangeEmail}
                        className="change-btn"
                      >
                        {i18n.t('changeMail')}
                      </button>
                    </>
                  ) : null}
                </div>
                {myself && (
                  <div className="form-group m-form__group input-field input-field-for-ch-pass">
                    <Field
                      name="password"
                      type="password"
                      disabled
                      component={CustomTextInput}
                      label={i18n.t('pass')}
                      className="m-input"
                      input={{ value: 'password' }}
                    />
                    <button
                      type="button"
                      onClick={openChangePassword}
                      className="change-btn"
                    >
                      {i18n.t('changePass')}
                    </button>
                  </div>
                )}
                <div className="form-group m-form__group input-field input-filed-flags-select">
                  <div className="flags-select-label">
                    {i18n.t('Country')}
                  </div>
                  <Field
                    name="country"
                    id="country"
                    component={CountrySelector}
                    buttonClassName="country-btn"
                    label={i18n.t('Country')}
                    className="m-input flagSelectClosed"
                    {...roleDependencies}
                    validate={[required]}
                  />
                </div>
                <div className="form-group m-form__group input-field input-filed-flags-select">
                  <div className="flags-select-label">
                    {i18n.t('reqLanguage')}
                  </div>
                  <Field
                    name="language"
                    component={LanguageSelector}
                    id="reqLanguage"
                    className="m-input flagSelectClosed"
                    buttonClassName="reqLanguage-btn"
                    label={i18n.t('reqLanguage')}
                    {...roleDependencies}
                  />
                </div>
              </div>
            </div>
            {!isNeedToShowBillingInfo && (<hr />)}
          </div>
        </div>
        <div className="row align-items-center justify-content-start">
          <div className="col-md-3 col-auto user-photo" />
          <div className="col-md-8">
            <div className="row justify-content-between align-items-start">
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field">
                  <Field
                    name="street"
                    component={CustomTextInput}
                    label={i18n.t('street')}
                    className="m-input"
                    autoComplete="off"
                    validate={[minLen3, maxLen50, required]}
                    isRequired
                    {...roleDependencies}
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="row city-zip">
                  <div className="form-group m-form__group input-field col-7 city-inp city-inp-comp-set">
                    <Field
                      name="city"
                      component={CustomTextInput}
                      label={i18n.t('city')}
                      className="m-input"
                      autoComplete="off"
                      validate={[minLen3, maxLen50, required]}
                      isRequired
                      {...roleDependencies}
                    />
                  </div>
                  <div className="form-group m-form__group input-field offset-1 col-4 zip-inp zip-inp-comp-set">
                    <Field
                      name="zip"
                      component={CustomTextInput}
                      label={i18n.t('zip')}
                      className="m-input"
                      autoComplete="off"
                      validate={[zipValidation, required]}
                      isRequired
                      {...roleDependencies}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isNeedToShowBillingInfo && (
          <div className="row align-items-md-start justify-content-md-start justify-content-center">
            <div className="col-md-3 col-auto user-photo" />
            <div className="col-md-8">
              <hr />
              <div className="every-new-error py-3 billing-label">
                {i18n.t('isBillingEnabledCheckboxTitle')}
              </div>
              <BillingAddress
                userRole="property_management"
                country={initialValues.country}
                isBillingAddressEnabled
                roleDependencies={roleDependencies}
                zipValidation={zipValidationBilling}
                roleType={myRoleType}
              />
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

FormSupport.propTypes = {
  initialize: PropTypes.func.isRequired,
  sendEmailRequest: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  openChangeEmail: PropTypes.func,
  openChangePassword: PropTypes.func,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  myRoleType: PropTypes.string,
  userRoleName: PropTypes.string,
  myself: PropTypes.bool,
  dataList: PropTypes.instanceOf(Array),
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  userRoleType: PropTypes.string,
  isNeedToShowBillingInfo: PropTypes.bool
};

const form = reduxForm({
  form: SETTING_PROFILE_FORM
})(FormSupport);

export default form;
