import { createSelector } from '@reduxjs/toolkit';

const getForgotPasswordSendSuccessfull = (state) => state.forgotPassword.sendSuccessfull;
const getForgotPasswordVerifyEmail = (state) => state.forgotPassword.verifyEmail;
const getForgotPasswordIsLoading = (state) => state.forgotPassword.isLoading;
const getForgotPasswordCounter = (state) => state.forgotPassword.counter;
const getForgotPasswordEmail = (state) => state.forgotPassword.email;

export const getForgotPasswordSendSuccessfullSelector = createSelector([getForgotPasswordSendSuccessfull], (sendSuccessfull) => sendSuccessfull);
export const getForgotPasswordVerifyEmailSelector = createSelector([getForgotPasswordVerifyEmail], (verifyEmail) => verifyEmail);
export const getForgotPasswordIsLoadingSelector = createSelector([getForgotPasswordIsLoading], (isLoading) => !!isLoading);
export const getForgotPasswordCounterSelector = createSelector([getForgotPasswordCounter], (counter) => counter);
export const getForgotPasswordEmailSelector = createSelector([getForgotPasswordEmail], (email) => email);
