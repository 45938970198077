/* eslint-disable arrow-body-style */
import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  maintenance_message_extended: '',
  maintenance_message: ''
};

const cloudStatusSlice = createSlice({
  name: 'cloudStatus',
  initialState,
  reducers: {
    requestCloudStatusMessage: epicEmptyFunction,
    receiveCloudStatusMessage: (state, action) => {
      return { ...action.payload.messages };
    },
    updateCloudStatusMessage: (state, action) => {
      const { message, extended } = action.payload;
      if (extended) {
        state.maintenance_message_extended.message = message;
      } else {
        state.maintenance_message.message = message;
      }
    }
  }
});

export const {
  receiveCloudStatusMessage,
  requestCloudStatusMessage,
  updateCloudStatusMessage
} = cloudStatusSlice.actions;

export default cloudStatusSlice.reducer;
