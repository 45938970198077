const replace = {
  changeSMID: 'Ændre 3S ID',
  reqSMID: '3S ID *',
  SMID: '3S ID',
  SMIDTolltip: 'The 3S ID er en unik identifikator for din enhed. Du finder dette ID på enheden under QR-koden.',
  enableSmId: '3S ID',
  smidLabel: '3S ID',
  confirmDeletingDesc: 'Bekræft sletningen af ​​alle data fra denne gateway ved at indtaste 3S ID. Efter bekræftelse kan denne proces ikke vendes.',
  confirmDeletingError: 'ID\'et stemmer ikke overens med 3S-ID\'et. Gateway\'en er ikke slettet.'
};

export default replace;
