import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { TextField } from '@mui/material';
import i18n from '../../../i18n';
import { isDeutsch } from '../../../utils/isDeutsch';

/**
 * Renders multiline text field for redux form
 * @memberof module:ReduxFormFields
 */
export const CustomTextField = (props) => {
  const {
    value,
    input,
    showTooltip,
    meta: {
      touched,
      error
    },
    inputProps = {},
    ...custom
  } = props;

  useEffect(() => {
    input.onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <TextareaAutosize
        {...input}
        {...custom}
        {...inputProps}
        showtooltip={showTooltip}
        minRows={1}
        style={{ overflow: 'auto' }}
      />
      {touched && error ? (
        <div className="textarea-custom-warning">
          {i18n.t(error)}
        </div>
      ) : null}
    </>
  );
};

export const renderTextField = ({
  label,
  input,
  isFloat,
  meta: { touched, invalid, error },
  ...custom
}) => {
  if (isFloat && input?.value && isDeutsch()) {
    input.value = String(input.value).replace('.', ',');
  }

  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid && error}
      helperText={(touched && error && (i18n.exists(error) ? i18n.t(error) : error))}
      {...input}
      {...custom}
    />
  );
};

CustomTextField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.string,
  showTooltip: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object)
};
