import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { CompanyLogo } from '../../../../components';

import i18n from '../../../../i18n';

/**
 * Displays company field with preview for setting profile
 * @memberof module:SettingProfile
 */
const CompanyLogoPreview = (props) => {
  const {
    getImagePreviewUrl: getImagePreviewUrlFromProps,
    deleteLogo: deleteLogoFromProps,
    input: inputFromProps,
    userName,
    disabled,
    logo
  } = props;
  const [imagePreviewUrl, setImagePreviewUrl] = useState(false);
  const fileInput = useRef();

  const _handleImageChange = (e, input) => {
    e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
      getImagePreviewUrlFromProps(reader.result);
      input.onChange(file);
    };
    reader.readAsDataURL(file);
  };

  const emulateClick = (e) => {
    fileInput.current.click(e);
  };

  const deleteLogo = () => {
    setImagePreviewUrl(null);
    deleteLogoFromProps();
    inputFromProps.onChange(null);
  };

  return (
    <>
      <CompanyLogo
        name={userName}
        logo={logo}
        background={imagePreviewUrl}
      />
      <input
        className="fileInput"
        type="file"
        accept="image/jpeg,image/png,image/gif,image/bmp,image/x-windows-bmp,image/tiff,image/x-tiff"
        onChange={(e) => _handleImageChange(e, inputFromProps)}
        ref={fileInput}
        hidden
      />
      {!disabled
        && (
          <div className="row justify-content-center">
            <button
              type="button"
              className="photo-ch col-auto m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
              title={i18n.t('editTooltip')}
              onClick={emulateClick.bind(this)}
            >
              <i className="la la-edit" />
            </button>
            <button
              type="button"
              className="photo-ch col-auto btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
              title={i18n.t('delete')}
              onClick={deleteLogo.bind(this)}
            >
              <i className="la la-trash" />
            </button>
          </div>
        )}
    </>
  );
};

CompanyLogoPreview.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Object)
  ]),
  userName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  deleteLogo: PropTypes.func.isRequired,
  getImagePreviewUrl: PropTypes.func.isRequired
};

export default CompanyLogoPreview;
