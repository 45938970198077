import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { replace } from 'react-router-redux';
import FormData from 'form-data';
import { saveAs } from 'file-saver';

import { InstalledSMApi } from './services';
import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import { closeModalWindow, UPDATE_CONFIRMATION_MODAL_ID } from '../ModalWindow/slice';
import { FIRMWARE_AVAILABLE_URL, FIRMWARE_UPDATE_URL, GATEWAYS_HARDWARE_URL } from '../../api/apiUrls';
import {
  installedSMRequestStopLoading,
  installedSMReplaceSelected,
  fetchHardwareListRequest,
  instSMFWVersionsRecieved,
  fetchHardwareListSuccess,
  installedSMSendUpdate,
  instSMGetFWVersions,
  installedSMSendRequest
} from './slice';

import i18n from '../../i18n';

/**
 * Gets list for filter of versions
 * @memberof module:InstalledSM
 */
function iSMGetFWVersionsEpic($action) {
  return $action.pipe(
    ofType(instSMGetFWVersions),
    map((action) => action.payload),
    // eslint-disable-next-line arrow-body-style
    switchMap(() => {
      return from(api.get((FIRMWARE_AVAILABLE_URL))).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          if (!result.error) {
            if (Array.isArray(result)) {
              return instSMFWVersionsRecieved({ versionsList: result });
            }
            return addNotification({ type: 'error', text: result });
          }

          return addNotification({ type: 'error', text: result.message });
        })
      );
    })
  );
}

/**
 * Gets list for filter of versions
 * @memberof module:InstalledSM
 */
function iSMSendRequestEpic($action) {
  return $action.pipe(
    ofType(installedSMSendRequest),
    map((action) => action.payload),
    // eslint-disable-next-line arrow-body-style
    switchMap(({ method, url, config, onResult = {}, modalID = '' }) => {
      return from(api.request({ method, url, ...config })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (typeof onResult.callback === 'function') {
            return of(
              onResult.callback(result),
              installedSMRequestStopLoading()
            );
          }
          if (typeof onResult.cb === 'function') {
            onResult.cb(result);
          }

          let fileReceived = false;
          if (onResult.downloadFile) {
            if (result instanceof Blob) {
              fileReceived = true;
              if (onResult?.type === 'zip') {
                saveAs(result, `${onResult.fileName || 'user-activity-logs-'}${Date.now()}.zip`);
              } else {
                saveAs(result, onResult.fileName || `sm-manager-${Date.now()}.csv`);
              }

              return of(installedSMRequestStopLoading());
            }
          }

          if (result.ok || !result.error || fileReceived) {
            if (!onResult.successMessage) {
              return of(installedSMRequestStopLoading());
            }
            return of(
              closeModalWindow({ modalID }),
              installedSMRequestStopLoading(),
              addNotification({ type: 'success', text: i18n.t(onResult.successMessage) })
            );
          }
          if (!onResult.errorMessage) {
            return of(installedSMRequestStopLoading());
          }

          return of(
            closeModalWindow({ modalID }),
            installedSMRequestStopLoading(),
            addNotification({ type: 'error', text: i18n.t(onResult.errorMessage) })
          );
        })
      );
    })
  );
}

/**
 * Sends selected rows to update
 * @memberof module:InstalledSM
 */
function iSMUpdateSelectedEpic($action) {
  return $action.pipe(
    ofType(installedSMSendUpdate),
    map((action) => action.payload),
    switchMap(({ fields }) => {
      const myForm = new FormData();
      const fieldsKeys = Object.keys(fields);

      if (typeof fields.file === 'undefined') {
        return of(
          closeModalWindow({ modalID: UPDATE_CONFIRMATION_MODAL_ID }),
          installedSMRequestStopLoading({ modalID: UPDATE_CONFIRMATION_MODAL_ID }),
          addNotification({ type: 'error', text: i18n.t('selectFileForUpdate') })
        );
      }

      if (!fields.version || fields.version.length < 3 || fields.version.length > 50) {
        return of(
          closeModalWindow({ modalID: UPDATE_CONFIRMATION_MODAL_ID }),
          installedSMRequestStopLoading({ modalID: UPDATE_CONFIRMATION_MODAL_ID }),
          addNotification({ type: 'error', text: i18n.t('invalidNameOfVersion') })
        );
      }

      fieldsKeys.forEach((fieldID) => {
        if (fieldID === 'version') {
          myForm.append(fieldID, fields[fieldID] ? fields[fieldID].trim() : '');
        } else {
          myForm.append(fieldID, fields[fieldID]);
        }
      });

      return from(api.post(FIRMWARE_UPDATE_URL, myForm, { headers: { 'Content-Type': 'multipart/form-data' } })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error) {
            return of(
              addNotification({ type: 'success', text: i18n.t('updateRequestCreated') }),
              InstalledSMApi.util.invalidateTags(['firmwareList']),
              replace(),
              installedSMReplaceSelected(),
              installedSMRequestStopLoading({ modalID: UPDATE_CONFIRMATION_MODAL_ID }),
              closeModalWindow({ modalID: UPDATE_CONFIRMATION_MODAL_ID })
            );
          }

          return of(
            addNotification({ type: 'error', text: result.message })
          );
        })
      );
    })
  );
}

function fetchHardwareListEpic(action$) {
  return action$.pipe(
    ofType(fetchHardwareListRequest),
    // eslint-disable-next-line arrow-body-style
    switchMap(() => {
      return from(api.get(GATEWAYS_HARDWARE_URL)).pipe(
        catchError(handleErrorDetailed),
        map((result) => result && fetchHardwareListSuccess({ hardwareList: result }))
      );
    })
  );
}

export default combineEpics(
  iSMUpdateSelectedEpic,
  iSMGetFWVersionsEpic,
  iSMSendRequestEpic,
  fetchHardwareListEpic
);
