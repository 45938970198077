import React from 'react';
import { useDispatch } from 'react-redux';

import { SignInForm } from './components';
import { signIn } from './slice';
import { useAddLanguageParam } from './utils';

import i18n, { transformLanguageForAPI } from '../../i18n';

import '../../App.css';
import './index.scss';

/**
 * @module SignIn
 */
/**
 * @memberof module:SignIn
 * @description renders Sign In page
 * @extends Component
 */
const SignIn = () => {
  const dispatch = useDispatch();

  useAddLanguageParam();

  /** Sends request to authorize
   * @param  {object} values - form values to authorize
   */
  const onSubmit = (values) => dispatch(signIn({ ...values, language: transformLanguageForAPI(i18n.language) }));

  return <SignInForm onSubmit={onSubmit} />;
};

export default SignIn;
