export const filteredListOfOptions = [
  {
    key: undefined,
    value: 'workStatusFilterPlaceholder'
  },
  {
    key: true,
    value: 'yes'
  },
  {
    key: false,
    value: 'no'
  }
];

export const signalOptions = [
  {
    key: undefined,
    value: 'workStatusFilterPlaceholder'
  },
  {
    key: 'connected',
    value: 'connected'
  },
  {
    key: 'not connected',
    value: 'not connected'
  }
];

export const releaseGroups = [
  {
    key: 'release',
    value: 'release'
  },
  {
    key: 'beta',
    value: 'beta'
  },
  {
    key: 'alpha',
    value: 'alpha'
  },
  {
    key: 'dev',
    value: 'dev'
  }
];
