import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUploadLoading: false,
  pendingDeleteFiles: [], // TODO: check if needed
  filesLoading: false,
  filesForUpload: [],
  selectedFiles: [],
  files: []
};

const epicEmptyFunction = (state) => state;

const documentationSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {
    getDocumentationFiles: epicEmptyFunction,
    downloadFiles: epicEmptyFunction,
    deleteFiles: epicEmptyFunction,
    uploadFiles: epicEmptyFunction,
    skipAction: epicEmptyFunction,
    setDocumentationFilesLoading: (state, action) => {
      state.filesLoading = action.payload.filesLoading;
    },
    setDocumentationFiles: (state, action) => {
      state.files = action.payload.files;
    },
    setUploadFilesLoading: (state, action) => {
      state.isUploadLoading = action.payload.isUploadLoading;
    },
    setFilesForUpload: (state, action) => {
      state.filesForUpload = action.payload.filesForUpload;
    },
    setSelectedFiles: (state, action) => {
      state.selectedFiles = action.payload.selectedFiles;
    }
  }
});

export const {
  setDocumentationFilesLoading,
  getDocumentationFiles,
  setDocumentationFiles,
  setUploadFilesLoading,
  setFilesForUpload,
  setSelectedFiles,
  downloadFiles,
  uploadFiles,
  deleteFiles,
  skipAction
} = documentationSlice.actions;

export default documentationSlice.reducer;
