import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import './index.scss';

const ZohoHoc = ({ children, zohoLink }) => (
  <Tooltip
    title={(
      <a className="zev-zoho-link" href={zohoLink} target="_blank" rel="noopener noreferrer">
        {zohoLink}
      </a>
    )}
    classes={{ tooltip: 'btn-tooltip', popper: 'btn-popper' }}
    enterTouchDelay={0}
    leaveTouchDelay={10000}
  >
    <div>
      {children}
    </div>
  </Tooltip>
);

ZohoHoc.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  zohoLink: PropTypes.string.isRequired
};

export default ZohoHoc;
