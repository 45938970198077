import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { Field, change } from 'redux-form';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';
import {
  editTariffId as editTariffFormID,
  PROVIDERS_LIST_ID
} from '../../constants';
import { useLazyStartOauth2ForProvidersQuery } from '../../services';
import { CustomTextInput, CustomFormFieldButton } from '../../../../components/ReduxFormFields';
import { TariffTextField } from './TariffTextField';

import Select from '../../../DevicesTable/components/Select';

import { required, getConnectedToProviderValidation, validateFloatWithThreeDecimal } from '../../../validation';
import { APP_ID, THERMONDO_APP_ID } from '../../../../config';
import i18n from '../../../../i18n';

const providersSelector = createSelector(
  (state) => state.dataList[PROVIDERS_LIST_ID]?.data,
  (providers) => providers || []
);

const renderProviderDescription = (providerInfo, isCurrent) => {
  const link = isCurrent ? providerInfo?.connectedLinkUrl : providerInfo?.linkUrl;
  const linkText = isCurrent ? providerInfo?.connectedLinkText : providerInfo?.linkText;
  const text = isCurrent ? providerInfo?.connectedText : providerInfo?.text;

  return providerInfo ? (
    <Box sx={{ mt: 2 }}>
      <Typography sx={{ color: 'secondary.dark' }} variant="body1">
        {text}
      </Typography>
      {link && (
        <a href={link} target="_blank" rel="noreferrer" className="support__url-btn">
          {linkText}
        </a>
      )}
    </Box>
  ) : null;
};

export const DynamicTariff = ({
  formValues = {},
  initialValues = {},
  user = {},
  skipValidation = false,
  field = ''
}) => {
  const dispatch = useDispatch();
  const providers = useSelector(providersSelector)?.filter(({ tariffTypes }) => !tariffTypes || tariffTypes.includes(field));
  const [startOauth2ForProviders, { isLoading: isStartOauth2ForProvidersLoading }] = useLazyStartOauth2ForProvidersQuery();
  const {
    [field]: settingData = {},
    [`${field}Oauth`]: { connectedTo } = {}
  } = formValues;
  const { provider } = settingData.dynamicTariff || {};

  const selectedProvider = providers.find((item) => item.value === provider);
  const isSelectedOauth2Provider = selectedProvider?.type === 'oauth2';
  const isSelectedMeteringCode = selectedProvider?.type === 'meteringCode';
  const isSelectedProviderInitial = initialValues.provider === provider;
  const validateConnectedTo = useMemo(() => getConnectedToProviderValidation(initialValues.provider, field), [initialValues.provider, field]);

  const { tooltip } = selectedProvider?.info || {};

  const isInitialOauth2Provider = providers.find((item) => item.value === initialValues.provider)?.type === 'oauth2'
    && isSelectedProviderInitial;

  const getValidation = useCallback((validations = []) => (skipValidation ? undefined : validations), [skipValidation]);

  const ProviderDescription = useCallback(() => renderProviderDescription(selectedProvider?.info, isInitialOauth2Provider || connectedTo === provider), [selectedProvider?.info, isInitialOauth2Provider, connectedTo, provider]);

  const connectProvider = () => {
    const stateId = `dynamic_tariff_${crypto.getRandomValues(new Uint32Array(1))[0]}`;
    sessionStorage.setItem(stateId, JSON.stringify(formValues));

    startOauth2ForProviders({
      provider,
      modalId: editTariffFormID,
      uiRedirectUrl: window.location.href,
      userId: user._id,
      stateId,
      type: field // importEnergy or exportEnergy
    });
  };

  useEffect(() => {
    if (selectedProvider) {
      dispatch(change(editTariffFormID, `${field}.dynamicTariff.providerType`, selectedProvider.type));
    }
  }, [selectedProvider]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ mt: 1 }}>
      {APP_ID !== THERMONDO_APP_ID && (
        <Typography sx={{ color: 'secondary.dark', mb: 2 }} variant="body1">
          {i18n.t('dynamicPriceSubTitle')}
        </Typography>
      )}
      <Field
        name="dynamicTariff.provider"
        component={Select}
        showTooltip={tooltip || 'selectProviderTooltip'}
        placeholder={`${i18n.t('selectProvider')}...`}
        label={i18n.t('selectProvider')}
        className="m-input"
        options={providers}
        validate={getValidation([required])}
      />

      {isSelectedMeteringCode && (
        <div className="mt-3">
          <Field
            name="dynamicTariff.meteringCode"
            showTooltip="meteringCodeTooltip"
            component={CustomTextInput}
            className="m-input w-100"
            required
            label="meteringCode"
            validate={getValidation([required])}
          />
        </div>
      )}

      <Typography component="div" sx={{ mt: 2, color: 'secondary.dark' }} variant="body1">
        <ProviderDescription />
      </Typography>

      {(isSelectedOauth2Provider || isInitialOauth2Provider) && (
        <Box sx={{ pb: 2 }}>
          <Field
            name={`${field}Oauth.connectedTo`}
            component={CustomFormFieldButton}
            label={(isInitialOauth2Provider || connectedTo === provider) ? 'connected' : 'connect'}
            onClick={connectProvider}
            validate={getValidation([validateConnectedTo])}
            disabled={isStartOauth2ForProvidersLoading}
            isShowCheckmk={isInitialOauth2Provider || connectedTo === provider}
          />
        </Box>
      )}

      {selectedProvider?.additionalFields?.includes('gridFees') && (
        <>
          <Typography
            sx={{
              textTransform: 'capitalize',
              color: '#131313',
              minWidth: 'max-content'
            }}
            variant="body2"
          >
            {`${i18n.t('gridFees')}`}
          </Typography>
          <TariffTextField
            name="dynamicTariff.gridFees"
            label={i18n.t('fee')}
            suffix={settingData.tariffPriceCurrency && `(${settingData.tariffPriceCurrency})`}
            validate={getValidation([required, validateFloatWithThreeDecimal])}
          />
        </>
      )}

      {selectedProvider?.additionalFields?.includes('fixCosts') && (
        <>
          <Typography
            sx={{
              textTransform: 'capitalize',
              color: '#131313',
              minWidth: 'max-content'
            }}
            variant="body2"
          >
            {`${i18n.t('fixcost')}`}
          </Typography>
          <TariffTextField
            name="dynamicTariff.fixCosts"
            label={i18n.t('fee')}
            suffix={settingData.tariffPriceCurrency && `(${settingData.tariffPriceCurrency})`}
            validate={getValidation([required, validateFloatWithThreeDecimal])}
          />
        </>
      )}
    </Box>
  );
};

DynamicTariff.propTypes = {
  formValues: PropTypes.instanceOf(Object),
  initialValues: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  skipValidation: PropTypes.bool,
  field: PropTypes.string
};
