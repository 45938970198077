import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { email, required, parseToLowerCase } from '../../../validation';
import { CustomTextInput } from '../../../../components/ReduxFormFields';
import ModalWindow from '../../../ModalWindow';
import { closeModalWindow } from '../../../ModalWindow/slice';
import { INVITE_MODAL_ID } from '../../constants';
import { PrimaryButton, CancelButton } from '../../../../components/UIKit';
import { getModalsInviteUserModalSelector } from '../../../../redux-store/selectors/modals';
import { AccessRole } from '../../../SettingProfile/components';

import i18n from '../../../../i18n';
import './index.scss';

const InviteUserModal = (props) => {
  const { pristine, reset, invalid, onSubmit, hidePermissions } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getModalsInviteUserModalSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: INVITE_MODAL_ID }));
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      email: e.target.elements.email.value,
      permission: e.target.elements.permission?.value,
      role: modal?.data?.invitedRole
    });
    handleOnClose();
  };

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
  }, [modal?.opened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWindow modalID={INVITE_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('inviteUser')}</h5>
      </div>
      <div className="modal-body">
        <form
          id={INVITE_MODAL_ID}
          onSubmit={handleSubmit}
          className="m-login__form m-form pop-up-form add-sm-us"
        >
          <div className="form-group m-form__group input-field">
            <Field
              name="email"
              component={CustomTextInput}
              label={`${i18n.t('email')} *`}
              className="m-input"
              autoComplete="off"
              validate={[required, email]}
              parse={parseToLowerCase}
              preventAuto
            />
          </div>
          {!hidePermissions && (
            <div className="notification-type-error-container role-type">
              <AccessRole />
            </div>
          )}
        </form>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <PrimaryButton
          form={INVITE_MODAL_ID}
          disabled={pristine || invalid}
          i18nLabel="sendInvite"
          isSubmit
          type="send"
        />
      </div>
    </ModalWindow>
  );
};

InviteUserModal.propTypes = {
  pristine: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  hidePermissions: PropTypes.bool
};

const form = reduxForm({
  form: 'inviteEmailForm'
})(InviteUserModal);

export default form;
