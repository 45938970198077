import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import waterHeaterIcon from '../../../../../assets/images/svg/water-heater.svg';
import waterHeaterBlueIcon from '../../../../../assets/images/svg/water-heater-blue.svg';
import waterHeaterRedIcon from '../../../../../assets/images/svg/water-heater-red.svg';

/**
 *  water temperature after which icon will change
 */
const waterHeaterTempForHotIcon = 55;

/**
 *  water temperature before which icon will change
 */
const waterHeaterTempForColdIcon = 40;

const renderWaterHeaterIcon = (temp) => {
  if (+temp > waterHeaterTempForHotIcon) {
    return waterHeaterRedIcon;
  }

  if (+temp < waterHeaterTempForColdIcon) {
    return waterHeaterBlueIcon;
  }

  return waterHeaterIcon;
};

const WaterHeaterTooltip = (props) => {
  const {
    currentWaterTemp,
    convertedCurrentPower,
    currentPercentOn,
    consumedForLast24h,
    heatModeAuto,
    heaterstep,
    powerSettingPercent
  } = props;

  return (
    <>
      <img
        src={
          Number.isFinite(currentWaterTemp)
            ? renderWaterHeaterIcon(currentWaterTemp)
            : waterHeaterBlueIcon
        }
        alt="Water heater Icon"
      />
      <div className="sensorData-title">
        <span>{i18n.t('waterTemp')}</span>

        {(!currentPercentOn && !Number.isFinite(currentPercentOn)) ? <span>{i18n.t('currentPowerForDevice')}</span> : <span>{i18n.t('switchState')}</span>}
        <span>{i18n.t('last24h')}</span>
        {typeof heatModeAuto === 'boolean' && <span>{i18n.t('heatModeAutoLabel')}</span>}
        {typeof heaterstep === 'number' && <span>{i18n.t('heaterstepLabel')}</span>}
        {typeof powerSettingPercent === 'number' && <span>{i18n.t('powerSettingPercentLabel')}</span>}
      </div>
      <div className="sensorData-value">
        <span>
          {currentWaterTemp || defaultValue}
          °C
        </span>

        {(!currentPercentOn && !Number.isFinite(currentPercentOn)) ? (
          <span>
            {convertedCurrentPower}
            {i18n.t('kWPeakOutputUnits')}
          </span>
        ) : (
          <span>
            {currentPercentOn ?? defaultValue}
            %
          </span>
        )}
        <span>
          {consumedForLast24h || defaultValue}
          {i18n.t('kwh')}
        </span>
        {typeof heatModeAuto === 'boolean' && (
          <span>{heatModeAuto ? i18n.t('auto') : i18n.t('manual')}</span>
        )}
        {Number.isFinite(heaterstep) && (<span>{heaterstep}</span>)}
        {Number.isFinite(powerSettingPercent) && (
          <span>
            {powerSettingPercent}
            %
          </span>
        )}
      </div>
    </>
  );
};

WaterHeaterTooltip.propTypes = {
  currentWaterTemp: PropTypes.number,
  currentPercentOn: PropTypes.number,
  consumedForLast24h: PropTypes.number,
  powerSettingPercent: PropTypes.number,
  heaterstep: PropTypes.number,
  heatModeAuto: PropTypes.bool,
  convertedCurrentPower: PropTypes.number.isRequired
};

export default WaterHeaterTooltip;
