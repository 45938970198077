import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../../i18n';
import switcherOnIcon from '../../../../../assets/images/svg/switcher-on.svg';
import switcherOffIcon from '../../../../../assets/images/svg/switcher-off.svg';
import switcherIcon from '../../../../../assets/images/svg/switcher.svg';

const renderSwitcherIcon = {
  0: switcherOffIcon,
  1: switcherOnIcon
};

const SwitchesTooltip = (props) => {
  const { switchState, convertedCurrentPower, consumedForLast24h } = props;

  return (
    <>
      <img
        src={Number.isFinite(switchState) ? renderSwitcherIcon[switchState] : switcherIcon}
        alt="Switcher Icon"
      />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
        <span>{i18n.t('last24h')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {convertedCurrentPower}
          {i18n.t('kWPeakOutputUnits')}
        </span>
        <span>
          {consumedForLast24h}
          {i18n.t('kwh')}
        </span>
      </div>
    </>
  );
};

SwitchesTooltip.propTypes = {
  switchState: PropTypes.number,
  convertedCurrentPower: PropTypes.number.isRequired,
  consumedForLast24h: PropTypes.number.isRequired
};

export default SwitchesTooltip;
