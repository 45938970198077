import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { countriesSymbols } from '../constants';

import i18n from '../../../../i18n';

import '../index.scss';

export const UserPhoneInput = (props) => {
  const { label, input, disabled, meta: { touched, error }, isRequired } = props;

  const [phoneS, setPhoneS] = useState(input?.value || '');

  /**
   * Set phone number into input field not connected to the end user's country but phone number country code
   * @param {string} phoneNumber string with phone number from input
   */
  const setPhone = (phoneNumber) => {
    setPhoneS(phoneNumber);

    const parsedNumber = phoneNumber && parsePhoneNumber(phoneNumber);
    const { countryCallingCode = '', nationalNumber = '' } = parsedNumber || {};

    input.onChange(parsedNumber ? countryCallingCode + nationalNumber : '');
  };

  useEffect(() => {
    const parsedNumber = phoneS && parsePhoneNumber(`+${phoneS?.replace(/\D/g, '')}`);
    if (parsedNumber) {
      setPhoneS(parsedNumber?.number || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <p className="phone-label">
        {label + (isRequired ? ' *' : '')}
      </p>
      <PhoneInput
        {...input}
        international
        withCountryCallingCode
        countryOptionsOrder={countriesSymbols}
        value={phoneS}
        disabled={disabled}
        onChange={setPhone}
        countries={countriesSymbols}
      />
      {touched && error && (
        <div className="inputCustomWarning">
          {i18n.t(error)}
        </div>
      )}
    </>
  );
};

UserPhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.instanceOf(Object).isRequired,
  isRequired: PropTypes.bool
};
