import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HelmetConfig = ({ children = null }) => (
  <>
    <Helmet>
      <meta
        name="viewport"
        content="user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1"
      />
    </Helmet>
    {children}
  </>
);

HelmetConfig.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default HelmetConfig;
