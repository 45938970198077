import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { zipCode } from '../containers/validation';
import { countryLetters } from '../components/ReduxFormFields/PhoneTextInput/constants';

export const useZipCodeValidation = (valueSelector, formCountryField = 'country') => {
  const country = useSelector((state) => valueSelector(state, formCountryField));
  const countryCode = countryLetters[country];
  const zipValidation = useMemo(() => zipCode(countryCode), [countryCode]);

  return zipValidation;
};
