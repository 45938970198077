export const DATA_LIST_ID = 'monitoring';
export const EMPLOYEE_LIST_ID = 'company';

export const PRIORITY_ARR = [{
  key: 'low',
  value: 'low'
}, {
  key: 'medium',
  value: 'medium'
}, {
  key: 'high',
  value: 'high'
}];

export const WORK_STATUS_ARR = [{
  key: 'new',
  value: 'new'
}, {
  key: 'pending',
  value: 'pending'
}, {
  key: 'wait for the customer',
  value: 'wait for the customer'
}, {
  key: 'wait for the customer / new',
  value: 'wait for the customer / new'
}, {
  key: 'schedule service',
  value: 'schedule service'
}, {
  key: 'service planned',
  value: 'service planned'
}, {
  key: 'in progress',
  value: 'in progress'
}, {
  key: 'on hold',
  value: 'on hold'
}, {
  key: 'resolved',
  value: 'resolved'
}];

export const WORK_STATUS_OBJ = {
  options: [{
    name: 'new',
    id: 'new'
  }, {
    name: 'pending',
    id: 'pending'
  }, {
    name: 'wait for the customer',
    id: 'wait for the customer'
  }, {
    name: 'wait for the customer / new',
    id: 'wait for the customer / new'
  }, {
    name: 'schedule service',
    id: 'schedule service'
  }, {
    name: 'service planned',
    id: 'service planned'
  }, {
    name: 'in progress',
    id: 'in progress'
  }, {
    name: 'on hold',
    id: 'on hold'
  }, {
    name: 'resolved',
    id: 'resolved'
  }, {
    name: 'done',
    id: 'done'
  }]
};

export const GET_INSTALLERS_PARAMS = {
  _limit: 10000,
  _start: 0,
  _sort: 'my_company:asc'
};

export const waitForTheCustomerNewStatus = 'wait for the customer / new';

export const MAX_CHARS_COUNT = 300;
