import { findDeviceByCategoryId } from '../findDeviceByCategoryId';

const setColorFromStore = (id, chart_settings) => {
  let color;
  let tagColor;

  if (chart_settings) {
    const { devicesCurrentPower, batteriesSoc } = chart_settings;
    const batteries = findDeviceByCategoryId(batteriesSoc, id);
    const currentPower = findDeviceByCategoryId(devicesCurrentPower, id);

    tagColor = batteries?.tag?.color || currentPower?.tag?.color;
    color = batteries?.devicePowerColor || currentPower?.devicePowerColor;
  }

  return tagColor || color;
};

export default setColorFromStore;
