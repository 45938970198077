import omit from 'lodash/omit';

export const transformFieldsForAPI = (fields) => {
  /**
   * Temperatures (currentWaterTemp)
   */
  const transformedTemperatures = fields?.temperatures
    // eslint-disable-next-line arrow-body-style
    ? Object.entries(fields.temperatures).reduce((result, [key, value]) => {
      const partKey = key.split('-')[0];
      if (result.find(({ _id }) => _id === partKey)) {
        const copy = [...result];
        const indexOfPoint = copy.findIndex((point) => point._id === partKey);
        copy[indexOfPoint] = { ...copy[indexOfPoint], color: value };

        return copy;
      }
      return [...result, { _id: partKey, showWaterTemp: value }];
    }, [])
    : [];

  /**
   * Devices current power (currentPower)
   */
  const transformedDevicesCurrentPower = fields?.devicesCurrentPower
    // eslint-disable-next-line arrow-body-style
    ? Object.entries(fields.devicesCurrentPower).reduce((result, [key, value]) => {
      const partKey = key.split('-')[0];
      if (result.find(({ _id }) => _id === partKey)) {
        const copy = [...result];
        const indexOfPoint = copy.findIndex((point) => point._id === partKey);
        copy[indexOfPoint] = { ...copy[indexOfPoint], color: value };

        return copy;
      }
      return [...result, { _id: partKey, showCurrentPower: value }];
    }, [])
    : [];

  /**
   * Inverters current power (currentPowerInvSm)
   */
  const transformedInvertersCurrentPower = fields?.invertersCurrentPower
    // eslint-disable-next-line arrow-body-style
    ? Object.entries(fields.invertersCurrentPower).reduce((result, [key, value]) => {
      const partKey = key.split('-')[0];
      if (result.find(({ _id }) => _id === partKey)) {
        const copy = [...result];
        const indexOfPoint = copy.findIndex((point) => point._id === partKey);
        copy[indexOfPoint] = { ...copy[indexOfPoint], color: value };

        return copy;
      }
      return [...result, { _id: partKey, showCurrentPowerInvSm: value }];
    }, [])
    : [];

  /**
   * Batteries SOC (SOC)
   */
  const transformedBatteriesSoc = fields?.batteriesSoc
    // eslint-disable-next-line arrow-body-style
    ? Object.entries(fields.batteriesSoc).reduce((result, [key, value]) => {
      const partKey = key.split('-')[0];
      if (result.find(({ _id }) => _id === partKey)) {
        const copy = [...result];
        const indexOfPoint = copy.findIndex((point) => point._id === partKey);
        copy[indexOfPoint] = { ...copy[indexOfPoint], color: value };

        return copy;
      }
      return [...result, { _id: partKey, showBatterySOC: value }];
    }, [])
    : [];

  return {
    ...omit(fields, 'temperatures'),
    temperatures: transformedTemperatures,
    devicesCurrentPower: transformedDevicesCurrentPower,
    invertersCurrentPower: transformedInvertersCurrentPower,
    batteriesSoc: transformedBatteriesSoc
  };
};
