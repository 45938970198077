import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { email, required, parseToLowerCase } from '../../validation';
import { CustomTextInput } from '../../../components/ReduxFormFields';
import { Preloader } from '../../../components';
import ModalWindow from '../../ModalWindow';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsAddDataSelector, getModalsIsLoadingSelector } from '../../../redux-store/selectors/modals';
import { ADD_DATA_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

import '../index.scss';

/**
 * AddPropMgmtWindow Form id
 */
const addPropMgmt = 'addPropMgmt';

/**
 * Modal window for adding new support member
 * @memberof module:SupportsTable
 */
const AddPropMgmtWindow = (props) => {
  const { handleSubmit, pristine, reset } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(getModalsIsLoadingSelector);
  const modal = useSelector(getModalsAddDataSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_DATA_MODAL_ID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={ADD_DATA_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addPropMgmt')}</h5>
      </div>
      {isLoading ? (
        <div className="user-delete-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <form
              id={addPropMgmt}
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field">
                <Field
                  name="email"
                  component={CustomTextInput}
                  label={i18n.t('regMail')}
                  className="m-input"
                  autoComplete="off"
                  validate={[required, email]}
                  parse={parseToLowerCase}
                  preventAuto
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={addPropMgmt} disabled={pristine} isSubmit type="send" />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddPropMgmtWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: 'addPropMgmt'
})(AddPropMgmtWindow);

export default form;
