import React, { useEffect, useState } from 'react';
import SwiperCore, { Keyboard, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import {
  generateTimeStringSequence,
  getHoursIndex,
  getMinutesIndex
} from './utils';

import './styles.scss';

/**
 * Array of strings to slide in an "hours swiper" - ['00', '01', '02', ..., '23']
 * @ignore
 */
const hoursArr = generateTimeStringSequence(24);

/**
 * Array of strings to slide in an "minutes swiper" - ['00', '01', '02', ..., '59']
 * @ignore
 */
const minutesArr = generateTimeStringSequence(60);

/**
 * Default time string for incorrect or empty input values.
 * @ignore
 */
const defaultTime = '00:00';

SwiperCore.use([Keyboard, Mousewheel]);

export const CustomTimePicker = (props) => {
  const { time = defaultTime, onChange } = props;

  const [minutesSwiper, setMinutesSwiper] = useState(null);
  const [hoursSwiper, setHoursSwiper] = useState(null);

  const hoursIndex = getHoursIndex(hoursArr, time);
  const minutesIndex = getMinutesIndex(minutesArr, time);

  const changeHours = ({ realIndex }) => {
    const currentHours = hoursArr[+realIndex];
    onChange(`${currentHours}:${time.split(':')[1]}`);
  };

  const changeMinutes = ({ realIndex }) => {
    const currentMinutes = minutesArr[+realIndex];
    onChange(`${time.split(':')[0]}:${currentMinutes}`);
  };

  useEffect(() => {
    if (!hoursSwiper?.destroyed) {
      hoursSwiper?.slideToLoop(hoursIndex, 0, false);
    }

    if (!minutesSwiper?.destroyed) {
      minutesSwiper?.slideToLoop(minutesIndex, 0, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursIndex, minutesIndex]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (hoursSwiper) {
        hoursSwiper?.destroy();
      }
      if (minutesSwiper) {
        minutesSwiper.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="time-picker-container">
      <div className="active-mask" />
      <div className="time-picker">
        <div className="picker">
          <Swiper
            onInit={(swiper) => setHoursSwiper(swiper)}
            onSlideChange={changeHours}
            slidesPerView={3}
            direction="vertical"
            centeredSlides
            keyboard
            loop
            initialSlide={getHoursIndex(hoursArr, time)}
            freeMode
            freeModeSticky
            mousewheel={{
              enabled: true,
              sensitivity: 0.5
            }}
          >
            {hoursArr.map((hour) => (
              <SwiperSlide key={hour}>
                <div className="time">{hour}</div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="time-colons">:</div>
        <div className="picker">
          <Swiper
            onInit={(swiper) => setMinutesSwiper(swiper)}
            onSlideChange={changeMinutes}
            slidesPerView={3}
            direction="vertical"
            keyboard
            centeredSlides
            loop
            initialSlide={getMinutesIndex(minutesArr, time)}
            freeMode
            freeModeSticky
            mousewheel={{
              enabled: true,
              sensitivity: 0.5
            }}
          >
            {minutesArr.map((minute) => (
              <SwiperSlide key={minute}>
                <div className="time">{minute}</div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

CustomTimePicker.propTypes = {
  time: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
