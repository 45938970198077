import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getInstalledSMIsLoadingSelector } from '../../../redux-store/selectors/installedSM';
import i18n from '../../../i18n';
import Select from '../../DevicesTable/components/Select';

/**
 * Renders confirmation window before sending to updated selected rows (installed SM page)
 * @memberof module:InstalledSM
 */
const UpdateConfirmationWindow = (props) => {
  const { confirmUpdate, handleOnClose, modalID, firmwareList = [] } = props;

  const isLoading = useSelector(getInstalledSMIsLoadingSelector);

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('chooseReleaseGroup')}</h5>
      </div>
      {isLoading ? (
        <div className="installedsm-delete-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <div className="text-confirmation">
              <span>
                {i18n.t('chooseReleaseGroupInfo')}
              </span>
              <div className="mt-4">
                <Field
                  name="releaseGroupsForFirmwareUpd"
                  component={Select}
                  options={firmwareList}
                  label={i18n.t('releaseGroup')}
                  className="m-input"
                  type="text"
                  placeholder={i18n.t('doNotChanges')}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton onClickHandler={confirmUpdate} i18nLabel="update" type="save" />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

UpdateConfirmationWindow.propTypes = {
  confirmUpdate: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired,
  firmwareList: PropTypes.instanceOf(Array)
};

export default UpdateConfirmationWindow;
