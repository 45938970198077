import React from 'react';
import PropTypes from 'prop-types';

import { getBackgroundAvatarColor, getFirstLetter } from './utils';

import './index.scss';

/**
 * Renders user avatar
 * @function Avatar
 */
const Avatar = (props) => {
  const { name = '', avatar, className = '', background } = props;

  if (background) {
    return (
      <div
        style={{ backgroundImage: `url(${background})` }}
        className={`usr-default-avatar ${className}`}
      />
    );
  }

  if (avatar) {
    return (
      <div
        style={{ backgroundImage: `url(${avatar})` }}
        className={`usr-default-avatar ${className}`}
      />
    );
  }

  return (
    <div
      style={{ backgroundColor: getBackgroundAvatarColor(name) }}
      className={`usr-default-avatar ${className}`}
    >
      {getFirstLetter(name)}
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  background: PropTypes.bool,
  avatar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};

export default Avatar;
