import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import * as workerTimers from 'worker-timers';

import i18n from '../../../i18n';

import '../index.scss';

const NoAccess = () => {
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const interval = workerTimers.setInterval(() => setCountdown((prev) => prev - 1), 1000);
    const reloadTimerInterval = workerTimers.setInterval(() => dispatch(push('/')), 5000);
    const intervalCleaner = workerTimers.setInterval(() => setInterval(interval ? workerTimers.clearInterval(interval) : null), 6000);

    return () => {
      workerTimers.clearInterval(interval);
      workerTimers.clearInterval(reloadTimerInterval);
      workerTimers.clearInterval(intervalCleaner);
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="center-center">
      <div className="no-access-payments-warning notification-message">
        <p className="notification-message-title">
          {i18n.t('noAccessPaymentsWarning')}
        </p>
        <p className="notification-message-subtitle">
          {i18n.t('noAccessPaymentsWarningSubtitle')}
          <br />
          {countdown}
          {' '}
          {i18n.t(countdown > 1 ? 'seconds' : 'second')}
        </p>
      </div>
    </div>
  );
};

export default NoAccess;
