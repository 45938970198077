import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { email, required, minLength, maxLength, parseToLowerCase } from '../../validation';
import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsIsLoadingSelector, getModalsAddDataSelector } from '../../../redux-store/selectors/modals';
import { ADD_DATA_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

import '../index.scss';
import { CustomTextInput } from '../../../components/ReduxFormFields';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

const formID = 'addPvInstaller';

/**
 * Renders add PV installer modal window for PvInstallers page
 * @memberof module:PvInstallers
 */
const AddPvInstallerWindow = (props) => {
  const { handleSubmit, pristine, reset } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(getModalsIsLoadingSelector);
  const modal = useSelector(getModalsAddDataSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_DATA_MODAL_ID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={ADD_DATA_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addPVInstaller')}</h5>
      </div>
      {isLoading ? <div className="pvinstaller-preloader-container"><Preloader /></div> : (
        <div>
          <div className="modal-body">
            <form
              id={formID}
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field">
                <Field
                  name="pv_installer"
                  component={CustomTextInput}
                  label={i18n.t('reqCompanyName')}
                  className="m-input"
                  autoComplete="off"
                  validate={[required, minLen3, maxLen50]}
                />
              </div>
              <div className="form-group m-form__group input-field">
                <Field
                  name="email"
                  component={CustomTextInput}
                  label={i18n.t('regMail')}
                  className="m-input"
                  autoComplete="off"
                  validate={[required, email]}
                  parse={parseToLowerCase}
                  preventAuto
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={formID} disabled={pristine} isSubmit type="send" />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddPvInstallerWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: 'addPvinstallerForm'
})(AddPvInstallerWindow);

export default form;
