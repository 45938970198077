import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { closeModalWindow, openModalWindow } from '../../../ModalWindow/slice';
import { uploadFiles, setFilesForUpload } from '../../store/slice';

import {
  getDocumentationFilesSelector,
  getDocumentationFilesForUploadSelector,
  getDocumentationIsUploadLoadingSelector
} from '../../../../redux-store/selectors/documentation';

import ModalWindow from '../../../ModalWindow';
import { transformBytesToNiceSize, getSize } from '../../helpers';
import { ReactComponent as CloseIco } from '../../../../assets/images/svg/close.svg';
import { MAX_FILE_SIZE, LIMIT_SIZE_MODAL_ID, UPLOAD_MODAL_ID } from '../../constants';
import { Preloader } from '../../../../components';

import i18n from '../../../../i18n';

import './index.scss';

const { Dragger } = Upload;

const IcoRender = () => (
  <div className="upload-icon">
    <i className="fa fa-upload" />
  </div>
);

const UploadModal = (props) => {
  const { userId } = props;
  const dispatch = useDispatch();

  const files = useSelector(getDocumentationFilesSelector);
  const isUploadLoading = useSelector(getDocumentationIsUploadLoadingSelector);
  const filesForUpload = useSelector(getDocumentationFilesForUploadSelector);

  const handleResetFile = (uid) => dispatch(setFilesForUpload({ filesForUpload: filesForUpload.filter((file) => file.uid !== uid) }));
  const handlerUploadFiles = (e) => {
    e.stopPropagation();

    dispatch(uploadFiles({ userId, files: filesForUpload.map(({ originFileObj }) => originFileObj) }));
  };
  const closeModal = () => dispatch(closeModalWindow({ modalID: UPLOAD_MODAL_ID }));

  const addFiles = (filesList) => {
    if (getSize(filesList) + getSize(files, 'fileSize') > MAX_FILE_SIZE) {
      dispatch(openModalWindow({ modalID: LIMIT_SIZE_MODAL_ID }));
    } else {
      dispatch(setFilesForUpload({ filesForUpload: filesList }));
    }
  };

  const draggerProps = {
    data: null,
    multiple: true,
    fileList: filesForUpload,
    name: 'file',
    showUploadList: false,
    iconRender: IcoRender,
    customRequest: () => null,
    onChange: (info) => addFiles(info.fileList)
  };

  return (
    <ModalWindow modalID={UPLOAD_MODAL_ID}>
      <div className="modal-header upload-modal-header">
        <h5 className="modal-title download-title">{i18n.t('uploadFiles')}</h5>
        <div className="btn-upd-dashb">
          <button
            type="button"
            className="close-btn"
            onClick={closeModal}
          >
            <CloseIco />
          </button>
        </div>
      </div>
      <div className="modal-body upload-modal-body">
        {isUploadLoading ? (<Preloader size={50} />) : (
          <section className="upload-section">
            <ul className="files-list">
              {filesForUpload.map((file) => (
                <li key={file.uid}>
                  <span>
                    {file.name}
                    {' '}
                    <span className="file-size">
                      (
                      {' '}
                      {transformBytesToNiceSize(file.size)}
                      {' '}
                      )
                    </span>
                  </span>
                  <div className="btn-upd-dashb">
                    <button
                      type="button"
                      className="close-btn"
                      onClick={() => handleResetFile(file.uid)}
                    >
                      <CloseIco />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="chooseFile-area">
              <Dragger className="dragger" {...draggerProps}>
                <div>
                  <button
                    type="button"
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn chooseFile-btn"
                    onClick={() => null}
                  >
                    {i18n.t('chooseFile')}
                  </button>
                  <p className="drop-file-text">
                    {' '}
                    {i18n.t('chooseFilesSubtitle')}
                  </p>
                </div>

                {(filesForUpload.length > 0) ? (
                  <div>
                    <button
                      type="button"
                      className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn upload-all-btn"
                      onClick={handlerUploadFiles}
                    >
                      {i18n.t('uploadALL')}
                    </button>
                    <p className="drop-file-text transparency">
                      {' '}
                      {i18n.t('chooseFilesSubtitle')}
                    </p>
                  </div>
                ) : null}

              </Dragger>
            </div>

          </section>
        )}
      </div>
      <div className="upload-modal-footer" />
    </ModalWindow>
  );
};

UploadModal.propTypes = {
  userId: PropTypes.string.isRequired
};

export default UploadModal;
