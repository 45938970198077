import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import editIcon from '../../../../../../assets/images/svg/edit.svg';
import i18n from '../../../../../../i18n';
import { parseFloatNumbersWithOneDigitAfterPoint } from '../../../../../validation';
import Tooltip from '../../../../../../components/Tooltip/index';

const SelectCar = ({ vehicles, selectedVehicle, setSelectedVehicle, submitError }) => {
  const [editMode, setEditMode] = useState(false);
  const [vehicleTitle, setVehicleTitle] = useState('');
  const [isShowTitleError, setIsShowTitleError] = useState(false);
  const [isShowBatteryCapacityError, setIsShowBatteryCapacityError] = useState(false);
  const [batteryCapacity, setBatteryCapacity] = useState('');

  const vehiclesList = vehicles;

  useEffect(() => {
    setVehicleTitle(selectedVehicle?.title);
    setBatteryCapacity(selectedVehicle?.batteryCapacity);
  }, [selectedVehicle]);

  useEffect(() => {
    if (submitError) {
      setEditMode(true);
      setIsShowBatteryCapacityError(true);
    }
  }, [submitError]);

  const saveNewData = () => {
    if (vehicleTitle?.length > 2 && batteryCapacity) {
      setIsShowTitleError(false);
      setEditMode(false);
      setIsShowBatteryCapacityError(false);
      setSelectedVehicle({ ...selectedVehicle, title: vehicleTitle, batteryCapacity });
      vehiclesList.find((vehicle) => vehicle.vin === selectedVehicle.vin).title = vehicleTitle;
      vehiclesList.find((vehicle) => vehicle.vin === selectedVehicle.vin).batteryCapacity = batteryCapacity;
    } else {
      setIsShowTitleError(vehicleTitle?.length <= 2);
      setIsShowBatteryCapacityError(!batteryCapacity);
    }
  };

  const setNewBatteryCapacity = (value) => {
    const newBatteryCapacity = parseFloatNumbersWithOneDigitAfterPoint(value);
    setBatteryCapacity(newBatteryCapacity);
  };

  const discardNewTitle = () => {
    setEditMode(false);
    setVehicleTitle(selectedVehicle.title);
    setBatteryCapacity(selectedVehicle.batteryCapacity);
  };

  return (
    <div className="select-car-wrapper">
      {vehiclesList?.length ? (
        vehiclesList?.map((vehicle) => (
          <div className="vehicle-card" key={vehicle.vin}>
            <div
              className="modern-radio-container"
              onClick={() => {
                setSelectedVehicle(vehicle);
              }}
            >
              <div className={`radio-outer-circle ${vehicle.vin !== selectedVehicle?.vin && 'unselected'}`}>
                <div className={`radio-inner-circle ${vehicle.vin !== selectedVehicle?.vin && 'unselected-circle'}`} />
              </div>
            </div>
            {editMode && vehicle.vin === selectedVehicle?.vin ? (
              <>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column mb-4">
                    <input
                      type="text"
                      className="vehicle-title-input"
                      value={vehicleTitle}
                      onChange={(e) => setVehicleTitle(e.target.value)}
                    />
                    {isShowTitleError && <span className="validation-error">{i18n.t('carNameError')}</span>}
                  </div>

                  <div>
                    <p className="vehicle-title label">
                      {i18n.t('batteryCapacity.label')}
                      :
                    </p>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="vehicle-title-input"
                        value={batteryCapacity}
                        onChange={(e) => setNewBatteryCapacity(e.target.value)}
                      />
                      <Tooltip
                        title={i18n.t('batteryCapacity.tooltip')}
                        placement="left"
                        style={{ right: '80px', top: '5px', position: 'absolute' }}
                      />
                    </div>
                    {isShowBatteryCapacityError && <p className="validation-error">{i18n.t('required')}</p>}
                  </div>
                </div>
                <div className="btn-wrapper">
                  <button type="button" className="save-btn" onClick={saveNewData}>
                    <i className="la la-check" />
                  </button>

                  <button type="button" className="cancel-btn" onClick={discardNewTitle}>
                    <i className="la la-times" />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <span className="vehicle-title">{vehicle.title}</span>
                  {vehicle.vin === selectedVehicle?.vin ? (
                    <div>
                      <span className="vehicle-title sub mr-2">
                        {i18n.t('batteryCapacity.label')}
                        :
                      </span>

                      <span className="vehicle-title">{vehicle.batteryCapacity}</span>
                    </div>
                  ) : null}
                </div>
                <button
                  type="button"
                  className="edit-car-btn"
                  disabled={vehicle.vin !== selectedVehicle?.vin}
                  onClick={() => setEditMode(true)}
                >
                  <img src={editIcon} alt="edit icon" />
                </button>
              </>
            )}
          </div>
        ))
      ) : (
        <span className="no-cars">{i18n.t('noCars')}</span>
      )}
    </div>
  );
};

SelectCar.propTypes = {
  vehicles: PropTypes.instanceOf(Object).isRequired,
  selectedVehicle: PropTypes.instanceOf(Object).isRequired,
  setSelectedVehicle: PropTypes.func.isRequired,
  submitError: PropTypes.bool.isRequired
};

export default SelectCar;
