import { createSelector } from '@reduxjs/toolkit';

import { getSignInUser } from '../../redux-store/selectors/signIn';
import { rolesWithCustomLogo } from './constants';

export const visibleWebSelector = createSelector(
  [getSignInUser],
  (user) => (rolesWithCustomLogo.includes(user?.role?.type)
    ? user?.my_company?.visible_web || user?.oem?.visible_web || user?.employerOem?.visible_web
    : user?.company?.visible_web)
);
