import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, startWith, endWith } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import {
  setNotAttachedToViewerEndUsers,
  setOEMsForViewerList,
  setPVInstallersForViewerList,
  getNotAttachedToViewerEndUsers,
  setLoading,
  getPVInstallersForViewerAllList,
  getOEMsListForViewer
} from './slice';
import { modalStopLoading } from '../ModalWindow/slice';
import i18n from '../../i18n';
import { OEM_URL, USERS_GET_NOT_ATTACHED_TO_VIEWER_END_USERS_URL, USERS_PV_INSTALLER_USERS_URL } from '../../api/apiUrls';

function getNotAttachedEndUsersToViewerEpic($action) {
  return $action.pipe(
    ofType(getNotAttachedToViewerEndUsers),
    map((action) => action.payload),
    switchMap(({ params, modalID }) => (
      from(api.get(`${USERS_GET_NOT_ATTACHED_TO_VIEWER_END_USERS_URL}`, { params })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          const stopLoadingAction = modalID ? [modalStopLoading({ modalID })] : [];

          if (!result?.error) {
            if (Array.isArray(result.list)) {
              const newNoAttachedUsers = result.list.map(({ _id, first_name, last_name, isChecked = false }) => ({ _id, name: `${first_name} ${last_name}`, isChecked }));
              return of(setNotAttachedToViewerEndUsers({ endUsers: newNoAttachedUsers }));
            }

            return of(
              ...stopLoadingAction,
              addNotification({ type: 'error', text: i18n.t('error') })
            );
          }

          return of(
            ...stopLoadingAction,
            addNotification({ type: 'error', text: result?.message })
          );
        }),
        startWith(setLoading({ isNotAttachedUsersLoading: true })),
        endWith(setLoading({ isNotAttachedUsersLoading: false }))
      ))
    )
  );
}

function getOEMsListForViewerEpic($action) {
  return $action.pipe(
    ofType(getOEMsListForViewer),
    map((action) => action.payload),
    switchMap(({ params }) => (
      from(api.get(OEM_URL, { params })).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          if (!result?.error) {
            if (Array.isArray(result?.list)) {
              return setOEMsForViewerList({ oems: result.list });
            }

            return addNotification({ type: 'error', text: i18n.t('error') });
          }

          return addNotification({ type: 'error', text: result?.message });
        }),
        startWith(setLoading({ isOemsListForViewerLoading: true })),
        endWith(setLoading({ isOemsListForViewerLoading: false }))
      ))
    )
  );
}

function getPVInstallersListForViewerEpic($action) {
  return $action.pipe(
    ofType(getPVInstallersForViewerAllList),
    map((action) => action.payload),
    switchMap(({ params }) => (
      from(api.get(USERS_PV_INSTALLER_USERS_URL, { params })).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          if (!result?.error) {
            if (Array.isArray(result.list)) {
              return setPVInstallersForViewerList({ pvInstallers: result.list });
            }

            return addNotification({ type: 'error', text: i18n.t('error') });
          }

          return addNotification({ type: 'error', text: result?.message });
        }),
        startWith(setLoading({ isPvInstallersListForViewerLoading: true })),
        endWith(setLoading({ isPvInstallersListForViewerLoading: false }))
      ))
    )
  );
}

export default combineEpics(
  getNotAttachedEndUsersToViewerEpic,
  getOEMsListForViewerEpic,
  getPVInstallersListForViewerEpic
);
