import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StatusMessage } from '../../components/UIKit';
import { deleteNotification } from './slice';
import { getNotificationsSelector } from '../../redux-store/selectors/notifications';

import './styles.scss';

/**
 * Renders list of notifications
 * @memberof module:Notification
 */
const NotificationList = () => {
  const dispatch = useDispatch();

  const notifications = useSelector(getNotificationsSelector);
  const onClick = (id) => dispatch(deleteNotification(id));

  return (
    <div id="toast-container" className="toast-bottom-right">
      {notifications.map(({ type, text, id }) => <StatusMessage key={id} type={type} text={text} onClick={onClick.bind(null, id)} />)}
    </div>
  );
};

export default NotificationList;
