import { createApi } from '@reduxjs/toolkit/query/react';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { trimDataByRange, parseMinifiedData, fillEmptySpacesByZero } from '../utils';
import { getChartType, getSubTypeRange, batteryDischargingNegation, addNewPoint, removeOldPoints } from '../chartUtils';

import { axiosBaseQuery } from '../../../api_helper';
import { addNotification } from '../../NotificationGenerator/slice';
import { dashboardCurrentValues } from '../slice/currentValues';
import { parseData, updatedActiveDevices as updatedActiveDevicesHelper } from '../utils/activeDevices';
import { updateChart, updateActiveDevices } from '../slice/dashboard';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['initialMainData', 'realTimeData', 'activeDevices'],
  // refetchOnFocus: true,
  endpoints: (builder) => ({
    getInitialMainData: builder.query({
      providesTags: ['initialMainData'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ user, from, to, chartType, resolution }) => {
        const additionalParamsForLowResolution = resolution === 4 ? '&chartResolution=low' : '';
        const chartTypeObjMap = {
          daily: 'month',
          monthly: 'year'
        };

        return ({
          url: `/data/dashboard-points/${user._id}?from=${from.toISOString()}&to=${to.toISOString()}&type=${chartTypeObjMap[chartType] || chartType}${additionalParamsForLowResolution}`,
          method: 'get'
        });
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const response = cloneDeep(data);

          // TODO! research why this is needed
          if (args.user?.flags?.includes('demo') && (args.chartType === 'monthly' || args.chartType === 'daily')) {
            trimDataByRange(response.data, {
              from: args.from,
              to: args.to
            });
          }

          response.data = parseMinifiedData(response.data);
          if (!['monthly', 'daily'].includes(args.chartType)) {
            fillEmptySpacesByZero({
              data: response.data,
              interval: response.interval,
              from: args.from.valueOf(),
              to: args.to.valueOf()
            });
          }

          if (response.data.battery_discharging) {
            response.data.battery_discharging = batteryDischargingNegation(response.data.battery_discharging);
          }

          const expectedScaleMS = moment(args.to).diff(args.from).valueOf();
          const chartType = getChartType(expectedScaleMS);
          const subRange = getSubTypeRange({ from: args.from, to: args.to });
          const isRealTimeData = moment().diff(args.to) <= response.interval && !['y', 'm'].includes(subRange);

          dispatch(updateChart({
            data: response.data,
            dataSum: response.x,
            from: args.from,
            to: args.to,
            isRealTimeData,
            expectedScaleMS,
            chartType,
            subRange,
            interval: response.interval,
            isToday: args.isToday,
            staticFrom: args.from,
            shadowData: response.data
          }));
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    }),
    getActiveDevices: builder.query({
      providesTags: ['activeDevices'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ gatewayId, from, to }) => ({ url: `/dashboard/active-devices?gatewayId=${gatewayId}&from=${from.toISOString()}&to=${to.toISOString()}`, method: 'get' }),
      transformResponse: (devices, _meta, args) => parseData(devices, args.from.valueOf(), args.to.valueOf(), args.settings),
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateActiveDevices(data, args.from.valueOf(), args.to.valueOf(), args.settings));
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    }),
    getRealTimeData: builder.query({
      providesTags: ['realTimeData'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ gatewayId, mySelf }) => {
        const url = mySelf ? '/data/real-time' : `data/real-time/${gatewayId}`;
        return ({ url, method: 'get', params: { isWeb: true } });
      },
      async onQueryStarted(_, { queryFulfilled, dispatch, getState }) {
        try {
          const { data: realTimeData } = await queryFulfilled;
          const { chart: oldChart, user, activeDevices } = getState().dashboardV2;
          const data = cloneDeep(realTimeData);
          const { newPoint, sensorsData, active_devices } = data?.rawSensorData || {};

          if (!newPoint) {
            //! we don't have to update chart if there is no new point in case if user has no data;
            return;
          }

          const temperatures = Array.isArray(sensorsData)
            ? sensorsData
              .filter((sensorData) => !Number.isNaN(sensorData?.currentWaterTemp))
              .reduce((obj, sensorData) => {
                obj[sensorData?._id] = sensorData.currentWaterTemp;
                return obj;
              }, {})
            : [];

          const isRealTimeCondition = oldChart.isRealTimeData
            && data?.isStreamActive
            && !['monthly', 'daily'].includes(oldChart.chartType)
            && moment(newPoint.timestamp).isAfter(oldChart.to);
          // update chart if difference between new point and old chart.to is more than chart interval
          const isNeedToUpdChart = Math.abs(moment(newPoint.timestamp).diff(oldChart.to)) >= oldChart.interval;

          if (isRealTimeCondition && oldChart.data) {
            const battery = user?.chart_settings?.additional_battery_curve;

            const chartData = cloneDeep(oldChart.data);
            const dataSumClone = cloneDeep(oldChart.dataSum);
            const shadowData = cloneDeep(oldChart.shadowData || {});
            const activeDevicesClone = cloneDeep(activeDevices);

            const expectedTo = newPoint.timestamp;
            let expectedFrom = expectedTo - oldChart.expectedScaleMS;

            if (oldChart.isToday) {
              expectedFrom = oldChart.from.valueOf();
            }

            if (oldChart.isToday && !moment().isSame(moment(expectedFrom), 'day')) {
              expectedFrom = moment().startOf('day').valueOf();
            }

            const newData = { data: chartData, dataSum: dataSumClone };
            const newShadowData = cloneDeep({ data: shadowData, dataSum: dataSumClone });

            addNewPoint(newShadowData, data?.rawSensorData, { battery });
            removeOldPoints(newShadowData, expectedFrom);

            if (isNeedToUpdChart) {
              addNewPoint(newData, data?.rawSensorData, { battery });
              removeOldPoints(newData, expectedFrom);

              dispatch(updateChart({
                ...oldChart,
                data: newData.data,
                dataSum: newShadowData.dataSum,
                from: expectedFrom,
                to: expectedTo,
                shadowData: newShadowData.data
              }));

              if (activeDevicesClone.activity && activeDevicesClone.devices) {
                const newActiveDevices = updatedActiveDevicesHelper(
                  newPoint.timestamp,
                  active_devices,
                  expectedFrom,
                  activeDevicesClone.activity,
                  activeDevicesClone.devices
                );
                dispatch(updateActiveDevices(newActiveDevices));
              }
            }

            if (!isNeedToUpdChart) {
              dispatch(updateChart({ dataSum: newShadowData.dataSum, shadowData: newShadowData.data }));
            }
          }

          //! upd current values
          dispatch(dashboardCurrentValues({ ...newPoint, temperatures }));
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error?.message }));
        }
      }
    })
  })

});

export const {
  useLazyGetInitialMainDataQuery,
  useLazyGetActiveDevicesQuery,
  useGetRealTimeDataQuery
} = dashboardApi;
