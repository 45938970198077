import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { delay, map, switchMap } from 'rxjs/operators';

import { deleteNotification, addNotification } from './slice';

function addNotificationEpic(action$) {
  return action$.pipe(
    ofType(addNotification),
    map((action) => action.payload.id || action.payload._id),
    delay(5000),
    switchMap((id) => (of(deleteNotification(id))))
  );
}

export default combineEpics(
  addNotificationEpic
);
