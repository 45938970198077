import clone from 'lodash/clone';

/**
 * @module SubUsersTable
 */
const transformResponse = (data) => {
  const clonedData = clone(data.list);
  const user = {
    first_name: 'Unknown User',
    last_name: '',
    sm_id: 'Unknown Id',
    id: 'Unknown Id',
    _id: 'Unknown Id'
  };

  clonedData.forEach((obj) => {
    if (!obj.user) {
      obj.user = user;
    }
  });

  return {
    total: data.total,
    data: clonedData
  };
};

export default transformResponse;
