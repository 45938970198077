import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { required, email, parseToLowerCase } from '../../validation';
import { CustomTextInput } from '../../../components/ReduxFormFields';

import i18n from '../../../i18n';

import '../../../App.css';

/**
 * @module SignUp
 */
/**
 * @description renders page to change email
 * @memberof module:SignUp
 * @extends Component
 */
const ChangeEmail = (props) => {
  const { handleSubmit, submitting, pristine } = props;

  return (
    <>
      <form className="m-login__form m-form">
        <div className="form-group m-form__group input-field">
          <Field
            name="old_email"
            component={CustomTextInput}
            label={i18n.t('prevMail')}
            className="m-input"
            disabled
          />
        </div>
        <div className="form-group m-form__group input-field">
          <Field
            name="new_email"
            component={CustomTextInput}
            label={i18n.t('reqNewMail')}
            className="m-input"
            autoComplete="off"
            validate={[required, email]}
            parse={parseToLowerCase}
          />
        </div>
        <div className="m-login__form-action">
          <button disabled={pristine || submitting} onClick={handleSubmit} type="submit" id="m_login_signin_submit" className="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn m-login__btn--primary">
            {i18n.t('resendBtn')}
          </button>
        </div>
      </form>
      <div className="m-login__account">
        <Link to="/login" id="m_login_signup" className="m-link m-link--light m-login__account-link">
          {i18n.t('haveAcc')}
        </Link>
      </div>
    </>
  );
};

ChangeEmail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default reduxForm({
  form: 'ChangeEmailForm'
})(ChangeEmail);
