import fieldsForDevices from './fieldsForDevices';
import { hideIPField } from './constants';

/**
 * Checks if device has IP field
 * @function
 * @memberof module:UserDevices
 * @param {string} deviceName Name of devices (which fields to render).
 * @returns {boolean}
 */
const hasIPField = (deviceName) => (
  !fieldsForDevices[deviceName] || fieldsForDevices[deviceName].indexOf(hideIPField) === -1
);

export default hasIPField;
