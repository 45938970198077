import moment from 'moment-timezone';
import { DATEPICKER_MASK } from '../constant';

/**
   * Formats the value from the Redux store to be displayed in the field input.
   * @param {number|string} value timestamp, date string
   * @param {string} name redux form field name
   * @returns {number|string}
   */
const formatDate = (value) => {
  if (Number.isInteger(value) && moment(value).isValid()) {
    return moment(value).format(DATEPICKER_MASK);
  }
  return value;
};

export default formatDate;
