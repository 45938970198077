import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';

import { api } from '../../api';
import { handleError } from '../../api_helper';
import { addNotification } from '../NotificationGenerator/slice';
import { AUTH_FORGOT_PASSWORD_URL } from '../../api/apiUrls';
import {
  forgotPasswordReceived,
  forgotPasswordCanceled,
  forgotPasswordSend
} from './slice';

import i18n from '../../i18n';

function sendForgotPasswordEpic(action$) {
  return action$.pipe(
    ofType(forgotPasswordSend),
    map((action) => action.payload),
    switchMap(({ fields: { email, language } }) => (
      from(api.post(AUTH_FORGOT_PASSWORD_URL, { email }, { language })).pipe(
        catchError(handleError),
        map((result) => {
          if (result?.status) {
            return forgotPasswordReceived({ email: result.email, verifyEmail: result.verifyEmail });
          }

          return forgotPasswordCanceled({ message: result });
        }
        )
      )
    ))
  );
}

function receiveForgotPasswordEpic(action$) {
  return action$.pipe(
    ofType(forgotPasswordReceived),
    switchMap(() => of(addNotification({ type: 'success', text: i18n.t('messageWithListSent') })))
  );
}

function cancelForgotPasswordEpic(action$) {
  return action$.pipe(
    ofType(forgotPasswordCanceled),
    map((action) => action.payload.message),
    switchMap((message) => of(addNotification({ type: 'error', text: message })))
  );
}

export default combineEpics(
  sendForgotPasswordEpic,
  receiveForgotPasswordEpic,
  cancelForgotPasswordEpic
);
