import {
  ONE_DAY_MS,
  ONE_HOUR_MS
} from '../constants';

/**
 *
 * @description Get grouping data for react highcharts
 * https://api.highcharts.com/highstock/plotOptions.series.dataGrouping
 * @param {object} params
 * @param {number} params.from start date, timestamp
 * @param {number} params.to end date, timestamp
 * @param {number} params.groupPixelWidth end date, timestamp
 * @returns {object[]}
 */
const getGroupingData = ({ from, to, groupPixelWidth }) => {
  const startDate = new Date(null);

  if ((Number.isNaN(from) || from <= startDate) || (Number.isNaN(to) || from <= startDate) || Number.isNaN(groupPixelWidth)) {
    return undefined;
  }

  const range = Math.abs(to - from);

  if (groupPixelWidth !== 4) {
    return {
      enabled: true,
      forced: false,
      units: undefined,
      groupPixelWidth
    };
  }

  if (range <= ONE_HOUR_MS) {
    return {
      enabled: true,
      forced: false,
      units: undefined,
      groupPixelWidth
    };
  }

  // Today, Day, Range > 1 hour - 1 day <=
  if (range > ONE_HOUR_MS && range <= ONE_DAY_MS) {
    return {
      enabled: true,
      forced: true,
      units: [['minute', [5]]]
    };
  }

  // Week, Range > 1 day - 7 days <=
  return {
    enabled: true,
    forced: true,
    units: [['minute', [15]]]
  };
};

export default getGroupingData;
