import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { changeSMID, CHANGE_SMID_FORM } from '../constants';
import { required, minLength, maxLength } from '../../validation';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsChangeSMIDSelector } from '../../../redux-store/selectors/modals';
import { CustomTextInput } from '../../../components/ReduxFormFields';
import { CHANGE_SMID_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

/**
 * Modal window for changin email from edit profile page.
 * @memberof module:SettingProfile
 */
const ChangeSMIdWindow = (props) => {
  const { handleSubmit, pristine, openScanQR, reset } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getModalsChangeSMIDSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: CHANGE_SMID_MODAL_ID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={CHANGE_SMID_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('changeSMID')}</h5>
      </div>
      <div className="modal-body">
        <form
          id={changeSMID}
          onSubmit={handleSubmit}
          className="m-login__form m-form pop-up-form add-sm-us"
        >
          <div className="form-group m-form__group input-field" style={{ position: 'relative' }}>
            <Field
              name="sm_id"
              component={CustomTextInput}
              label={i18n.t('reqSMID')}
              className="m-input"
              validate={[required, minLen3, maxLen50]}
            />
            <a
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right search-ip-btn"
              onClick={openScanQR}
            >
              {i18n.t('QRLink')}
            </a>
          </div>
          <div className="row justify-content-between data_SMID_container">{i18n.t('existingDataOver')}</div>
        </form>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <PrimaryButton form={changeSMID} disabled={pristine} isSubmit i18nLabel="changeBtn" type="save" />
      </div>
    </ModalWindow>
  );
};

ChangeSMIdWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  openScanQR: PropTypes.func.isRequired
};

const form = reduxForm({
  form: CHANGE_SMID_FORM
})(ChangeSMIdWindow);

export default form;
