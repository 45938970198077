import { formatDateTime } from '../../../utils';

/**
 * Render function for React-Table. Column of formatted date and time
 * @memberof module:ReactTable
 * @param  {(timestamp|dateString)} value - Time and Date
 */
const RenderDate = ({ value }) => formatDateTime(value);

export default RenderDate;
