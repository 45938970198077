import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { RadioButtons } from '../../../../../../components/ReduxFormFields';
import './index.scss';

const SupportedBrands = ({ setSelectedBrand, carBrandsList = [] }) => {
  const selectedBrandId = useSelector(getFormValues('supportedBrandsForm'))?.brand;
  const selectedBrand = carBrandsList?.find((brand) => brand.value === selectedBrandId);

  useEffect(() => {
    setSelectedBrand(selectedBrand);
  }, [setSelectedBrand, selectedBrand]);

  return (
    <div className="supported-brands-wrapper">
      <form id="supportedBrandsForm">
        <Field
          name="brand"
          component={RadioButtons}
          props={{
            defaultValue: '',
            variants: carBrandsList
          }}
        />
      </form>
    </div>
  );
};

SupportedBrands.propTypes = {
  carBrandsList: PropTypes.instanceOf(Object),
  setSelectedBrand: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'supportedBrandsForm'
})(SupportedBrands);

export default form;
