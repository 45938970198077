/**
 * Filters data by range
 * @param {object} data
 * @param {number} from timestamp
 * @param {number} to timestamp
 * @returns {array}
 */
const filterDataByRange = (data, from, to) => data.filter(([timestamp]) => from >= timestamp && to <= timestamp);

/**
 * Filters sensors data by range
 * @param {object} data
 * @param {number} from timestamp
 * @param {number} to timestamp
 * @returns {object}
 */
// eslint-disable-next-line arrow-body-style
const filterSensorsDataByRange = (data, from, to) => {
  return Object.entries(data).reduce((filteredData, [key, values]) => {
    filteredData[key] = filterDataByRange(values, from, to);

    return filteredData;
  }, {});
};

/**
 * Trims data by valid range
 * @param {object} data
 * @param {object} range
 * @param {number} range.from timestamp
 * @param {number} range.to timestamp
 * @returns {object}
 */
const trimDataByRange = (data, range) => {
  const {
    from,
    to
  } = range;

  const filteredData = Object.entries(data)
    .reduce((result, [key, values]) => {
      switch (key) {
        case 'main':
          result[key] = filterDataByRange(values, from, to);
          break;

        case 'temperatures':
          result[key] = filterSensorsDataByRange(values, from, to);
          break;

        case 'sensors':
          result[key] = Object.entries(values).reduce((sensors, [type, valuesByType]) => {
            sensors[type] = filterSensorsDataByRange(valuesByType, from, to);
            return sensors;
          }, {});
          break;

        default:
          result[key] = values;
          break;
      }

      return result;
    }, {});

  return filteredData;
};

export default trimDataByRange;
