import React from 'react';

import PropTypes from 'prop-types';
import { checkNotNumber } from '../../../containers/validation';
import { CustomTextInput } from '.';
import { isDeutsch } from '../../../utils/isDeutsch';

const isDotKeyCode = (keyCode) => keyCode === 190 || keyCode === 110 || keyCode === 188;

export const CustomTextInputHoc = (props) => {
  const { isInteger, isFloat, input, ...rest } = props;

  const onKeyDown = (e) => {
    const checkIsInteger = isInteger && checkNotNumber(e.key);
    const checkIsFloat = isFloat && (!isDotKeyCode(e.keyCode) && checkNotNumber(e.key));
    if (checkIsInteger || checkIsFloat) {
      e.preventDefault();
    }
  };

  if (isFloat && input?.value && isDeutsch()) {
    input.value = String(input.value).replace('.', ',');
  }

  return <CustomTextInput {...rest} input={input} onKeyDown={onKeyDown} />;
};

CustomTextInputHoc.propTypes = {
  isFloat: PropTypes.bool,
  isInteger: PropTypes.bool,
  input: PropTypes.instanceOf(Object)
};
