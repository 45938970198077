import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';

import './index.scss';

/**
 * Renders Search Field for tables
 * @param  {function} onChange
 * @param  {string} searchByFields
 * @param  {string} placeholder - placeholder of search input
 * @param  {string|boolean} singleSearch
 */
const SearchField = (props) => {
  const { onChange, searchByFields, defaultValue, placeholder, singleSearch = false, classes = '' } = props;
  const ref = useRef(null);

  const debounceInputChange = (event) => {
    const newValue = event.target.value.replace(/\s+/g, ' ');

    const valueToFilters = singleSearch
      ? { [singleSearch]: newValue }
      : { fields: newValue === '' ? '' : searchByFields, search: newValue };

    onChange(valueToFilters);
  };

  return (
    <div className={`form-group m-form__group row align-items-center ${classes}`}>
      <div className="m-input-icon m-input-icon--left col-md-12" id="searchWrapper">
        <DebounceInput
          inputRef={ref}
          className="form-control m-input m-input--solid search"
          placeholder={placeholder}
          id="generalSearch"
          minLength={3}
          value={(defaultValue !== undefined && ref.current?.value) || defaultValue || ''}
          debounceTimeout={300}
          onChange={debounceInputChange}
        />
        <span className="m-input-icon__icon m-input-icon__icon--left search">
          <span><i className="la la-search" /></span>
        </span>
      </div>
    </div>
  );
};

SearchField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  searchByFields: PropTypes.string,
  classes: PropTypes.string,
  singleSearch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};

export default SearchField;
