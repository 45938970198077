const replace = {
  changeSMID: "Modifier l'ID du 3S OEM",
  reqSMID: '3S ID *',
  SMID: '3S ID',
  SMIDTolltip: 'L\'ID 3S est un identifiant unique de votre 3S. Vous trouverez cet identifiant sur l\'appareil sous le code QR.',
  enableSmId: '3S ID',
  smidLabel: '3S ID',
  confirmDeletingDesc: 'Confirmez la suppression de toutes les données de cette passerelle en entrant dans le 3S ID. Après confirmation, ce processus ne peut pas être inversé.',
  confirmDeletingError: 'L\'ID ne correspond pas à l\'ID du 3S. La passerelle n\'est pas supprimée.'
};

export default replace;
