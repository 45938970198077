import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from '../../Tooltip';
import { useForceUpdate } from '../../../hooks';

import i18n from '../../../i18n';

import './index.scss';
import { openModalWindow, SEARCH_CHARGERS_MODAL_ID } from '../../../containers/ModalWindow/slice';
import { getUserDevicesUserSelector } from '../../../redux-store/selectors/userDevices';

import { removeChargers, scanChargers } from '../../../containers/UserDetails/slice';
import { getSignInUserRoleTypeSelector, getSignInUserSelector } from '../../../redux-store/selectors/signIn';

const isChrome = window?.browser?.name === 'Chrome';

/**
 * Renders text input for redux form
 * @memberof module:ReduxFormFields
 */
export const SearchChargersField = (props) => {
  const {
    input,
    label,
    showTooltip = '',
    placement,
    meta: { touched, error, form },
    preventAuto,
    className,
    defaultValue,
    languageToSync = '',
    isRequired,
    ...custom
  } = props;
  const [timeout, setNewTimeout] = useState(null);
  const myInputRef = useRef();
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();

  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const user = useSelector(getUserDevicesUserSelector);
  const signInUser = useSelector(getSignInUserSelector);
  const smid = myRoleType === 'end_user' ? signInUser.gateway.sm_id : user.gateway.sm_id;

  const onClickSearchHandler = () => {
    dispatch(openModalWindow({ modalID: SEARCH_CHARGERS_MODAL_ID, data: { form } }));
    dispatch(removeChargers());
    dispatch(scanChargers(smid));
  };

  useEffect(() => {
    if (isChrome) {
      setNewTimeout(setTimeout(() => forceUpdate(), 1000));
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labelProps = {
    className: `inputLabel${isRequired ? ' required' : ''}`,
    ...(isChrome && myInputRef?.current?.matches(':-webkit-autofill')) ? {
      shrink: true
    } : {}
  };

  return (
    <div className="ocppCharderField">
      {!!showTooltip && (
        <div className="switch-tooltip">
          <Tooltip title={i18n.t(showTooltip)} placement="right" />
        </div>
      )}
      <TextField
        variant="standard"
        inputRef={myInputRef}
        label={label}
        defaultValue={defaultValue}
        {...input}
        {...custom}
        classes={{
          root: `customTextField ${className}`
        }}
        InputProps={{
          classes: {
            root: 'inputRoot',
            input: 'inputField'
          }
        }}
        inputProps={
          preventAuto
            ? {
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: 'off'
            }
            : {}
        }
        InputLabelProps={labelProps}
      />
      {touched && (error || languageToSync) && (
        <div className="inputCustomWarning">
          {i18n.t(error)}
        </div>
      )}
      <button
        className="searchChargersBtn btn m-btn--pill m-btn--air btn-secondary btn-table-inst"
        type="button"
        onClick={onClickSearchHandler}
      >
        {i18n.t('search')}
      </button>
    </div>
  );
};

SearchChargersField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  placement: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.PropTypes.instanceOf(Object)
  ]).isRequired,
  showTooltip: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  languageToSync: PropTypes.string,
  preventAuto: PropTypes.bool,
  isRequired: PropTypes.bool
};
