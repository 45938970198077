import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  notificationsValue: null
};

const monitoringNotificationsSlice = createSlice({
  name: 'monitoringNotifications',
  initialState,
  reducers: {
    sendRequest: epicEmptyFunction,
    receiveNotificationInfo: (state, action) => {
      state.notificationsValue = action.payload.data;
    }
  }
});

export const {
  receiveNotificationInfo,
  sendRequest
} = monitoringNotificationsSlice.actions;

export default monitoringNotificationsSlice.reducer;
