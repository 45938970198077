import React from 'react';
import Tooltip from '../../Tooltip';
/**
 * Render function for React-Table. Header of column with Tooltip
 * @memberof module:ReactTable
 * @param  {string} headerText - Text of header
 * @param  {string} tooltipText - Information, which will be shown, when hovering
 * @param  {string} placement - Placement of tooltip
 * @param  {string} style - Additional styles for tooltip
 */
const RenderHeaderWithTooltip = ({
  headerText, tooltipText, placement, style
}) => () => (
  <div>
    {headerText}
    <Tooltip
      title={tooltipText}
      placement={placement}
      style={style}
      tooltipInsideRowHeader
    />
  </div>
);

export default RenderHeaderWithTooltip;
