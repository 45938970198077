import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import inverterIcon from '../../../../../assets/images/svg/inverter.svg';

const InverterTooltip = (props) => {
  const { currentPowerInvSm } = props;

  return (
    <>
      <img src={inverterIcon} alt="Inverter Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {currentPowerInvSm || defaultValue}
          {i18n.t('w')}
        </span>
      </div>
    </>
  );
};

InverterTooltip.propTypes = {
  currentPowerInvSm: PropTypes.number
};

export default InverterTooltip;
