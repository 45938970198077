import { createSelector } from '@reduxjs/toolkit';

const getSignUpSuccessfull = (state) => state.signUp.sendSuccessfull;
const getSignUpResendEmail = (state) => state.signUp.resendEmail;
const getSignUpChangeEmail = (state) => state.signUp.changeEmail;
const getSignUpIsLoading = (state) => state.signUp.isLoading;
const getSignUpEmail = (state) => state.signUp.email;

export const getSignUpSuccessfullSelector = createSelector([getSignUpSuccessfull], (sendSuccessfull) => sendSuccessfull);
export const getSignUpResendEmailSelector = createSelector([getSignUpResendEmail], (resendEmail) => resendEmail);
export const getSignUpChangeEmailSelector = createSelector([getSignUpChangeEmail], (changeEmail) => changeEmail);
export const getSignUpIsLoadingSelector = createSelector([getSignUpIsLoading], (isLoading) => !!isLoading);
export const getSignUpEmailSelector = createSelector([getSignUpEmail], (email) => email);
