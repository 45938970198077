import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '../../../../components';

import './index.scss';

const Logo = ({ url, name }) => (
  <div className="row justify-content-center">
    <Avatar className="big" name={name} avatar={url} />
  </div>
);

Logo.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  name: PropTypes.string.isRequired
};

export default Logo;
