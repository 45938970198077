import React from 'react';
import PropTypes from 'prop-types';

import Info from './Info';
import Email from './Email';
import Status from './Status';
import './Value.scss';

const Value = ({ value, type = '' }) => {
  let element = null;
  switch (type) {
    case 'email':
      element = (<Email email={value} />);
      break;
    case 'status':
      element = (<Status status={value} />);
      break;
    default:
      element = (<Info value={value} />);
      break;
  }
  return (
    <td className="m-datatable__cell">
      {element}
    </td>
  );
};

Value.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  type: PropTypes.string
};

export default Value;
