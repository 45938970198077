import {
  RenderSignal,
  RenderButtons,
  RenderDevicesError,
  RenderDevicesErrorDetails,
  RenderHeaderWithTooltip,
  RenderSensorData
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';
import { EditColumns } from '../../../components';

/**
 * Creates smart-meter columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 */
const smartMeter = (myRoleType, isReadOnly, width = {}, columns = {}) => ([
  {
    sortable: false,
    width: 35
  },
  {
    Header: i18n.t('name'),
    id: 'device_group',
    accessor: (d) => i18n.t(d.device_group),
    minWidth: width.device_group,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('tag'),
      tooltipText: i18n.t('tagFieldTooltip'),
      placement: 'right-start',
      style: {
        verticalAlign: 'middle',
        marginLeft: '6px'
      }
    }),
    id: 'tag',
    accessor: 'tag.name',
    minWidth: width.tag,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('IP'),
    accessor: 'ip',
    id: 'ip',
    minWidth: width.ip,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType) && columns.ip
  },
  {
    Header: i18n.t('MAC'),
    accessor: 'mac',
    id: 'mac',
    width: width.mac,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'pv_installer',
      'end_user',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType) && columns.mac
  },
  {
    Header: i18n.t('signal'),
    accessor: 'signal',
    id: 'signal',
    minWidth: width.signal,
    Cell: RenderSignal,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType) && columns.signal
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('error'),
      tooltipText: i18n.t('errorsHintTooltip'),
      placement: 'right-start',
      style: {
        verticalAlign: 'middle',
        marginLeft: '6px'
      }
    }),
    accessor: 'errorcodes',
    id: 'errorcodes',
    minWidth: width.errorcodes,
    Cell: RenderDevicesError,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType) && columns.errorcodes
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('errorDetails'),
      tooltipText: i18n.t('errorsHintTooltip'),
      placement: 'right-start',
      style: {
        verticalAlign: 'middle',
        marginLeft: '6px'
      }
    }),
    accessor: 'errorDetails',
    Cell: RenderDevicesErrorDetails,
    minWidth: width.errorDetails,
    filterable: false,
    sortable: false,
    show: !['end_user'].includes(myRoleType) && columns.errorDetails
  },
  {
    Header: i18n.t('data'),
    accessor: 'sensors_data',
    id: 'sensors_data',
    minWidth: width.sensors_data,
    Cell: (args) => RenderSensorData({ ...args }),
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: EditColumns,
    accessor: 'buttons',
    id: 'buttons',
    width: !isReadOnly ? width.buttons : undefined,
    className: 'action-buttons',
    // eslint-disable-next-line arrow-body-style
    Cell: (row) => {
      return RenderButtons(row.original, {
        editRow: [
          'root',
          'solar_admin',
          'property_management',
          'oem',
          'pv_installer',
          'pv_installer_employee',
          'pv_installer_employee_read_install',
          'oem_employee',
          'end_user',
          'viewer'
        ].includes(myRoleType) && !isReadOnly,
        removeDevice: [
          'root',
          'solar_admin',
          'oem',
          'property_management',
          'pv_installer',
          'pv_installer_employee',
          'pv_installer_employee_read_install',
          'oem_employee',
          'end_user',
          'viewer'
        ].includes(myRoleType) && !isReadOnly
      });
    },
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  }
]);

export default smartMeter;
