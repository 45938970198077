import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import { useDispatch } from 'react-redux';
import { DataListHocForDevicesTable } from '../../../DataList';

import columns from '../../columns';

import { SENSORS_LIST_ID } from '../../../UserDetails/constants';

import i18n from '../../../../i18n';
import { openModalWindow, UPGRADE_TO_UNLOCK_POPUP } from '../../../ModalWindow/slice';

/**
 * @module DevicesTable
 */
/**
 * Input Devices tab from DevicesTable
 * @memberof module:DevicesTable
 */
const InputDevicesTab = (props) => {
  const {
    myRoleType,
    userId,
    total,
    defaultParams,
    transformResponse,
    devicesUrl,
    openAddDevices,
    widthSetting,
    columnsSetting,
    isReadOnlyPermission,
    license
  } = props;

  const dispatch = useDispatch();

  const addInputDeviceHandler = () => {
    if (license?.restrictions?.allowedDeviceTypes?.length && !license.restrictions.allowedDeviceTypes.includes('Input Device')) {
      dispatch(openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));
    } else {
      openAddDevices();
    }
  };

  return (
    <>
      {['viewer', 'end_user', 'pv_installer_employee_read_only', 'oem_employee_read_only'].includes(myRoleType) || isReadOnlyPermission ? null : (
        <div className="devices-tab-panel col-auto">
          <a
            onClick={addInputDeviceHandler}
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right"
          >
            <span>{i18n.t('addInputDevice')}</span>
          </a>
        </div>
      )}
      <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
        <DataListHocForDevicesTable
          tab="input-devices"
          listID={SENSORS_LIST_ID}
          listURL={devicesUrl}
          params={defaultParams}
          transformResponse={transformResponse}
          Component={ReactTable}
          componentProps={{
            defaultPageSize: 100,
            className: '-highlight m-datatable__table',
            columns: columns(myRoleType, userId, 'input-devices', total, isReadOnlyPermission, widthSetting, columnsSetting),
            showPagination: false,
            sorted: []
          }}
        />
      </div>
    </>
  );
};

InputDevicesTab.propTypes = {
  myRoleType: PropTypes.string,
  userId: PropTypes.string,
  devicesUrl: PropTypes.string,
  total: PropTypes.number,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  openAddDevices: PropTypes.func,
  transformResponse: PropTypes.func.isRequired,
  widthSetting: PropTypes.instanceOf(Object),
  columnsSetting: PropTypes.instanceOf(Object),
  license: PropTypes.instanceOf(Object),
  isReadOnlyPermission: PropTypes.bool.isRequired
};

export default InputDevicesTab;
