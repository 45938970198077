import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsConfirmationWindowDataSelector } from '../../../redux-store/selectors/modals';
import { CONFIRMATION_WINDOW_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

/**
 * @memberof module:SettingProfile
 */
const ConfirmationWindow = (props) => {
  const { onSubmit, changeSMIdForm = '' } = props;
  const dispatch = useDispatch();

  const data = useSelector(getModalsConfirmationWindowDataSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: CONFIRMATION_WINDOW_MODAL_ID, data: changeSMIdForm }));

  return (
    <ModalWindow modalID={CONFIRMATION_WINDOW_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('changeSMID')}</h5>
      </div>
      <div className="modal-body">
        <div className="text-confirmation">{i18n.t('confirmDelData')}</div>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <PrimaryButton onClickHandler={onSubmit.bind(null, data)} type="delete" />
      </div>
    </ModalWindow>
  );
};

ConfirmationWindow.propTypes = {
  changeSMIdForm: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default ConfirmationWindow;
