import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for signal of device.
 * @memberof module:ReactTable
 * @param  {string} value - conection signal
 */
const RenderSignal = ({ value }) => (
  (value === 'connected') ? (
    <>
      <span className="m-badge m-badge--success m-badge--dot" />
      &nbsp;
      <span className="m--font-bold signal m--font-success">
        {i18n.t(value)}
      </span>
    </>
  ) : (
    <>
      <span className="m-badge m-badge--danger m-badge--dot" />
      &nbsp;
      <span className="m--font-bold signal m--font-danger">
        {i18n.t(value)}
      </span>
    </>
  )
);

RenderSignal.propTypes = {
  value: PropTypes.string
};

export default RenderSignal;
