import { EMPTY, from, of } from 'rxjs';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

import { handleError, trimAllFields } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import {
  signUpSend,
  resendToEmail,
  receiveSignUp,
  sendEmailChange,
  sendEmailChangeSucc,
  reveivedInfoFromServer
} from './slice';
import { SIGNUP_CHANGE_EMAIL_URL, SIGNUP_PV_INSTALLER_URL, SIGNUP_RESEND_VERIFY_URL, SIGNUP_USER_URL } from '../../api/apiUrls';

function sendSignUpEpic(action$) {
  return action$.pipe(
    ofType(signUpSend),
    map((action) => action.payload),
    switchMap((props) => {
      const { type, language, fields } = props;
      let url;
      if (type === 'user') {
        url = SIGNUP_USER_URL;
      } else if (type === 'company') {
        url = SIGNUP_PV_INSTALLER_URL;
      }

      return url
        ? from(api.post(url, { ...trimAllFields(fields) }, { params: { language } })).pipe(
          catchError(handleError),
          mergeMap((result) => {
            if (result?.email) {
              return of(
                reveivedInfoFromServer(),
                receiveSignUp({ email: result.email })
              );
            }

            return of(
              reveivedInfoFromServer(),
              addNotification({ type: 'error', text: result })
            );
          })
        )
        : EMPTY;
    })
  );
}

function sendSignUpChangeEmail(action$) {
  return action$.pipe(
    ofType(sendEmailChange),
    map((action) => action.payload),
    switchMap(({ values, language }) => (
      from(api.post(SIGNUP_CHANGE_EMAIL_URL, { ...trimAllFields(values) }, { language }))
        .pipe(
          catchError(handleError),
          map((result) => {
            if (result?.email) {
              return sendEmailChangeSucc({ email: result.email, verifyEmail: result.verifyEmail });
            }

            return addNotification({ type: 'error', text: result });
          })
        )
    ))
  );
}

function reSendSignUp(action$) {
  return action$.pipe(
    ofType(resendToEmail),
    map((action) => action.payload),
    switchMap(({ email, language }) => (
      from(api.post(SIGNUP_RESEND_VERIFY_URL, { email }, { language }))
        .pipe(
          catchError(handleError),
          map((result) => {
            if (result?.email) {
              return null;
            }

            return addNotification({ type: 'error', text: result });
          })
        )
    ))
  );
}

export default combineEpics(sendSignUpEpic, sendSignUpChangeEmail, reSendSignUp);
