import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../api_helper';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['paymentMethod'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getPaymentMethod: builder.query({
      query: ({ userID }) => ({
        url: `/payments/token${userID ? `/${userID}` : ''}`,
        method: 'get'
      }),
      providesTags: ['paymentMethod']
    }),
    updatePaymentMethod: builder.mutation({
      refetchOnMountOrArgChange: false,
      query: ({ userID }) => ({
        url: `/payments/token${userID ? `/${userID}` : ''}`,
        method: 'post',
        data: { redirectUrl: window.location.href }
      }),
      invalidatesTags: ['paymentMethod']
    })
  })
});

export const {
  useUpdatePaymentMethodMutation,
  useGetPaymentMethodQuery
} = paymentsApi;
