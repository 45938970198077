import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import {
  settingPrGetUser,
  settingPrChange,
  settingPrChangeModal,
  settingPrGetUserSucc,
  resendToEmail
} from './slice';
import { ChangePassWindow, ChangeEmailWindow, ChangeSMIdWindow, ConfirmationWindow } from './modals';
import { openModalWindow, closeModalWindow, modalWindowSend, CHANGE_SMID_MODAL_ID, CHANGE_EMAIL_MODAL_ID, QR_CODE_SCANNER_MODAL_ID, CHANGE_PASSWORD_MODAL_ID, CONFIRMATION_WINDOW_MODAL_ID } from '../ModalWindow/slice';
import { GetLayout } from './forms/RenderSettingByUserRole';
import { getInitialValues } from './utils';
import { transformToFormData } from '../../api_helper';

import { Preloader } from '../../components';
import i18n, { transformLanguageForAPI } from '../../i18n';
import { CHANGE_EMAIL_FORM, CHANGE_SMID_FORM } from './constants';
import { USER_UPDATE_URL } from '../../api/apiUrls';
import { QRCodeScannerModalWindow } from '../../modals';

import { getSignInUserSelector } from '../../redux-store/selectors/signIn';
import { getSettingProfileInitialDataUserSelector, getSettingProfileIsLoadingSelector } from '../../redux-store/selectors/settingProfile';
import { getModalsConfirmationWindowDataSelector } from '../../redux-store/selectors/modals';

import './index.scss';
import '../../App.css';

const emailFormSelector = formValueSelector(CHANGE_EMAIL_FORM);
const smidFormSelector = formValueSelector(CHANGE_SMID_FORM);

/**
 * Setting profile page.
 * @module SettingProfile
 */
/**
 * Main component of setting profile page
 * @memberof module:SettingProfile
 */
const SettingProfile = (props) => {
  const { myRoleType, match, history } = props;
  const dispatch = useDispatch();

  const myUser = useSelector(getSignInUserSelector);
  const isLoading = useSelector(getSettingProfileIsLoadingSelector);
  const sm_id_data = useSelector(getModalsConfirmationWindowDataSelector);
  const initialData = useSelector(getSettingProfileInitialDataUserSelector);
  const userEmail = useSelector((state) => emailFormSelector(state, 'old_email'));
  const changeSMIdForm = useSelector((state) => smidFormSelector(state, CHANGE_SMID_FORM));

  const { userID: userIdFromProps, roleLink } = match.params;
  const myself = useRef(false);
  const userID = useRef(userIdFromProps);

  const scanQR = (value) => {
    if (typeof value !== 'string') return;
    dispatch(change(CHANGE_SMID_FORM, 'sm_id', value));
    dispatch(closeModalWindow({ modalID: QR_CODE_SCANNER_MODAL_ID }));
  };

  const resendEmail = () => dispatch(resendToEmail({ email: userEmail, language: transformLanguageForAPI(i18n.language) }));

  const submitDeleteData = () => {
    dispatch(settingPrChangeModal(
      {
        myself,
        roleLink,
        userID: initialData?.id || initialData?._id,
        modalID: CHANGE_SMID_MODAL_ID,
        fields: { ...sm_id_data, _submitConfirmed: true },
        successfulCB: (res) => dispatch(settingPrGetUserSucc({ initialData: res }))
      }));
    dispatch(closeModalWindow({ modalID: CONFIRMATION_WINDOW_MODAL_ID }));
  };

  const sendPasswordChanges = (fields) => {
    dispatch(modalWindowSend({
      modalID: CHANGE_PASSWORD_MODAL_ID,
      requestConfig: {
        method: 'put',
        url: USER_UPDATE_URL,
        data: transformToFormData(fields),
        headers: { 'Content-Type': 'multipart/form-data' }
      },
      cb: (res) => settingPrGetUserSucc({ initialData: res })
    }));
  };

  const onSubmitHandler = (fields) => {
    dispatch(settingPrChange(
      {
        myself: myself.current,
        roleLink,
        userID: initialData?.id || initialData?._id,
        fields
      }));
  };

  const onSubmitChangeSMIDHandler = (fields) => {
    dispatch(settingPrChange(
      {
        myself: myself.current,
        roleLink,
        userID: initialData?.id || initialData?._id,
        modalID: CHANGE_SMID_MODAL_ID,
        fields
      }));
  };

  const onBackHandler = () => {
    // if it is the first page, then go to the hypothetical page
    if (!history.location.key) {
      const prevPath = {
        'property-management': `/property-managements/${userIdFromProps}`
      };

      const path = myself.current ? '/' : prevPath[roleLink] || '/';
      history.push(path);
      return;
    }

    history.goBack();
  };

  const onSubmitChangeEmailHandler = (fields) => {
    dispatch(settingPrChange(
      {
        myself: myself.current,
        roleLink,
        userID: initialData?.id || initialData?._id,
        modalID: CHANGE_EMAIL_MODAL_ID,
        fields
      }));
  };

  useEffect(() => {
    if (myUser) {
      if (!userIdFromProps || userIdFromProps === myUser._id) {
        myself.current = true;
        userID.current = myUser._id;
      } else {
        myself.current = false;
        userID.current = userIdFromProps;
      }

      dispatch(settingPrGetUser({ userID: userID.current, myself: myself.current, roleLink }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser, roleLink, userIdFromProps]);

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor settings-user-prof">
        {isLoading ? (
          <div className="profile-edit-prealoder-container">
            <Preloader />
          </div>
        ) : (
          <>
            <ConfirmationWindow changeSMIdForm={changeSMIdForm} onSubmit={submitDeleteData} />
            <ChangePassWindow onSubmit={sendPasswordChanges} />
            <QRCodeScannerModalWindow scanQR={scanQR} />

            <ChangeSMIdWindow
              onSubmit={onSubmitChangeSMIDHandler}
              initialValues={{ old_sm_id: initialData?.sm_id, save_data: true }}
              openScanQR={() => dispatch(openModalWindow({ modalID: QR_CODE_SCANNER_MODAL_ID, data: null }))}
              enableReinitialize
            />

            <ChangeEmailWindow
              onSubmit={onSubmitChangeEmailHandler}
              initialValues={{ old_email: initialData?.email }}
              enableReinitialize
            />

            <GetLayout
              userRoleType={initialData?.role?.type}
              userRoleName={initialData?.role?.name}
              userApp={initialData?.userApp}
              myRoleType={myRoleType}
              sendEmailRequest={resendEmail}
              openChangeEmail={() => dispatch(openModalWindow({ modalID: CHANGE_EMAIL_MODAL_ID, data: null }))}
              openChangeSMID={() => dispatch(openModalWindow({ modalID: CHANGE_SMID_MODAL_ID, data: null }))}
              openChangePassword={() => dispatch(openModalWindow({ modalID: CHANGE_PASSWORD_MODAL_ID, data: null }))}
              myself={myself.current}
              goBack={onBackHandler}
              onSubmit={onSubmitHandler}
              initialValues={getInitialValues(initialData)}
              enableReinitialize
            />
          </>
        )}
      </div>
    </div>
  );
};

SettingProfile.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

export default SettingProfile;
