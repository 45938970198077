import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as ModalWindow from '../ModalWrapper';
import { APP_ID, SOLAR_MANAGER_APP_ID } from '../../config';
import logoVariant2 from '../../assets/images/logo-variant-2.png';
import dashboardMini from '../../assets/images/dashboard-mini.png';
import { getPaymentUrlRequest } from '../../containers/Payments/slice';
import { BgImage } from './assets';
import { getSignInUserSelector } from '../../redux-store/selectors/signIn';
import { logOut } from '../../containers/SignIn/slice';
import { closeModalWindow } from '../../containers/ModalWindow/slice';

import i18n from '../../i18n';

const PERMISSION_DENY_MODAL_ID_AIT = 'PERMISSION_DENY_MODAL_ID_AIT';

export const PermissionDenyAIT = () => {
  const dispatch = useDispatch();

  const user = useSelector(getSignInUserSelector);

  const handleGetAccess = () => {
    dispatch(getPaymentUrlRequest());
  };

  const logOutHandler = () => {
    dispatch(closeModalWindow({ modalID: PERMISSION_DENY_MODAL_ID_AIT }));
    dispatch(logOut());
  };

  if (APP_ID !== SOLAR_MANAGER_APP_ID) {
    return null;
  }

  return (
    <ModalWindow.ModalWrapper
      modalID={PERMISSION_DENY_MODAL_ID_AIT}
      handleClose={() => {}}
      bodyProps={{
        className: 'modal-body-v2 permission-deny',
        sx: {
          px: 0,
          pt: 5,
          width: '80%',
          maxWidth: 1240,
          minWidth: 1000,
          position: 'relative'
        }
      }}
      backdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.20)', backdropFilter: 'blur(8px)' } }}
    >
      <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
        <BgImage type="left" lang={user?.language} />
      </Box>
      <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
        <BgImage type="right" lang={user?.language} />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 4,
        position: 'relative',
        width: '100%'
      }}
      >
        <ModalWindow.ModalHeader
          componentProps={{ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 2, maxWidth: 675, mb: 2 } }}
        >
          <img src={logoVariant2} alt="logo" />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h2" component="h2" className="modal-title-v2" sx={{ pb: 0.25 }}>
              {i18n.t('greetingAIT')}
            </Typography>
            <Typography variant="h4" component="p" className="modal-subtitle-v2">
              {i18n.t('permissionDenyModalTitleAIT')}
            </Typography>
          </Box>
        </ModalWindow.ModalHeader>

        <ModalWindow.ModalContent
          componentProps={{
            sx: {
              px: '10%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: 2,
              justifyContent: 'center',
              width: '100%'
            }
          }}
        >
          <img src={dashboardMini} alt="dashboard" />

          <Typography
            variant="h4"
            component="h4"
            className="modal-content-v2"
            sx={{ maxWidth: 960 }}
          >
            <Box
              sx={{
                borderRadius: '582px',
                backgroundColor: '#010101',
                filter: 'blur(9.350000381469727px)',
                height: '7px',
                maxWidth: '582px',
                m: 'auto',
                my: 3
              }}
            />
            <Typography
              variant="h6"
              component="p"
              className="modal-content-v2"
              sx={{ mb: 4 }}
            >
              {i18n.t('moreInfo')}
              {' '}
              <a
                href="https://www.solarmanager.ch/produkt/"
                target="_blank"
                rel="noreferrer"
                style={{ color: 'var(--primary-color)' }}
              >
                {i18n.t('here')}
              </a>
            </Typography>

            {i18n.t('permissionDenyModalDescAIT')}
            <Typography
              variant="h6"
              component="p"
              className="modal-content-v2 sub-content"
              sx={{ mt: 1.5 }}
            >
              {i18n.t('permissionDenyModalSubDescAIT')}
            </Typography>
          </Typography>

        </ModalWindow.ModalContent>

        <ModalWindow.ModalFooter
          componentProps={{ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 2, pb: 5 } }}
          confirmLabel={i18n.t('getAccessNow')}
          cancelLabel={i18n.t('logout')}
          onConfirm={handleGetAccess}
          onClose={logOutHandler}
        />
      </Box>
    </ModalWindow.ModalWrapper>
  );
};
