import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import { minLength, maxLength, required, parseToLowerCase, email } from '../../validation';
import { CustomTextInput } from '../../../components/ReduxFormFields';

import i18n from '../../../i18n';

import '../../../App.css';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

/**
 * @description renders SignUp page for companies
 * @memberof module:SignUp
 * @extends Component
 */
const SignUpCompany = (props) => {
  const { handleSubmit, pristine, submitting } = props;

  return (
    <>
      <form className="m-login__form m-form">
        <div className="form-group m-form__group input-field">
          <Field
            name="pv_installer"
            component={CustomTextInput}
            label={i18n.t('reqCompanyName')}
            className="m-input"
            autoComplete="off"
            validate={[required, minLen3, maxLen50]}
          />
        </div>
        <div className="form-group m-form__group input-field">
          <Field
            name="email"
            component={CustomTextInput}
            label={i18n.t('regMail')}
            className="m-input"
            autoComplete="off"
            validate={[required, email]}
            parse={parseToLowerCase}
            preventAuto
          />
        </div>
        <div className="m-login__form-action">
          <button
            disabled={pristine || submitting}
            onClick={handleSubmit}
            type="submit"
            id="m_login_signin_submit"
            className="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn m-login__btn--primary"
          >
            {i18n.t('submitBtn')}
          </button>
        </div>
      </form>
      <div className="m-login__account">
        <Link
          to="/login"
          id="m_login_signup"
          className="m-link m-link--light m-login__account-link"
        >
          {i18n.t('haveAcc')}
        </Link>
      </div>
    </>
  );
};

SignUpCompany.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default reduxForm({
  form: 'signUpCompanyForm'
})(SignUpCompany);
