/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from '../../Tooltip';
import { scanIP, removeIP } from '../../../containers/UserDetails/slice';
import { addNotification } from '../../../containers/NotificationGenerator/slice';
import { additionalFields, hasIPField } from '../../../containers/DevicesTable/utils';
import { addDeviceFormId, editDeviceFormId } from '../../../containers/DevicesTable/constants';
import { openModalWindow, SEARCH_IP_MODAL_ID } from '../../../containers/ModalWindow/slice';

import { sendIOTCommand } from './actions';

import { getSignInUserRoleTypeSelector } from '../../../redux-store/selectors/signIn';
import { getModalsEditRowDataIdSelector } from '../../../redux-store/selectors/modals';
import { getUserDevicesUserGateway_IdSelector } from '../../../redux-store/selectors/userDevices';

import i18n from '../../../i18n';

import './index.scss';

/**
 * Render IPField
 * @function IPField
 * @memberof module:UserDevices
 */
export const IPField = (props) => {
  const { device_group, formID, type, isShow } = props;
  const dispatch = useDispatch();

  const sensorId = useSelector(getModalsEditRowDataIdSelector);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const gateway_id = useSelector(getUserDevicesUserGateway_IdSelector);

  const onClickSearchHandler = () => {
    const gateway = myRoleType === 'end_user' ? '' : `/${gateway_id}`;
    dispatch(openModalWindow({ modalID: SEARCH_IP_MODAL_ID, data: { formID } }));
    dispatch(removeIP());
    dispatch(scanIP({ gateway, device: { type, device_group } }));
  };

  const sendIOTCommandHandler = () => {
    switch (formID) {
      case addDeviceFormId: {
        dispatch(addNotification({ type: 'error', text: i18n.t('needsFirstToBeCreated') }));
        break;
      }
      case editDeviceFormId: {
        dispatch(sendIOTCommand({ sensor_id: sensorId }));
        break;
      }
      default:
        break;
    }
  };

  const isAskoheat = ['ASKOHEAT SM'].includes(device_group);
  return (
    (isShow || hasIPField(device_group)) && (
      <div
        className={`form-group m-form__group input-field ip-field-container ${isAskoheat ? 'askoheat-bottom' : ''}`}
        style={{ position: 'relative' }}
      >
        <Field
          name="ip"
          {...additionalFields.ip}
          label={i18n.t(additionalFields.ip.label)}
          className="ip-field"
        />
        <a
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right search-ip-btn"
          onClick={onClickSearchHandler}
        >
          {i18n.t('Search IP')}
        </a>
        {isAskoheat && (
          <div className="identify-container">
            <a
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right identify-btn"
              onClick={sendIOTCommandHandler}
            >
              {i18n.t('identifyButton')}
            </a>
            <Tooltip title={i18n.t('identifyTooltip')} placement="right" />
          </div>
        )}
      </div>
    )
  );
};

IPField.propTypes = {
  formID: PropTypes.string.isRequired,
  device_group: PropTypes.string,
  type: PropTypes.string,
  isShow: PropTypes.bool
};
