import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { countriesSymbols } from '../constants';
import { parseStringOfNumbers } from '../../../../containers/validation';

import i18n from '../../../../i18n';

import '../index.scss';

/**
   * Set phone number into input field and set country into redux store
   * @param {string} phoneNumber string with phone number from input
   */
export const CommonPhoneInput = (props) => {
  const { meta: { touched, error }, input, phone = '', label, disabled, isRequired } = props;

  const [phoneNumber, setPhoneNumber] = useState(phone ? `+${parseStringOfNumbers(phone)}` : '');

  const setPhone = (value) => {
    setPhoneNumber(value);

    const parsedNumber = value && parsePhoneNumber(value);

    if (parsedNumber) {
      input.onChange(parsedNumber.nationalNumber);
    } else {
      input.onChange('');
    }
  };

  return (
    <>
      <p className="phone-label">
        {label + (isRequired ? ' *' : '')}
      </p>
      <PhoneInput
        {...input}
        international
        countryOptionsOrder={countriesSymbols}
        value={phoneNumber}
        disabled={disabled}
        onChange={setPhone}
        countries={countriesSymbols}
      />
      {touched && error && (
        <div className="inputCustomWarning">
          {i18n.t(error)}
        </div>
      )}
    </>
  );
};

CommonPhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  country: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  phone: PropTypes.string,
  isRequired: PropTypes.bool
};
