import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  allPvInstallersList: null,
  pvInstallersList: null
};

const pvInstallersDetailsSlice = createSlice({
  name: 'pvInstallersDetails',
  initialState,
  reducers: {
    getAllConnectedInstallers: epicEmptyFunction,
    getAllInstallers: (state) => {
      state.isLoading = true;
    },
    setAllInstallers: (state, action) => {
      state.pvInstallersList = action.payload.installers;
      state.isLoading = false;
    },
    setAllConnectedInstallers: (state, action) => {
      state.allPvInstallersList = action.payload.installers;
    }
  }
});

export const {
  getAllConnectedInstallers,
  setAllConnectedInstallers,
  setAllInstallers,
  getAllInstallers
} = pvInstallersDetailsSlice.actions;

export default pvInstallersDetailsSlice.reducer;
