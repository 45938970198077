import React from 'react';
import { useDispatch } from 'react-redux';

import { CHANGE_COLUMN_MODAL_ID, openModalWindow } from '../../containers/ModalWindow/slice';
import { ReactComponent as ChangeColumnsIco } from '../../assets/images/svg/change-columns.svg';

import './index.scss';

/**
 * Renders change-columns button for users react table
 * @memberof module:ReactTable
 */
const EditColumns = () => {
  const dispatch = useDispatch();

  const handleOpenModalClick = () => dispatch(openModalWindow({ modalID: CHANGE_COLUMN_MODAL_ID }));

  return (
    <div className="edit-column-container">
      <button
        type="button"
        onClick={handleOpenModalClick}
        className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
      >
        <ChangeColumnsIco />
      </button>
    </div>
  );
};

export default EditColumns;
