import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../api_helper';

export const InvitedUsersApi = createApi({
  reducerPath: 'InvitedUsersApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['invitedUsers'],
  endpoints: (builder) => ({
    getInvitedUsers: builder.query({
      providesTags: ['invitedUsers'],
      query: ({ endUserId }) => ({ url: `/users/${endUserId}/invitees`, method: 'get' }),
      transformResponse: ({ viewers, connectedOems, companies, propertyManagements }) => {
        const originalOem = connectedOems.find((oem) => oem.original);
        const originalInstaller = companies.find((installer) => installer.original);
        const invitedOems = connectedOems.filter((oem) => !oem.original);
        const invitedInstallers = companies.filter((installer) => !installer.original);
        const installers = [...invitedInstallers, ...invitedOems];
        if (originalInstaller) installers.unshift(originalInstaller);
        if (originalOem) installers.unshift(originalOem);

        return ({ viewers, installers, propertyManagements });
      }
    }),
    invitedUser: builder.mutation({
      refetchOnMountOrArgChange: false,
      invalidatesTags: ['invitedUsers'],
      query: ({ data, endUserId }) => ({ url: `/users/${endUserId}/share-plant`, method: 'post', data })
    }),
    updateInvitedUserPermission: builder.mutation({
      refetchOnMountOrArgChange: false,
      invalidatesTags: ['invitedUsers'],
      query: ({ data, endUserId }) => ({ url: `/users/permission/${endUserId}`, method: 'put', data })
    }),
    removeInvitedUser: builder.mutation({
      refetchOnMountOrArgChange: false,
      invalidatesTags: ['invitedUsers'],
      query: ({ endUserId, invitedUserId }) => ({ url: `/users/${endUserId}/invitee/${invitedUserId}`, method: 'delete' })
    })
  })
});

export const {
  useGetInvitedUsersQuery,
  useInvitedUserMutation,
  useRemoveInvitedUserMutation,
  useUpdateInvitedUserPermissionMutation
} = InvitedUsersApi;
