import React from 'react';
import moment from 'moment';

export const getColumns = ({
  shellhubUid,
  hostName,
  online,
  lastSeenAt,
  createdAt,
  agentVersion,
  osVersion,
  warnings
}) => [[
  {
    title: 'shellhubUid',
    isAllowed: true,
    Value: () => (
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        <a
          href={`https://remote.solarmanager.ch/device/${shellhubUid}`}
          target="_blank"
          rel="noreferrer"
        >
          {shellhubUid}
        </a>
      </div>
    )
  },
  {
    title: 'hostName',
    isAllowed: true,
    Value: () => hostName || ''
  },
  {
    title: 'online',
    isAllowed: true,
    Value: () => <p className="text-lowercase">{String(online ?? '')}</p>
  },
  {
    title: 'lastSeenAt',
    isAllowed: true,
    Value: () => (
      <p>
        {lastSeenAt ? moment(lastSeenAt).format('DD.MM.YYYY HH:mm:ss') : ''}
      </p>
    )
  },
  {
    title: 'created',
    isAllowed: true,
    Value: () => (
      <p style={{ textTransform: 'none' }}>
        {createdAt ? moment(createdAt).format('DD.MM.YYYY HH:mm:ss') : ''}
      </p>
    )
  },
  {
    title: 'agentVersion',
    isAllowed: true,
    Value: () => <p>{agentVersion}</p>
  },
  {
    title: 'osVersion',
    isAllowed: true,
    Value: () => <p>{osVersion}</p>
  },
  {
    title: 'warnings',
    isAllowed: true,
    Value: () => <p>{warnings}</p>
  }
]];
