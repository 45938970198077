import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { CustomTextArea } from '../../../components/ReduxFormFields';

import { initialValuesSelector } from '../selectors';
import { noteTextSelector } from '../../../redux-store/selectors/form';
import { getModalsIsLoadingSelector, getModalsEditNoteSelector } from '../../../redux-store/selectors/modals';
import { MAX_CHARS_COUNT } from '../constants';
import { EDIT_NOTE_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

import '../index.scss';

const formID = 'monitoringNote';

/**
 * Modal window to change note
 * @memberof module:Monitoring
 */
const EditNoteWindow = (props) => {
  const { reset, handleOnSubmit } = props;
  const dispatch = useDispatch();

  const noteText = useSelector(noteTextSelector);
  const modal = useSelector(getModalsEditNoteSelector);
  const isLoading = useSelector(getModalsIsLoadingSelector);

  const { data } = modal || {};

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleOnSubmit({ ticketId: data?.ticketId, noteText });
  };

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: EDIT_NOTE_MODAL_ID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={EDIT_NOTE_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title text-capitalize">{i18n.t('note')}</h5>
      </div>
      {isLoading ? (<div className="note-edit-prealoder-container"><Preloader /></div>) : (
        <form id={formID} onSubmit={handleSubmit}>
          <div className="modal-body">
            <Field
              name="note"
              type="text"
              component={CustomTextArea}
              className="input-note"
              maxLength={MAX_CHARS_COUNT}
            />
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={formID} isSubmit i18nLabel="save" type="save" />
          </div>
        </form>
      )}
    </ModalWindow>
  );
};

EditNoteWindow.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'monitoringNoteForm',
  enableReinitialize: true
})(EditNoteWindow);

const mapStateToProps = createStructuredSelector({
  initialValues: initialValuesSelector
});

export default connect(mapStateToProps)(form);
