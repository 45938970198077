import { from, of, EMPTY } from 'rxjs';
import { saveAs } from 'file-saver';
import { ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { sendFileRequest } from './actions';
import { addNotification } from '../NotificationGenerator/slice';
import i18n from '../../i18n';

/**
 * Download CSV file of all installers
 * @memberof module:PvInstallers
 */
const pvInstallersSendRequestEpic = ($action) => (
  $action.pipe(
    ofType(sendFileRequest),
    map((action) => action.payload),
    switchMap(({ method, url, config, onResult = {} }) => (
      from(api.request({ method, url, ...config })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (typeof onResult.callback === 'function') {
            return of(onResult.callback(result));
          }
          let fileReceived;
          if (onResult.downloadFile) {
            if (result instanceof Blob) {
              fileReceived = true;
              saveAs(result, `sm-manager-${Date.now()}.csv`);
            }
          }

          if (result.ok || fileReceived) {
            if (!onResult.successMessage) return EMPTY;
            return of(
              addNotification({ type: 'success', text: i18n.t(onResult.successMessage) })
            );
          }

          if (!onResult.errorMessage) return EMPTY;
          return of(
            addNotification({ type: 'error', text: i18n.t(onResult.errorMessage) })
          );
        })
      )
    ))
  )
);

export default pvInstallersSendRequestEpic;
