const formatDateTime = (dtString) => {
  if (!dtString || +dtString === 0) return '';

  let dateTime = new Date(dtString);

  if (+dtString && typeof +dtString === 'number') {
    dateTime = new Date(+dtString);
  }

  const day = dateTime.getDate();
  const month = dateTime.getMonth() + 1;
  const year = dateTime.getFullYear();

  return `${day}.${month}.${year}`;
};

export default formatDateTime;
