export const DATA_LIST_ID = 'installed_sm';

/**
 * Add gateway form id
 */
export const ADD_GATEWAY_FORM_ID = 'addGatewayForm';

/**
 * Max text length for gateways textarea.
 * 1000 strings 16 chars length every, plus one space and comma for each gateways.
 */
export const MAX_CHARS_COUNT = 18000;

export const releaseGroups = ['release', 'beta', 'alpha', 'dev'];
