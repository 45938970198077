import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import moment from 'moment-timezone';

import { api, deleteOldJwt, updateCookies, updateJwt } from '../../api';
import { handleError } from '../../api_helper';
import { cancelSignIn, signInCheckJWT } from '../SignIn/slice';
import { rootSignIn } from './actions';
import { USERS_BACKDOOR_URL } from '../../api/apiUrls';

function rootSignInEpic(action$) {
  return action$.pipe(
    ofType(rootSignIn),
    map((action) => action.payload),
    // eslint-disable-next-line arrow-body-style
    switchMap(({ userId }) => {
      return from(api.get(USERS_BACKDOOR_URL, { params: { userId } })).pipe(
        catchError(handleError),
        mergeMap((result) => {
          if (result?.jwt) {
            // delete old token
            deleteOldJwt();

            // set new token
            updateJwt(result?.jwt);
            updateCookies(result?.jwt, { expires: moment().add(30, 'days').toDate() });

            return of(
              signInCheckJWT()
            );
          }
          return cancelSignIn({ message: result });
        })
      );
    })
  );
}

export default combineEpics(rootSignInEpic);
