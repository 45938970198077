/* eslint-disable react/prop-types */
import React from 'react';

import { RenderButtons, RenderStatus, RenderResendEmail, RenderAccess } from '../../../components/ReactTable/renderFunctions';
import { DELETE_INVITED_USER_MODAL_ID } from '../constants';

import i18n from '../../../i18n';

export const getCrumbs = (userId, myRoleType) => (myRoleType === 'end_user' ? [
  { name: i18n.t('settings'), url: '/my-devices' },
  { name: i18n.t('invitedUsers') }
] : [
  { name: i18n.t('SMUs'), url: '/users' },
  { name: i18n.t('settings'), url: `/users/${userId}` },
  { name: i18n.t('invitedUsers') }
]);

export const getViewerColumns = (myRoleType, isReadOnly, sendEmailRequest) => [
  {
    Header: i18n.t('fName'),
    accessor: '',
    minWidth: 180,
    sortable: false,
    Cell: ({ original }) => `${original.first_name || ''} ${original.last_name || ''}`
  },
  {
    Header: i18n.t('email'),
    accessor: 'email',
    minWidth: 180,
    sortable: false,
    className: 'email-td'
  },
  {
    Header: i18n.t('access'),
    id: 'access',
    Cell: (row) => RenderAccess(row),
    minWidth: 100,
    filterable: false,
    sortable: false,
    show: true
  },
  {
    Header: i18n.t('status'),
    accessor: 'status',
    minWidth: 169,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td',
    sortable: false,
    filterable: false
  },
  {
    Header: '',
    Cell: ({ original }) => (<RenderResendEmail email={original.email} sendEmailRequest={sendEmailRequest} />),
    className: 'acc_status-td',
    sortable: false,
    filterable: false,
    show: !['viewer', 'pv_installer_employee_read_only', 'oem_employee_read_only'].includes(myRoleType) && !isReadOnly
  },
  {
    sortable: false,
    accessor: '',
    width: 70,
    className: '',
    filterable: false,
    show: !['viewer', 'pv_installer_employee_read_only', 'oem_employee_read_only'].includes(myRoleType),
    Cell: ({ original }) => (
      <div className="btn-cell">
        {RenderButtons(
          original,
          { deleteInvitedUser: !isReadOnly, modalID: DELETE_INVITED_USER_MODAL_ID, editUserAccess: !isReadOnly }
        )}
      </div>
    )
  }
];

export const getInstallerColumns = (myRoleType, isReadOnly) => [
  {
    Header: i18n.t('fName'),
    accessor: '',
    minWidth: 180,
    sortable: false,
    Cell: ({ original }) => `${original.first_name || ''} ${original.last_name || ''}`
  },
  {
    Header: i18n.t('installerName'),
    accessor: '',
    minWidth: 180,
    sortable: false,
    Cell: ({ original }) => original?.company?.name || original?.oem?.name || ''
  },
  {
    Header: i18n.t('email'),
    accessor: 'email',
    minWidth: 180,
    sortable: false,
    className: 'email-td'
  },
  {
    Header: i18n.t('access'),
    id: 'access',
    Cell: (row) => RenderAccess(row),
    minWidth: 100,
    filterable: false,
    sortable: false,
    show: true
  },
  {
    Header: i18n.t('status'),
    accessor: 'status',
    minWidth: 169,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td',
    sortable: false,
    filterable: false
  },
  {
    sortable: false,
    accessor: '',
    width: 70,
    className: '',
    filterable: false,
    show: !['viewer', 'pv_installer_employee_read_only', 'oem_employee_read_only'].includes(myRoleType),
    Cell: ({ original }) => (
      <div className="btn-cell">
        {RenderButtons(
          original,
          { deleteInvitedUser: !(original?.original || isReadOnly), modalID: DELETE_INVITED_USER_MODAL_ID, editUserAccess: !(original?.original || isReadOnly) }
        )}
      </div>
    )
  }
];

export const getPropMgmtColumns = (myRoleType, isReadOnly) => [
  {
    Header: i18n.t('fName'),
    accessor: '',
    minWidth: 180,
    sortable: false,
    Cell: ({ original }) => `${original.first_name || ''} ${original.last_name || ''}`
  },
  {
    Header: i18n.t('email'),
    accessor: 'email',
    minWidth: 180,
    sortable: false,
    className: 'email-td'
  },
  {
    Header: i18n.t('status'),
    accessor: 'status',
    minWidth: 169,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td',
    sortable: false,
    filterable: false
  },
  {
    sortable: false,
    accessor: '',
    width: 70,
    className: '',
    filterable: false,
    show: !['viewer', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem', 'oem_employee', 'oem_employee_read_only', 'end_user'].includes(myRoleType),
    Cell: ({ original }) => (
      <div className="btn-cell">
        {RenderButtons(
          original,
          { deleteInvitedUser: !(original?.original || isReadOnly), modalID: DELETE_INVITED_USER_MODAL_ID }
        )}
      </div>
    )
  }
];
