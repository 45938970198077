import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'react-router-redux';
import * as workerTimers from 'worker-timers';
import moment from 'moment-timezone';
import Highcharts from 'highcharts';
import addNoDataModule from 'highcharts/modules/no-data-to-display';

import {
  LoadManagementDashboardContainer,
  LoadManagementCurrentValues,
  LoadManagementChartControls
} from './components';
import CalendarPickerModal from '../DashboardV2/components/CalendarPickerModal';
import { TopBar } from '../../components';

import {
  changeDataForRequest,
  receiveInitUser,
  changeScaleType,
  requestInitUser,
  getPhasesData,
  sendIOTCommand,
  getChartData
} from './slice';
import { closeModalWindow, DATE_PICKER_MODAL_MODAL_ID, openModalWindow } from '../ModalWindow/slice';
import { formatLoadManagementInterval, chartChangeRange, getRange } from './utils';
import { getSignInUserSelector } from '../../redux-store/selectors/signIn';
import {
  getLoadManagementDashboardScaleTypeSelector,
  getLoadManagementDashboardUserTimezoneSelector,
  getLoadManagementDashboardChartSelector,
  getLoadManagementDashboardUserSelector
} from '../../redux-store/selectors/loadManagementDashboard';
import { fiveMinutesInMS, oneMinuteInMS, dayInMS } from './constants';

import './index.scss';

addNoDataModule(Highcharts);

const LoadManagementDashboard = (props) => {
  const { myUserID, location, match } = props;
  const dispatch = useDispatch();

  const signInUser = useSelector(getSignInUserSelector);
  const user = useSelector(getLoadManagementDashboardUserSelector);
  const chart = useSelector(getLoadManagementDashboardChartSelector);
  const scaleType = useSelector(getLoadManagementDashboardScaleTypeSelector);
  const timezone = useSelector(getLoadManagementDashboardUserTimezoneSelector);

  const [tabHasFocus, setTabHasFocus] = useState(true);

  const { userId } = match.params;
  const myself = !(userId && userId !== myUserID);
  const userName = user ? `${user.first_name} ${user.last_name}` : false;
  const { gatewayId, smartMeterId } = user || {};
  const searchParams = new URLSearchParams(window.location.search);
  const scaleTypeFromURL = searchParams.get('type');
  const fromFromURL = searchParams.get('from');
  const toFromURL = searchParams.get('to');
  const subTypeFromURL = searchParams.get('subType');
  const now = moment().valueOf();
  const realTimeData = moment(now).diff(chart.to) <= fiveMinutesInMS * 2;

  const closeDatePicker = () => dispatch(closeModalWindow({ modalID: DATE_PICKER_MODAL_MODAL_ID }));

  const openDatePicker = () => dispatch(openModalWindow({ modalID: DATE_PICKER_MODAL_MODAL_ID, data: null }));

  const replaceFromProps = (path) => dispatch(replace(path));

  const handleChangeCalendar = (date) => {
    closeDatePicker();

    const from = moment(date).startOf([scaleType?.type, subTypeFromURL].includes('w') ? 'week' : 'day');
    const to = moment(date).endOf([scaleType?.type, subTypeFromURL].includes('w') ? 'week' : 'day');
    dispatch(getChartData({ data: { gatewayId, from: from.toISOString(), to: to.toISOString() } }));
    chartChangeRange(from, to, replaceFromProps, location.pathname, [scaleType?.type, subTypeFromURL].includes('w') ? 'w' : 'd');
    dispatch(changeDataForRequest({
      data: {
        from: from.toISOString(),
        to: to.toISOString(),
        scaleMS: [scaleType?.type, subTypeFromURL].includes('w') ? dayInMS * 7 : dayInMS
      }
    }));
  };

  const zoomHandler = (xScale) => {
    if (xScale.max && xScale.min && moment(xScale.max).diff(moment(xScale.min), 'minutes') < 5) {
      return false;
    }
    chartChangeRange(xScale.min, xScale.max, replaceFromProps, location.pathname);
    dispatch(changeScaleType({ data: { type: 'range' } }));
    dispatch(changeDataForRequest({
      data: {
        from: new Date(xScale.min).toISOString(),
        to: new Date(xScale.max).toISOString(),
        scaleMS: xScale.max - xScale.min
      }
    }));
    return false;
  };

  useEffect(() => {
    if (timezone) {
      moment.tz.setDefault(timezone);
    }

    return () => {
      moment.tz.setDefault();
    };
  }, [timezone]);

  useEffect(() => {
    const handleFocus = () => {
      setTabHasFocus(true);
    };

    const handleBlur = () => {
      setTabHasFocus(false);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    if (gatewayId && tabHasFocus && smartMeterId) {
      dispatch(sendIOTCommand({ gatewayId }));
    }

    const loadManagementIOTRequest = workerTimers.setInterval(() => {
      if (gatewayId && tabHasFocus && smartMeterId) {
        dispatch(sendIOTCommand({ gatewayId }));
      }
    }, oneMinuteInMS);

    return () => {
      workerTimers.clearInterval(loadManagementIOTRequest);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId, smartMeterId, tabHasFocus]);

  useEffect(() => {
    if (signInUser?.role?.type === 'end_user') {
      dispatch(receiveInitUser({ result: signInUser }));
    } else if (userId) {
      dispatch(requestInitUser({ userId }));
    }

    if (scaleTypeFromURL) {
      if (['t', 'w', 'd', 'h'].includes(scaleTypeFromURL)) {
        dispatch(changeScaleType({ data: { type: scaleTypeFromURL } }));
      } else if (scaleTypeFromURL === 'range') {
        dispatch(changeDataForRequest({
          data: {
            from: new Date(Number(fromFromURL)).toISOString(),
            to: new Date(Number(toFromURL)).toISOString(),
            scaleMS: toFromURL - fromFromURL
          }
        }));
      }
    } else {
      dispatch(changeScaleType({ data: { type: 't' } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gatewayId && smartMeterId) {
      dispatch(getPhasesData({
        data: { myself, gatewayId, smartMeterId }
      }));
    }

    const currentValuesInterval = workerTimers.setInterval(() => {
      if (gatewayId && smartMeterId) {
        dispatch(getPhasesData({
          data: { myself, gatewayId, smartMeterId }
        }));
      }
    }, 10000);

    return () => {
      workerTimers.clearInterval(currentValuesInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (gatewayId) {
      const { from, to, expectedScaleMS } = getRange(scaleType?.type, chart, subTypeFromURL);

      dispatch(changeDataForRequest({
        data: { from: from.toISOString(), to: to.toISOString(), scaleMS: expectedScaleMS }
      }));

      dispatch(getChartData({
        data: { gatewayId, from: from.toISOString(), to: to.toISOString() }
      }));
    }

    const chartDataInterval = workerTimers.setInterval(() => {
      if (gatewayId) {
        const { from, to, expectedScaleMS } = getRange(scaleType?.type, chart, subTypeFromURL);

        dispatch(changeDataForRequest({
          data: { from: from.toISOString(), to: to.toISOString(), scaleMS: expectedScaleMS }
        }));

        dispatch(getChartData({
          data: { gatewayId, from: from.toISOString(), to: to.toISOString() }
        }));
      }
    }, fiveMinutesInMS);

    return () => {
      workerTimers.clearInterval(chartDataInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleType, user]);

  return (
    <>
      <CalendarPickerModal
        maxDetail={[scaleType?.type, subTypeFromURL].includes('w') ? 'week' : 'date'}
        from={chart.from}
        handleChange={handleChangeCalendar}
      />

      <TopBar
        visibleWeb
        goBackButton={!myself}
        pageInfo={!myself ? userName : false}
      />
      <LoadManagementCurrentValues />
      <LoadManagementChartControls
        replaceFromProps={replaceFromProps}
        scaleType={scaleType}
        subType={subTypeFromURL}
        location={location}
        openDatePicker={openDatePicker}
        realTimeData={realTimeData}
        intervalStr={formatLoadManagementInterval(chart.from, chart.to)}
        zoom
      />
      {user ? (<LoadManagementDashboardContainer zoomHandler={zoomHandler} />) : null}
    </>
  );
};

LoadManagementDashboard.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  myUserID: PropTypes.string.isRequired
};

export default LoadManagementDashboard;
