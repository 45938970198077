import {
  RenderEmail,
  RenderDate,
  RenderStatus,
  RenderButtons,
  RenderFullName,
  RenderPhoneNumber
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columns = (myRoleType, { width }) => ([
  {
    width: 35
  },
  {
    Header: i18n.t('fName'),
    accessor: 'first_name',
    minWidth: width.first_name,
    className: '',
    Cell: RenderFullName
  },
  {
    Header: i18n.t('mail'),
    accessor: 'email',
    minWidth: width.email,
    className: 'email-text-td',
    Cell: RenderEmail
  },
  {
    Header: i18n.t('phoneNumber'),
    accessor: 'phone',
    minWidth: width.phone,
    className: 'phone_num-td',
    Cell: RenderPhoneNumber
  },
  {
    Header: i18n.t('city'),
    accessor: 'city',
    minWidth: width.city,
    className: 'city-td'
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: width.status,
    Cell: RenderStatus,
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: width.last_login,
    className: 'last_login-td',
    Cell: RenderDate
  },
  {
    Header: '',
    accessor: 'buttons',
    width: width.buttons,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        view: { preLink: '/property-managements/' },
        editUser: { preLink: '/setting-profile/property-management/' },
        remove: ['root', 'solar_admin'].includes(myRoleType)
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
