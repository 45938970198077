import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { linkForLogoSelector } from './selector';
import i18n from '../../i18n';

import './index.scss';

/**
 * Renders company logo
 */
const CompanyLogo = (props) => {
  const { logo, className = '', background = '', isTopBar } = props;

  const linkForLogo = useSelector(linkForLogoSelector);

  const [displayCompanyLogo, setDisplayCompanyLogo] = useState(true);

  const disableCompanyLogo = () => setDisplayCompanyLogo(false);

  if (background) {
    return (
      <div
        style={{ backgroundImage: `url(${background})` }}
        className={`company-logo ${className}`}
      />
    );
  }

  if (isTopBar && logo) {
    if (displayCompanyLogo) {
      return (
        <div className="col-auto topbar-company-logo">
          <div className={`company-logo2 ${className}`}>
            <a
              className={`${linkForLogo ? '' : 'link-company-logo-disabled'}`}
              href={linkForLogo || null}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="image"
                src={`${logo}`}
                alt={i18n.t('Company logo')}
                onError={disableCompanyLogo}
              />
            </a>
          </div>
        </div>
      );
    }
    return null;
  }

  if (logo) {
    return (
      <div
        style={{ backgroundImage: `url(${logo})` }}
        className={`company-logo ${className}`}
      />
    );
  }

  return (
    <div className="company-logo logo-default">
      {i18n.t('Company logo')}
    </div>
  );
};

CompanyLogo.propTypes = {
  className: PropTypes.string,
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  logo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Object).isRequired
  ]),
  isTopBar: PropTypes.bool
};

export default CompanyLogo;
