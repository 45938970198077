import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { push, goBack } from 'react-router-redux';
import {
  map,
  switchMap,
  catchError,
  mergeMap,
  ignoreElements,
  concatMap,
  tap
} from 'rxjs/operators';
import moment from 'moment-timezone';

import { handleErrorDetailed } from '../../api_helper';
import { api, deleteOldJwt, updateCookies, updateJwt } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import {
  signInUpdateUser,
  signInInvalidJWT,
  signInCheckJWT,
  receiveSignIn,
  cancelSignIn,
  signIn,
  logOut
} from './slice';
import { setPagesSetting } from '../PagesSettings/slice';
import {
  CKW_LICENSE_RESTRICTIONS_MODAL,
  UPGRADE_TO_UNLOCK_POPUP,
  openModalWindow
} from '../ModalWindow/slice';

import i18n, { changeLanguageTo } from '../../i18n';
import { getLanguageCode } from './utils/getLanguageCode';

function signInEpic(action$, state$) {
  return action$.pipe(
    ofType(signIn),
    map((action) => action.payload),
    switchMap(({ remember, email, password, language }) => from(
      api.post('/auth/local', { identifier: email, password, isWeb: true }, { language })
    ).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (result?.jwt) {
          updateJwt(result.jwt);
          updateCookies(result.jwt, {
            expires: remember ? moment().add(30, 'days').toDate() : null
          });

          const language_ = getLanguageCode(result.user.language);
          const {
            signIn: { needGoBackAfterLogin }
          } = state$.value;

          changeLanguageTo(language_, true);
          return receiveSignIn({
            user: result.user,
            jwt: result.jwt,
            remember,
            needGoBack: needGoBackAfterLogin
          });
        }

        return cancelSignIn({ message: result?.message });
      })
    )
    )
  );
}

const getRestrictionModal = (user) => {
  const { restrictions } = user?.license || {};
  if (restrictions?.isAccessToAppRestricted) {
    return 'PERMISSION_DENY_MODAL_ID_AIT';
  }

  if (restrictions?.isCarRestricted && user.isCarExists) {
    return UPGRADE_TO_UNLOCK_POPUP;
  }

  if (
    restrictions?.numberOfAllowedDevices
    && restrictions.numberOfAllowedDevices < user.virtualCountOfInstalledDevices
  ) {
    return CKW_LICENSE_RESTRICTIONS_MODAL;
  }

  return null;
};

function receiveSignIpEpic(action$) {
  return action$.pipe(
    ofType(receiveSignIn),
    map((action) => action.payload),
    mergeMap(({ user, needGoBack }) => {
      if (user.role.type === 'sub_user') {
        return of(push('/login'));
      }
      const isEndUser = user.role.type === 'end_user';
      const path = isEndUser ? '/' : '/users/';

      return of(
        signInCheckJWT(),
        needGoBack ? goBack() : push(path),
        addNotification({ type: 'success', text: i18n.t('youAreIn') })
      );
    })
  );
}

function cancelSignInEpic(action$) {
  return action$.pipe(
    ofType(cancelSignIn),
    map((action) => action.payload?.message),
    switchMap((message) => of(addNotification({ type: 'error', text: message })))
  );
}

function logoutEpic(action$) {
  return action$.pipe(
    ofType(logOut),
    switchMap(() => {
      deleteOldJwt();

      return of(
        push('/login'),
        addNotification({ type: 'success', text: i18n.t('youAreOut') }),
        setPagesSetting({ page: 'modals', data: { expertModeModalId: true } })
      );
    })
  );
}

function signInCheckJWTEpic(action$) {
  return action$.pipe(
    ofType(signInCheckJWT),
    concatMap(() => from(api.get('/user/me', { params: { isWeb: true } })).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          deleteOldJwt();

          return of(signInInvalidJWT(), addNotification({ type: 'error', text: result.message }));
        }
        const isEndUser = result?.role?.type === 'end_user';

        const restrictionModalId = isEndUser && getRestrictionModal(result);

        return restrictionModalId
          ? of(openModalWindow({ modalID: restrictionModalId }), signInUpdateUser({ user: result }))
          : of(signInUpdateUser({ user: result }));
      })
    )
    )
  );
}

function signInUpdateUserEpic(action$) {
  return action$.pipe(
    ofType(signInUpdateUser),
    map((action) => action.payload.user),
    tap((user) => {
      const language = getLanguageCode(user.language);
      changeLanguageTo(language, true);
    }),
    ignoreElements()
  );
}

export default combineEpics(
  signInEpic,
  receiveSignIpEpic,
  cancelSignInEpic,
  logoutEpic,
  signInCheckJWTEpic,
  signInUpdateUserEpic
);
