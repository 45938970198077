export const DEFAULT_FONT = 'Helvetica Neue Pro, sans-serif';

export const INSTALLATION_ONGOING_COLOR = '#3998F9';
export const NOT_CONNECTED_STATUS_COLOR = '#D2342F';
export const CONNECTED_COLOR = '#00975F';

export const OK_COLOR = '#1BBC9B';
export const WARNING_COLOR = '#FF9E22';
export const ERROR_COLOR = '#F66400';
export const NOT_CONNECTED_MONITORING_COLOR = '#DB422D';

export const PIE_CHART_COLORS = {
  countColor: '#FFFFFF',
  itemHiddenStyle: '#888888',
  itemHoverStyle: '#888888',
  itemStyleColor: '#888888',
  titleColor: '#888888',
  bgColor: '#F7F7F7'
};

export const CIRCLE_CHART_COLORS = {
  itemHiddenStyle: '#888888',
  itemHoverStyle: '#888888',
  itemStyleColor: '#888888',
  titleColor: '#888888',
  bgColor: '#F7F7F7',
  OVERALL_ACTIVE_COLOR: '#d23a2f',
  ONE_YEAR_ACTIVE_COLOR: '#3998f9',
  ONE_MONTH_ACTIVE_COLOR: '#43985f',
  OVERALL_EMPTY_COLOR: '#ebbcba',
  ONE_YEAR_EMPTY_COLOR: '#bddaf7',
  ONE_MONTH_EMPTY_COLOR: '#acdac9'
};

export const tooltip = {
  zIndex: 9999,
  backgroundColor: '#fff',
  borderColor: '#dadada',
  style: {
    color: '#131313',
    fontSize: '16px'
  }
};
