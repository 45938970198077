import { isDeutsch } from './isDeutsch';

const isFloat = (num) => (isDeutsch() ? /^[+-]?[0-9]+\,[0-9]+$/ : /^[+-]?[0-9]+\.[0-9]+$/).test(num);
const formatFloat = (num) => Number.parseFloat(isDeutsch() ? num.replace(',', '.') : num);

const formatFloatSeparator = (data) => Object.entries(data).reduce(
  (obj, [key, value]) => (isFloat(value)
    ? { ...obj, [key]: formatFloat(value) }
    : { ...obj, [key]: value }),
  {}
);

export default formatFloatSeparator;
