import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@mui/material';

/**
 * Popover (user details) at left side of header
 * @memberof module:UIKit
 */
const SimplePopover = (props) => {
  const { ButtonComponent, children } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Changes state of popover (closed or opened)
   * @memberof module:UIKit
   */
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  /**
   * Closes popover
   * @memberof module:UIKit
   */
  const handleClose = () => setAnchorEl(null);

  const opened = Boolean(anchorEl);

  return (
    <>
      <ButtonComponent
        aria-describedby={opened ? 'simple-popover' : undefined}
        onClick={handleClick}
      />
      <Popover
        open={opened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        classes={{
          root: 'simplePopoverRoot',
          paper: 'simplePopoverParent'
        }}
      >
        {children}
      </Popover>
    </>
  );
};

SimplePopover.propTypes = {
  ButtonComponent: PropTypes.func.isRequired,
  children: PropTypes.instanceOf(Object).isRequired
};

export default SimplePopover;
