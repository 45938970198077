import PropTypes from 'prop-types';

import i18n from '../../../i18n';

/**
 * All available input functions
 */
const functions = {
  1: 'switchOffBlocked',
  2: 'restrictToMin',
  3: 'restrictTo50',
  4: 'increaseToMax',
  5: 'fromGridMaximumUsage',
  6: 'fromGridBlocked',
  7: 'switchOffBlockedPVAllowed'
};

const functionsByType = {
  feed_in: 'downregulationFunc'
};

/**
 * Render function for React-Table. Column for input device function.
 * @memberof module:ReactTable
 * @param {object} props - General props object
 * @param {object} props.original - object with properties to render
 * @param {string} props.original.inputDeviceFunction - Input device function id
 */
const RenderInputDeviceFunction = ({ original: { inputDeviceFunction, type } }) => (
  i18n.exists(functionsByType[type] || '') ? i18n.t(functionsByType[type]) : i18n.t(functions[inputDeviceFunction]) || null
);

RenderInputDeviceFunction.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderInputDeviceFunction;
