import getColor from './getColor';

const updateDeviceList = (devices, newDevice) => {
  const isTag = 'tag' in newDevice;
  const name = isTag ? newDevice?.tag : newDevice.type;
  const device = devices.find((d) => (d.id === newDevice.id && d.isTag === isTag));

  if (typeof device === 'undefined') {
    const y = devices.push({
      id: newDevice.id || newDevice._id,
      name,
      color: newDevice.color || getColor(name, isTag),
      isTag
    }) - 1;
    devices[y].y = y;
    return devices[y];
  }
  return device;
};

export default updateDeviceList;
