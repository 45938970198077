import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import smartPlugOnIcon from '../../../../../assets/images/svg/smart-plug-on.svg';
import smartPlugOffIcon from '../../../../../assets/images/svg/smart-plug-off.svg';
import smartPlugIcon from '../../../../../assets/images/svg/smart-plug.svg';

const renderSmartPlugIcon = {
  0: smartPlugOffIcon,
  1: smartPlugOnIcon
};

const SmartPlugTooltip = (props) => {
  const { switchState, convertedCurrentPower, consumedForLast24h } = props;

  return (
    <>
      <img
        src={Number.isFinite(switchState) ? renderSmartPlugIcon[switchState] : smartPlugIcon}
        alt="Smart Plug Icon"
      />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
        <span>{i18n.t('last24h')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {convertedCurrentPower}
          {i18n.t('kWPeakOutputUnits')}
        </span>
        <span>
          {consumedForLast24h || defaultValue}
          {i18n.t('kwh')}
        </span>
      </div>
    </>
  );
};

SmartPlugTooltip.propTypes = {
  switchState: PropTypes.number,
  consumedForLast24h: PropTypes.number,
  convertedCurrentPower: PropTypes.number.isRequired
};

export default SmartPlugTooltip;
