import React from 'react';

import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { required, validateFloatWithThreeDecimal, parseFloatNumbersWithOneDigitAfterPoint } from '../../../validation';
import { TariffTextField } from './TariffTextField';

import i18n from '../../../../i18n';

export const SingleTariff = ({ unit = '', skipValidation = false }) => (
  <Box>
    <TariffTextField
      name="singleTariff.price"
      label={`${i18n.t('singleTariff')} (${unit})`}
      variant="body2"
      parser={parseFloatNumbersWithOneDigitAfterPoint}
      validate={skipValidation ? [] : [required, validateFloatWithThreeDecimal]}
    />

    <Typography sx={{ my: 2, color: 'secondary.dark' }} variant="body1">
      {i18n.t('singleTariffDescription')}
    </Typography>
  </Box>
);

SingleTariff.propTypes = {
  unit: PropTypes.string,
  skipValidation: PropTypes.bool
};
