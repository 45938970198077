/**
 * Removes old points from one data array
 * @param points {ChartData}
 * @param sum {number}
 * @param minTimestamp {number} minTimestamp Timestamp in milliseconds for left side limit of data.
 * @returns {undefined|number}
 */
function removePointsFromArray(points, sum, minTimestamp) {
  if (!points) {
    return undefined;
  }

  let numberOfPointsToRemove = 0;

  for (let i = 0; i < points.length; i += 1) {
    if (points[i][0] >= minTimestamp) {
      break;
    }

    numberOfPointsToRemove += 1;

    if (typeof sum !== 'undefined') {
      sum -= points[i][1];
    }
  }

  points.splice(0, numberOfPointsToRemove);

  return sum;
}

/**
 * Removes old points from one data array (in Self Consumption Absolute - self_consumption_abs).
 * Notice: this calculation will not be 100% correct if x_consumption or x_production is grouped.
 * @param data
 * @param dataSum
 * @param minTimestamp
 */
function removePointsFromSCA(data, dataSum, minTimestamp) {
  if (typeof dataSum.self_consumption_abs !== 'undefined') {
    for (let i = 0; i < data.x_consumption.length; i += 1) {
      if (data.x_consumption[i][0] < minTimestamp) {
        dataSum.self_consumption_abs -= Math.min(data.x_consumption[i][1], data.x_production[i][1]);
      } else {
        break;
      }
    }
  }
}
/**
 * Removes points which timestamp is older then 'minTimestamp'. It modifies input params.
 * Accessory function for Dashboard Epics
 * @param data {Object}
 * @param dataSum {Object}
 * @param minTimestamp {number} minTimestamp Timestamp in milliseconds for left side limit of data.
 */
export default function removeOldPoints({ data, dataSum }, minTimestamp) {
  removePointsFromSCA(data, dataSum, minTimestamp);

  Object.keys(data).forEach((key) => {
    if (['temperatures', 'devicesCurrentPower', 'invertersCurrentPower', 'batteriesSoc'].includes(key) && typeof data[key] === 'object' && data[key] !== null) {
      Object.keys(data[key]).forEach((sensorId) => {
        removePointsFromArray(data[key][sensorId], dataSum[key], minTimestamp);
      });
    } else if (Array.isArray(data[key])) {
      dataSum[key] = removePointsFromArray(data[key], dataSum[key], minTimestamp);
    }
  });
}
