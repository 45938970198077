import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

import { signInCheckJWT, signInInvalidJWT } from '../../SignIn/slice';
import { Preloader } from '../../../components';
import { getSignInUserSelector, getSignInVerifyingJWTSelector } from '../../../redux-store/selectors/signIn';
import { getJwtFromCookies } from '../../../api';

export default (Component) => {
  /**
   * Checks if user is NOT authorized. If he is NOT authorized, it allows him to open guests page. Otherwise it does redirect to home page.
   * @memberof module:PermissionChecker
   */
  const OnlyGuests = (props) => {
    const dispatch = useDispatch();

    const user = useSelector(getSignInUserSelector);
    const verifyingJWT = useSelector(getSignInVerifyingJWTSelector);

    useEffect(() => {
      const jwt = getJwtFromCookies();

      if (jwt) {
        if (verifyingJWT) {
          dispatch(signInCheckJWT());
        }
      } else {
        dispatch(signInInvalidJWT());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verifyingJWT) {
      return <Preloader />;
    }

    if (user) {
      return <Redirect to="/" />;
    }

    return (
      <Suspense fallback={<Preloader />}>
        <Component
          {...props}
          user={user}
          verifyingJWT={verifyingJWT}
        />
      </Suspense>
    );
  };

  return withTranslation()(OnlyGuests);
};
