import moment from 'moment-timezone';

export default function getSubTypeRange(chart) {
  const { from, to } = chart || {};

  if (!from || !to) {
    return null;
  }

  const hourDiff = moment(to).diff(from, 'hours');
  if (hourDiff <= 1) {
    return 'h';
  }

  const daysDiff = moment(to).diff(from, 'days');
  if (daysDiff <= 1) {
    return 'd';
  }

  const weeksDiff = moment(to).diff(from, 'weeks');
  if (weeksDiff <= 1) {
    return 'w';
  }

  const monthsDiff = moment(to).diff(from, 'months');
  if (monthsDiff <= 1) {
    return 'm';
  }

  const yearsDiff = moment(to).diff(from, 'years');
  if (yearsDiff <= 1) {
    return 'y';
  }

  return null;
}
