import { from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

import { handleErrorDetailed } from '../../../api_helper';
import { addNotification } from '../../../containers/NotificationGenerator/slice';
import i18n from '../../../i18n';
import { sendIOTCommand } from './actions';
import { api } from '../../../api';
import { SENSORS_IDENTIFY_URL } from '../../../api/apiUrls';

function sendIOTCommandEpic($action) {
  return $action.pipe(
    ofType(sendIOTCommand),
    map((action) => action.payload),
    switchMap(({ sensor_id }) => from(api.post(`${SENSORS_IDENTIFY_URL}/${sensor_id}`)).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          return addNotification({ type: 'success', text: i18n.t('success') });
        }

        return addNotification({ type: 'error', text: result.message });
      })
    ))
  );
}

export default combineEpics(sendIOTCommandEpic);
