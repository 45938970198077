import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import Tooltip from '../../Tooltip';
import { useForceUpdate } from '../../../hooks';

import i18n from '../../../i18n';

import './index.scss';

const isChrome = window?.bowser?.name === 'Chrome';

/**
 * Renders text input for redux form
 * @memberof module:ReduxFormFields
 */
export const CustomTextInput = (props) => {
  const {
    input,
    label,
    showTooltip = '',
    changeTooltipPosition,
    placement,
    meta: { touched, error },
    preventAuto,
    className,
    defaultValue,
    languageToSync = '',
    isRequired,
    forceError = '',
    deviceName: _deviceName,
    deviceType: _deviceType,
    ...custom
  } = props;

  const [timeout, setNewTimeout] = useState(null);
  const myInputRef = useRef();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (isChrome) {
      setNewTimeout(setTimeout(() => forceUpdate(), 1000));
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labelProps = {
    className: `inputLabel${isRequired ? ' required' : ''}`,
    ...(isChrome && myInputRef?.current?.matches(':-webkit-autofill')) ? {
      shrink: true
    } : {}
  };

  const localeLabel = i18n.exists(label) ? i18n.t(label) : label;

  return (
    <>
      {!!showTooltip && (
        <div className="switch-tooltip" style={changeTooltipPosition ?? {}}>
          <Tooltip
            title={i18n.exists(showTooltip) ? i18n.t(showTooltip) : showTooltip}
            placement="right"
          />
        </div>
      )}
      <TextField
        variant="standard"
        inputRef={myInputRef}
        label={localeLabel}
        defaultValue={defaultValue}
        {...input}
        {...custom}
        classes={{
          root: `customTextField ${className}`
        }}
        InputProps={{
          classes: {
            root: 'inputRoot',
            input: 'inputField'
          }
        }}
        inputProps={
          preventAuto
            ? {
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: 'off'
            }
            : {}
        }
        InputLabelProps={labelProps}
      />
      {
        touched && (error || languageToSync) && (
          <div className="inputCustomWarning">
            {i18n.t(error)}
          </div>
        )
      }
      {
        forceError && (
          <div className="inputCustomWarning">
            {i18n.t(forceError)}
          </div>
        )
      }
    </>
  );
};

CustomTextInput.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  placement: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.PropTypes.instanceOf(Object)
  ]).isRequired,
  showTooltip: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  changeTooltipPosition: PropTypes.instanceOf(Object),
  className: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  forceError: PropTypes.string,
  languageToSync: PropTypes.string,
  preventAuto: PropTypes.bool,
  isRequired: PropTypes.bool,
  deviceName: PropTypes.string,
  deviceType: PropTypes.string
};
