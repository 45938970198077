import React from 'react';
import { Field } from 'redux-form';

import { Tooltip } from '../../../../components';
import { additionalFields } from '../../utils';

import i18n from '../../../../i18n';

import '../../index.scss';

/**
 * Render note field
 * @function NoteField
 * @memberof module:RenderFieldsByDevName
 */
const NoteField = () => (
  <div className="form-group m-form__group">
    <div className="flags-select-label">
      {`${i18n.t(additionalFields.notes.label)}`}
      <div className="switch-tooltip tooltipInSelect">
        <Tooltip title={i18n.t(additionalFields.notes.showTooltip)} placement="right" />
      </div>
    </div>
    <Field
      name="data.notes"
      {...additionalFields.notes}
      placeholder={i18n.t(additionalFields.notes.label)}
    />
  </div>
);

export default NoteField;
