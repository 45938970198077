/**
 * @typedef {Object} DashboardStatistic
 * @property {number} consumption Consumpted (used) energy also called 'consumption'.
 * @property {number} pvEnergy Produced PV-energy also called 'production'.
 * @property {Object} self_consumption
 * @property {number} self_consumption.amount Value of self-consumption sum (energy that was produced then used by itself)
 * @property {number} self_consumption.percent Percentage of self-consumption to consumption
 * @property {number} self_consumption.percentByProduction Percentage of self-consumption to production
 * @property {Object} energyExport
 * @property {number} energyExport.amount Exported energy sum. (The rest of produced energy after self-usage)
 * @property {number} energyExport.percent Percentage of eported energy to pvEnergy
 * @property {Object} energyImport
 * @property {number} energyImport.amount Imported energy sum. (Lack of produced energy for self-usage)
 * @property {number} energyImport.percent Percentage of imported energy to consumption
 */

import RenderWh from './RenderWh';

/**
 * Convert number into percent
 * @method
 * @memberof module:Dashboard
 * @param {number} value Value what to convert
 * @param {number} digits Number of decimal digits (default)
 * @returns {number}
 */
const toPercent = (value, digits = 0) => {
  const newValue = value > 1 ? 1 : value;
  return Number.isFinite(newValue)
    ? Math.round(newValue * (10 ** (digits + 2))) / (10 ** digits)
    : '-';
};

/**
 * Counts summary of received statistic. It will be shown at bottom of dashboard
 * @memberof module:Dashboard
 * @param {number} consumptionSum Sum of consumption (used energy).
 * @param {number} self_consumption Sum of self-consumpted energy.
 * @param {boolean} debugMode Run in debug mode.
 * @returns {DashboardStatistic}
 */
const countSummary = (
  {
    x_consumption = null,
    x_production = null,
    self_consumption = null,
    self_consumption_abs = null,
    battery_charging = 0,
    x_battery_discharging = null,
    import: Import = null
  },
  debugMode
) => {
  const result = {
    consumption: x_consumption,
    pvEnergy: x_production,
    self_consumption: {
      amount: self_consumption,
      percent: self_consumption_abs ? toPercent((self_consumption_abs + x_battery_discharging) / x_consumption, 1) : null,
      percentByProduction: 0
    },
    energyExport: {
      amount: x_production - self_consumption,
      percent: 0
    },
    energyImport: {
      amount: Import,
      percent: 0
    },
    battery_charging,
    x_battery_discharging,
    Import
  };

  result.self_consumption.percentByProduction = result.self_consumption.amount
    ? toPercent(
      result.self_consumption.amount / result.pvEnergy
    )
    : null;
  result.energyExport.percent = toPercent(
    result.energyExport.amount / result.pvEnergy
  );
  result.energyImport.percent = toPercent(
    result.energyImport.amount / result.consumption
  );

  result.self_consumption.amount = result.self_consumption.amount ? RenderWh(result.self_consumption.amount, 1, !debugMode) : '-';
  result.energyExport.amount = result.energyExport.amount ? RenderWh(result.energyExport.amount, 1, !debugMode) : '-';
  result.energyImport.amount = result.energyImport.amount ? RenderWh(result.energyImport.amount, 1, !debugMode) : '-';
  result.consumption = result.consumption ? RenderWh(result.consumption, 1, !debugMode) : '-';
  result.pvEnergy = result.pvEnergy ? RenderWh(result.pvEnergy, 1, !debugMode) : '-';
  result.battery_charging = RenderWh(result.battery_charging, 1, !debugMode);
  result.x_battery_discharging = result.x_battery_discharging ? RenderWh(result.x_battery_discharging, 1, !debugMode) : '-';
  result.Import = RenderWh(result.Import, 1, !debugMode);

  return result;
};

export default countSummary;
