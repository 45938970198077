import { createSelector } from '@reduxjs/toolkit';

const getSignUp2InitialValues = (state) => state.signUp2.initialValues;
const getSignUp2ErrorMessage = (state) => state.signUp2.errorMessage;
const getSignUp2StopMessage = (state) => state.signUp2.stopMessage;
const getSignUp2Verified = (state) => state.signUp2.verified;

export const getSignUp2InitialValuesSelector = createSelector([getSignUp2InitialValues], (initialValues) => initialValues);
export const getSignUp2ErrorMessageSelector = createSelector([getSignUp2ErrorMessage], (errorMessage) => errorMessage);
export const getSignUp2StopMessageSelector = createSelector([getSignUp2StopMessage], (stopMessage) => stopMessage);
export const getSignUp2VerifiedSelector = createSelector([getSignUp2Verified], (verified) => verified);
