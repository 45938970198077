import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for load guard - input device description.
 * @memberof module:ReactTable
 * @param {object} props - General props object
 * @param {object} props.original - object with properties to render
 * @param {object} props.original.inputDevice - Input device
 * @param {string} props.original.inputDevice.description - Input device description
 */
const RenderLoadGuardDescription = ({ original }) => ((original.type === 'feed_in')
  ? i18n.t('feedInManagement')
  : original?.inputDevice?.description || '');

export default RenderLoadGuardDescription;
