import moment from 'moment';

export const sortByTime = ((a, b) => {
  const aFrom = moment(a.fromTime, 'HH:mm');
  const bFrom = moment(b.fromTime, 'HH:mm');

  if (aFrom.isBefore(bFrom)) return -1;
  if (aFrom.isAfter(bFrom)) return 1;
  return 0;
});
