import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './index.scss';

const Breadcrumbs = ({ crumbs = [], breadcrumbsClass = '' }) => (
  <div className={`breadcrumbs-container ${breadcrumbsClass}`}>
    <span className="breadcrumbs">
      <i className="fa fa-home breadcrumbs-icons" />
      {crumbs.map(({ name, url }) => (
        <Fragment key={name}>
          {' '}
          -&nbsp;
          {url ? <Link data-cy="breadcrumbs-crump-link" to={url} className="breadcrumbs-crump">{name}</Link> : name}
          {' '}
        </Fragment>
      ))}
    </span>
  </div>
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string
  })),
  breadcrumbsClass: PropTypes.string
};

export default Breadcrumbs;
