import { countryLabels } from '../../../components/ReduxFormFields/CountrySelector/constants';

const countries = Object.values(countryLabels);

/**
 * Destructuring user date depending for role type
 * @function getInitialValues
 * @param {Object} initialData Fields of user
 * @memberof module:SettingProfile
 */
const getInitialValues = (initialData) => {
  const commonFields = {
    first_name: initialData?.first_name,
    last_name: initialData?.last_name,
    old_email: initialData?.email,
    password: initialData?.password,
    street: initialData?.street,
    zip: initialData?.zip,
    phone: initialData?.phone,
    city: initialData?.city,
    language: initialData?.language,
    avatarURL: initialData?.avatar?.url || false,
    country: initialData?.country || '',
    status: initialData?.status || '',
    note: initialData?.note || '',
    _id: initialData?._id
  };

  const userRoleType = initialData?.role?.type;

  switch (userRoleType) {
    case 'sub_user':
      return {
        first_name: initialData?.first_name,
        last_name: initialData?.last_name,
        old_email: initialData?.email,
        country: initialData?.country || '',
        status: initialData?.status || '',
        language: initialData?.language
      };
    case 'end_user':
      return {
        ...commonFields,
        old_sm_id: initialData?.gateway?.sm_id || '',
        company: initialData?.company?._id || 'null',
        connectedOem: initialData?.connectedOem?._id,
        company_name: initialData?.company_name || '',
        plant: initialData?.plant || '',
        billingAddress: initialData.billingAddress,
        isBillingAddressEnabled: initialData.isBillingAddressEnabled,
        userName: initialData?.userName,
        isPropMgmtEndUser: Boolean(initialData?.userPropertyManagement),
        adminNote: initialData?.adminNote
      };

    case 'pv_installer':
      return {
        ...commonFields,
        webpage: initialData?.webpage || '',
        companyLogoUrl: initialData?.company_logo?.url || false,
        company: initialData?.my_company?.name || '',
        link_for_logo: initialData?.company_logo ? initialData?.my_company?.link_for_logo : '',
        visible_web: initialData?.my_company?.visible_web,
        visible_installer_app: initialData?.my_company?.visible_installer_app,
        visible_end_user_app: initialData?.my_company?.visible_end_user_app
      };

    case 'solar_admin':
      return {
        ...commonFields,
        webpage: initialData?.webpage || '',
        link_for_logo: initialData?.my_company?.link_for_logo,
        authorizedCountries: initialData?.authorizedCountries?.length ? initialData?.authorizedCountries : countries
      };

    case 'oem':
      return {
        ...commonFields,
        webpage: initialData?.webpage || '',
        companyLogoUrl: initialData?.company_logo?.url,
        company: initialData?.oem?.name || '',
        link_for_logo: initialData?.oem?.link_for_logo || '',
        visible_web: initialData?.oem?.visible_web,
        visible_installer_app: initialData?.oem?.visible_installer_app,
        visible_end_user_app: initialData?.oem?.visible_end_user_app
      };

    case 'oem_employee':
    case 'oem_employee_read_only':
    case 'pv_installer_employee':
    case 'pv_installer_employee_read_only':
    case 'pv_installer_employee_read_install':
      return {
        ...commonFields,
        link_for_logo: initialData?.my_company?.link_for_logo,
        role_type: initialData?.role?.type
      };

    case 'property_management':
      return {
        ...commonFields,
        billingAddress: initialData.billingAddress,
        link_for_logo: initialData?.my_company?.link_for_logo
      };

    default:
      return {
        ...commonFields,
        link_for_logo: initialData?.my_company?.link_for_logo
      };
  }
};

export default getInitialValues;
