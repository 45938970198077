import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../api_helper';

export const dataImportApi = createApi({
  reducerPath: 'dataImportApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    importFile: builder.mutation({
      refetchOnMountOrArgChange: false,
      query: ({ userId, formData }) => ({
        url: `/data/import/${userId}`,
        method: 'POST',
        data: formData,
        formData: true
      })
    })
  })
});

export const { useImportFileMutation } = dataImportApi;
