import { createSelector } from '@reduxjs/toolkit';

const getViewerDetailsNotAttachedEndUsers = (state) => state.viewerDetails?.notAttachedEndUsers || [];
const getViewerDetailsPvInstallers = (state) => state.viewerDetails?.pvInstallers || [];
const getViewerDetailsOems = (state) => state.viewerDetails?.oems || [];

const getViewerDetailsIsPvInstallersListForViewerLoading = (state) => state.viewerDetails?.isPvInstallersListForViewerLoading;
const getViewerDetailsIsOemsListForViewerLoading = (state) => state.viewerDetails?.isOemsListForViewerLoading;
const getViewerDetailsIsNotAttachedUsersLoading = (state) => state.viewerDetails?.isNotAttachedUsersLoading;

export const getViewerDetailsNotAttachedEndUsersSelector = createSelector([getViewerDetailsNotAttachedEndUsers], (notAttachedEndUsers) => notAttachedEndUsers || []);
export const getViewerDetailsPvInstallersSelector = createSelector([getViewerDetailsPvInstallers], (pvInstallers) => pvInstallers || []);
export const getViewerDetailsOemsSelector = createSelector([getViewerDetailsOems], (oems) => oems || []);

export const getViewerDetailsIsLoadingSelectorEndUsers = createSelector(
  [
    getViewerDetailsIsNotAttachedUsersLoading,
    getViewerDetailsIsOemsListForViewerLoading,
    getViewerDetailsIsPvInstallersListForViewerLoading
  ],
  (isNotAttachedUsersLoading, isOemsListForViewerLoading, isPvInstallersListForViewerLoading) => (
    {
      isNotAttachedUsersLoading,
      isOemsListForViewerLoading,
      isPvInstallersListForViewerLoading
    }
  )
);
