/**
 * Short id of language like its spelled in react-flags-select
 * @ignore
 */
export const countries = ['US', 'CH', 'DE', 'FR', 'IT', 'DK'];
export const thermondoCountriesCode = ['US', 'DE'];

/**
 * Language long id to short
 * @ignore
 */
export const countryLabelsBack = {
  English: 'US',
  'Deutsch (Schweiz)': 'CH',
  'Deutsch (Deutschland)': 'DE',
  Deutsch: 'CH',
  French: 'FR',
  Italian: 'IT',
  Danish: 'DK'
};

/**
 * Language short id to long
 * @ignore
 */
export const countryLabels = {
  US: 'English',
  CH: 'Deutsch (Schweiz)',
  DE: 'Deutsch (Deutschland)',
  FR: 'French',
  IT: 'Italian',
  DK: 'Danish'
};

export const thermondoCountryLabels = {
  US: 'English',
  DE: 'Deutsch (Deutschland)'
};
