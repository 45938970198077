import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  employeesForFilter: undefined,
  parsedInstallers: undefined,
  parsedAssignee: undefined,
  choosedCompany: undefined,
  choosedStatus: undefined,
  choosedUser: undefined,
  installers: undefined,
  employees: undefined,
  errors: undefined,
  selectedFields: {},
  selectedRows: [],
  checked: false,
  status: [],
  data: []
};

const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    monitoringSendApplyWithFields: epicEmptyFunction,
    monitoringGetInstallers: epicEmptyFunction,
    getErrorInfo: epicEmptyFunction,
    setMonitoringFilters: (state, action) => {
      state.selectedFields = {
        ...state.selectedFields,
        ...action.payload.fields
      };
    },
    errorInfoReceived: (state, action) => {
      state.errors = {
        ...state.errors,
        [action.payload.taskId]: action.payload.errorsList
      };
    },
    monitoringInstallersReceived: (state, action) => {
      state.installers = action.payload.installers;
    },
    addParsedData: (state, action) => {
      state[action.payload.fieldName] = action.payload.arr;
    },
    saveAllDataListUsers: (state, action) => {
      state.data = [
        ...state.data,
        ...action.payload.dataListUsers
      ];
    },
    changeArchieveErrorSwitch: (state, action) => {
      state.checked = action.payload.checked;
    },
    addChoosedCompany: (state, action) => {
      const { choosedCompany, choosedUser, choosedStatus } = action.payload;
      state.choosedCompany = {
        ...state[choosedCompany],
        value: choosedCompany
      };
      state.choosedUser = {
        ...state[choosedUser],
        value: choosedUser
      };
      state.choosedStatus = choosedStatus ? [...choosedStatus] : [];
    },
    monitoringReplaceSelected: (state, action) => {
      state.selectedRows = action.payload?.newSelected || [];
    },
    monitoringClearFilters: (state) => {
      state.checked = false;
      state.selectedFields = {};
    },
    clearPropsFromFields: (state) => {
      state.selectedFields = {
        ...state.selectedFields,
        assignField: null,
        priorityField: null,
        workStatusField: null
      };
    },
    sendPropFromField: (state, action) => {
      const { fieldName, selections } = action.payload;
      state.selectedFields = {
        ...state.selectedFields,
        [fieldName]: {
          ...state[fieldName],
          value: selections
        }
      };
    },
    employeeReceived: (state, action) => {
      const { field, companyID, listID, parsedResult, listURL } = action.payload;
      const { data, total } = parsedResult;

      state[field] = {
        ...state[field],
        [companyID]: {
          ...state[listID],
          listURL,
          data,
          total,
          errorMessage: undefined,
          loading: false
        }
      };
    },
    employeeSendRequest: (state, action) => {
      const { company, dataList, listID } = action.payload;
      const { listURL, params, transformResponse } = dataList;

      state[listID] = {
        ...state[listID],
        company,
        listURL,
        loading: true,
        params,
        transformResponse,
        errorMessage: undefined
      };
    }
  }
});

export const {
  monitoringSendApplyWithFields,
  monitoringInstallersReceived,
  monitoringReplaceSelected,
  monitoringGetInstallers,
  monitoringClearFilters,
  setMonitoringFilters,
  saveAllDataListUsers,
  clearPropsFromFields,
  employeeSendRequest,
  errorInfoReceived,
  sendPropFromField,
  addChoosedCompany,
  employeeReceived,
  getErrorInfo
} = monitoringSlice.actions;

export default monitoringSlice.reducer;
