import { createSelector } from '@reduxjs/toolkit';

const getInstalledSMDefaultLicenseId = (state) => state.installedSM?.defaultLicenseId;
const getInstalledSMHardwaresList = (state) => state.installedSM?.hardwaresList;
const getInstalledSMVersionsList = (state) => state.installedSM?.versionsList;
const getInstalledSMIsLoading = (state) => state.installedSM?.isLoading;
const getInstalledSMSelected = (state) => state.installedSM?.selected;

export const getInstalledSMDefaultLicenseIdSelector = createSelector([getInstalledSMDefaultLicenseId], (defaultLicenseId) => defaultLicenseId);
export const getInstalledSMHardwaresListSelector = createSelector([getInstalledSMHardwaresList], (hardwaresList) => hardwaresList || []);
export const getInstalledSMVersionsListSelector = createSelector([getInstalledSMVersionsList], (versionsList) => versionsList);
export const getInstalledSMIsLoadingSelector = createSelector([getInstalledSMIsLoading], (isLoading) => !!isLoading);
export const getInstalledSMSelectedSelector = createSelector([getInstalledSMSelected], (selected) => selected);
