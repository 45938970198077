import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render function for React-Table. Column of formatted date and time
 * @memberof module:ReactTable
 * @param  {(timestamp|dateString)} value - Time and Date
 */
const RenderAccess = ({ original }) => {
  const { type: roleType } = original?.role || {};
  const permission = original?.permission || '';

  switch (permission ? permission : roleType) {
    case 'pv_installer_employee':
    case 'oem_employee':
    case 'read_and_write':
      return <div className="access-label">R/W</div>;
    case 'pv_installer_employee_read_only':
    case 'oem_employee_read_only':
    case 'read':
      return <div className="access-label">R</div>;
    case 'pv_installer_employee_read_install':
      return <div className="access-label">R/I</div>;
    default:
      return null;
  }
};

RenderAccess.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderAccess;
