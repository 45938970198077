import { createSelector } from '@reduxjs/toolkit';
import { getModalsEditNoteSelector } from '../../redux-store/selectors/modals';

import { getSignInUser } from '../../redux-store/selectors/signIn';
import { EMPLOYEE_ROLE_TYPES } from '../UserDetails/constants';

const rolesWithCustomLogo = ['pv_installer', 'oem', ...EMPLOYEE_ROLE_TYPES, 'oem_employee', 'oem_employee_read_only'];

export const visibleWebSelector = createSelector([getSignInUser], (user) => (rolesWithCustomLogo.includes(user?.role?.type)));
export const companyUserIdSelector = createSelector([getSignInUser], (user) => user?.my_company?._id || user?.company?._id);
export const initialValuesSelector = createSelector([getModalsEditNoteSelector], (modal) => ({ note: modal?.data?.note || '' }));
