import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import homeAppliancesIcon from '../../../../../assets/images/svg/HomeAppliances.svg';

const HomeAppliancesTooltip = (props) => {
  const { homeApplianceStatus, remainingTime = '' } = props;
  const applianceStatus = {
    0: 'noInformation',
    1: 'deviceOffline',
    2: 'deviceOnline',
    3: 'readyToStart',
    4: 'running',
    5: 'finished'
  };

  return (
    <>
      <img src={homeAppliancesIcon} alt="Home Appliances Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('operationMode')}</span>
        {homeApplianceStatus === 4 && (<span>{i18n.t('timeRemaining')}</span>)}
      </div>
      <div className="sensorData-value">
        <span>
          {i18n.t(homeApplianceStatus ? applianceStatus[homeApplianceStatus] : applianceStatus[defaultValue])}
        </span>
        {homeApplianceStatus === 4 && (<span>{remainingTime}</span>)}
      </div>
    </>
  );
};

HomeAppliancesTooltip.propTypes = {
  remainingTime: PropTypes.string,
  homeApplianceStatus: PropTypes.number
};

export default HomeAppliancesTooltip;
