import {
  RenderDate,
  RenderStatus,
  RenderButtons
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columns = (myRoleType, { width }) => ([
  {
    Header: i18n.t('company'),
    id: 'my_company',
    accessor: (d) => (d.my_company ? d.my_company.name : 'No company'),
    minWidth: width.company
  },
  {
    Header: i18n.t('oem'),
    id: 'oems',
    accessor: (d) => d.oems?.map((oem) => oem.name).join(', '),
    minWidth: width.oems,
    show: !['oem', 'oem_employee'].includes(myRoleType),
    sortable: false
  },
  {
    Header: i18n.t('city'),
    accessor: 'city',
    minWidth: width.city,
    className: ''
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: width.status,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: width.last_login,
    className: 'last_login-td',
    Cell: (row) => RenderDate(row)
  },
  {
    Header: '',
    accessor: 'buttons',
    width: width.buttons,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        devices: false,
        editUser: { preLink: '/setting-profile/pv-installer-users/' },
        view: { preLink: '/pv-installers/' },
        remove: (myRoleType === 'root' || myRoleType === 'solar_admin')
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
