import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { GENERAL_SETTINGS_MODAL_ID, openModalWindow } from '../../../../ModalWindow/slice';
import { ItemsList } from '../ItemsList';
import { getColumns } from './columns';
import { TariffSection } from './TariffSection';
import i18n from '../../../../../i18n';

import './index.scss';
import { READ_ONLY_ROLES } from '../../../../../constants';

const LinkToEdit = ({ onClickHandler }) => (
  <div className="user-info-link">
    <a
      onClick={onClickHandler}
      className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
      title={i18n.t('editTooltip')}
    >
      <i className="la la-edit" />
    </a>
  </div>
);

/**
 * General user info. Viewed by everyone except end_user.
 * @memberof module:UserDetails
 */
const GeneralInfo = (props) => {
  const { user, myRoleType, isReadOnlyPermission } = props;
  const dispatch = useDispatch();

  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);
  const columns = useMemo(() => getColumns({ user, myRoleType }), [user, myRoleType]);
  const editTooltipHandler = (modalId) => dispatch(openModalWindow({ modalID: modalId, data: null }));
  const isAllowedToEdit = !(isReadOnlyRole || isReadOnlyPermission);

  return (
    <Box className="main-info" sx={{ p: 1, py: 0, display: 'flex', gap: '20px', flexWrap: 'wrap', alignItems: 'initial' }}>
      <Box className="tab-wrapper general-info" sx={{ p: 3, flex: 1, pr: 5, position: 'relative', flexBasis: '35%' }}>
        {isAllowedToEdit && <LinkToEdit onClickHandler={() => editTooltipHandler(GENERAL_SETTINGS_MODAL_ID)} />}
        <ItemsList columns={columns} />
      </Box>
      <Box className="tab-wrapper" sx={{ p: 3, flex: 1, pr: 5, position: 'relative', flexBasis: '55%' }}>
        {isAllowedToEdit && <LinkToEdit onClickHandler={() => editTooltipHandler('editTariff')} />}
        <TariffSection user={user} myRoleType={myRoleType} />
      </Box>
    </Box>
  );
};

GeneralInfo.propTypes = {
  user: PropTypes.instanceOf(Object),
  isReadOnlyPermission: PropTypes.bool.isRequired,
  myRoleType: PropTypes.string
};

LinkToEdit.propTypes = {
  onClickHandler: PropTypes.func.isRequired
};

export default GeneralInfo;
