import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { email, required, parseToLowerCase } from '../../validation';
import ModalWindow from '../../ModalWindow';
import { CustomTextInput } from '../../../components/ReduxFormFields';
import { ADD_USER_TO_PROPERTY_MANAGEMENT, addUserForm } from '../constants';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsIsLoadingSelector } from '../../../redux-store/selectors/modals';
import Preloader from '../../../components/Preloader';

import { closeModalWindow } from '../../ModalWindow/slice';
import i18n from '../../../i18n';

const selector = formValueSelector(addUserForm);

const getAddEndUserForm = createSelector(
  (state) => selector(state, 'sm_id', 'email', 'connectedOem', 'pv_installer'),
  (data) => data
);

const AddPropMgmtModal = (props) => {
  const { handleSubmit, pristine, valid, initialize, reset } = props;
  const dispatch = useDispatch();
  const isLoading = useSelector(getModalsIsLoadingSelector);

  const addEndUserForm = useSelector(getAddEndUserForm);

  const modal = useSelector((state) => state.modals[ADD_USER_TO_PROPERTY_MANAGEMENT]);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_USER_TO_PROPERTY_MANAGEMENT }));

  useEffect(() => {
    if (modal?.opened) {
      const { email: emailFormValue, sm_id, connectedOem, pv_installer } = addEndUserForm;

      initialize({
        userName: emailFormValue,
        sm_id,
        connectedOem,
        pv_installer
      });
    } else {
      reset();
    }
  }, [modal?.opened]); // eslint-disable-line

  return (
    <ModalWindow modalID={ADD_USER_TO_PROPERTY_MANAGEMENT}>
      <div>
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('addSMU')}</h5>
        </div>
        <div className="modal-body">
          {isLoading ? (
            <div className="preloader-container" style={{ height: 90 }}>
              <Preloader height={70} />
            </div>
          ) : null}
          {!isLoading ? (
            <form
              id={ADD_USER_TO_PROPERTY_MANAGEMENT}
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field">
                <Field
                  name="propertyManagementEmail"
                  component={CustomTextInput}
                  label={i18n.t('propMgmtEmail')}
                  className="m-input"
                  autoComplete="off"
                  validate={[required, email]}
                  parse={parseToLowerCase}
                  preventAuto
                  required
                />
              </div>
            </form>
          ) : null}
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton form={ADD_USER_TO_PROPERTY_MANAGEMENT} disabled={(pristine && !valid) || isLoading} isSubmit type="send" />
        </div>
      </div>
    </ModalWindow>
  );
};

AddPropMgmtModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

const form = reduxForm({ form: ADD_USER_TO_PROPERTY_MANAGEMENT })(AddPropMgmtModal);

export default form;
