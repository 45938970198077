import { createSelector } from '@reduxjs/toolkit';
import {
  getPvInstallersDetailsAllPvInstallersList,
  getPvInstallersDetailsPvInstallersList
} from '../../redux-store/selectors/pvInstallersDetails';

export const filteredInstallersSelector = createSelector(
  [getPvInstallersDetailsPvInstallersList, getPvInstallersDetailsAllPvInstallersList],
  (pv_installers, allConnectedInstallers) => {
    const filteredInstallersNew = pv_installers
      .filter((installer) => installer?.my_company
        && !allConnectedInstallers.some((connectedInstaller) => installer._id === connectedInstaller._id)
      );

    const filteredInstallersData = filteredInstallersNew.map(({ _id, my_company, isChecked = false }) => (
      { _id, name: my_company?.name, isChecked }
    ));

    return filteredInstallersData || [];
  }
);
