/* eslint-disable arrow-body-style */
import pick from 'lodash/pick';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  autarchy_degree: undefined,
  temperatures: undefined,
  consumption: undefined,
  difference: undefined,
  production: undefined,
  timestamp: undefined,
  battery: undefined,
  import: undefined
};

const currentValuesSlice = createSlice({
  name: 'dashboardV2CurrentValues',
  initialState,
  reducers: {
    dashboardCurrentValues: (state, action) => {
      return pick(action.payload, ['production', 'consumption', 'battery', 'difference', 'import', 'autarchy_degree', 'timestamp', 'temperatures']);
    },
    dashboardUpdCurrent: (state, action) => {
      // eslint-disable-next-line no-case-declarations
      const sensorsData = action.payload?.rawSensorData?.sensorsData || [];

      // eslint-disable-next-line no-case-declarations
      const temperatures = sensorsData
        .filter((sensor) => typeof sensor?.currentWaterTemp !== 'undefined')
        .reduce((result, { _id, currentWaterTemp }) => {
          result[_id] = currentWaterTemp;
          return result;
        }, {});

      // eslint-disable-next-line no-case-declarations
      const devicesCurrentPower = sensorsData
        .filter((sensor) => typeof sensor?.currentPower !== 'undefined')
        .reduce((result, { _id, currentPower }) => {
          result[_id] = currentPower;
          return result;
        }, {});

      // eslint-disable-next-line no-case-declarations
      const invertersCurrentPower = sensorsData
        .filter((sensor) => typeof sensor?.currentPower !== 'undefined')
        .reduce((result, { _id, currentPower }) => {
          result[_id] = currentPower;
          return result;
        }, {});

      // eslint-disable-next-line no-case-declarations
      const batteriesSoc = sensorsData
        .filter((sensor) => typeof sensor?.SOC !== 'undefined')
        .reduce((result, { _id, SOC }) => {
          result[_id] = SOC;
          return result;
        }, {});

      const newState = {
        ...pick(action.payload.rawSensorData?.newPoint, ['production', 'consumption', 'battery', 'difference', 'import', 'autarchy_degree', 'timestamp']),
        temperatures,
        devicesCurrentPower,
        invertersCurrentPower,
        batteriesSoc
      };
      return newState;
    }
  }
});

// eslint-disable-next-line no-empty-pattern
export const { dashboardCurrentValues } = currentValuesSlice.actions;

export default currentValuesSlice.reducer;
