import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import batteryIcon from '../../../../../assets/images/svg/battery.svg';

const BatteryTooltip = (props) => {
  const { convertedCurrentPower, SOC } = props;

  return (
    <>
      <img src={batteryIcon} alt="Battery Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
        <span>{i18n.t('batterySoc')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {convertedCurrentPower}
          {i18n.t('kWPeakOutputUnits')}
        </span>
        <span>
          {SOC || defaultValue}
          %
        </span>
      </div>
    </>
  );
};

BatteryTooltip.propTypes = {
  SOC: PropTypes.number,
  convertedCurrentPower: PropTypes.number.isRequired
};

export default BatteryTooltip;
