import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { required } from '../../validation';
import { CustomTextInput } from '../../../components/ReduxFormFields';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getInstalledSMIsLoadingSelector } from '../../../redux-store/selectors/installedSM';
import { getModalsConfirmRemoveGatewaySelector } from '../../../redux-store/selectors/modals';
import { CONFIRM_REMOVE_GATEWAY_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

import '../index.css';

const formID = 'confirmDeleteGatewayForm';

/**
 * Renders delete gateway confirmation modal window for InstalledSM page
 * @memberof module:InstalledSM
 */
const ConfirmDeleteGatewayWindow = (props) => {
  const { handleSubmit, reset } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getModalsConfirmRemoveGatewaySelector);
  const isLoading = useSelector(getInstalledSMIsLoadingSelector);
  const formValues = useSelector(getFormValues(formID));
  const { data, opened } = modal;

  const equalToGatewayId = useCallback((value) => (value && value === data?.sm_id ? undefined : 'confirmDeletingError'), [data?.sm_id]);
  const handleSubmitHandler = (e) => {
    e.preventDefault();
    if (formValues.gatewayId === data?.sm_id) {
      handleSubmit(data?.id || data?._id);
    }
  };

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: CONFIRM_REMOVE_GATEWAY_MODAL_ID }));

  useEffect(() => {
    if (!opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <ModalWindow modalID={CONFIRM_REMOVE_GATEWAY_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('confirmDeleting')}</h5>
      </div>
      {isLoading ? (<div className="installedsm-delete-prealoder-container"><Preloader /></div>) : (
        <div>
          <div className="modal-body">
            <div className="text-confirmation confirm-deleting-text">{i18n.t('confirmDeletingDesc')}</div>
            <form
              id={formID}
              className="confirmation-field"
              onSubmit={handleSubmitHandler}
            >
              <Field
                name="gatewayId"
                label={i18n.t('smidLabel')}
                className="m-input"
                component={CustomTextInput}
                validate={[required, equalToGatewayId]}
              />
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={formID} isSubmit i18nLabel="confirmBtn" type="save" />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

ConfirmDeleteGatewayWindow.propTypes = {
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'confirmDeleteGatewayForm'
})(ConfirmDeleteGatewayWindow);

export default form;
