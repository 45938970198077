import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { CustomSelect } from '../../../components/ReduxFormFields';
import { LongCheckboxArr, Preloader } from '../../../components';
import { CancelButton, PrimaryButton } from '../../../components/UIKit';
import ModalWindow from '../../ModalWindow';
import {
  getNotAttachedToViewerEndUsers,
  getOEMsListForViewer,
  getPVInstallersForViewerAllList,
  setNotAttachedToViewerEndUsers
} from '../slice';
import { getModalsAddEndUsersToViewerSelector } from '../../../redux-store/selectors/modals';
import {
  getViewerDetailsIsLoadingSelectorEndUsers,
  getViewerDetailsNotAttachedEndUsersSelector,
  getViewerDetailsOemsSelector,
  getViewerDetailsPvInstallersSelector
} from '../../../redux-store/selectors/viewerDetails';
import {
  getEndUsersForViewerParams,
  addEndUsersToViewerForm,
  selectAllCheckbox,
  getOEMSForViewerParams,
  getInstallersForViewerParams
} from '../constants';
import { getPVInstallersListOptions, getOEMsListOptions } from '../utils';
import { ADD_END_USERS_TO_VIEWER_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

const selector = formValueSelector(addEndUsersToViewerForm);

/**
 * Renders add End User to viewer modal window for ViewerDetails page
 * @memberof module:ViewerDetails
 */
const AddEndUserToViewerWindow = (props) => {
  const { handleSubmit, viewerUserId, reset, change } = props;
  const dispatch = useDispatch();

  const {
    isNotAttachedUsersLoading,
    isOemsListForViewerLoading,
    isPvInstallersListForViewerLoading
  } = useSelector(getViewerDetailsIsLoadingSelectorEndUsers);

  const oems = useSelector(getViewerDetailsOemsSelector);
  const modal = useSelector(getModalsAddEndUsersToViewerSelector);
  const selectedOEM = useSelector((state) => selector(state, 'oem'));
  const pvInstallers = useSelector(getViewerDetailsPvInstallersSelector);
  const selectedPVInstaller = useSelector((state) => selector(state, 'pvInstaller'));
  const filteredEndUsers = useSelector(getViewerDetailsNotAttachedEndUsersSelector);

  const selectedEndUsers = useMemo(() => filteredEndUsers.filter(({ isChecked }) => isChecked).length, [filteredEndUsers]);
  const isLoadingData = isOemsListForViewerLoading || isPvInstallersListForViewerLoading || (isNotAttachedUsersLoading && !filteredEndUsers.length);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_END_USERS_TO_VIEWER_MODAL_ID }));

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const dataForReq = filteredEndUsers.filter(({ isChecked }) => isChecked).map(({ _id }) => _id);
    handleSubmit(dataForReq);
    reset();
  };

  const pickSearchOptions = (option, value) => {
    dispatch(getNotAttachedToViewerEndUsers(
      {
        params: {
          viewerId: viewerUserId,
          ...getEndUsersForViewerParams,
          oemId: selectedOEM && selectedOEM !== 'all' ? selectedOEM : undefined,
          pvInstallerId: selectedPVInstaller && selectedPVInstaller !== 'all' ? selectedPVInstaller : undefined,
          [option]: value && value !== 'all' ? value : undefined
        }
      }
    ));
    change(selectAllCheckbox, false);
  };

  const handleChange = (value) => {
    if (isNotAttachedUsersLoading) return;

    const copy = [...filteredEndUsers];
    const indexOfPoint = copy.findIndex((point) => point._id === value);
    copy[indexOfPoint] = {
      ...copy[indexOfPoint],
      isChecked: !copy[indexOfPoint].isChecked
    };
    dispatch(setNotAttachedToViewerEndUsers({ endUsers: copy }));

    const isAllIsChecked = copy.every((point) => point.isChecked);
    change(selectAllCheckbox, isAllIsChecked);
  };

  const handleChangeAll = (event) => {
    dispatch(setNotAttachedToViewerEndUsers({ endUsers: filteredEndUsers.map((point) => ({ ...point, isChecked: event.target.checked })) }));
  };

  useEffect(() => {
    if (!modal.opened) {
      dispatch(setNotAttachedToViewerEndUsers({ endUsers: filteredEndUsers.map((point) => ({ ...point, isChecked: false })) }));
      reset();
    }
  }, [modal.opened]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getNotAttachedToViewerEndUsers({ params: { viewerId: viewerUserId, ...getEndUsersForViewerParams } }));
    dispatch(getOEMsListForViewer({ params: getOEMSForViewerParams }));
    dispatch(getPVInstallersForViewerAllList({ params: { ...getInstallersForViewerParams, _limit: 10000 } }));

    return () => dispatch(setNotAttachedToViewerEndUsers({ endUsers: [] }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWindow customClass="max-width-550" modalID={ADD_END_USERS_TO_VIEWER_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addSMU')}</h5>
      </div>
      {isLoadingData ? (
        <div className="user-add-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <form
              id={addEndUsersToViewerForm}
              onSubmit={handleSubmitForm}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="row users-filter">
                <div className="col-6">
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="oem"
                      component={CustomSelect}
                      placeholder={i18n.t('selectOEM')}
                      options={getOEMsListOptions(oems)}
                      className="m-input"
                      onChange={(value) => pickSearchOptions('oemId', value)}
                      disabled={isNotAttachedUsersLoading}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="pvInstaller"
                      component={CustomSelect}
                      placeholder={i18n.t('selectPVI')}
                      options={getPVInstallersListOptions(pvInstallers)}
                      className="m-input"
                      onChange={(value) => pickSearchOptions('pvInstallerId', value)}
                      disabled={isNotAttachedUsersLoading}
                    />
                  </div>
                </div>
              </div>
              <LongCheckboxArr
                formId={addEndUsersToViewerForm}
                handleChange={handleChange}
                noDataText="noUser"
                checkedData={filteredEndUsers}
                disabled={isNotAttachedUsersLoading}
                handleChangeAll={handleChangeAll}
                selectAllCheckboxLabel="selectAllEndUsers"
              />
            </form>
          </div>
          <div className="modal-footer modal-add-installer-to-oem">
            <p className="modal-selected">
              {i18n.t('Selected')}
              {': '}
              {selectedEndUsers}
            </p>
            <div className="modal-buttons">
              <CancelButton onClickHandler={handleOnClose} />
              <PrimaryButton
                customButtonClass="ml-2 installer-to-oem-btn"
                form={addEndUsersToViewerForm}
                disabled={!selectedEndUsers}
                i18nLabel="add"
                isSubmit
                type="plus"
              />
            </div>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddEndUserToViewerWindow.propTypes = {
  viewerUserId: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const form = reduxForm({
  form: addEndUsersToViewerForm
})(AddEndUserToViewerWindow);

export default form;
