import { createSelector } from '@reduxjs/toolkit';

import { getAddDeviceForm, getEditDeviceForm } from '../../../redux-store/selectors/form';
import { getModals } from '../../../redux-store/selectors/modals';

export const deviceFormSelector = createSelector(
  [getModals, getAddDeviceForm, getEditDeviceForm],
  // eslint-disable-next-line arrow-body-style
  (isOpenedAddDeviceModal, addDeviceform, editDeviceform) => {
    return isOpenedAddDeviceModal?.addDevice?.opened
      ? addDeviceform
      : editDeviceform;
  }
);
