import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { ColorComponent } from './components';

const DeviceColorButton = (props) => {
  const { deviceColor, form, fieldName = '', disabled = false, device } = props;

  return (
    <Field
      name={fieldName}
      component={ColorComponent}
      deviceColor={deviceColor}
      formId={form}
      fieldName={fieldName}
      disabled={disabled}
      device={device}
    />
  );
};

DeviceColorButton.propTypes = {
  deviceColor: PropTypes.string,
  fieldName: PropTypes.string,
  disabled: PropTypes.bool,
  device: PropTypes.instanceOf(Object),
  form: PropTypes.string.isRequired
};

export default DeviceColorButton;
