import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../containers/ModalWindow';
import DataList from '../../containers/DataList';
import { dataListReloadData } from '../../containers/DataList/slice';
import { CHOOSE_LICENSE_MODAL_ID, closeModalWindow, modalWindowSend } from '../../containers/ModalWindow/slice';
import LicenseElement from './components/LicenseElement';
import { selectLicenses, selectValue } from './selector';
import { RadioButtons } from '../../components/ReduxFormFields';
import { FORM_ID, DATA_LIST_ID } from './constants';
import { CancelButton, PrimaryButton } from '../../components/UIKit';
import { GATEWAY_LICENSE_URL, USER_GET_LICENSE_LIST_URL, USER_CHANGE_LICENSE_URL } from '../../api/apiUrls';
import { getModalsChooseLicenseWindowSelector } from '../../redux-store/selectors/modals';

import i18n from '../../i18n';

import './index.scss';

/**
 * Renders modal window for choosing user or installer license
 */
const ChooseLicenseWindow = (props) => {
  const { initialize, pristine, reset, type } = props;
  const dispatch = useDispatch();

  const licenses = useSelector((state) => selectLicenses(state));
  const modal = useSelector(getModalsChooseLicenseWindowSelector);
  const currentLicense = useSelector((state) => selectValue(state, 'license'));

  const { userId, parentListId, gatewayId, license, licenseCode } = modal?.data || {};

  const onClose = () => dispatch(closeModalWindow({ modalID: CHOOSE_LICENSE_MODAL_ID }));

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(modalWindowSend({
      modalID: CHOOSE_LICENSE_MODAL_ID,
      requestConfig: {
        method: 'put',
        url: userId ? `${USER_CHANGE_LICENSE_URL}/${userId}` : `${GATEWAY_LICENSE_URL}/${gatewayId}`,
        data: { newLicense: currentLicense }
      },
      cb: () => dataListReloadData({ listID: parentListId })
    })
    );
  };

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    } else {
      initialize({ license: license || licenseCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={CHOOSE_LICENSE_MODAL_ID}>
      <DataList
        listID={DATA_LIST_ID}
        listURL={USER_GET_LICENSE_LIST_URL}
        params={{ type }}
        forceKeepData
      />
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('License options')}</h5>
      </div>
      <div className="modal-body">
        <form
          id={FORM_ID}
          onSubmit={onSubmit}
          className="m-login__form m-form pop-up-form add-sm-us"
        >
          <Field
            name="license"
            component={RadioButtons}
            props={{
              variants: licenses,
              InputElement: LicenseElement
            }}
          />
        </form>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={onClose} />
        <PrimaryButton form={FORM_ID} disabled={pristine} isSubmit type="save" />
      </div>
    </ModalWindow>
  );
};

ChooseLicenseWindow.propTypes = {
  reset: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  type: PropTypes.string
};

const form = reduxForm({
  form: FORM_ID
})(ChooseLicenseWindow);

export default form;
