import React from 'react';
import PropTypes from 'prop-types';
import ReactFlagsSelect from 'react-flags-select';

import { countries, countryLabelsBack, countryLabels } from './constants';

import i18n from '../../../i18n';

import './index.scss';

/**
 * Renders languageSelector for redux form
 * @memberof module:ReduxFormFields
 */
export const CountrySelector = ({
  input,
  id,
  label,
  buttonClassName,
  meta: { touched, error },
  ...custom
}) => (
  <>
    <ReactFlagsSelect
      {...input}
      {...custom}
      rfsKey={id}
      selectButtonClassName={`${buttonClassName} ${custom.disabled ? `${buttonClassName}_disabled` : ''}`}
      onSelect={(country) => input.onChange(countryLabels[country])}
      countries={countries}
      customLabels={countryLabels}
      selected={countryLabelsBack[input.value]}
      selectedSize={18}
      placeholder={`${i18n.t('select')}`}
      searchable
      searchPlaceholder={`${i18n.t('search')}`}
    />
    {touched && error && (
      <div className="inputCustomWarning">
        {i18n.t(error)}
      </div>
    )}
  </>
);

CountrySelector.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  buttonClassName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired
};
