import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import solarLogo from './logos/logo-light.png';
import solarLogoDark from './logos/logo.png';

import './index.scss';

/**
 * Renders Solar Manager Logo for all pages
 */

const Logo = ({ mode }) => (
  <div className="m-login__log">
    <Link to="/">
      <img src={mode === 'dark' ? solarLogoDark : solarLogo} alt="logo_solar_manager" className="topBarLogo" />
    </Link>
  </div>
);

Logo.propTypes = {
  mode: PropTypes.string
};

Logo.defaultProps = {
  mode: 'light'
};

export default Logo;
