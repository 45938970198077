import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: undefined
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    modalWindowSend: (state, action) => {
      const { requestConfig, modalID, cb, reloadPage, finally: finallyCb, keepOpenIfError } = action.payload;
      state[modalID] = {
        ...(state[modalID] || {}),
        requestConfig,
        cb,
        reloadPage,
        finally: finallyCb
      };
      state.isLoading = true;
      state.keepOpenIfError = keepOpenIfError;
    },
    openModalWindow: (state, action) => {
      const { data, modalID } = action.payload;
      state[modalID] = {
        data,
        opened: true
      };
    },
    closeModalWindow: (state, action) => {
      const { modalID } = action.payload;
      state[modalID] = {
        data: {},
        opened: false
      };
    },
    modalStopLoading: (state) => {
      state.isLoading = false;
    },
    modalStartLoading: (state) => {
      state.isLoading = true;
    }
  }
});

export const {
  openModalWindow,
  closeModalWindow,
  modalWindowSend,
  modalStopLoading,
  modalStartLoading
} = modalsSlice.actions;

export default modalsSlice.reducer;

export const ADD_USER_MODAL_ID = 'addUser';
export const ADD_DATA_MODAL_ID = 'addData';
export const EDIT_ROW_MODAL_ID = 'editRow';
export const EDIT_NOTE_MODAL_ID = 'editNote';
export const UPLOAD_MODAL_ID = 'uploadModal';
export const SEARCH_IP_MODAL_ID = 'searchIP';
export const SEARCH_CHARGERS_MODAL_ID = 'searchChargers';
export const SKI_SEARCH_MODAL_ID = 'skiSearch';
export const ADD_DEVICE_MODAL_ID = 'addDevice';
export const CHANGE_SMID_MODAL_ID = 'changeSMID';
export const DELETE_DATA_MODAL_ID = 'deleteData';
export const REMOVE_DATA_MODAL_ID = 'removeData';
export const CHANGE_EMAIL_MODAL_ID = 'changeEmail';
export const COLOR_PICKER_MODAL_ID = 'colorPicker';
export const ADD_EMPLOYEE_MODAL_ID = 'addEmployee';
export const EDIT_USER_ACCESS_MODAL_ID = 'editUserAccess';
export const CLOUD_STATUS_MODAL_ID = 'CloudStatus';
export const DATA_EXPORT_MODAL_ID = 'dataExportModal';
export const DATA_IMPORT_MODAL_ID = 'dataImportModal';
export const DATA_EXPORT_SUB_METER_MODAL_ID = 'dataExportSubMeterModal';
export const DOWNLOAD_FILE_MODAL_ID = 'downloadFile';
export const CHANGE_COLUMN_MODAL_ID = 'changeColumn';
export const REMOVE_GATEWAY_MODAL_ID = 'removeGateway';
export const DELETE_FILES_MODAL_ID = 'deleteFilesModal';
export const QR_CODE_SCANNER_MODAL_ID = 'QRCodeScanner';
export const INVITE_SUB_USER_MODAL_ID = 'inviteSubUser';
export const REMOVE_SUB_USER_MODAL_ID = 'removeSubUser';
export const REMOVE_CAR_CHARGER_MODAL_ID = 'removeCarCharger';
export const CHANGE_PASSWORD_MODAL_ID = 'changePassword';
export const GENERAL_SETTINGS_MODAL_ID = 'generalSettings';
export const DATE_PICKER_MODAL_MODAL_ID = 'datePickerModal';
export const CHOOSE_LICENSE_MODAL_ID = 'chooseLicenseWindow';
export const DASHBOARD_SETTINGS_MODAL_ID = 'dashboardSettings';
export const CONFIRMATION_WINDOW_MODAL_ID = 'confirmationWindow';
export const UPDATE_CONFIRMATION_MODAL_ID = 'updateConfirmation';
export const CREATING_NEW_USER_NOTE_MODAL_ID = 'creatingNewUserNote';
export const ADD_PV_INSTALLER_TO_OEM_MODAL_ID = 'addPvInstallerToOEM';
export const CONFIRM_REMOVE_GATEWAY_MODAL_ID = 'confirmRemoveGateway';
export const CONFIRM_PASS_MODAL_ID = 'confirmRebootGateway';
export const REMOVE_FIRMWARE_UPDATE_MODAL_ID = 'removeFirmwareUpdate';
export const ADD_END_USERS_TO_VIEWER_MODAL_ID = 'addEndUsersToViewer';
export const ADD_END_USERS_TO_PROP_MGMT_MODAL_ID = 'addEndUsersToPropMgmt';
export const ADD_END_USERS_TO_PROP_MGMT_WINDOW_MODAL_ID = 'addEndUsersToPropMgmtWindow';
export const ADD_CAR_CHARGERS_WINDOW_MODAL_ID = 'addCarChargers';
export const DATA_EXPORT_INFO_POPUP = 'dataExportInfoPopUp';
export const UPGRADE_TO_UNLOCK_POPUP = 'upgradeToUnlockPopUp';
export const CKW_LICENSE_RESTRICTIONS_MODAL = 'ckwLicenseRestrictionsModal';
