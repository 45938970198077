import React from 'react';
import PropTypes from 'prop-types';

const Info = ({ value }) => (
  <span className="company-detail-td">
    <span className="table-details-user-nifo">{value}</span>
  </span>
);

Info.propTypes = {
  value: PropTypes.string.isRequired
};

export default Info;
