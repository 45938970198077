import { from } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import { getAllInstallers, setAllInstallers, setAllConnectedInstallers, getAllConnectedInstallers } from './slice';

function oemGetInstallersEpic($action) {
  return $action.pipe(
    ofType(getAllInstallers),
    map((action) => action.payload),
    switchMap(({ url, params }) => from(api.get(url, { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setAllInstallers({ installers: result.list });
          }
          return addNotification({ type: 'error', text: result });
        }

        return addNotification({ type: 'error', text: result.message });
      })
    ))
  );
}

function getAllConnectedInstallersEpic($action) {
  return $action.pipe(
    ofType(getAllConnectedInstallers),
    map((action) => action.payload),
    switchMap(({ url, params }) => from(api.get(url, { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setAllConnectedInstallers({ installers: result.list });
          }
          return addNotification({ type: 'error', text: result });
        }

        return addNotification({ type: 'error', text: result.message });
      })
    ))
  );
}

export default combineEpics(
  oemGetInstallersEpic,
  getAllConnectedInstallersEpic
);
