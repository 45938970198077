import i18n from '../../../i18n';

/**
 * Generates options for function select
 * @function getUniqueTypesList
 * @memberof module:UserDevices
 * @returns {Object[]} Array of unique option. Type is an Object: { value, label }.
 */
const getFunctionOptions = () => [
  {
    label: i18n.t('switchOffBlocked'),
    value: 1
  },
  {
    label: i18n.t('switchOffBlockedPVAllowed'),
    value: 7
  },
  {
    label: i18n.t('restrictToMin'),
    value: 2
  },
  {
    label: i18n.t('restrictTo50'),
    value: 3
  },
  {
    label: i18n.t('increaseToMax'),
    value: 4
  },
  {
    label: i18n.t('fromGridMaximumUsage'),
    value: 5
  },
  {
    label: i18n.t('fromGridBlocked'),
    value: 6
  }
];

export default getFunctionOptions;
