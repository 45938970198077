import { createSelector } from '@reduxjs/toolkit';

import { formValueSelector } from 'redux-form';

const selectValue = formValueSelector('chartSettings');
const allDataSensors = (state) => selectValue(state, 'temperatures', 'batteriesSoc', 'devicesCurrentPower', 'invertersCurrentPower');

export const getAllDataSensorsSelector = createSelector(
  [allDataSensors],
  (dataSensors) => {
    if (!dataSensors) return [];

    const temperatures = Object.values(dataSensors.temperatures || []).filter((point) => typeof point === 'string');
    const batteriesSoc = Object.values(dataSensors.batteriesSoc || []).filter((point) => typeof point === 'string');
    const devicesCurrentPower = Object.values(dataSensors.devicesCurrentPower || []).filter((point) => typeof point === 'string');
    const invertersCurrentPower = Object.values(dataSensors.invertersCurrentPower || []).filter((point) => typeof point === 'string');

    return [...temperatures, ...batteriesSoc, ...devicesCurrentPower, ...invertersCurrentPower];
  }
);
