import * as actionTypes from './constants';

export function basicAuthSignIn({ login, password }) { // epic
  return {
    type: actionTypes.BASIC_AUTH_SIGN_IN,
    payload: {
      password,
      login
    }
  };
}
