import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { CustomTextInput } from '../../../components/ReduxFormFields';
import { required } from '../../validation';
import i18n from '../../../i18n';
import { CancelButton, PrimaryButton } from '../../../components/UIKit';
import ModalWindow from '../../ModalWindow';
import { closeModalWindow } from '../../ModalWindow/slice';

const formID = 'confirmPassForm';

const ConfirmPassWindow = (props) => {
  const { reset, modalID } = props;
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.modals[modalID]) || {};
  const formValues = useSelector(getFormValues(formID));
  const { data, opened } = modal;

  const equalToPassword = useCallback(
    (value) => (value && value === data?.password ? undefined : 'confirmPassError'),
    [data?.password]
  );

  const handleOnClose = () => dispatch(closeModalWindow({ modalID }));

  const handleSubmitHandler = (e) => {
    e.preventDefault();
    if (formValues?.password === data?.password) {
      data?.onSubmit();
      handleOnClose();
    }
  };

  useEffect(() => {
    if (!opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t(data?.label || 'confirmReboot')}</h5>
      </div>
      <div>
        <div className="modal-body">
          <div className="text-confirmation confirm-deleting-text">
            {i18n.t(data?.info || 'confirmRebootDesc')}
          </div>
          <form id={formID} className="confirmation-field" onSubmit={handleSubmitHandler}>
            <Field
              autoComplete="off"
              name="password"
              label={`${i18n.t('pass')} *`}
              className="m-input"
              type="password"
              component={CustomTextInput}
              validate={[required, equalToPassword]}
            />
          </form>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton
            form={formID}
            isSubmit
            i18nLabel="confirmBtn"
            disabled={!formValues?.password}
          />
        </div>
      </div>
    </ModalWindow>
  );
};

ConfirmPassWindow.propTypes = {
  reset: PropTypes.func.isRequired,
  modalID: PropTypes.string.isRequired
};

const form = reduxForm({
  form: formID
})(ConfirmPassWindow);

export default form;
