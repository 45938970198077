import { range as createRangeArray, remove as removeItem } from 'lodash';

import normalizeActiveDevices from './normalizeActiveDevices';
import _applyRange from './applyRange';
import createRange from './createRange';
import insertRange from './insertRange';
import updateDeviceList from './updateDeviceList';

/**
 * Main function for handling realtime updates for active devices.
 * Makes shallow copy of activity and devices.
 * @param timestamp {Number}
 * @param updates {Array}
 * @param minX {Number} Start of chart in milliseconds
 * @param prevActivity {Array} Previous activity of active devices
 * @param prevDevices {Array} Previous devices
 * @returns {{activity: Array, devices: Array}}
 */
const updateActiveDevices = (timestamp, updates, minX, prevActivity, prevDevices) => {
  const activity = [...prevActivity];
  const devices = [...prevDevices];

  const min = minX || Date.now();

  const missingDevices = createRangeArray(devices.length);
  const applyRange = _applyRange.bind(
    null,
    createRange,
    insertRange,
    activity,
    min
  );

  if (Array.isArray(updates)) {
    updates.forEach((newDevice) => {
      const device = updateDeviceList(devices, newDevice);

      if (device !== null) {
        removeItem(missingDevices, (n) => n === device.y);

        applyRange(
          timestamp,
          device.y,
          device.color,
          newDevice.state
        );
      }
    });
  }

  missingDevices.forEach((y) => {
    applyRange(
      timestamp,
      y,
      undefined,
      undefined,
      true
    );
  });

  normalizeActiveDevices(activity);

  return { devices, activity };
};

export default updateActiveDevices;
