const replace = {
  changeSMID: 'Change 3S ID',
  reqSMID: '3S ID *',
  SMID: '3S ID',
  SMIDTolltip: 'The 3S ID is a unique identifier of your device. You find this ID on the device below the QR code.',
  enableSmId: '3S ID',
  smidLabel: '3S ID',
  confirmDeletingDesc: 'Confirm deleting of all data of this gateway by entering the 3S ID. After pressing Confirm this process cannot be reversed.',
  confirmDeletingError: 'The ID does not match with the 3S ID. The gateway is not deleted.'
};

export default replace;
