import React from 'react';
import PropTypes from 'prop-types';
/**
 * Render function for React-Table. Column for email.
 * @memberof module:ReactTable
 * @param  {string} value - user or company's email
 */
const RenderEmail = ({ value }) => (
  <a href={`mailto:${value}`}>{value}</a>
);

RenderEmail.propTypes = {
  value: PropTypes.string.isRequired
};

export default RenderEmail;
