import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';
import PieChart from './PieChart';

import {
  INSTALLATION_ONGOING_COLOR,
  NOT_CONNECTED_STATUS_COLOR,
  CONNECTED_COLOR,
  OK_COLOR,
  WARNING_COLOR,
  ERROR_COLOR,
  NOT_CONNECTED_MONITORING_COLOR
} from '../../constants';

import './index.scss';

const OverviewPieCharts = (props) => {
  const { monitoring, status } = props;
  const { installationOngoing, connected, notConnected: notConnectedStatus } = status || {};
  const { error, notConnected: notConnectedMonitoring, ok, warning } = monitoring || {};

  const statusData = [
    {
      y: installationOngoing,
      color: INSTALLATION_ONGOING_COLOR,
      name: i18n.t('installationOngoing')
    },

    {
      y: notConnectedStatus,
      color: NOT_CONNECTED_STATUS_COLOR,
      name: i18n.t('not connected')
    },
    {
      y: connected,
      color: CONNECTED_COLOR,
      name: i18n.t('connected')
    }
  ];

  const monitoringData = [
    {
      y: ok,
      color: OK_COLOR,
      name: i18n.t('ok')
    },
    {
      y: warning,
      color: WARNING_COLOR,
      name: i18n.t('warning')
    },
    {
      y: error,
      color: ERROR_COLOR,
      name: i18n.t('error')
    },
    {
      y: notConnectedMonitoring,
      color: NOT_CONNECTED_MONITORING_COLOR,
      name: i18n.t('not connected')
    }
  ];

  return (
    <div className="overview-pie-charts-wrapper">
      <div className="status-pie-chart">
        <PieChart data={statusData} title={i18n.t('status')} />
      </div>
      <div className="monitoring-pie-chart">
        <PieChart data={monitoringData} title={i18n.t('monitoring')} />
      </div>
    </div>
  );
};

OverviewPieCharts.propTypes = {
  monitoring: PropTypes.instanceOf(Object),
  status: PropTypes.instanceOf(Object)
};

export default OverviewPieCharts;
