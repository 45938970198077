import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import ArrowDown from '../../../../../../assets/images/svg/ArrowDown.svg';
import { getFormattedListOfDays } from '../../../helper';
import i18n from '../../../../../../i18n';
import { SELECT_ALL } from '../../../constants';
import { additionalFields } from '../../../../../../containers/DevicesTable/utils';
import './index.scss';

const Closed = (props) => {
  const {
    data,
    handleToggleCollapse,
    switchTime,
    switchModeName,
    deviceType,
    userApp = undefined
  } = props;
  const slicedWeekDays = getFormattedListOfDays(data.activeDays).includes(SELECT_ALL)
    ? i18n.t(getFormattedListOfDays(data.activeDays)[0])
    : getFormattedListOfDays(data.activeDays).map((day) => i18n.t(day).slice(0, 3)).join(' ');

  return (
    <div className="closed">
      <div className="closed-time__container">
        <div className="closed-time__range">
          <span>
            <Field
              readOnly
              name={`${switchTime}.from`}
              component={(({ input }) => input.value)}
              type="time"
              className="closed-time__range-from"
            />
            {' '}
            -
            {' '}
            <Field
              readOnly
              name={`${switchTime}.to`}
              component={(({ input }) => input.value)}
              type="time"
              className="closed-time__range-from"
            />
          </span>
          <p>{slicedWeekDays}</p>
        </div>
        <div className="closed-time__switch">
          <h2>
            <Field
              readOnly
              name={`${switchTime}.${switchModeName}`}
              component={(({ input }) => additionalFields[switchModeName].options({ deviceType, isShow: true, userApp }).find(({ value }) => String(value) === String(input.value))?.label || '')}
              type="time"
              className="closed-time__range-from"
            />
          </h2>
        </div>
      </div>
      <div className="closed-button">
        <img src={ArrowDown} alt="Down" onClick={handleToggleCollapse} />
      </div>
    </div>
  );
};

Closed.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  handleToggleCollapse: PropTypes.func.isRequired,
  switchTime: PropTypes.string.isRequired,
  switchModeName: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  userApp: PropTypes.string
};

export default Closed;
