import React from 'react';
import PropTypes from 'prop-types';
import plusIcon from '../../../assets/images/svg/plus.svg';

import './index.scss';

const AddCirclePlus = (props) => {
  const { width = 45, handleClick, title = '' } = props;

  return (
    <div
      className="AddCirclePlus"
      title={title}
      style={{ width }}
    >
      <img
        style={{ height: width - 10 }}
        src={plusIcon}
        alt="plusIcon"
        onClick={handleClick}
      />
    </div>
  );
};

AddCirclePlus.propTypes = {
  handleClick: PropTypes.func.isRequired,
  width: PropTypes.number,
  title: PropTypes.string
};

export default AddCirclePlus;
