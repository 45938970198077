export const getLanguageCode = (languageName) => {
  switch (languageName) {
    case 'Deutsch':
    case 'Deutsch (Schweiz)':
      return 'deutsch_ch';
    case 'Deutsch (Deutschland)':
      return 'deutsch_de';
    default:
      return languageName.toLowerCase();
  }
};
