import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = (props) => {
  const { name, label, value, selected, disabled, needBR, callback, icon = '' } = props;

  const id = `radio_${name}_${value}`;
  return (
    <>
      <label htmlFor={id} className="customRadio">
        <input
          type="radio"
          name={name}
          id={id}
          onChange={callback}
          value={value}
          checked={selected}
          disabled={disabled}
        />
        <div className={`checkmark ${disabled ? 'disabled' : ''}`} />
        {icon && <img src={icon} alt="icon" />}
        {label}
      </label>
      {needBR && <br />}
    </>
  );
};

RadioInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Object)
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  callback: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  needBR: PropTypes.bool,
  icon: PropTypes.string
};

export default RadioInput;
