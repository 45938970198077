export const getMaxDetail = (subTypeRange) => {
  let maxDetail;
  switch (subTypeRange) {
    case 'y':
      maxDetail = 'year';
      break;
    case 'm':
      maxDetail = 'month';
      break;
    case 'w':
      maxDetail = 'week';
      break;
    default:
      maxDetail = 'date';
      break;
  }
  return maxDetail;
};
