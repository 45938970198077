import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../../i18n';
import energyMeasurementIcon from '../../../../../assets/images/svg/energy-measurement.svg';

const EnergyMeasurementTooltip = (props) => {
  const { convertedCurrentPower } = props;

  return (
    <>
      <img src={energyMeasurementIcon} alt="Energy Measurement Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {convertedCurrentPower}
          {i18n.t('kWPeakOutputUnits')}
        </span>
      </div>
    </>
  );
};

EnergyMeasurementTooltip.propTypes = {
  convertedCurrentPower: PropTypes.number.isRequired
};

export default EnergyMeasurementTooltip;
