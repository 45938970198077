import React from 'react';
import PropTypes from 'prop-types';

import '../index.scss';
import i18n from '../../../i18n';

const isNotConfirmed = 'pending user confirmation';

/**
 * Render function for React-Table. User name
 * @memberof module:ReactTable
 * @param  {string} value - company name
 */
const RenderUserNameMonitoring = (props) => {
  const { original: { user } } = props;
  const { first_name, last_name, status, email, sm_id } = user;

  if (first_name === 'Unknown User' && sm_id === 'Unknown Id') {
    return (
      <div className="d-flex align-items-center">
        <div className="yellow-warning-icon">!</div>
        {i18n.t('unknownUser')}
      </div>
    );
  }

  return ((status === isNotConfirmed) ? email : `${first_name} ${last_name}`);
};

RenderUserNameMonitoring.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderUserNameMonitoring;
