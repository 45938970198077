import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../containers/ModalWindow';
import {
  getModalsIsLoadingSelector,
  getModalsRemoveDataSelector
} from '../../redux-store/selectors/modals';
import { REMOVE_DATA_MODAL_ID, closeModalWindow } from '../../containers/ModalWindow/slice';
import { CancelButton, PrimaryButton } from '../../components/UIKit';
import { Preloader } from '../../components';

import i18n from '../../i18n';

/**
 * Renders delete End user confirmation modal window for Users page
 * @memberof module:UsersTable
 */
const DeleteEndUserModalWindow = (props) => {
  const { onSubmit } = props;
  const dispatch = useDispatch();
  const isLoading = useSelector(getModalsIsLoadingSelector);
  const modal = useSelector(getModalsRemoveDataSelector);
  const data = modal?.data || {};
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: REMOVE_DATA_MODAL_ID }));

  return (
    <ModalWindow modalID={REMOVE_DATA_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('delUser')}</h5>
      </div>
      {isLoading ? (
        <Preloader />
      ) : (
        <div>
          <div className="modal-body">
            <div className="text-confirmation">{i18n.t('confirmDelUser')}</div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton
              onClickHandler={onSubmit.bind(null, { id: data?.id })}
              type="delete"
            />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

DeleteEndUserModalWindow.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default DeleteEndUserModalWindow;
