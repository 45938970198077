import React from 'react';
import PropTypes from 'prop-types';
import { goBack } from 'react-router-redux';
import { useDispatch } from 'react-redux';

import ModalWindow from '../../containers/ModalWindow';
import { CancelButton, PrimaryButton } from '../../components/UIKit';
import { CREATING_NEW_USER_NOTE_MODAL_ID } from '../../containers/ModalWindow/slice';
import { ReactComponent as WarningIcon } from '../../assets/images/svg/warning.svg';
import { APP_ID } from '../../config';

import i18n from '../../i18n';

import './index.scss';

/**
 * Renders creating new user note window
 * @memberof module:ModalWindow
 */
const CreatingNewUserNoteWindow = (props) => {
  const { toggleModal } = props;
  const dispatch = useDispatch();

  const redirectToLogin = () => {
    toggleModal();

    dispatch(goBack());
  };

  return (
    <ModalWindow modalID={CREATING_NEW_USER_NOTE_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">
          <WarningIcon />
          <span className="ml-3">{i18n.t('creatingNewUserNoteTitle')}</span>
        </h5>
      </div>
      <div className="modal-body">
        <div className="text-confirmation">
          {APP_ID === 'solarManagerEndUserApp' ? i18n.t('creatingNewUserNoteContent') : i18n.t('createNewInstallerPopUp')}
        </div>
      </div>
      <div className="modal-footer">
        {APP_ID === 'solarManagerEndUserApp' ? (
          <>
            <CancelButton onClickHandler={toggleModal.bind(this, 0)} i18nLabel="goToSelfRegistration" />
            <PrimaryButton onClickHandler={toggleModal.bind(this, 1)} i18nLabel="imAnInstaller" />
          </>
        ) : (
          <>
            <CancelButton onClickHandler={redirectToLogin} i18nLabel="backToLoginPage" />
            <PrimaryButton onClickHandler={toggleModal} i18nLabel="createNewInstallerBtn" />
          </>
        )}
      </div>
    </ModalWindow>
  );
};

CreatingNewUserNoteWindow.propTypes = {
  toggleModal: PropTypes.func.isRequired
};

export default CreatingNewUserNoteWindow;
