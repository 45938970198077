import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Renders text input for redux form
 * @memberof module:ReduxFormFields
 */
export const CustomTextArea = (props) => {
  const { value, input, ...custom } = props;

  useEffect(() => {
    if (value) {
      input.onChange(value);
    }
  }, [value, input]);

  return (
    <textarea {...input} {...custom} />
  );
};

CustomTextArea.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.string
};
