import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ReactTable from 'react-table';

import { DataListV2 } from '../../../DataList';
import { ADD_END_USERS_TO_VIEWER_MODAL_ID, DELETE_DATA_MODAL_ID, modalWindowSend } from '../../../ModalWindow/slice';
import { dataListIDEndUsersForViewer, getEndUsersForViewerParams } from '../../constants';
import { AddEndUserToViewerWindow, DeleteViewerEndUserWindow } from '../../modals';
import { columnsEndUser } from '../../columns';
import { UserInfoSub } from '../../../../components/ReactTable/renderFunctions';
import { getNotAttachedToViewerEndUsers } from '../../slice';
import { dataListReloadData } from '../../../DataList/slice';
import { USERS_ATTACH_VIEWER_TO_USERS_URL, USERS_REMOVE_VIEWER_FROM_USERS_URL } from '../../../../api/apiUrls';

const EndUsersTab = (props) => {
  const {
    connectedEndUsersUrl,
    transformResponse,
    onExpandedChange,
    viewerID,
    expanded
  } = props;
  const dispatch = useDispatch();

  const columns = useMemo(() => columnsEndUser(), []);

  const sendDeleteEndUser = ({ id }) => dispatch(modalWindowSend(
    {
      modalID: DELETE_DATA_MODAL_ID,
      requestConfig: {
        method: 'put',
        url: USERS_REMOVE_VIEWER_FROM_USERS_URL,
        data: {
          viewerId: viewerID,
          usersId: [id]
        }
      },
      cb: () => {
        dispatch(getNotAttachedToViewerEndUsers({ params: { viewerId: viewerID, ...getEndUsersForViewerParams } }));
        return dataListReloadData({ listID: dataListIDEndUsersForViewer });
      }
    }));

  const sendAddEndUsers = (userIds) => {
    dispatch(modalWindowSend(
      {
        modalID: ADD_END_USERS_TO_VIEWER_MODAL_ID,
        requestConfig: {
          method: 'put',
          url: USERS_ATTACH_VIEWER_TO_USERS_URL,
          data: {
            viewerId: viewerID,
            users: userIds.map((id) => ({ _id: id, permission: 'read' }))
          }
        },
        cb: () => {
          dispatch(getNotAttachedToViewerEndUsers({ params: { viewerId: viewerID, ...getEndUsersForViewerParams } }));
          return dataListReloadData({ listID: dataListIDEndUsersForViewer });
        }
      }));
  };

  return (
    <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
      <DeleteViewerEndUserWindow onSubmit={sendDeleteEndUser} />
      <AddEndUserToViewerWindow handleSubmit={sendAddEndUsers} viewerUserId={viewerID} />

      <DataListV2
        listID={dataListIDEndUsersForViewer}
        listURL={connectedEndUsersUrl}
        params={{ ...getEndUsersForViewerParams }}
        transformResponse={transformResponse}
        Component={ReactTable}
        componentProps={{
          defaultPageSize: 0,
          className: '-highlight m-datatable__table',
          columns,
          SubComponent: UserInfoSub,
          manual: true,
          onExpandedChange,
          expanded
        }}
      />
    </div>
  );
};

EndUsersTab.propTypes = {
  viewerID: PropTypes.string.isRequired,
  connectedEndUsersUrl: PropTypes.string.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired,
  expanded: PropTypes.instanceOf(Object)
};

export default EndUsersTab;
