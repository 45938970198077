import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useGetPaymentMethodQuery, useUpdatePaymentMethodMutation } from '../../services/payments';
import Preloader from '../Preloader';
import { addNotification } from '../../containers/NotificationGenerator/slice';

const renderPayPal = (paymentMethodData) => (
  <div className="d-flex flex-column align-items-center">
    <img
      style={{ height: '25px' }}
      src={paymentMethodData.paymentMethodConfiguration?.resolvedImageUrl}
      alt={paymentMethodData.paymentMethodConfiguration?.name}
    />
    <span className="pt-2 text-center">{paymentMethodData.name}</span>
  </div>
);

export const PaymentsMeans = () => {
  const { t } = useTranslation();
  // get user id from url
  const { userID } = useParams();
  const dispatch = useDispatch();
  const { data: paymentMethodData, isLoading: isPaymentMethodLoading } = useGetPaymentMethodQuery({ userID });
  const [updatePaymentMethod, { isLoading, error, data: updatedPaymentMethodData }] = useUpdatePaymentMethodMutation();

  useEffect(() => {
    if (updatedPaymentMethodData) {
      window.location.href = updatedPaymentMethodData.javascriptUrl;
    }
  }, [updatedPaymentMethodData]);

  useEffect(() => {
    if (error) {
      dispatch(addNotification({ type: 'error', text: error.message }));
    }
  }, [dispatch, error]);

  if (isPaymentMethodLoading) {
    return <Preloader />;
  }

  const isTWINT = paymentMethodData?.paymentMethodConfiguration?.name === 'TWINT';
  const isPayPal = paymentMethodData?.paymentMethodConfiguration?.name === 'PayPal';

  return (
    <div className="d-flex flex-column align-items-center my-4" style={{ gap: '15px' }}>
      <p className="payment-method-label">{t('paymentMeans')}</p>
      {paymentMethodData && (
        <div className="payment-method-text">
          {isPayPal && renderPayPal(paymentMethodData)}
          {isTWINT && (
            <div className="d-flex flex-column align-items-center">
              <img
                style={{ height: '40px' }}
                src={paymentMethodData.paymentMethodConfiguration?.resolvedImageUrl}
                alt={paymentMethodData.paymentMethodConfiguration?.name}
              />
            </div>
          )}

          {!isPayPal && !isTWINT
            && (
              <>
                <div className="d-flex align-items-center mb-2">
                  <img
                    style={{ height: '25px' }}
                    src={paymentMethodData.paymentMethodConfiguration?.resolvedImageUrl}
                    alt={paymentMethodData.paymentMethodConfiguration?.name}
                  />
                  <span className="pl-2 pr-4">{paymentMethodData.paymentMethodConfiguration?.name}</span>

                  <span>
                    {paymentMethodData.name.includes('X') ? paymentMethodData.name.replace(/X/g, '*').slice(-9) : paymentMethodData.name}
                  </span>
                </div>
                {paymentMethodData.expiresOn && (
                  <div className="d-flex justify-content-between px-4">
                    <span className="">
                      {t('endsOn')}
                      :
                    </span>
                    <span>{moment(paymentMethodData.expiresOn).format('MM/YY')}</span>
                  </div>
                )}
              </>
            )}
        </div>
      )}
      <div>
        <button
          style={{ minWidth: '160px' }}
          type="button"
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-save-chan"
          onClick={() => updatePaymentMethod({ userID })}
          disabled={isLoading}
        >
          {paymentMethodData ? t('editTooltip') : t('add')}
        </button>

      </div>
    </div>
  );
};
