import { createSelector } from '@reduxjs/toolkit';

const getDocumentationFiles = (state) => state.documentation?.files;
const getDocumentationFilesLoading = (state) => state.documentation?.filesLoading;
const getDocumentationSelectedFiles = (state) => state.documentation?.selectedFiles;
const getDocumentationFilesForUpload = (state) => state.documentation?.filesForUpload;
const getDocumentationIsUploadLoading = (state) => state.documentation?.isUploadLoading;

export const getDocumentationFilesSelector = createSelector([getDocumentationFiles], (files) => files);
export const getDocumentationFilesLoadingSelector = createSelector([getDocumentationFilesLoading], (filesLoading) => filesLoading);
export const getDocumentationSelectedFilesSelector = createSelector([getDocumentationSelectedFiles], (selectedFiles) => selectedFiles);
export const getDocumentationFilesForUploadSelector = createSelector([getDocumentationFilesForUpload], (filesForUpload) => filesForUpload);
export const getDocumentationIsUploadLoadingSelector = createSelector([getDocumentationIsUploadLoading], (isUploadLoading) => isUploadLoading);
