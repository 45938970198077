import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { CustomTextInput, UserPhoneInput, CountrySelector } from '../ReduxFormFields';

import { checkValidPhone, email, required, minLength, maxLength } from '../../containers/validation';

import i18n from '../../i18n';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);
const maxLen100 = maxLength(100);

export const BillingAddress = ({
  userRole,
  isBillingAddressEnabled = true,
  roleDependencies = {},
  billingReferenceRoleDependencies = roleDependencies,
  country = '',
  zipValidation = () => {},
  wrapperClasses = 'row justify-content-between align-items-start',
  colClasses = 'col-lg-5'
}) => {
  const additionalPhoneValidation = userRole === 'property_management' ? [required] : [];

  return (
    <div className={wrapperClasses}>
      <div className={colClasses}>
        <div className="form-group m-form__group input-field input-field-company">
          <Field
            name="billingAddress.company_name"
            component={CustomTextInput}
            label={i18n.t('userCompany')}
            className="m-input"
            autoComplete="off"
            validate={isBillingAddressEnabled ? [maxLen100] : []}
            {...roleDependencies}
          />
        </div>
        <div className="form-group m-form__group input-field input-field">
          <Field
            name="billingAddress.first_name"
            component={CustomTextInput}
            label={i18n.t('reqFirstName')}
            className="m-input"
            autoComplete="off"
            validate={isBillingAddressEnabled ? [required, minLen3, maxLen50] : []}
            {...roleDependencies}
          />
        </div>
        <div className="form-group m-form__group input-field input-field">
          <Field
            name="billingAddress.last_name"
            component={CustomTextInput}
            label={i18n.t('reqLastName')}
            className="m-input"
            autoComplete="off"
            validate={isBillingAddressEnabled ? [required, minLen3, maxLen50] : []}
            {...roleDependencies}
          />
        </div>
        <div className="form-group m-form__group input-field input-field-email">
          <Field
            name="billingAddress.email"
            component={CustomTextInput}
            validate={isBillingAddressEnabled ? [required, email] : []}
            label={`${i18n.t('mail')} *`}
            className="m-input"
            {...roleDependencies}
          />
        </div>
        <div className="form-group m-form__group input-field input-field">
          <Field
            name="billingAddress.billingReference"
            component={CustomTextInput}
            label={`${i18n.t('billingReference')}`}
            className="m-input"
            {...billingReferenceRoleDependencies}
          />
        </div>
      </div>
      <div className={colClasses}>
        <div className="form-group m-form__group input-field">
          <Field
            name="billingAddress.phoneNumber"
            component={UserPhoneInput}
            label={i18n.t('phoneNumber')}
            className="m-input"
            autoComplete="off"
            country={country}
            isRequired={userRole === 'property_management'}
            validate={isBillingAddressEnabled ? [checkValidPhone, ...additionalPhoneValidation] : []}
            {...roleDependencies}
          />
        </div>
        <div className="form-group m-form__group input-field input-filed-flags-select">
          <div className="flags-select-label">
            {i18n.t('Country')}
          </div>
          <Field
            name="billingAddress.country"
            id="billingAddress.country"
            component={CountrySelector}
            buttonClassName="country-btn"
            label={i18n.t('Country')}
            className="m-input flagSelectClosed"
            {...roleDependencies}
            validate={isBillingAddressEnabled ? [required] : []}
          />
        </div>
        <div className="form-group m-form__group input-field input-field-street">
          <Field
            name="billingAddress.street"
            component={CustomTextInput}
            label={`${i18n.t('street')} *`}
            className="m-input"
            autoComplete="off"
            validate={isBillingAddressEnabled ? [minLen3, maxLen50, required] : []}
            {...roleDependencies}
          />
        </div>
        <div className="row city-zip">
          <div className="form-group m-form__group input-field col-7 city-inp">
            <Field
              name="billingAddress.city"
              component={CustomTextInput}
              label={`${i18n.t('city')} *`}
              className="m-input"
              autoComplete="off"
              validate={isBillingAddressEnabled ? [minLen3, maxLen50, required] : []}
              {...roleDependencies}
            />
          </div>
          <div className="form-group m-form__group input-field offset-1 col-4 zip-inp">
            <Field
              name="billingAddress.zip"
              component={CustomTextInput}
              label={`${i18n.t('zip')} *`}
              className="m-input"
              autoComplete="off"
              validate={isBillingAddressEnabled ? [zipValidation, required] : []}
              {...roleDependencies}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BillingAddress.propTypes = {
  isBillingAddressEnabled: PropTypes.bool,
  roleDependencies: PropTypes.instanceOf(Object),
  billingReferenceRoleDependencies: PropTypes.instanceOf(Object),
  country: PropTypes.string,
  zipValidation: PropTypes.func,
  wrapperClasses: PropTypes.string,
  colClasses: PropTypes.string,
  userRole: PropTypes.string
};
