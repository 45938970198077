import omit from 'lodash/omit';

export const getInitialValuesForChangeSettingsModal = (settings, resolution) => {
  /**
   * Temperatures (currentWaterTemp)
   */
  const temperatures = Array.isArray(settings?.temperatures)
    ? settings.temperatures.reduce((result, device) => {
      result[device._id] = device.showWaterTemp;
      result[`${device._id}-color`] = device.color;
      return result;
    }, {})
    : {};

  /**
   * Devices current power (currentPower)
   */
  const devicesCurrentPower = Array.isArray(settings?.devicesCurrentPower)
    ? settings.devicesCurrentPower.reduce((result, device) => {
      result[device._id] = device.showCurrentPower;
      result[`${device._id}-color`] = device.color;
      return result;
    }, {})
    : {};

  /**
   * Inverters current power (currentPowerInvSm)
   */
  const invertersCurrentPower = Array.isArray(settings?.invertersCurrentPower)
    ? settings.invertersCurrentPower.reduce((result, device) => {
      result[device._id] = device.showCurrentPowerInvSm;
      result[`${device._id}-color`] = device.color;
      return result;
    }, {})
    : {};

  /**
   * Batteries SOC (SOC)
   */
  const batteriesSoc = Array.isArray(settings?.batteriesSoc)
    ? settings.batteriesSoc.reduce((result, device) => {
      result[device._id] = device.showBatterySOC;
      result[`${device._id}-color`] = device.color;
      return result;
    }, {})
    : {};

  return {
    ...omit(settings, 'temperatures'),
    temperatures,
    devicesCurrentPower,
    invertersCurrentPower,
    batteriesSoc,
    resolution
  };
};
