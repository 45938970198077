import React from 'react';
import { Redirect } from 'react-router';
import { updateCookies, updateJwt } from '../../api';

const BurkhalterAuth = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  if (token) {
    updateCookies(token);
    updateJwt(token);
  }
  return <Redirect to="/" />;
};

export default BurkhalterAuth;
