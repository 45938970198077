import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getSignInUserSelector } from '../../../redux-store/selectors/signIn';
import DropDownList from '../../UIKit/DropDownList';
import i18n from '../../../i18n';
import { getInstalledSMSelectedSelector } from '../../../redux-store/selectors/installedSM';
import { useRebootGatewaysMutation } from '../../../containers/InstalledSM/services';
import { addNotification } from '../../../containers/NotificationGenerator/slice';
import { CONFIRM_PASS_MODAL_ID, openModalWindow } from '../../../containers/ModalWindow/slice';

const getDropdownWidth = (lang) => {
  switch (lang) {
    case 'French':
      return '171px';
    case 'Deutsch (Schweiz)':
    case 'Deutsch (Deutschland)':
    case 'Deutsch':
      return '155px';
    case 'Italian':
      return '125px';
    case 'Danish':
      return '129px';
    default:
      return '125px';
  }
};

/**
 * Render function for React-Table. Column for reboot installed devices
 * @memberof module:ReactTable
 * @param  {string} value - reboot status of user
 */
const RenderRebootDropdown = (props) => {
  const { language } = useSelector(getSignInUserSelector);
  const selectedGateways = useSelector(getInstalledSMSelectedSelector);
  const [open, setOpen] = useState(false);
  const [rebootGateways, { error: rebootError, isSuccess: isRebootSuccess }] = useRebootGatewaysMutation();
  const dispatch = useDispatch();
  const rebootPassword = 'LargeRollout';

  useEffect(() => {
    if (isRebootSuccess) {
      dispatch(addNotification({ type: 'success', text: i18n.t('rebootRequestSuccessful') }));
    }

    if (rebootError) {
      dispatch(addNotification({ type: 'error', text: i18n.t('rebootRequestError') }));
    }
  }, [dispatch, isRebootSuccess, rebootError]);

  const openConfirmRebootPopup = (onSubmit) => {
    dispatch(
      openModalWindow({
        modalID: CONFIRM_PASS_MODAL_ID,
        data: { onSubmit, password: rebootPassword }
      })
    );
  };

  const rebootRequest = (isForceReboot) => {
    const payload = {
      gateways: props?.original?._id ? [props?.original?._id] : selectedGateways,
      isForceReboot
    };

    if (selectedGateways.length > 100) {
      payload.password = rebootPassword;
    }

    rebootGateways({ data: payload });
  };

  if (props.isOnlyReboot) {
    return (
      <button
        onClick={() => rebootRequest(false)}
        disabled={props.disabled}
        type="button"
        className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst software-update-btn flex-1"
      >
        <span>{i18n.t('rebootRequest')}</span>
      </button>
    );
  }

  const menuItems = [
    {
      onClick:
        selectedGateways.length > 100 && !(props?.original?._id)
          ? openConfirmRebootPopup.bind(null, rebootRequest.bind(null, false))
          : rebootRequest.bind(null, false),
      value: 'rebootRequest',
      key: 'rebootRequest'
    },
    {
      onClick:
        selectedGateways.length > 100 && !(props?.original?._id)
          ? openConfirmRebootPopup.bind(null, rebootRequest.bind(null, true))
          : rebootRequest.bind(null, true),
      value: 'forceRebootRequest',
      key: 'forceRebootRequest'
    }
  ];

  return (
    <DropDownList
      customStyles={{ width: getDropdownWidth(language) }}
      i18TitleValue={i18n.t('rebootRequest')}
      dropDownType="reboot"
      menuItems={menuItems}
      original={props?.original}
      setOpen={setOpen}
      open={open}
      disabled={!(props?.original?._id) && !selectedGateways.length}
    />
  );
};

RenderRebootDropdown.propTypes = {
  original: PropTypes.instanceOf(Object),
  isOnlyReboot: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RenderRebootDropdown;
