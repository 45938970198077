import { from } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import { getOEMs, getOEMsByID, setOEMs } from './slice';
import { OEM_CONNECTED_OEMS_URL } from '../../api/apiUrls';

function getOEMsEpic($action) {
  return $action.pipe(
    ofType(getOEMs),
    map((action) => action.payload),
    switchMap(() => (
      from(api.get(OEM_CONNECTED_OEMS_URL)).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          if (!result.error) {
            if (Array.isArray(result.list)) {
              return setOEMs({ oems: result.list });
            }

            return addNotification({ type: 'error', text: result });
          }

          return addNotification({ type: 'error', text: result.message });
        })
      ))
    )
  );
}

function getOEMsByIDEpic($action) {
  return $action.pipe(
    ofType(getOEMsByID),
    map((action) => action.payload),
    switchMap(({ url, params }) => (
      from(api.get(url, { params })).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          if (!result.error) {
            if (Array.isArray(result.list)) {
              return setOEMs({ oems: result.list });
            }

            return addNotification({ type: 'error', text: result });
          }

          return addNotification({ type: 'error', text: result.message });
        })
      )
    ))
  );
}

export default combineEpics(
  getOEMsEpic, getOEMsByIDEpic
);
