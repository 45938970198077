import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, SubmissionError } from 'redux-form';
import { Box, Button } from '@mui/material';
import { TariffStep } from '../../UserDetailsTabs/components/GeneralInfo/TariffStep';
import NestedStepForm from './NestedStepForm';
import { sortByTime } from '../../../../utils';

const StepDay = (props) => {
  const { daySteps, label, highTariff, lowTariff, field, subField, fields } = props;
  const [isAddStep, setIsAddStep] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  const handleSubmit = ({ type, tariffOption, fromTime, elementIndex }) => {
    if (type === 'add') {
      if (daySteps.some((step) => step.fromTime === fromTime)) {
        throw new SubmissionError({ fromTime: 'timeAlreadyExists' });
      }

      const newStep = { fromTime, tariffOption };

      // we need to sort the steps by time for the correct order
      const data = [...daySteps, newStep].sort(sortByTime);
      fields.removeAll();
      data.forEach((step) => fields.push(step));
    }

    if (type === 'edit') {
      if (daySteps.some((step, index) => index !== elementIndex && step.fromTime === fromTime)) {
        throw new SubmissionError({ from: 'timeAlreadyExists' });
      }

      const newStep = { fromTime, tariffOption };
      fields.remove(elementIndex);
      fields.insert(elementIndex, newStep);
    }
    setIsAddStep(false);
    setIsEdit(false);
  };

  const handleAdd = (isOpen) => {
    setIsAddStep(isOpen);
    setIsEdit(false);
    setInitialValues(isOpen ? { type: 'add' } : null);
  };

  const handleEdit = (isOpen, data) => {
    setIsEdit(isOpen);
    setIsAddStep(false);
    setInitialValues(isOpen ? { type: 'edit', ...data } : null);
  };

  const handleDelete = (index) => {
    fields.remove(index);
    setIsEdit(false);
    setInitialValues(null);
  };

  const renderEditComponent = (data) => (
    <Button
      disableElevation
      disableRipple
      disabled={isEdit || isAddStep}
      variant="text"
      sx={{
        color: 'secondary.main',
        py: 0,
        px: 1,
        minWidth: 'auto',
        fontSize: 16,
        '&:disabled': {
          opacity: 0.5,
          color: 'secondary.main'
        }
      }}
      style={{ backgroundColor: 'transparent' }}
      onClick={() => handleEdit(!isEdit, data)}
    >
      <i className="la la-pen" />
    </Button>
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor:
        '#F7F7F7',
      p: 2,
      borderRadius: '7px',
      mb: 1,
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        cursor: 'pointer',
        width: '0.2em',
        height: '0.2em'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'primary.main',
        outline: '1px solid primary.main'
      }
    }}
    >
      <NestedStepForm
        label={label}
        isAddStep={isAddStep}
        isEdit={isEdit}
        daySteps={[...daySteps].map((step, index) => ({ ...step, elementIndex: index }))}
        initialValues={initialValues}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        form={`nested${field}${subField}`}
        onSubmit={handleSubmit}
      />
      <TariffStep
        steps={[...daySteps].map((step, index) => ({ ...step, elementIndex: index }))}
        highTariff={highTariff}
        lowTariff={lowTariff}
        styles={{ padding: '4px 6px', fontSize: 16, height: 40, color: 'secondary.main' }}
        subStyles={{ fontSize: 12 }}
        renderEditComponent={renderEditComponent}
        isBlur={isAddStep || isEdit}
      />
    </Box>
  );
};

export const StepsEditFormBySeason = (props) => {
  const { formValues, field } = props;
  const dataForStep = [
    {
      daySteps: formValues[field]?.mondayFriday || [],
      label: 'mondayFriday',
      highTariff: formValues.prices?.highTariff,
      lowTariff: formValues.prices?.lowTariff,
      name: `doubleTariff.${field}.mondayFriday`,
      subField: 'mondayFriday',
      component: StepDay
    },
    {
      daySteps: formValues[field]?.saturday || [],
      label: 'saturday',
      highTariff: formValues.prices?.highTariff,
      lowTariff: formValues.prices?.lowTariff,
      name: `doubleTariff.${field}.saturday`,
      subField: 'saturday',
      component: StepDay
    },
    {
      daySteps: formValues[field]?.sunday || [],
      label: 'sunday',
      highTariff: formValues.prices?.highTariff,
      lowTariff: formValues.prices?.lowTariff,
      name: `doubleTariff.${field}.sunday`,
      subField: 'sunday',
      component: StepDay
    }
  ];

  // eslint-disable-next-line react/jsx-props-no-spreading
  return dataForStep.map((data) => <FieldArray key={`doubleTariff${data.field}${data.name}`} {...data} field={`doubleTariff.${field}`} />);
};

StepsEditFormBySeason.propTypes = {
  field: PropTypes.string.isRequired,
  formValues: PropTypes.instanceOf(Object).isRequired
};

StepDay.propTypes = {
  daySteps: PropTypes.instanceOf(Array).isRequired,
  highTariff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lowTariff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  subField: PropTypes.string.isRequired,
  fields: PropTypes.instanceOf(Object).isRequired
};
