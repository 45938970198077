import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { replace } from 'react-router-redux';
import Select from 'react-select';

import i18n, {
  changeLanguageTo,
  getShortLanguageName
} from '../../../i18n';
import { queryURLFormat } from '../../../utils';
import { options } from './constants';

/**
 * Renders language selector for guests page
 * @memberof module:UIKit
 */
const LanguageSelector = () => {
  const dispatch = useDispatch();
  /**
   * Handles language changes for selector
   * @memberof module:UIKit
   * @param {Object} selected
   * @param {string} selected.value Selected language
   */
  const onChangeHandle = (selected) => {
    const { value } = selected;
    const pathName = window.location.pathname;
    if (pathName === '/login') {
      dispatch(replace(pathName + queryURLFormat({ l: getShortLanguageName(value) })));
    }
    changeLanguageTo(value, true);
  };

  return (
    <Select
      clearable={false}
      searchable={false}
      options={options}
      className="topLanguageSelector react-select-container"
      classNamePrefix="react-select"
      onChange={onChangeHandle}
      value={options.find((option) => (option.value === i18n.language)) || null}
    />
  );
};

export default withTranslation('')(LanguageSelector);
