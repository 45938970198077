import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

const PlantsData = (props) => {
  const { plants = 0, supportContracts = 0 } = props;

  return (
    <div className="plants-data">
      <div>
        <div className="title">{i18n.t('numOfPlants')}</div>
        <div className="value">
          {plants}
        </div>
      </div>
      <div className="vertical-line" />
      <div>
        <div className="title">{i18n.t('onlySupportContracts')}</div>
        <div className="value">
          {supportContracts}
        </div>
      </div>
    </div>
  );
};

PlantsData.propTypes = {
  plants: PropTypes.number,
  supportContracts: PropTypes.number
};

export default PlantsData;
