import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { initialize } from 'redux-form';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import {
  receiveSensorsConfigurationFile,
  getSensorsConfigurationFile,
  usersSensorsReceived,
  usersSensorsRequest,
  setDeviceIdRtu,
  getZohoRedirectRequest
} from './slice';
import { addNotification } from '../NotificationGenerator/slice';
import { modalStopLoading } from '../ModalWindow/slice';

import { dataListReloadData } from '../DataList/slice';
import { SENSORS_LIST_ID } from '../UserDetails/constants';
import { SENSORS_AUTH_OAUTH2_URL, SENSORS_SET_DEVICE_ID_URL, USERS_SOLAR_MANAGERS_USERS_URL, ZOHO_REDIRECT_URL } from '../../api/apiUrls';
import i18n from '../../i18n';

function getUserInfoEpic($action) {
  return $action.pipe(
    ofType(usersSensorsRequest),
    map((action) => action.payload.userId),
    switchMap((userId) => from(api.get(`${USERS_SOLAR_MANAGERS_USERS_URL}/${userId}`)).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (result && !result.error) {
          if (result?.gateway) {
            return usersSensorsReceived({ data: result?.gateway?.sensors });
          }
        }

        return addNotification({ type: 'error', text: result.error.message || result.error });
      })
    )
    )
  );
}
function getZohoUrl($action) {
  return $action.pipe(
    ofType(getZohoRedirectRequest),
    map((action) => action.payload),
    switchMap(({ support_url, isReplace }) => from(api.get(`${ZOHO_REDIRECT_URL}?redirectUrl=${encodeURIComponent(support_url)}`)).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (result?.error) return addNotification({ type: 'error', text: i18n.t('error') });

        if (isReplace) {
          window.document.write(result);
          return addNotification({ type: 'success', text: i18n.t('success') });
        }

        const win = window.open('', 'Zoho', `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${window.innerWidth},height=${window.innerHeight},top=0,left=0,right=0`);
        win.document.write(result);
        return addNotification({ type: 'success', text: i18n.t('success') });
      })
    )
    )
  );
}

function getSensorsConfigurationFileEpic($action) {
  return $action.pipe(
    ofType(getSensorsConfigurationFile),
    switchMap(() => from(api.get(SENSORS_AUTH_OAUTH2_URL)).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (result && !result.error) {
          return receiveSensorsConfigurationFile({ data: result });
        }

        return addNotification({ type: 'error', text: result.error.message || result.error });
      })
    )
    )
  );
}

function setDeviceIdEpic($action, $state) {
  return $action.pipe(
    ofType(setDeviceIdRtu),
    map((action) => action.payload),
    switchMap(({ sensorId, value, modalId }) => {
      const state = $state.value;

      const {
        signIn: {
          user: {
            role: { type: myRoleType }
          }
        },
        userDevices: {
          user
        },
        form: {
          editDeviceForm: { values: listOfValues }
        }
      } = state;

      const url = myRoleType === 'end_user'
        ? `${SENSORS_SET_DEVICE_ID_URL}/my/${sensorId}`
        : `${SENSORS_SET_DEVICE_ID_URL}/${user?._id || user.id}/${sensorId}`;

      return from(api.put(url, { Device_ID_RTU: `${value}` })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (result?.error) {
            return addNotification({ type: 'error', text: result.error.message || result.error });
          }

          return of(
            initialize({ ...listOfValues, data: result?.data }),
            modalStopLoading({ modalID: modalId }),
            dataListReloadData({ listID: SENSORS_LIST_ID }),
            addNotification({ type: 'success', text: i18n.t('success') })
          );
        })
      );
    })
  );
}

export default combineEpics(getUserInfoEpic, getSensorsConfigurationFileEpic, setDeviceIdEpic, getZohoUrl);
