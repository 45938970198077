/* eslint-disable react/button-has-type */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

import './index.scss';

const PrimaryButton = memo((props) => {
  const {
    customButtonClass = '',
    onClickHandler,
    i18nLabel,
    isSubmit,
    isLargeButton,
    disabled,
    type,
    form
  } = props;
  const isExistIcon = !!type;

  const buttonOptions = {
    save: {
      iconClassName: 'fa fa-save',
      label: 'save'
    },
    delete: {
      iconClassName: 'fa fa-trash',
      label: 'delete'
    },
    remove: {
      iconClassName: 'fa fa-trash',
      label: 'removeBtn'
    },
    send: {
      iconClassName: 'fa fa-paper-plane',
      label: 'send'
    },
    archive: {
      iconClassName: 'fa fa-archive',
      label: 'archive'
    },
    upload: {
      iconClassName: 'la la-download download-icon',
      label: 'uploadFiles'
    },
    import: {
      iconClassName: 'la la-upload',
      label: 'uploadFiles'
    },
    plus: {
      iconClassName: 'fa fa-plus',
      label: 'add'
    },
    default: {
      iconClassName: 'fa fa-save',
      label: 'save'
    }
  };

  const params = {
    type: 'button',
    onClick: onClickHandler,
    'data-cy': 'primary-button',
    className: `btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user btn-popup-sav ${customButtonClass ?? ''}`,
    disabled,
    ...(isSubmit
      ? {
        form,
        disabled,
        type: 'submit',
        onClick: null
      }
      : {}),
    ...(isLargeButton
      ? {
        'data-cy': 'large-button',
        disabled,
        className: `is-large-button ${customButtonClass ?? ''}`
      }
      : {})
  };

  const { iconClassName, label } = buttonOptions?.[type] || buttonOptions.default;
  return (
    <button {...params}>
      {isExistIcon && (
        <>
          <i className={iconClassName} />
          {' '}
        </>
      )}
      {i18n.t(i18nLabel || label)}
    </button>
  );
});

PrimaryButton.propTypes = {
  type: PropTypes.string,
  customButtonClass: PropTypes.string,
  onClickHandler: PropTypes.func,
  i18nLabel: PropTypes.string,
  form: PropTypes.string,
  disabled: PropTypes.bool,
  isLargeButton: PropTypes.bool,
  isSubmit: PropTypes.bool
};

export default PrimaryButton;
