import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { AddCirclePlus } from '../../../../../components/UIKit';
import {
  ADD_CAR_CHARGERS_WINDOW_MODAL_ID,
  openModalWindow,
  REMOVE_CAR_CHARGER_MODAL_ID
} from '../../../../ModalWindow/slice';
import { getUserDevicesUser_IdSelector, getUserDevicesUserGatewaySensorsSelector } from '../../../../../redux-store/selectors/userDevices';
import { getSignInUser_IdSelector, getSignInUserGatewaySensorsSelector } from '../../../../../redux-store/selectors/signIn';
import i18n from '../../../../../i18n';
import '../index.scss';
import { getDataListSelector } from '../../../../../redux-store/selectors/dataList';
import { Tooltip } from '../../../../../components';
import { READ_ONLY_ROLES } from '../../../../../constants';

/**
 * Car chargers headers
 *  @type {*}
 */
const headers = [
  {
    title: 'name'
  },
  {
    title: 'tag'
  }
];

const CarChargers = (props) => {
  const { rowData, myRoleType, isReadOnlyPermission } = props;
  const dispatch = useDispatch();

  const currentCarId = rowData?.original?._id;
  const signInUserId = useSelector(getSignInUser_IdSelector);
  const userDeviceId = useSelector(getUserDevicesUser_IdSelector);
  const userId = myRoleType === 'end_user' ? signInUserId : userDeviceId;

  const signInUserSensors = useSelector(getSignInUserGatewaySensorsSelector);
  const userDeviceSensors = useSelector(getUserDevicesUserGatewaySensorsSelector);
  const sensors = myRoleType === 'end_user' ? signInUserSensors : userDeviceSensors;
  const carChargers = sensors.filter((device) => device.type === 'Car Charging');
  const assignedCarChargers = useSelector(getDataListSelector)?.cars?.data?.find((car) => car._id === currentCarId)?.carChargers;
  const carChargersId = assignedCarChargers?.map((device) => device._id) || [];
  const availableCarChargers = carChargers.filter((device) => !carChargersId.includes(device._id));
  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);

  const handleRemove = (carChargerId) => dispatch(
    openModalWindow({
      modalID: REMOVE_CAR_CHARGER_MODAL_ID,
      data: { userId, id: carChargerId, carChargersId, deviceId: currentCarId, deviceType: 'Car', rowData: rowData?.original }
    })
  );
  const addNewCarCharger = (id) => dispatch(
    openModalWindow({
      modalID: ADD_CAR_CHARGERS_WINDOW_MODAL_ID,
      data: { id, carChargers: availableCarChargers, carChargersId, deviceType: 'Car', rowData: rowData?.original }
    })
  );

  return (
    <div className="wrapper">
      <div className="sub-component-title">
        {i18n.t('carChargers')}
        {' '}
        <Tooltip title={i18n.t('assignCarChargerToCar')} />
      </div>
      <div className="sub-component-container">
        <table>
          {assignedCarChargers?.length ? (
            <>
              <thead>
                <tr>
                  {headers.map(({ title }) => (
                    <th key={title}>{i18n.t(title)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {assignedCarChargers.map(({ device_group, tag, _id }) => (
                  <tr key={_id}>
                    <td>{device_group}</td>
                    <td>{tag?.name || ''}</td>
                    {!(isReadOnlyRole || isReadOnlyPermission) ? (
                      <td className="sub-component-delete">
                        <a
                          title={i18n.t('delete')}
                          onClick={handleRemove.bind(null, _id)}
                          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
                        >
                          <i className="la la-close" />
                        </a>
                      </td>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <thead className="no-data-yet">
              <tr>
                <th>{i18n.t('NoCarChargers')}</th>
              </tr>
            </thead>
          )}
        </table>
        {!(isReadOnlyRole || isReadOnlyPermission) && !assignedCarChargers?.length && (
          <div className="sub-component-add-icon">
            <AddCirclePlus width={38} handleClick={addNewCarCharger.bind(null, userId)} />
          </div>
        )}
      </div>
    </div>
  );
};

CarChargers.propTypes = {
  myRoleType: PropTypes.string,
  rowData: PropTypes.instanceOf(Object).isRequired,
  isReadOnlyPermission: PropTypes.bool.isRequired
};

export default CarChargers;
