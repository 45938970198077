import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { push } from 'react-router-redux';

import { api, updateCookies, updateJwt } from '../../api';
import { handleError, trimAllFields } from '../../api_helper';
import { addNotification } from '../NotificationGenerator/slice';
import { receiveSignIn } from '../SignIn/slice';
import { resetPassSend } from './actions';

import i18n, { changeLanguageTo } from '../../i18n';
import { AUTH_RESET_PASSWORD_URL } from '../../api/apiUrls';

function resetPassSendEpic(action$) {
  return action$.pipe(
    ofType(resetPassSend),
    map((action) => action.payload.fields),
    switchMap((fields) => (
      from(api.post(AUTH_RESET_PASSWORD_URL, { ...trimAllFields(fields) })).pipe(
        catchError(handleError),
        mergeMap((result) => {
          if (result?.jwt) {
            updateCookies(result?.jwt);
            updateJwt(result?.jwt);

            changeLanguageTo(result.user, true);
            return of(
              receiveSignIn({ user: result.user, needGoBack: false }),
              push(result.user.role.type === 'end_user' ? '/' : '/users/'),
              addNotification({ type: 'success', text: i18n.t('resetPasswordSuccess') })
            );
          }

          return of(
            addNotification({ type: 'error', text: result })
          );
        })
      )
    ))
  );
}

export default combineEpics(resetPassSendEpic);
