import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { dataListSendRequest } from '../slice';
import { getDataListSelector } from '../../../redux-store/selectors/dataList';

/**
 * Filtering component DataList
 * @memberof module:DataList
 */
const DataListFilter = (props) => {
  const { componentProps = {}, Component, listID } = props;
  const dispatch = useDispatch();

  const allDataLists = useSelector(getDataListSelector);
  const [dataList, setDataList] = useState({});

  /**
   * Replaces old filters by new.
   * If need to remove a parameter set empty string as value of parameter
   * @param {Object} filters Paramets list for filtering
   * @param {string|number} filters.nameOfParameter Parameter to filter with
   */
  const addFilters = (filters) => {
    Object.keys(filters).forEach((filterID) => {
      const filterValue = filters[filterID];
      if (filterValue === '') {
        delete dataList.params[filterID];
      } else {
        dataList.params[filterID] = filterValue;
      }
    });
    dispatch(dataListSendRequest({ listID, dataList }));
  };

  useEffect(() => {
    if (allDataLists[listID]) {
      setDataList({
        ...allDataLists[listID],
        params: {
          ...allDataLists[listID].params,
          _start: 0
        }
      });
    }
  }, [allDataLists, listID]);

  const allParams = dataList?.params || {};
  return (
    <Component
      onChange={addFilters}
      {...componentProps}
      allParams={allParams}
    />
  );
};

DataListFilter.propTypes = {
  Component: PropTypes.func.isRequired,
  listID: PropTypes.string.isRequired,
  componentProps: PropTypes.instanceOf(Object)
};

export default DataListFilter;
