import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import ModalWindow from '../../ModalWindow';
import { getModalsRemoveDataSelector } from '../../../redux-store/selectors/modals';

import i18n from '../../../i18n';
import { REMOVE_DATA_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

/**
 * Renders delete employee confirmation modal window for employee page
 * @memberof module:Employee
 */
const DeleteEmployeeWindow = (props) => {
  const { onSubmit } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getModalsRemoveDataSelector);
  const data = modal ? modal.data : {};
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: REMOVE_DATA_MODAL_ID }));

  return (
    <ModalWindow modalID={REMOVE_DATA_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('delEmployee')}</h5>
      </div>
      <div>
        <div className="modal-body">
          <div className="text-confirmation">{i18n.t('confirmDelEmployee')}</div>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton onClickHandler={onSubmit.bind(null, { id: data?.id || data?._id })} type="delete" />
        </div>
      </div>
    </ModalWindow>
  );
};

DeleteEmployeeWindow.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default DeleteEmployeeWindow;
