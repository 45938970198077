import {
  RenderInputDeviceName,
  RenderLoadGuardDescription,
  RenderInputDeviceFunction,
  RenderAffectedDevices,
  RenderButtons
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';

const fullAccessRoles = [
  'root',
  'solar_admin',
  'property_management',
  'oem',
  'pv_installer',
  'pv_installer_employee',
  'pv_installer_employee_read_install',
  'oem_employee'
];

/**
 * Creates load-guard columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 */
const loadGuard = (myRoleType, isReadOnly, width = {}, userId = null) => ([
  {
    sortable: false,
    width: 35
  },
  {
    Header: i18n.t('inputDevice'),
    id: 'inputDevice',
    accessor: 'device_group',
    minWidth: width.device_group,
    Cell: RenderInputDeviceName,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('description'),
    id: 'description',
    accessor: 'description',
    minWidth: width.description,
    Cell: RenderLoadGuardDescription,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('function'),
    id: 'inputDeviceFunction',
    accessor: 'inputDeviceFunction',
    minWidth: width.inputDeviceFunction,
    Cell: RenderInputDeviceFunction,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('affectedDevices'),
    id: 'affectedDevices',
    accessor: 'affectedDevices',
    minWidth: width.affectedDevices,
    Cell: RenderAffectedDevices,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: '',
    accessor: 'buttons',
    width: myRoleType !== 'viewer' ? width.buttons : undefined,
    className: 'action-buttons',
    Cell: (row) => {
      const additionalConditionFoRemoveByType = {
        feed_in: { preLink: '/production-regulations' }
      };

      return RenderButtons(row.original, {
        edit: fullAccessRoles.includes(myRoleType) && !isReadOnly && row.original.type === 'feed_in' ? { preLink: `/feed-in-management/edit/${userId}/` } : false,
        editRow: fullAccessRoles.includes(myRoleType) && !isReadOnly && row.original.type !== 'feed_in',
        removeDevice: fullAccessRoles.includes(myRoleType) && !isReadOnly && ((additionalConditionFoRemoveByType[row.original.type] || true))
      });
    },
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES
    ].includes(myRoleType)
  }

]);

export default loadGuard;
