import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { ConvertValue } from '../utils';

const ProductionStatistics = (props) => {
  const { productionData } = props;
  const { today, last7Days, thisYear } = productionData || {};

  return (
    <div className="production-statistics">
      <div className="today">
        <div className="title">{i18n.t('today')}</div>
        {Number.isFinite(today) ? ConvertValue(today) : ConvertValue(0)}
      </div>
      <div className="vertical-line" />
      <div className="last-7-days">
        <div className="title">{i18n.t('last7days')}</div>
        {Number.isFinite(last7Days) ? ConvertValue(last7Days) : ConvertValue(0)}
      </div>
      <div className="vertical-line" />
      <div className="year">
        <div className="title">{i18n.t('year')}</div>
        {Number.isFinite(thisYear) ? ConvertValue(thisYear) : ConvertValue(0)}
      </div>
    </div>
  );
};

ProductionStatistics.propTypes = {
  productionData: PropTypes.instanceOf(Object)
};

export default ProductionStatistics;
