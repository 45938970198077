import { useEffect } from 'react';

const redirectToStore = () => {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const { searchParams } = new URL(document.location);

  const redirectUrlAndroid = searchParams.get('redirectUrlAndroid');
  const redirectUrlIos = searchParams.get('redirectUrlIos');

  if (isAndroid && redirectUrlAndroid) {
    window.location.href = redirectUrlAndroid;
  }

  if (isIos && redirectUrlIos) {
    window.location.href = redirectUrlIos;
  }
};

export const useRedirectToStore = () => {
  useEffect(() => {
    redirectToStore();
  }, []);
};
