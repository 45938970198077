import React from 'react';
import { useDispatch } from 'react-redux';
import { goBack } from 'react-router-redux';

import i18n from '../../i18n';

import './index.scss';

const defaultImageName = 'english';

const languageImagesMap = {
  deutsch: 'deutsch',
  danish: 'danish',
  english: defaultImageName
};

const DashboardInfo = () => {
  const dispatch = useDispatch();
  const language = languageImagesMap[i18n.language] || defaultImageName;

  return (
    <div
      className="dashboard-info"
      style={{ background: `url(/../images/dashboard-info/${language}.png) no-repeat center`, 'background-size': 'contain' }}
    >
      <div className="dashboard-info-container">
        <button type="button" onClick={() => dispatch(goBack())}>
          {i18n.t('back')}
        </button>
      </div>
    </div>
  );
};

export default DashboardInfo;
