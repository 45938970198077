import { createSelector } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { getSignInUserRoleType } from '../../redux-store/selectors/signIn';

const getInvitedUserPermission = (state) => state.dashboardV2?.user?.permission;
const getIsInstallationCompleted = (state) => state.dashboardV2?.user?.gateway?.isInstallationCompleted;

export const currentValuesSelectorV2 = createSelector(
  [(state) => state.dashboardV2CurrentValues, (state) => state.dashboardV2.user],
  (currentValues, user) => {
    const data = pick(currentValues, ['consumption', 'production', 'difference', 'battery']);
    const temperaturesSettings = user?.chart_settings?.temperatures;

    const temperatureSensors = Array.isArray(temperaturesSettings)
      ? temperaturesSettings.filter(({ showWaterTemp }) => showWaterTemp)
      : [];

    const temperatureSensor = temperatureSensors?.find((sensor) => currentValues?.temperatures?.[sensor?._id]);
    const waterTemperature = currentValues?.temperatures?.[temperatureSensor?._id];

    return {
      ...data,
      water_temperature: waterTemperature,
      waterTemperatureColor: temperatureSensor?.tag?.color || temperatureSensor?.deviceLineColor || '#f66400'
    };
  });

export const isInvitedUserHasReadOnlyPermissionDashboardSelector = createSelector(
  [getSignInUserRoleType, getInvitedUserPermission],
  (myRoleType, permission) => (['viewer', 'pv_installer', 'oem'].includes(myRoleType) ? permission === 'read' : false)
);

export const isPvEmployeeInstallHasReadOnlyAccessDashboardSelector = createSelector(
  [getSignInUserRoleType, getIsInstallationCompleted],
  (myRoleType, isInstallationCompleted) => (myRoleType === 'pv_installer_employee_read_install' ? !!isInstallationCompleted : false)
);
