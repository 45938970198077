import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { EDIT_NOTE_MODAL_ID, openModalWindow } from '../../../containers/ModalWindow/slice';
import i18n from '../../../i18n';

import '../index.scss';

const getEmailSubject = (props) => {
  const {
    company: { name: companyName } = {},
    user: { zip, street, city } = {},
    gateway: { sm_id } = {}
  } = props;

  return companyName?.match(/helion/gi)
    ? `ST_H1 | ${street}, ${zip} ${city} | Ref. Helion ONE ${sm_id}`
    : sm_id;
};

/**
 * Render function for React-Table. Column with buttons for Monitoring page.
 * @memberof module:ReactTable
 * @param  {Object} props
 * @param  {Object} props.user - user object
 * @param  {string} props.user._id - user _id
 * @param  {string} props.user.id - user id
 * @param  {string} props.user.sm_id - user solar manager id
 * @param  {string} props._id - monitoring error id
 * @param  {string} props.note - monitoring error note
 * @param  {Object} showBtns - Defines the button what will be shown.
 */
const RenderMonitoringTableButtons = (props, showBtns) => {
  const {
    user: {
      _id,
      id = '',
      email
    },
    _id: ticketId,
    note
  } = props;
  const dispatch = useDispatch();

  const onClickEditNote = () => dispatch(openModalWindow({ modalID: EDIT_NOTE_MODAL_ID, data: { ticketId, note } }));

  return (
    <>
      {showBtns?.sendEmail && (
        <a
          title={i18n.t('sendEmail')}
          href={`mailto:${email}?subject=${getEmailSubject(props)}`}
          className="send-email-btn btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i id="send-email-icon" className="send-email-icon la la-envelope" />
        </a>
      )}
      {showBtns?.editNote && (
        <a
          title={i18n.t('editNote')}
          onClick={onClickEditNote}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i className="la la-edit" />
        </a>
      )}
      {showBtns?.devices && (
        <Link
          to={`${showBtns.devices.preLink}${id || _id}/`}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
          title={i18n.t('settings')}
        >
          <i className="la la-cog" />
        </Link>
      )}
    </>
  );
};

RenderMonitoringTableButtons.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    id: PropTypes.string,
    sm_id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  _id: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  showBtns: PropTypes.shape({
    sendEmail: PropTypes.bool,
    editNote: PropTypes.bool,
    devices: PropTypes.bool
  }).isRequired
};

export default RenderMonitoringTableButtons;
