import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { CHOOSE_LICENSE_MODAL_ID, openModalWindow } from '../../../containers/ModalWindow/slice';
import { getPaymentUrlRequest } from '../../../containers/Payments/slice';
import i18n from '../../../i18n';
import { APP_ID, EZEE_APP_ID, CKW_APP_ID } from '../../../config';

import '../index.scss';

const allowedRoles = [EZEE_APP_ID, CKW_APP_ID].includes(APP_ID) ? ['solar_admin', 'root', 'pv_installer', 'oem'] : ['solar_admin', 'root', 'pv_installer'];

/**
 *
 * @param {Object} props
 * @param {Object} props.value
 * @param {string} props.value.userId
 * @param {string} props.value.licenseId
 * @param {string} props.value.licenseTitle
 * @param {boolean} props.value.hide - hide or show modal window with licenses
 * @param {string} props.value.parentListId - short page name or key
 * @param {string} myRoleType - user role
 */
const RenderPaidLicense = (props, myRoleType) => {
  const {
    value: {
      userId, licenseId, licenseTitle, hide, parentListId
    } = {},
    original: {
      initialLicense,
      _id
    }
  } = props;
  const dispatch = useDispatch();

  const { name } = initialLicense || {};

  if (hide) return null;

  const handlerOpenModal = () => dispatch(openModalWindow({
    modalID: CHOOSE_LICENSE_MODAL_ID,
    data: {
      userId,
      license: licenseId,
      parentListId,
      gatewayId: _id
    }
  }));

  const handlerClick = () => {
    if (!userId) {
      handlerOpenModal();
      return;
    }

    dispatch(getPaymentUrlRequest({ userId }));
  };

  return (
    <>
      {allowedRoles.includes(myRoleType) && (
        <a
          title={i18n.t('editTooltip')}
          onClick={handlerClick}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill render-paid-license"
        >
          <i className="la la-pencil" />
        </a>
      )}
      <span>{licenseTitle || name}</span>
    </>
  );
};

RenderPaidLicense.propTypes = {
  value: PropTypes.instanceOf(Object),
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderPaidLicense;
