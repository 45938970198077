import { clone } from 'lodash';

const transformResponse = (data) => {
  const clonedData = clone(data.list);
  const user = {
    first_name: 'Unknown User',
    last_name: '',
    sm_id: 'Unknown Id',
    id: 'Unknown Id',
    _id: 'Unknown Id'
  };
  const gateway = {
    sm_id: 'Unknown Id',
    _id: 'Unknown Id',
    signal: 'not connected',
    name: 'Unknown Name',
    id: 'Unknown Id',
    lastErrorDate: new Date()
  };

  const company = {
    _id: 'Unknown Id',
    name: 'Unknown Name'
  };

  clonedData.forEach((obj) => {
    if (!obj.user) {
      obj.user = user;
    }

    if (!obj.gateway) {
      obj.gateway = gateway;
    }

    if (!obj.company) {
      obj.company = company;
    }
  });

  return {
    total: data.total,
    data: clonedData
  };
};

export default transformResponse;
