import moment from 'moment';

/**
 * Return string of date with format 'DD.MM.YYYY HH:MM'
 * @param {*} dateString example '2022-06-21T12:46:17.523Z'
 * @returns string example '21.06.2022 15:06'
 */
const formatFullDateTime = (dateString) => (dateString ? moment(dateString).format('DD.MM.YYYY HH:mm') : '');

/**
 * Render function for React-Table. Column of formatted date and time
 * @memberof module:ReactTable
 * @param  {(timestamp|dateString)} value - Time and Date
 */
const RenderFullDate = ({ value }) => formatFullDateTime(value);

export default RenderFullDate;
