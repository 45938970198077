import { FRIDAY, MONDAY, SATURDAY, SELECT_ALL, SUNDAY, THURSDAY, TUESDAY, WEDNESDAY } from '../constants';

/**
 * Array of names of days
 * @constant {Array<string>} array of values
 */
export const arrOfNamesDays = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

/**
 * Array of default numbers of days
 * @constant {Array<number>}
 */
export const defaultListOfDays = [1, 1, 1, 1, 1, 1, 1];

/**
 * Get days options list
 * @param {Array<object>} arrOfNumbs array of object of days, [..., { label: Monday, value: true, key: 'f3c2c9e2-e86d-4fa5-8236-85785318a1f9'}]
 * @returns {Array<number>} return array of numbers
 */
// eslint-disable-next-line arrow-body-style
export const getActiveDaysOptions = (rawDaysList = defaultListOfDays) => {
  return rawDaysList.map((number, index) => ({
    value: number,
    label: arrOfNamesDays[index],
    key: index
  }));
};

/**
 * Get formatted list of days
 * @param {Array<number>} listOfNumbs array of numbers
 * @returns {string} return string of days or string 'Select all'
 */
export const getFormattedListOfDays = (rawDays = defaultListOfDays) => {
  if (rawDays.every((number) => number === 1)) {
    return [SELECT_ALL];
  }
  return rawDays.reduce((acm, number, index) => (number === 1 ? [...acm, arrOfNamesDays[index]] : acm), []);
};

/**
 * Converts time string to minutes. Example: '02:10' becomes 130
 * @param {string} hhmm string of time, example '02:10'
 * @returns {number} return time converted to minutes
 */
export const convertTimeIntoMinutes = (hhmm) => hhmm.split(':').map(Number).reduceRight((minutes, hours) => minutes + (hours * 60));
