import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import moment from 'moment-timezone';
import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import {
  getChartData,
  getPhasesData,
  receiveChartData,
  receiveInitUser,
  receivePhasesData,
  requestInitUser,
  sendIOTCommand,
  sendIOTCommandError,
  sendIOTCommandSuccess
} from './slice';
import { fillDataGaps } from './utils';
import { DATA_SENSORS_LOAD_DATA_URL, GATEWAY_TURN_ON_LOAD_MANAGEMENT_URL, USERS_SOLAR_MANAGERS_USERS_URL } from '../../api/apiUrls';

function initUserEpic($action) {
  return $action.pipe(
    ofType(requestInitUser),
    map((action) => action.payload.userId),
    switchMap((userId) => from(api.get(`${USERS_SOLAR_MANAGERS_USERS_URL}/${userId}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result.error) {
          return of(
            addNotification({ type: 'error', text: result.message })
          );
        }

        return of(receiveInitUser({ result }));
      })
    ))
  );
}

function getPhasesDataEpic(action$) {
  return action$.pipe(
    ofType(getPhasesData),
    map((action) => action.payload.data),
    switchMap(({ myself, gatewayId, smartMeterId }) => {
      const url = myself ? '/data/real-time' : `/data/real-time/${gatewayId}`;

      return from(api.get(`${url}?isWeb=true`)).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (result.error) {
            return of(
              addNotification({ type: 'error', text: result.message })
            );
          }

          const smartMeterData = result?.rawSensorData?.sensorsData?.find(
            (device) => device?._id === smartMeterId
          );
          return of(receivePhasesData({ result: smartMeterData }));
        })
      );
    })
  );
}

function getChartEpic(action$) {
  return action$.pipe(
    ofType(getChartData),
    map((action) => action.payload.data),
    switchMap(({ gatewayId, from: fromDate, to }) => {
      // add 1 millisecond to toDate to include the last point
      const toDate = moment(to).add(1, 'milliseconds').toISOString();

      const url = `${DATA_SENSORS_LOAD_DATA_URL}?gatewayId=${gatewayId}&from=${fromDate}&to=${toDate}`;

      return from(api.get(url)).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (result.error) {
            return of(
              addNotification({ type: 'error', text: result.message })
            );
          }

          const L1 = result.L1 ? fillDataGaps(result.L1, fromDate, toDate) : [];
          const L2 = result.L2 ? fillDataGaps(result.L2, fromDate, toDate) : [];
          const L3 = result.L3 ? fillDataGaps(result.L3, fromDate, toDate) : [];

          return of(receiveChartData({ result: { L1, L2, L3 } }));
        })
      );
    })
  );
}
function sendIOTCommandEpic(action$) {
  return action$.pipe(
    ofType(sendIOTCommand),
    map((action) => action.payload.gatewayId),
    switchMap((gatewayId) => {
      const url = `${GATEWAY_TURN_ON_LOAD_MANAGEMENT_URL}/${gatewayId}`;

      return from(api.get(url)).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          if (result.error) {
            return sendIOTCommandError();
          }

          return sendIOTCommandSuccess();
        })
      );
    })
  );
}

export default combineEpics(initUserEpic, getPhasesDataEpic, getChartEpic, sendIOTCommandEpic);
