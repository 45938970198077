/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  FormEmployee,
  FormCompany,
  FormSubUser,
  FormSupport,
  FormAdmin,
  FormUser
} from './index';

import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';

/**
 * Renders edit profile layout depending on role type
 * @function GetLayout
 * @memberof module:SettingProfile
 * @param {Object} options Contains userRoleType and any another props that will be passed to the layout component
 * @param {string} options.userRoleType Role type of user
 */
export const GetLayout = ({ userRoleType = '', ...restProps }) => {
  const installersRoles = ['pv_installer', 'oem'];

  if (!userRoleType) {
    return null;
  }

  if (userRoleType === 'end_user') {
    return (
      <FormUser {...restProps} userRoleType={userRoleType} />
    );
  }
  if (userRoleType === 'sub_user') {
    return (
      <FormSubUser {...restProps} userRoleType={userRoleType} />
    );
  }
  if (installersRoles.includes(userRoleType)) {
    return (
      <FormCompany {...restProps} userRoleType={userRoleType} />
    );
  }
  if ([...EMPLOYEE_ROLE_TYPES, 'oem_employee', 'oem_employee_read_only'].includes(userRoleType)) {
    return (
      <FormEmployee {...restProps} userRoleType={userRoleType} />
    );
  }
  if (userRoleType === 'solar_admin') {
    return (
      <FormAdmin {...restProps} userRoleType={userRoleType} />
    );
  }

  return (
    <FormSupport
      {...restProps}
      userRoleType={userRoleType}
      isNeedToShowBillingInfo={['property_management'].includes(userRoleType)}
    />
  );
};

GetLayout.propTypes = {
  userRoleType: PropTypes.string
};
