/**
 * Formats query string from location
 * @param queryObject {Object}
 * @returns {string}
 */
const queryURLFormat = (queryObject) => {
  const keys = Object.keys(queryObject);
  if (keys.length === 0) return '';
  const queries = [];
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = queryObject[key];
    if (typeof value !== 'undefined') {
      queries.push(`${key}=${value}`);
    }
  }
  return `?${queries.join('&')}`;
};

export default queryURLFormat;
