import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Field } from 'redux-form';
import { CustomTextInput } from '../CustomTextInput';
import { normalizeLength } from '../../../containers/validation';

import i18n from '../../../i18n';

/**
 * Render DescriptionTagField
 * @function DescriptionTagField
 * @memberof module:UserDevices
 * @param {Object} props
 * @param {string} props.deviceType one of: inverters, smart-meter, input-devices, ...
 */
export const DescriptionTagField = memo((props) => {
  const { deviceType } = props;

  return (
    ['input-devices'].includes(deviceType) ? (
      <div className="form-group m-form__group input-field">
        <Field
          name="description"
          component={CustomTextInput}
          label={i18n.t('description')}
          className="m-input"
          normalize={normalizeLength(25)}
        />
      </div>
    ) : null
  );
});

DescriptionTagField.propTypes = {
  deviceType: PropTypes.string.isRequired
};
