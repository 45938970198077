import moment from 'moment-timezone';

/**
 * Get date which should be disabled in datepicker view
 * @param {object} current moment object
 * @returns {boolean}
 */
const disableDate = (current) => current.startOf('day').isAfter(moment().startOf('day'));

export default disableDate;
