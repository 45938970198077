import moment from 'moment';

/**
 * Render function for React-Table. Column of formatted date and time
 * @memberof module:ReactTable
 * @param  {(timestamp|dateString)} value - Time and Date
 */
const RenderDateAndTime = ({ value }) => (value ? moment(value).format('D.M.YYYY HH:mm') : '');

export default RenderDateAndTime;
