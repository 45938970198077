import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

import { signInCheckJWT, signInInvalidJWT, signInGoBackIfSucc } from '../../SignIn/slice';
import { Preloader } from '../../../components';
import { getSignInUserSelector, getSignInVerifyingJWTSelector } from '../../../redux-store/selectors/signIn';
import { updateJwt } from '../../../api';

export default (Component, allowedRoles, properties) => {
  /**
   * Analog of ProtectedRoute but used for iOS app
   * @memberof module:PermissionChecker
   */
  const ProtectedRoute = (props) => {
    const { match } = props;
    const dispatch = useDispatch();

    const { jwt } = match.params;

    const user = useSelector(getSignInUserSelector);
    const verifyingJWT = useSelector(getSignInVerifyingJWTSelector);
    updateJwt(jwt);

    useEffect(() => {
      if (jwt) {
        dispatch(signInCheckJWT());
      } else {
        dispatch(signInInvalidJWT());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verifyingJWT) {
      return <Preloader />;
    }

    if (user) {
      if (!allowedRoles || allowedRoles.includes(user.role.type)) {
        return (
          <Suspense fallback={<Preloader />}>
            <Component
              {...props}
              {...properties}
              user={user}
              verifyingJWT={verifyingJWT}
              myRoleType={user.role.type}
              myUserID={user._id}
              runByApp
            />
          </Suspense>
        );
      }
      return <Redirect to="/" />;
    }
    dispatch(signInGoBackIfSucc({ needGoBackAfterLogin: true }));
    return <Redirect push to="/login" />;
  };

  ProtectedRoute.propTypes = {
    match: PropTypes.instanceOf(Object).isRequired
  };

  return withTranslation()(ProtectedRoute);
};
