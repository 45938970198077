import {
  RenderSignal,
  RenderButtons,
  RenderDevicesError,
  RenderHeaderWithTooltip,
  RenderDateAndTime,
  RenderSoc
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';

/**
 * Creates smart-meter columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 */
const cars = (myRoleType, isReadOnly, width = {}) => ([
  {
    Header: i18n.t('name'),
    id: 'name',
    accessor: (d) => i18n.t(d.name),
    minWidth: width.name,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('signal'),
    accessor: 'signal',
    id: 'signal',
    minWidth: width.signal,
    Cell: RenderSignal,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('remainingDistance'),
    id: 'remainingDistance',
    accessor: (d) => (d.remainingDistance ? `${d.remainingDistance}km` : ''),
    minWidth: width.remainingDistance,
    filterable: false,
    sortable: false,
    show: true
  },
  {
    Header: i18n.t('batterySoc'),
    accessor: 'soc',
    id: 'soc',
    minWidth: width.signal,
    filterable: false,
    sortable: false,
    Cell: RenderSoc,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: i18n.t('lastUpdate'),
    accessor: 'lastUpdate',
    Cell: (row) => RenderDateAndTime({ value: row.value }),
    id: 'lastUpdate',
    minWidth: width.signal,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('error'),
      tooltipText: i18n.t('errorsHintTooltip'),
      placement: 'right-start',
      style: {
        verticalAlign: 'middle',
        marginLeft: '6px'
      }
    }),
    accessor: 'errorcodes',
    id: 'errorcodes',
    minWidth: width.errorcodes,
    Cell: RenderDevicesError,
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'viewer',
      'oem',
      'pv_installer',
      'oem_employee',
      'oem_employee_read_only',
      ...EMPLOYEE_ROLE_TYPES,
      'end_user'
    ].includes(myRoleType)
  },
  {
    Header: '',
    accessor: 'buttons',
    id: 'buttons',
    width: !isReadOnly ? width.buttons : undefined,
    className: 'action-buttons',
    // eslint-disable-next-line arrow-body-style
    Cell: (row) => {
      return RenderButtons(row.original, {
        editRow: [
          'root',
          'solar_admin',
          'property_management',
          'oem',
          'pv_installer',
          'pv_installer_employee',
          'pv_installer_employee_read_install',
          'oem_employee',
          'end_user',
          'viewer'
        ].includes(myRoleType) && !isReadOnly,
        removeDevice: [
          'root',
          'solar_admin',
          'oem',
          'property_management',
          'pv_installer',
          'pv_installer_employee',
          'pv_installer_employee_read_install',
          'oem_employee',
          'end_user',
          'viewer'
        ].includes(myRoleType) && !isReadOnly
      });
    },
    filterable: false,
    sortable: false,
    show: [
      'root',
      'solar_admin',
      'property_management',
      'oem',
      'viewer',
      'pv_installer',
      'pv_installer_employee',
      'pv_installer_employee_read_install',
      'oem_employee',
      'end_user'
    ].includes(myRoleType)
  }
]);

export default cars;
