import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { email, required, notEqualValue, parseToLowerCase } from '../../validation';
import { CustomTextInput } from '../../../components/ReduxFormFields';
import ModalWindow from '../../ModalWindow';
import { changeEmail, CHANGE_EMAIL_FORM } from '../constants';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsChangeEmailSelector } from '../../../redux-store/selectors/modals';

import i18n from '../../../i18n';
import { CHANGE_EMAIL_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

/**
 * Checks if the old email is UNEQUAL to the new email
 */
const newEmailShouldDiffer = notEqualValue('old_email', i18n.t('newEmailShouldDiffer'));

/**
 * Modal window for changing email from edit profile page.
 * @memberof module:SettingProfile
 */
const ChangeEmailWindow = (props) => {
  const { handleSubmit, pristine, reset } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getModalsChangeEmailSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: CHANGE_EMAIL_MODAL_ID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={CHANGE_EMAIL_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('changeMail')}</h5>
      </div>
      <div className="modal-body">
        <form
          id={changeEmail}
          onSubmit={handleSubmit}
          className="m-login__form m-form pop-up-form add-sm-us"
        >
          <div className="form-group m-form__group input-field">
            <Field
              name="email"
              component={CustomTextInput}
              label={i18n.t('reqNewMail')}
              className="m-input"
              autoComplete="off"
              validate={[required, email, newEmailShouldDiffer]}
              parse={parseToLowerCase}
              preventAuto
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <PrimaryButton form={changeEmail} disabled={pristine} isSubmit i18nLabel="changeBtn" type="save" />
      </div>
    </ModalWindow>
  );
};

ChangeEmailWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: CHANGE_EMAIL_FORM
})(ChangeEmailWindow);

export default form;
