import React from 'react';
import { Field } from 'redux-form';
import { FormControlLabel, Radio, Typography } from '@mui/material';

import { renderRadioGroup } from '../../../../components/ReduxFormFields';
import i18n from '../../../../i18n';

const renderLabel = (label) => (
  <Typography sx={{ color: '#131313' }} variant="h4">
    {label}
  </Typography>
);

export const ControlSection = () => (
  <>
    <Typography sx={{ mt: 2, color: 'secondary.dark' }} variant="body2">
      {i18n.t('tariffType')}
    </Typography>
    <Field
      sx={{
        justifyContent: 'space-between',
        minWidth: 'max-content',
        color: 'red'
      }}
      row
      name="tariffType"
      component={renderRadioGroup}
    >
      <FormControlLabel
        value="single"
        control={<Radio disableRipple />}
        label={renderLabel(i18n.t('single'))}
      />
      <FormControlLabel
        value="double"
        control={<Radio disableRipple />}
        label={renderLabel(i18n.t('double'))}
      />
      <FormControlLabel
        value="dynamic"
        control={<Radio disableRipple />}
        label={renderLabel(i18n.t('dynamic'))}
      />
    </Field>
  </>
);
