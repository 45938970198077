import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { required } from '../../containers/validation';
import {
  REMOVE_DATA_MODAL_ID,
  closeModalWindow,
  openModalWindow
} from '../../containers/ModalWindow/slice';
import ModalWindow from '../../containers/ModalWindow';
import i18n from '../../i18n';
import { CustomTextInput } from '../../components/ReduxFormFields';
import { CancelButton, PrimaryButton } from '../../components/UIKit';
import { CONFIRM_REMOVE_USER_MODAL_ID } from './constants';
import { getModalsConfirmRemoveUserSelector } from '../../redux-store/selectors/modals';

const formID = 'confirmDeleteUserForm';

const ConfirmDeleteUserWindow = (props) => {
  const { reset, userRole } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getModalsConfirmRemoveUserSelector);
  const formValues = useSelector(getFormValues(formID));
  const { data, opened } = modal;

  const equalToUserEmailOrUserName = useCallback(
    (value) => (value && value === (data?.email || data?.userName) ? undefined : 'confirmUserDeletingError'),
    [data?.email, data?.userName]
  );

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: CONFIRM_REMOVE_USER_MODAL_ID }));

  const handleSubmitHandler = (e) => {
    e.preventDefault();
    if (formValues?.userEmail === (data?.email || data?.userName)) {
      handleOnClose();
      dispatch(openModalWindow({ modalID: REMOVE_DATA_MODAL_ID, data: { id: data?.id } }));
    }
  };

  useEffect(() => {
    if (!opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <ModalWindow modalID={CONFIRM_REMOVE_USER_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('confirmDeleting')}</h5>
      </div>
      <div>
        <div className="modal-body">
          <div className="text-confirmation confirm-deleting-text">
            {i18n.t('confirmUserDeletingDesc', { userRole })}
          </div>
          <form id={formID} className="confirmation-field" onSubmit={handleSubmitHandler}>
            <Field
              name="userEmail"
              label={`${i18n.t(data?.userName ? 'username' : 'mail')}`}
              className="m-input"
              component={CustomTextInput}
              validate={[required, equalToUserEmailOrUserName]}
            />
          </form>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton form={formID} isSubmit i18nLabel="confirmBtn" disabled={!formValues?.userEmail} />
        </div>
      </div>
    </ModalWindow>
  );
};

ConfirmDeleteUserWindow.propTypes = {
  reset: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired
};

const form = reduxForm({
  form: formID
})(ConfirmDeleteUserWindow);

export default form;
