/**
 * Create array with company ids or user id
 * @param {Array} data Array with objects. Object contain all info about field
 * @param {Array} arrayOfChoosedFields id of chosen field
 * @param {String} choosedEl name of key which we should get from object
 * @returns {Array}
 */
const parseChoosedElement = (data, arrayOfChoosedFields, choosedEl) => Array.from(new Set(data.map((el) => {
  if (arrayOfChoosedFields.find((id) => id === el._id)) {
    return el[choosedEl] ? el[choosedEl]._id : 'no company';
  }
  return null;
}).filter(Boolean)));

export default parseChoosedElement;
