import {
  RenderEmail,
  RenderStatus,
  RenderButtons,
  RenderSubUserName,
  RenderSubUserParentId
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columns = (myRoleType, { width }) => ([
  {
    minWidth: 35
  },
  {
    Header: i18n.t('SMID'),
    id: 'sm_id',
    accessor: 'sm_id',
    Cell: RenderSubUserParentId,
    minWidth: width.sm_id,
    className: 'sm_id'
  },
  {
    Header: i18n.t('fName'),
    id: 'last_name',
    Cell: RenderSubUserName,
    minWidth: width.last_name
  },
  {
    Header: i18n.t('mail'),
    accessor: 'email',
    minWidth: width.email,
    className: 'email-text-td',
    Cell: (row) => RenderEmail(row)
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: width.status,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td'
  },
  {
    accessor: 'buttons',
    width: 120,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        ...(['root', 'solar_admin', 'pv_installer', 'pv_installer_employee'].includes(myRoleType)) ? {
          editUser: { preLink: '/setting-profile/subuser/' }
        } : {},
        remove: (myRoleType === 'root' || myRoleType === 'solar_admin')
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
