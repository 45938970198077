import { createSelector } from '@reduxjs/toolkit';

const getLoadManagementDashboardSmartMeter = (state) => state.loadManagementDashboard?.smartMeterData;
const getLoadManagementDashboardChartData = (state) => state.loadManagementDashboard?.chartData;
const getLoadManagementDashboardScaleType = (state) => state.loadManagementDashboard?.scaleType;
const getLoadManagementDashboardLoading = (state) => state.loadManagementDashboard?.loading;
const getLoadManagementDashboardChart = (state) => state.loadManagementDashboard?.chart;
const getChartUser = (state) => state.loadManagementDashboard?.user || state.dashboardV2?.user;

export const getLoadManagementDashboardUserSelector = createSelector([(state) => state.loadManagementDashboard?.user], (user) => user);
export const getLoadManagementDashboardUserTimezoneSelector = createSelector([getChartUser], (user) => user?.timezone);
export const getLoadManagementDashboardUserHouseFuseSelector = createSelector([getChartUser], (user) => user?.houseFuse);

export const getLoadManagementDashboardChartDataSelector = createSelector([getLoadManagementDashboardChartData], (chartData) => chartData);
export const getLoadManagementDashboardScaleTypeSelector = createSelector([getLoadManagementDashboardScaleType], (scaleType) => scaleType);
export const getLoadManagementDashboardLoadingSelector = createSelector([getLoadManagementDashboardLoading], (loading) => loading);
export const getLoadManagementDashboardChartSelector = createSelector([getLoadManagementDashboardChart], (chart) => chart);

export const smartMeterPhasesDataSelector = createSelector([getLoadManagementDashboardSmartMeter], (data) => {
  const {
    currentL1,
    currentL2,
    currentL3,
    currentL1Mean,
    currentL2Mean,
    currentL3Mean
  } = data || {};

  return {
    currentL1,
    currentL2,
    currentL3,
    currentL1Mean,
    currentL2Mean,
    currentL3Mean
  };
});

export const yAxisMaxSelector = createSelector(
  [getLoadManagementDashboardSmartMeter, getLoadManagementDashboardChartData],
  (smartMeter, chartData) => {
    const smartMeterMaxValue = Math.max(smartMeter?.currentL1 || 0, smartMeter?.currentL2 || 0, smartMeter?.currentL3 || 0);
    const phase1MaxValue = chartData?.L1 ? Math.max(...chartData.L1.map((value) => value[1])) : 0;
    const phase2MaxValue = chartData?.L2 ? Math.max(...chartData.L2.map((value) => value[1])) : 0;
    const phase3MaxValue = chartData?.L3 ? Math.max(...chartData.L3.map((value) => value[1])) : 0;
    const chartDataMaxValue = Math.max(phase1MaxValue, phase2MaxValue, phase3MaxValue);

    return Math.ceil(Math.max(smartMeterMaxValue, chartDataMaxValue) / 10) * 10;
  }
);

export const yAxisMinSelector = createSelector(
  [getLoadManagementDashboardSmartMeter, getLoadManagementDashboardChartData],
  (smartMeter, chartData) => {
    const smartMeterMinValue = Math.min(smartMeter?.currentL1 || 0, smartMeter?.currentL2 || 0, smartMeter?.currentL3 || 0);
    const phase1MinValue = chartData?.L1 ? Math.min(...chartData.L1.map((value) => value[1])) : 0;
    const phase2MinValue = chartData?.L2 ? Math.min(...chartData.L2.map((value) => value[1])) : 0;
    const phase3MinValue = chartData?.L3 ? Math.min(...chartData.L3.map((value) => value[1])) : 0;
    const chartDataMinValue = Math.min(phase1MinValue, phase2MinValue, phase3MinValue);

    return Math.floor(Math.min(smartMeterMinValue, chartDataMinValue) / 10) * 10;
  }
);

export {
  getChartUser
};
