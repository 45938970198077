import {
  RenderEmail,
  RenderDate,
  RenderStatus,
  RenderButtons,
  RenderFullName
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columns = (myRoleType, { width }) => ([
  {
    width: 35
  },
  {
    Header: i18n.t('fName'),
    accessor: 'first_name',
    minWidth: width.first_name,
    className: '',
    Cell: (row) => RenderFullName(row)
  },
  {
    Header: i18n.t('mail'),
    accessor: 'email',
    minWidth: width.email,
    className: 'email-text-td',
    Cell: (row) => RenderEmail(row)
  },
  {
    Header: i18n.t('phoneNumber'),
    accessor: 'phone',
    minWidth: width.phone,
    className: 'phone_num-td'
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: width.status,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: width.last_login,
    className: 'last_login-td',
    Cell: (row) => RenderDate(row)
  },
  {
    Header: '',
    accessor: 'buttons',
    width: width.buttons,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        editUser: { preLink: '/setting-profile/solar-admin/' },
        remove: myRoleType === 'root'
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
