import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { DATA_LIST_ID } from '../constants';

/**
 * Checks if all timestamps are sorted positive numbers
 */
function isDataValidData(inputData) {
  if (inputData.length === 0) return true;
  return inputData[0].timestamp > 0
    && inputData.every(
      ({ timestamp }, i) => {
        if (i === 0) return true;
        const prevItem = inputData[i - 1];
        return typeof timestamp === 'number'
          && timestamp > prevItem.timestamp;
      }
    );
}

function parsePredictionData(inputData) {
  if (!isDataValidData(inputData)) {
    return { ranges: [], expected: [] };
  }

  const rangesData = [];
  const expectedData = inputData.map(({
    timestamp, expected, min, max
  }) => {
    rangesData.push([
      timestamp, min, max
    ]);
    return [timestamp, expected];
  });

  return {
    ranges: rangesData,
    expected: expectedData
  };
}

const getData = (state) => get(state, ['dataList', DATA_LIST_ID, 'data'], []);

export default createSelector(getData, parsePredictionData);
