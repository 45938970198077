import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { getDataListSelector } from '../../../redux-store/selectors/dataList';
import { CustomSelect } from '../CustomSelect';
import { required } from '../../../containers/validation';
import DeviceColorButton from '../../../containers/DeviceColorButton';
import { getZohoRedirectRequest } from '../../../containers/DevicesTable/slice';
import i18n from '../../../i18n';
import additionalFields from '../../../containers/DevicesTable/utils/additionalFields';
import fieldsForDevices from '../../../containers/DevicesTable/utils/fieldsForDevices';
import { APP_ID, SOLAR_MANAGER_APP_ID } from '../../../config';

import './index.scss';

export const DeviceNameField = (props) => {
  const {
    tagColor = '',
    myRoleType,
    randomColor,
    namesList = [],
    placeholder,
    disabled,
    labelDeviceGroup,
    currentId, form,
    isSearchable,
    filterOption,
    newDeviceType,
    support_url,
    deviceName = ''
  } = props;

  const dispatch = useDispatch();
  const dataLists = useSelector(getDataListSelector);
  const deviceColor = useMemo(() => dataLists[
    (newDeviceType !== 'sub-meter')
      ? 'sensors'
      : newDeviceType]?.data?.find((device) => [device.id, device._id].includes(currentId))?.devicePowerColor, [currentId, dataLists, newDeviceType
  ]);

  const isGenerateTokenExistForDevice = fieldsForDevices?.[deviceName]?.some((field) => field === 'generatedToken');

  const isShowZohoLinkCondition = ([SOLAR_MANAGER_APP_ID].includes(APP_ID) || !['end_user', 'viewer'].includes(myRoleType)) && support_url;

  const getZohoUrl = () => dispatch(getZohoRedirectRequest({ support_url }));

  return (
    <div className="form-group m-form__group mb-4">
      <div className="device-name-field-container">
        <div className="device-name-field">
          <div className="flags-select-label">{labelDeviceGroup}</div>
          <Field
            name="device_group"
            component={CustomSelect}
            placeholder={placeholder}
            options={namesList}
            validate={[required]}
            className="m-input"
            searchable
            isSearchable={isSearchable}
            filterOption={filterOption}
            disabled={disabled}
          />
        </div>
        <div className="device-color-field">
          <DeviceColorButton
            deviceColor={tagColor || deviceColor || randomColor}
            form={form}
            fieldName="device_color"
          />
        </div>
      </div>
      <div className="mt-3 d-flex">
        {isShowZohoLinkCondition && (
          <button
            type="button"
            className="support__url-btn"
            onClick={getZohoUrl}
          >
            <span className="question-mark-icon">?</span>
            {' '}
            {i18n.t('deviceInstruction')}
          </button>
        )}
        {isGenerateTokenExistForDevice && (
          <Field
            {...additionalFields.tokenString}
            isShowFieldForGenerateToken
            name="data.generatedToken"
            deviceName={deviceName}
            className="fakeField"
          />
        )}
      </div>
    </div>
  );
};

DeviceNameField.propTypes = {
  namesList: PropTypes.instanceOf(Array),
  filterOption: PropTypes.func,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  currentId: PropTypes.string,
  randomColor: PropTypes.string,
  newDeviceType: PropTypes.string,
  labelDeviceGroup: PropTypes.string.isRequired,
  support_url: PropTypes.string,
  tagColor: PropTypes.string,
  deviceName: PropTypes.string,
  myRoleType: PropTypes.string.isRequired
};
