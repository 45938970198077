import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';

import { sendPropFromField } from '../slice';

import '../index.scss';

/**
 * Renders Custom Search Field for tables
 * @param  {string} placeholder - placeholder of search input
 */
const CustomSearchField = (props) => {
  const { initialValueForCustomSearch, onChangeField, placeholder, fieldName } = props;
  const dispatch = useDispatch();

  const handleChange = (value) => {
    dispatch(sendPropFromField({ fieldName: [fieldName], selections: value }));
    onChangeField({ search: value || undefined });
  };

  return (
    <div className="custom-search-container form-group m-form__group row align-items-center">
      <div className="m-input-icon m-input-icon--left col-md-12" id="searchWrapper">
        <DebounceInput
          className="form-control m-input m-input--solid search customSearch"
          placeholder={placeholder}
          id="customSearch"
          minLength={3}
          debounceTimeout={300}
          onChange={(event) => handleChange(event.target.value.trim())}
          value={initialValueForCustomSearch}
        />
        <span className="m-input-icon__icon m-input-icon__icon--left search">
          <span><i className="la la-search" /></span>
        </span>
      </div>
    </div>
  );
};

CustomSearchField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  initialValueForCustomSearch: PropTypes.string
};

export default CustomSearchField;
