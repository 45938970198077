import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../../api_helper';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getUserFromQuery: builder.query({
      providesTags: ['userQuery'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ userID, mySelf }) => ({
        url: mySelf
          ? '/user/me?isWeb=true'
          : `/users/solar-managers-users/${userID}`,
        method: 'get'
      })
    })
  })
});

export const {
  useGetUserFromQueryQuery
} = userApi;
