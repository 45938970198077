import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import { modalWindowSend, closeModalWindow, modalStopLoading } from './slice';
import i18n from '../../i18n';

/**
 * It is called when form from modal window is submitet
 * @memberof module:ModalWindow
 * @async
 */
function modalWindowSendEpic(action$) {
  return action$.pipe(
    ofType(modalWindowSend),
    map((action) => action.payload),
    switchMap((payload) => {
      const { modalID, requestConfig, cb, reloadPage, finally: finallyCb, keepOpenIfError } = payload;
      const { method, url, isSubSensor, ...rest } = requestConfig;

      return from(api.request({ method, url, ...rest })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error) {
            if (typeof reloadPage === 'boolean') {
              window.location.reload();
              return of(modalStopLoading({ modalID }));
            }

            if (typeof reloadPage === 'string') {
              window.location.replace(reloadPage);
              return of(modalStopLoading({ modalID }));
            }

            if (finallyCb) {
              finallyCb();
            }

            return cb
              ? of(
                modalStopLoading({ modalID }),
                closeModalWindow({ modalID }),
                cb(result, isSubSensor),
                addNotification({ type: 'success', text: i18n.t('success') })
              )
              : of(
                modalStopLoading({ modalID }),
                closeModalWindow({ modalID }),
                addNotification({ type: 'success', text: i18n.t('success') })
              );
          }

          const actions = [
            modalStopLoading({ modalID }),
            addNotification({ type: 'error', text: result.message })
          ];

          if (!keepOpenIfError) {
            actions.push(closeModalWindow({ modalID }));
          }

          return of(...actions);
        })
      );
    })
  );
}

export default combineEpics(modalWindowSendEpic);
