import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import Tooltip from '../../Tooltip';

/**
 * Render function for React-Table. Column for device errors.
 * @memberof module:ReactTable
 * @param  {object} original - props of the company details
 */
const RenderDevicesErrorDetails = ({ original }) => (
  Array.isArray(original.errorCodes)
    ? (
      <div className="errors-container">

        <div
          key={shortid.generate()}
        >
          <Tooltip
            title={(
              <>
                {original.errorCodes.map(({ errorDetails }) => (errorDetails ? (
                  <p className="error-title" key={shortid.generate()}>
                    {errorDetails}
                  </p>
                ) : null))}
              </>
            )}
            customClasses={{ tooltip: 'error-tooltip_wrapper' }}
          >
            {original.errorCodes.map(({ errorDetails }) => (errorDetails ? (
              <span key={shortid.generate()}>
                {errorDetails}
                {' '}
              </span>
            ) : null))}
          </Tooltip>
        </div>

      </div>
    )
    : null
);

RenderDevicesErrorDetails.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderDevicesErrorDetails;
