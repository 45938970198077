import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  counter: 0,
  email: undefined,
  isLoading: undefined,
  verifyEmail: undefined,
  sendSuccessfull: undefined
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    forgotPasswordSend: (state) => {
      state.counter += 1;
      state.isLoading = true;
    },
    forgotPasswordCanceled: (state) => {
      state.counter -= 1;
      state.isLoading = false;
    },
    forgotPasswordReceived: (state, action) => {
      const { email, verifyEmail } = action.payload;
      state.sendSuccessfull = true;
      state.email = email;
      state.verifyEmail = verifyEmail;
      state.isLoading = false;
    }
  }
});

export const {
  forgotPasswordSend,
  forgotPasswordReceived,
  forgotPasswordCanceled
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
