import React from 'react';
import i18n from '../../i18n';
import './styles.scss';

import warningIcon from '../../assets/images/svg/warning-icon.svg';

const PredictionError = () => (
  <div className="prediction-error-wrapper">
    <div className="prediction-error-container">
      <img src={warningIcon} alt="Warning icon" className="prediction-error-icon" />
      <p className="prediction-error-sub-title">
        {i18n.t('predictionErrorSubTitle')}
      </p>
      <ul className="prediction-error-reason">
        <li>
          {i18n.t('firstPredictionErrorReason')}
        </li>
        <li>
          {i18n.t('secondPredictionErrorReason')}
        </li>
        <li>
          {i18n.t('thirdPredictionErrorReason')}
        </li>
      </ul>
      <span className="prediction-error-footer-text">
        {i18n.t('predictionErrorFooterText')}
      </span>
    </div>
  </div>
);

export default PredictionError;
