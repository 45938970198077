import { USERS_GET_PV_INSTALLER_USERS_ATTACHED_TO_VIEWER_URL, USERS_PV_INSTALLER_USERS_URL } from '../../api/apiUrls';

export const dataListPviId = 'companies';

export const getPvInstallersConfig = {
  viewer: {
    url: USERS_GET_PV_INSTALLER_USERS_ATTACHED_TO_VIEWER_URL,
    params: {
      _limit: 10000
    }
  },
  default: {
    url: USERS_PV_INSTALLER_USERS_URL,
    params: {
      _limit: 10000
    }
  }
};

export const dataListID = 'sm_users';
export const rolesWithCustomLogo = ['oem', 'pv_installer', 'oem_employee', 'oem_employee_read_only'];
export const addUserForm = 'addUserForm';
export const addUserAdmFormID = 'addUserAdm';
export const ADD_USER_TO_PROPERTY_MANAGEMENT = 'addUserToPropertyManagement';
