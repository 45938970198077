import React from 'react';
import PropTypes from 'prop-types';
/**
 * Render function for React-Table. Column for signal of device.
 * @memberof module:ReactTable
 * @param {object} props - General props object
 * @param {object} props.original - object with properties to render
 * @param {object} props.original.gateway - object with gateway info
 * @param {string} signal - conection signal
 */
const RenderSignalIcon = ({ original: { gateway: { signal } } }) => (signal === 'connected' ? (
  <span className="m-badge m-badge--success m-badge--dot" />
) : (
  <span className="m-badge m-badge--danger m-badge--dot" />
));

RenderSignalIcon.propTypes = {
  original: PropTypes.shape({
    gateway: PropTypes.shape({
      signal: PropTypes.string.isRequired
    })
  }).isRequired
};

export default RenderSignalIcon;
