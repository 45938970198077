import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Renders file selector for redux form
 * @memberof module:ReduxFormFields
 */
export const FileSelector = (props) => {
  const { file,
    setFile,
    className,
    buttonLabel,
    handleSubmit,
    fileSelectorRef: fileSelectorRefFromProps,
    onClick
  } = props;

  const FileNameRef = useRef();
  const FileSelectorRef = useRef();
  useEffect(() => {
    if (file) {
      handleSubmit();
      setFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleImageChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      FileNameRef.current.innerHTML = selectedFile.name;
      setFile(selectedFile);
    };
    reader.readAsDataURL(selectedFile);
  };

  const emulateClick = (e) => FileSelectorRef?.current?.click(e);

  return (
    <>
      <input
        className="FileSelector"
        type="file"
        onChange={handleImageChange}
        ref={fileSelectorRefFromProps || FileSelectorRef}
        hidden
      />
      <button
        className={className}
        onClick={onClick || emulateClick}
        type="button"
      >
        {buttonLabel}
      </button>
      <div ref={FileNameRef} className="uploadFileName" />
    </>
  );
};

FileSelector.propTypes = {
  setFile: PropTypes.func,
  handleSubmit: PropTypes.func,
  className: PropTypes.string.isRequired,
  file: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  fileSelectorRef: PropTypes.instanceOf(Object)
};
