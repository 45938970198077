/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box } from '@mui/material';

import { changeSupportContractsRequest, changeInstallationFinishedRequest, userDataSendRequest, setFirmwareVersion } from '../../../slice';
import { getColumns } from './columns';
import { initialValuesSelector } from '../../../selectors';
import { ItemsList } from '../ItemsList';
import { getUserDevicesIsSupportContractFlagAllowedToBeChangedSelector } from '../../../../../redux-store/selectors/userDevices';
import { USERS_SOLAR_MANAGERS_USERS_URL } from '../../../../../api/apiUrls';
import { useStartSoftwareUpdateMutation, useLazyGetFirmwareVersionQuery } from '../../../services';
import { setFirmwareVersion as setFirmwareVersionForEndUser } from '../../../../SignIn/slice';
import { openModalWindow } from '../../../../ModalWindow/slice';

/**
 * Info about software and hardware for user. Viewed by everyone except end_user.
 * @memberof module:UserDetails
 * @param {UserObject} user User Object
 */
const SoftwareInfo = (props) => {
  const { user, myRoleType, isReadOnlyPermission } = props;
  const dispatch = useDispatch();
  const [isStartSoftwareUpdate, setIsStartSoftwareUpdate] = useState(false);

  const isSupportContractFlagAllowedToBeChanged = useSelector(getUserDevicesIsSupportContractFlagAllowedToBeChangedSelector);
  const gateway = user.gateway ? user.gateway : {};

  const handleSupportContractsChange = (_, newValue) => dispatch(changeSupportContractsRequest({ userId: user._id, supportContractFlag: newValue }));
  const handleInstallationFinishedChange = (_, newValue) => dispatch(changeInstallationFinishedRequest({ gatewayId: gateway._id, isInstallationCompleted: newValue, userId: user._id }));
  const handleUpdateUserRentedFlag = (_, newValue) => {
    const formData = new FormData();
    formData.append('rentedPVSystemFlag', newValue);

    dispatch(userDataSendRequest({
      method: 'put',
      url: `${USERS_SOLAR_MANAGERS_USERS_URL}/${user._id}`,
      config: { data: formData }
    }));
  };

  const [startSoftwareUpdate] = useStartSoftwareUpdateMutation();
  const [getFirmwareVersion, { data: currentSoftwareVersion, isFetching }] = useLazyGetFirmwareVersionQuery();
  const onStartSoftwareUpdate = () => {
    setIsStartSoftwareUpdate(true);
    startSoftwareUpdate({ data: { gateways: [gateway._id], releaseGroup: gateway.releaseGroup } });

    setTimeout(() => {
      getFirmwareVersion({ gateway: gateway._id });
    }, 10000);
  };
  const onFinishUpdate = (isActive) => {
    if (!isActive) return;

    if (currentSoftwareVersion !== user.gateway.availableSoftwareVersion) {
      dispatch(openModalWindow({ modalID: 'requireMoreTimeID' }));
    }

    const responseHandler = myRoleType !== 'end_user' ? setFirmwareVersion : setFirmwareVersionForEndUser;
    dispatch(responseHandler({ data: currentSoftwareVersion }));
    setIsStartSoftwareUpdate(false);
  };

  const columns = useMemo(() => getColumns({
    user,
    myRoleType,
    isSupportContractFlagAllowedToBeChanged,
    handleSupportContractsChange,
    handleInstallationFinishedChange,
    handleUpdateUserRentedFlag,
    onStartSoftwareUpdate,
    isStartSoftwareUpdate,
    isReadOnlyPermission
  }), [user, myRoleType, isSupportContractFlagAllowedToBeChanged, isStartSoftwareUpdate, isReadOnlyPermission]);// eslint-disable-line

  useEffect(() => {
    onFinishUpdate(isFetching);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <Box className="main-info" sx={{ p: 4, pr: 10 }}>
      <Box className="software-container">
        <ItemsList columns={columns} />
      </Box>
    </Box>
  );
};

SoftwareInfo.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired,
  isReadOnlyPermission: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: 'settingSoftwareInfo',
  enableReinitialize: true
})(SoftwareInfo);

const mapStateToProps = createStructuredSelector({
  initialValues: initialValuesSelector
});

export default connect(mapStateToProps)(form);
