import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';

const parseMinifiedData = (minifiedData) => {
  const { mainKeys, main } = minifiedData;

  const { temperatures } = pick(minifiedData, ['temperatures']);
  const { sensors } = pick(minifiedData, ['sensors']);

  const TIMESTAMP = 'timestamp';
  const TIMESTAMP_INDEX = mainKeys.indexOf(TIMESTAMP);

  const emptyData = mainKeys.reduce((obj, key) => {
    if (key !== TIMESTAMP) {
      obj[key] = [];
    }
    return obj;
  }, {});

  const result = main.reduce((data, rawPoint) => {
    mainKeys.forEach((key, index) => {
      if (index !== TIMESTAMP_INDEX) {
        data[key].push([rawPoint[TIMESTAMP_INDEX], rawPoint[index]]);
      }
    });
    return data;
  }, cloneDeep(emptyData));

  return {
    ...result,
    temperatures,
    ...sensors
  };
};

export default parseMinifiedData;
