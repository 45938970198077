import i18n from '../../../i18n';

const getOEMsListOptions = (oems) => {
  const oemsListOptions = oems.map(({ oem }) => ({
    label: oem?.name || i18n.t('noComp'),
    value: oem?._id
  }));
  const all = {
    label: i18n.t('selectAllOEMs'),
    value: 'all'
  };
  return oemsListOptions.length ? [all, ...oemsListOptions] : oemsListOptions;
};

export default getOEMsListOptions;
