import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import { receiveOverviewData, requestOverviewData } from './slice';
import { OVERVIEW_URL } from '../../api/apiUrls';

function overviewEpic($action) {
  return $action.pipe(
    ofType(requestOverviewData),
    map((action) => action.payload),
    switchMap(() => from(api.get(OVERVIEW_URL)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return of(
            addNotification({ type: 'error', text: result.message })
          );
        }

        return of(receiveOverviewData(result));
      })
    ))
  );
}

export default combineEpics(
  overviewEpic
);
