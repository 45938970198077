import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { CancelButton, PrimaryButton } from '../../../components/UIKit';
import {
  getModalsIsLoadingSelector,
  getModalRemoveCarChargerDataSelector
} from '../../../redux-store/selectors/modals';
import { REMOVE_CAR_CHARGER_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

const RemoveCarCharger = (props) => {
  const { onSubmit } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(getModalsIsLoadingSelector);
  const data = useSelector(getModalRemoveCarChargerDataSelector);
  const filteredIds = data?.carChargersId?.filter((id) => id !== data?.id);
  const idOfDeletedElement = data?.deviceType === 'Car' ? data?.userId : data?.deviceId;
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: REMOVE_CAR_CHARGER_MODAL_ID }));
  const onSubmitHandler = () => {
    onSubmit(filteredIds, idOfDeletedElement, data?.deviceType, data?.rowData);
    handleOnClose();
  };

  return (
    <ModalWindow modalID={REMOVE_CAR_CHARGER_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('removeCarCharger')}</h5>
      </div>
      {isLoading ? (
        <div className="delete-device-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <div className="text-confirmation">
              {i18n.t(
                data?.deviceType === 'Car'
                  ? 'removeCarChargerFromCar'
                  : 'removeCarChargerConfirmMessage'
              )}
            </div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton onClickHandler={onSubmitHandler} type="remove" />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

RemoveCarCharger.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default RemoveCarCharger;
