import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for company name
 * @memberof module:ReactTable
 * @param  {string} value - company name
 */
const RenderGateway = ({ value }) => (!value ? (i18n.t('noSolarManager')) : (value));

export default RenderGateway;
