import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  defaultLicenseId: undefined,
  hardwaresList: [],
  versionsList: [],
  isLoading: false,
  selected: []
};

const installedSMSlice = createSlice({
  name: 'installedSM',
  initialState,
  reducers: {
    fetchHardwareListRequest: epicEmptyFunction,
    instSMGetFWVersions: epicEmptyFunction,
    installedSMRequestStopLoading: (state) => {
      state.isLoading = false;
    },
    instSMFWVersionsRecieved: (state, action) => {
      state.versionsList = action.payload.versionsList;
    },
    fetchHardwareListSuccess: (state, action) => {
      state.hardwaresList = action.payload.hardwareList?.hardware;
      state.defaultLicenseId = action.payload.hardwareList?.defaultLicenseId;
    },
    installedSMSendRequest: (state) => {
      state.isLoading = true;
    },
    installedSMSendUpdate: (state) => {
      state.isLoading = true;
    },
    installedSMReplaceSelected: (state, action) => {
      state.selected = action.payload?.newSelected || [];
    }
  }
});

export const {
  installedSMRequestStopLoading,
  installedSMReplaceSelected,
  fetchHardwareListSuccess,
  fetchHardwareListRequest,
  instSMFWVersionsRecieved,
  installedSMSendRequest,
  installedSMSendUpdate,
  instSMGetFWVersions
} = installedSMSlice.actions;

export default installedSMSlice.reducer;
