import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';
import { Field } from 'redux-form';
import i18n from '../../i18n';
import SearchField from '../SearchField';
import { Checkbox } from '../ReduxFormFields/Checkbox';

import './index.scss';

const LongCheckboxArr = ({
  handleChange = () => null,
  noDataText,
  checkedData = [],
  formId,
  disabled = false,
  selectAllCheckbox = 'selectAll',
  handleChangeAll,
  isShowSelectAllCheckbox = true,
  selectAllCheckboxLabel = 'Select All',
  searchPlaceholder = ''
}) => {
  const [searchStr, setSearchStr] = useState('');
  const filteredData = checkedData.filter(({ name, isChecked }) => isChecked || name.toLowerCase().includes(searchStr.toLowerCase().trim()));

  const onSearch = ({ search }) => setSearchStr(search);

  const rowRenderer = ({ index, style }) => (
    <div
      style={style}
      key={index}
      onClick={() => handleChange(filteredData[index]._id)}
      className="form-group m-form__group checkbox-row"
    >
      <label
        htmlFor={formId}
        className="m-checkbox m-checkbox--air m-checkbox--state-warning checkbox-label-color"
      >
        <input
          onChange={() => null}
          className="m-input"
          type="checkbox"
          disabled={disabled}
          checked={(filteredData[index].isChecked || false)}
        />
        {`${filteredData[index].name}`}
        <span />
      </label>
    </div>
  );

  return (
    <>
      <SearchField placeholder={searchPlaceholder ? i18n.t(searchPlaceholder) : i18n.t('searchAllTablesElements')} onChange={onSearch} classes="mb-3 mx-0" />
      {!filteredData.length && (
        <div style={{ height: '300px' }} className="no-installer">
          {i18n.t(noDataText)}
        </div>
      )}

      {Boolean(filteredData.length && isShowSelectAllCheckbox) && (
        <div className="form-group m-form__group checkbox-row">
          <Field
            type="checkbox"
            name={selectAllCheckbox}
            component={Checkbox}
            className="m-input"
            autoComplete="off"
            label={i18n.t(selectAllCheckboxLabel)}
            labelClass=""
            showTooltip={false}
            onChange={handleChangeAll}
          />
        </div>
      )}
      {Boolean(filteredData.length) && (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              height={300}
              rowCount={filteredData.length}
              rowHeight={50}
              width={width}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      )}
    </>
  );
};

LongCheckboxArr.propTypes = {
  handleChange: PropTypes.func.isRequired,
  checkedData: PropTypes.instanceOf(Array),
  noDataText: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selectAllCheckbox: PropTypes.string,
  handleChangeAll: PropTypes.func,
  isShowSelectAllCheckbox: PropTypes.bool,
  selectAllCheckboxLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string
};

export default LongCheckboxArr;
