import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { CancelButton, PrimaryButton } from '../../../components/UIKit';
import { CustomTextInput } from '../../../components/ReduxFormFields';

import { email, parseToLowerCase, required } from '../../validation';

import i18n from '../../../i18n';
import { addSubUserFormId } from '../constants';
import { getModalsInviteSubUserDataSelector, getModalsIsLoadingSelector } from '../../../redux-store/selectors/modals';
import { INVITE_SUB_USER_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

const InviteSubUser = (props) => {
  const { handleSubmitHandler, initialize, pristine, valid } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(getModalsIsLoadingSelector);
  const data = useSelector(getModalsInviteSubUserDataSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: INVITE_SUB_USER_MODAL_ID }));

  const handleOnCloseHandler = () => {
    initialize('email', '');
    handleOnClose();
  };

  const handleOnSubmitHandler = (e) => {
    e.preventDefault();
    if (!valid) return;
    initialize('email', '');
    handleSubmitHandler({ email: e.target.elements.email.value, parentSubMeter: data.id || data._id });
  };

  return (
    <ModalWindow customOnClose={handleOnCloseHandler} modalID={INVITE_SUB_USER_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addSubUser')}</h5>
      </div>
      {isLoading ? (
        <div className="delete-device-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <form
              id={addSubUserFormId}
              onSubmit={handleOnSubmitHandler}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field">
                <Field
                  name="email"
                  component={CustomTextInput}
                  label={i18n.t('regMail')}
                  className="m-input"
                  autoComplete="off"
                  validate={[required, email]}
                  parse={parseToLowerCase}
                  preventAuto
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnCloseHandler} />
            <PrimaryButton form={addSubUserFormId} disabled={pristine && !valid} isSubmit type="send" />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

InviteSubUser.propTypes = {
  initialize: PropTypes.func.isRequired,
  handleSubmitHandler: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: addSubUserFormId
})(InviteSubUser);

export default form;
