import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for load guard - input device name.
 * @memberof module:ReactTable
 * @param {object} props - General props object
 * @param {object} props.original - object with properties to render
 * @param {object} props.original.inputDevice - Input device
 * @param {string} props.original.inputDevice.device_group - Input device name
 */
const RenderInputDeviceName = ({ original = {} }) => i18n.t((original.inputDevice || original.input_device)?.device_group || '');

export default RenderInputDeviceName;
