export const ctaHeatPump = 'CTA heat pump';
export const ctaHeatPumpRTU = 'CTA heat pump RTU';
export const ctaHeatPumpCarel = 'CTA carel';

/**
 * Affected device types for Load guard
 */
export const allowedAffectedDeviceTypes = [
  'Car Charging',
  'Water Heater',
  'Switches',
  'Heatpump',
  'Battery',
  'Car Charging System',
  'V2X'
];

/**
 * EditDeviceWindow form id
 */
export const editDeviceFormId = 'editDeviceForm';

/**
 * AddDeviceWindow form id
 */
export const addDeviceFormId = 'addDeviceForm';

/**
 * InviteSubUser form id
 */
export const addSubUserFormId = 'addSubUserForm';
