/**
 *
 * @description Mutates incoming chart data array.
 * Fills empty data between extremes (from data).
 *
 * Example:
 * data = [[1652033840000, 10], [1652033850000, 11], [1652033900000, 17]]
 * interval = 10000
 * fill = 0
 *
 * Result:
 * data = [[1652033840000, 10], [1652033850000, 11], [1652033860000, 0], [1652033870000, 0], [1652033880000, 0], [1652033890000, 0], [1652033900000, 17]]
 *
 * @param {object} options
 * @param {ChartData} options.data chart data
 * @param {number} options.interval expected interval between points
 * @param {any} options.fill point value to insert
 * @returns {chartData} updated chart data
 */
const fillEmptyDataBetweenExtremes = ({ data, interval, fill = null }) => {
  if (data.length <= 1) {
    return data;
  }

  const maxInterval = interval * 2;
  let i = 1;

  while (i < data.length) {
    const [currentTimestamp] = data[i];
    const [prevTimestamp] = data[i - 1];
    const currentInterval = currentTimestamp - prevTimestamp;

    if (currentInterval >= maxInterval) {
      const numberOfPointsToInsert = Math.floor(currentInterval / interval) - 1;
      const desiredInterval = Math.round(currentInterval / (numberOfPointsToInsert + 1));

      const pointsToInsert = [];

      let lastTimeStamp = prevTimestamp;

      for (let j = 0; j < numberOfPointsToInsert; j += 1) {
        lastTimeStamp += desiredInterval;
        pointsToInsert.push([lastTimeStamp, fill]);
      }

      data.splice(i, 0, ...pointsToInsert);

      i += numberOfPointsToInsert;
    } else {
      i += 1;
    }
  }

  return data;
};

/**
 *
 * @description Mutates incoming chart data array. Fills empty points to the start of the data.
 *
 * Example:
 * data = [[1652033840000, 10], [1652033850000, 11], [1652033900000, 17]]
 * interval = 10000
 * from = 1652033800000
 * fill = 0
 *
 * Result:
 * data = [[1652033800000, 0], [1652033810000, 0], [1652033820000, 0], [1652033830000, 0], [1652033840000, 10], [1652033850000, 11], [1652033900000, 17]]
 *
 * @param {object} options
 * @param {object} options.data chart data
 * @param {number} options.interval expected interval between points
 * @param {number} options.from start date timestamp
 * @param {any} options.fill
 * @returns {chartData} updated chart data
 */
const addPointsToStart = ({ data, interval, from, fill = null }) => {
  const [firstTimestamp] = data[0];

  const range = Math.round(firstTimestamp - from);

  if (range < interval) {
    return data;
  }

  const numberOfPointsToInsert = Math.floor(range / interval) - 1;
  const desiredInterval = Math.round(range / (numberOfPointsToInsert + 1));

  let count = 0;

  let timestamp = from;

  const pointsToInsert = [];

  while (count < numberOfPointsToInsert) {
    pointsToInsert.push([timestamp, fill]);

    timestamp += desiredInterval;
    count += 1;
  }

  data.splice(0, 0, ...pointsToInsert);

  return data;
};

/**
 *
 * @description Mutates incoming chart data array. Fills empty points to the end of the data.
 *
 * Example:
 * data = [[1652033840000, 10], [1652033850000, 11], [1652033900000, 17]]
 * interval = 10000
 * to = 1652033950000
 * fill = 0
 *
 * Result:
 * data = [[1652033840000, 10], [1652033850000, 11], [1652033900000, 17], [1652033910000, 0], [1652033920000, 0], [1652033930000, 0], [1652033940000, 0], [1652033950000, 0]]
 *
 * @param {object} options
 * @param {object} options.data chart data
 * @param {number} options.interval expected interval between points
 * @param {number} options.to end date timestamp
 * @param {any} options.fill
 * @returns {chartData} updated chart data
 */
const addPointsToEnds = ({ data, interval, to, fill = null }) => {
  const [lastTimeStamp] = data[data.length - 1];

  const range = Math.round(to - lastTimeStamp);

  if (range < interval) {
    return data;
  }

  const numberOfPointsToInsert = Math.floor(range / interval) - 1;
  const desiredInterval = Math.round(range / (numberOfPointsToInsert + 1));

  let count = 0;

  let timestamp = lastTimeStamp;

  while (count < numberOfPointsToInsert) {
    timestamp += desiredInterval;

    data.push([timestamp, fill]);

    count += 1;
  }

  return data;
};

/**
 *
 * @description Fill empty data by value.
 *
 * Example:
 * data = [[1652033840000, 10], [1652033850000, 11], [1652033900000, 17]]
 * interval = 10000
 * from = 1652033800000
 * to = 1652033950000
 * fill = 0
 *
 * Result:
 * data = [[1652033800000, 10], [1652033810000, 10], [1652033820000, 10], [1652033830000, 10], [1652033840000, 10], [1652033850000, 11], [1652033860000, 11], [1652033870000, 11], [1652033880000, 11], [1652033900000, 17], [1652033910000, 0], [1652033920000, 0], [1652033930000, 0], [1652033940000, 0], [1652033950000, 0]]
 *
 * @param {object} options
 * @param {ChartData} options.data chart data
 * @param {number} options.interval interval between points
 * @param {number} options.from start timestamp
 * @param {number} options.to end timestamp
 * @param {*} options.fill
 */
const fillEmptyData = ({ data, interval, from, to, fill = null }) => {
  if (!Array.isArray(data)) {
    return [];
  }

  if (!data.length) {
    return data;
  }

  addPointsToStart({ data, interval, from, fill });
  addPointsToEnds({ data, interval, to, fill });
  fillEmptyDataBetweenExtremes({ data, interval, fill });

  return data;
};

export default fillEmptyData;
