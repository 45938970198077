import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { sendPropFromField } from '../slice';
import { waitForTheCustomerNewStatus } from '../constants';
import { getSignInUserRoleTypeSelector } from '../../../redux-store/selectors/signIn';
import { getMonitoringSelectedFieldsSelector } from '../../../redux-store/selectors/monitoring';

import i18n from '../../../i18n';

import './index.scss';

/**
 * Renders lists of different types (assign, priority, work status) for Monitoring page
 * @memberof module:Monitoring
 */
const ExpanderSide = (props) => {
  const {
    onChangeField,
    defaultValue,
    choosingList = [],
    fieldName,
    id
  } = props;
  const dispatch = useDispatch();

  const selectedFields = useSelector(getMonitoringSelectedFieldsSelector);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);

  const handleChange = (event) => {
    dispatch(sendPropFromField({ fieldName: [fieldName], selections: event.target.value }));
    onChangeField();
  };

  const getFilteredListOfOptions = ['root', 'solar_admin', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem', 'oem_employee', 'oem_employee_read_only'].includes(myRoleType)
    ? choosingList
    : choosingList.filter(({ value }) => value !== waitForTheCustomerNewStatus);

  return (
    <select
      className="form-control m-input m-input--air ExpanderSide-select"
      id={id}
      onChange={handleChange}
      value={selectedFields?.[fieldName]?.value || ''}
    >
      <option value="">{defaultValue}</option>
      {getFilteredListOfOptions
        ? (getFilteredListOfOptions.filter(Boolean).map(({ value, key }) => (
          <option
            key={`ChooseId_${shortid.generate()}`}
            value={key}
            disabled={key === waitForTheCustomerNewStatus}
          >
            {i18n.exists(value) ? i18n.t(value) : value}
          </option>
        ))) : null}
    </select>
  );
};

ExpanderSide.propTypes = {
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  choosingList: PropTypes.instanceOf(Array)
};

export default ExpanderSide;
