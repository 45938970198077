import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import {
  RenderHeaderWithTooltip,
  RenderSwitcher,
  RenderLicense,
  RenderPaidLicense,
  RenderSingleButton,
  RenderRebootDropdown,
  RenderSignal,
  RenderStatus,
  RenderError,
  RenderButtons
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';
import { DATA_LIST_ID } from './constants';

const columns = (myRoleType, { width }) => ([
  {
    Header: i18n.t('SMID'),
    accessor: 'sm_id',
    minWidth: width.sm_id,
    className: 'sm_id'
  },
  {
    Header: i18n.t('hardware'),
    accessor: 'hardware',
    minWidth: width.hardware,
    className: ''
  },
  {
    Header: i18n.t('firmwareVersion'),
    accessor: 'firmware',
    minWidth: width.firmware,
    className: ''
  },
  {
    Header: i18n.t('initializedDate'),
    accessor: 'initialized',
    minWidth: width.initialized,
    Cell: ({ value }) => (value ? moment(value).format('DD.MM.YYYY HH:mm') : ''),
    className: ''
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('signal'),
      tooltipText: i18n.t('signalTooltip'),
      placement: 'right-start'
    }),
    accessor: 'signal',
    minWidth: width.signal,
    Cell: (row) => RenderSignal(row),
    filterable: false,
    sortable: false
  },
  {
    Header: i18n.t('error'),
    id: 'hasErrors',
    accessor: 'error',
    minWidth: width.hasErrors,
    Cell: (row) => RenderError(row),
    filterable: false,
    sortable: true
  },
  {
    Header: i18n.t('accStatus'),
    id: 'status',
    accessor: (d) => (d.owner?.status || ''),
    minWidth: width.status,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td',
    show: false
  },
  {
    Header: i18n.t('company'),
    id: 'company',
    accessor: (d) => (d.company?.name || i18n.t('noComp')),
    minWidth: width.company,
    show: false
  },
  {
    Header: i18n.t('License'),
    accessor: (row) => ({
      licenseId: get(row, 'owner.license.licenseCode'),
      licenseTitle: get(row, 'owner.license.name', ''),
      userId: get(row, 'owner._id'),
      hide: !row.owner,
      parentListId: DATA_LIST_ID
    }),
    id: 'license',
    minWidth: width.license,
    Cell: (row) => (row.original.owner?.license?.type === 'payment' ? RenderPaidLicense(row, myRoleType) : RenderLicense(row)),
    sortable: false
  },
  {
    Header: i18n.t('releaseGroup'),
    accessor: 'releaseGroup',
    minWidth: width.releaseGroup,
    className: ''
  },
  {
    Header: i18n.t('debugLogging'),
    accessor: 'debug_logging',
    className: 'switcher-logging',
    minWidth: width.debug_logging,
    Cell: RenderSwitcher
  },
  {
    Header: () => <div className="rt-resizable-header-content rebootRequest-header">{i18n.t('rebootRequest')}</div>,
    accessor: 'rebootReqBtn',
    className: 'rebootReqBtn',
    id: 'rebootReqBtn',
    resizable: false,
    minWidth: width.rebootReqBtn,
    Cell: (row) => RenderRebootDropdown(row),
    sortable: false
  },
  {
    Header: i18n.t('removeDevice'),
    accessor: '',
    minWidth: width.removeDevice,
    Cell: (row) => RenderSingleButton({ row, title: 'removeDevice' }),
    sortable: false
  },
  {
    Header: i18n.t('removeFirmware'),
    accessor: '',
    minWidth: width.removeFirmware,
    Cell: (row) => RenderSingleButton({ row, title: 'removeFirmware' }),
    sortable: false
  },
  {
    Header: '',
    accessor: '',
    minWidth: 80,
    className: 'action-buttons',
    Cell: (row) => (row.original.owner?._id ? RenderButtons(
      { _id: row.original.owner?._id },
      { exportOneUser: true, devices: { preLink: '/users/' } }
    ) : ''),
    filterable: false,
    sortable: false
  }
]);

export default columns;
