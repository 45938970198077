import React from 'react';
import PropTypes from 'prop-types';
import { getLangCode } from '../../../i18n';

import LeftDeCh from './DE_CH-left.svg';
import LeftDe from './DE_DE-left.svg';
import LeftEn from './EN-left.svg';
import LeftDa from './DK-left.svg';
import LeftFr from './FR-left.svg';
import LeftIt from './IT-left.svg';

import RightDeCh from './DE_CH-right.svg';
import RightDe from './DE_DE-right.svg';
import RightEn from './EN-right.svg';
import RightDa from './DK-right.svg';
import RightFr from './FR-right.svg';
import RightIt from './IT-right.svg';

const images = {
  left: {
    deutsch_de: LeftDe,
    english: LeftEn,
    french: LeftFr,
    italian: LeftIt,
    danish: LeftDa,
    deutsch_ch: LeftDeCh
  },
  right: {
    deutsch_de: RightDe,
    english: RightEn,
    french: RightFr,
    italian: RightIt,
    danish: RightDa,
    deutsch_ch: RightDeCh
  }
};

export const BgImage = ({ type, lang = 'english' }) => {
  const image = images[type][getLangCode(lang)];
  return Image ? <img src={image} alt="background" /> : null;
};

BgImage.propTypes = {
  type: PropTypes.string.isRequired,
  lang: PropTypes.string
};
