export const addEndUsersToViewerForm = 'addEndUsersToViewerForm';
export const dataListIDViewer = 'currentViewer';
export const dataListIDEndUsersForViewer = 'viewerDetailsUsers';

export const getViewerParams = {
  _limit: 10,
  _start: 0,
  _sort: 'first_name:asc'
};

export const getEndUsersForViewerParams = {
  _limit: 10,
  _start: 0,
  _sort: 'smid:asc'
};

export const selectAllCheckbox = 'selectAll';

export const getInstallersForViewerParams = {
  _limit: 10,
  _start: 0,
  _sort: 'company:asc'
};

export const getOEMSForViewerParams = {
  _start: 0,
  _sort: 'oemCompany:asc'
};
