/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';

import i18n from '../../../../../../i18n';
import exampleUrl from '../../../../../../assets/images/url.png';
import { CustomTextInput } from '../../../../../../components/ReduxFormFields';
import { required } from '../../../../../validation';
import { TESLA_URL_FORM_ID } from '../../constants';

const Url = ({ redirectUrl, isError, selectedBrand }) => (
  <div className="url-tab-wrapper">
    <div className="tesla-link">
      <Trans i18nKey="personalTeslaAuthenticationForm">
        Please open this personal <a href={redirectUrl} target="_blank" rel="noreferrer">{selectedBrand.value}.com authentication form</a> in a private tab, enter your {selectedBrand.label} credentials, copy the final URL and paste it below.
      </Trans>
    </div>
    <span className="example-url">{i18n.t('teslaExampleUrl')}</span>
    <img src={exampleUrl} alt="example" />
    <Field
      label={i18n.t('url')}
      component={CustomTextInput}
      className="m-input"
      validate={[required]}
      name="url"
    />
    {isError && <span className="validation-error">{i18n.t('carLoginError')}</span>}
  </div>
);

Url.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  selectedBrand: PropTypes.instanceOf(Object).isRequired
};

const form = reduxForm({
  form: TESLA_URL_FORM_ID
})(Url);

export default form;
