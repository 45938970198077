import _ from 'lodash';
import devices from './devices.json';
import view from './view.json';
import replace from './replace';

import frontendFields from './frontendFields.json';

const english = _.merge({}, view, devices, frontendFields, replace);

export default english;
