import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import './index.scss';

/**
 * Renders filter of firmware versions for Installed SM page
 * @memberof module:InstalledSM
 */
const RenderSelectFilter = (props) => {
  const { onChange, allParams, filteredListOfOptions, label, fieldName } = props;

  const fixValue = (value) => (i18n.t('workStatusFilterPlaceholder') === value ? undefined : value);

  return (
    <div className="error-filter__container">
      <span>
        {i18n.t(label)}
        :
      </span>
      <select
        className="additional-filters__select"
        onChange={(e) => onChange({ [fieldName]: fixValue(e.target.value) })}
        value={String(allParams[fieldName])}
      >
        {filteredListOfOptions.map(({ key, value }) => (
          <option value={key} key={value}>
            {i18n.t(value)}
          </option>
        ))}
      </select>
    </div>
  );
};
RenderSelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  allParams: PropTypes.instanceOf(Object).isRequired,
  filteredListOfOptions: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired
};

export default RenderSelectFilter;
