import moment from 'moment-timezone';
import i18n from '../../../i18n';

/**
 * Formats date and time for showing at top of chart
 * @memberof module:DashboardChart
 * @param {((timestamp|dateString))} from_ Date and time when chart data starts
 * @param {((timestamp|dateString))} to_ Date and time when chart data ends
 * @param {boolean} [debugMode=false] True if need to show more detailed date
 * @param {string} chartType
 */
const formatInterval = (from_, to_, chartType) => {
  if (!from_ || !to_) return null;
  const from = moment(from_).add(1, 'second');
  const to = moment(to_).isAfter(moment()) ? moment() : moment(to_).subtract(1, 'second');

  if (chartType === 'monthly') {
    return `${from.format('YYYY')}`;
  }
  if (chartType === 'daily') {
    return `${from.format('MMM, YYYY')}`;
  }

  const formatByLanguage = i18n.language === 'deutsch' ? 'Do MMM' : 'MMM Do';

  if (from.format('Y') === to.format('Y')) {
    let showYear = ', YYYY';
    if (moment().format('Y') === to.format('Y')) {
      showYear = '';
    }

    const formattedType = `${formatByLanguage}${showYear}`;

    if (from.format('DDD') === to.format('DDD')) {
      return `${from.format(formattedType)}`;
    }

    return `${from.format(formattedType)} - ${to.format(formattedType)}`;
  }

  const fromFormat = from.format(`${formatByLanguage}, YYYY`);
  const toFormat = to.format(`${formatByLanguage}, YYYY`);
  return `${fromFormat} - ${toFormat}`;
};

export default formatInterval;
