import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'react-router-redux';

import { queryURLFormat } from '../../../utils';
import i18n, { getShortLanguageName, getFullLanguageName, changeLanguageTo } from '../../../i18n';

const useAddLanguageParam = () => {
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('l');

  useEffect(() => {
    if (token) {
      const currentFullLanguage = getFullLanguageName(token);
      if (currentFullLanguage === '') {
        dispatch(replace(window.location.pathname + queryURLFormat({ l: 'de' })));
      } else if (currentFullLanguage !== i18n.language) {
        changeLanguageTo(currentFullLanguage, true);
      }
    } else {
      dispatch(replace(window.location.pathname + queryURLFormat({ l: getShortLanguageName(i18n.language) })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
};

export default useAddLanguageParam;
