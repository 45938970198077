import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

import { ReactComponent as MaintenanceIcon } from '../../assets/images/svg/maintenance.svg';
import i18n from '../../i18n';

import './index.scss';

const Maintenance = ({ location }) => {
  if (!location?.state?.error) {
    return <Redirect to="/" />;
  }

  return (
    <div className="maintenance-container">
      <MaintenanceIcon />
      <h2>{i18n.t('maintenanceHeader')}</h2>
      <span>
        {i18n.t('maintenanceText1Line')}
      </span>
      <span>
        {i18n.t('maintenanceText2Line')}
      </span>
      <span>
        {i18n.t('maintenanceText3Line')}
      </span>
    </div>
  );
};

Maintenance.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired
};

export default Maintenance;
