import { get } from 'lodash';

/**
 * Returns page settings.
 * @returns {Object} All page settings from localStorage by 'pageSettings' key.
 */
const getAllSettings = () => JSON.parse(localStorage.getItem('pageSettings') || '{}') || {};

/**
 * Returns page settings for specific page.
 * @param {Object} params - The params for loading page settings from local storage.
 * @param {string} params.pageName - The page name.
 * @param {string} params.pathName - The path name from url.
 * @param {number} params.historyKey - The unique key for current page in history.
 * @returns {Object} Page settings.
 */
const loadPageSettings = ({ pageName, pathName, historyKey }) => {
  const settings = getAllSettings();
  if (settings?.[pageName]) {
    if (pathName
      || historyKey
      || settings[pageName]?.pathName
      || settings[pageName]?.historyKey) {
      if (pathName === settings[pageName].pathName && historyKey === settings[pageName].historyKey) {
        return settings[pageName];
      }
      return {};
    }
    return settings[pageName];
  }
  return {};
};

/**
 * Save new settings for page in local storage.
 * @param {string} page The page name.
 * @param {Object} newSettings The settings for page.
 */
const savePageSettings = (page, newSettings) => {
  const settings = getAllSettings();
  settings[page] = newSettings;
  localStorage.setItem('pageSettings', JSON.stringify(settings));
};

/**
 * Sync settings for a specific page or tab with local storage.
 * @param {Object} this_ An refer to the Component object.
 * @param {string} pageName The page name.
 * @param {Object} additionalParameters Not required additional parameters for page, which will be saved in local storage.
 */
export const configSavedSettings = (
  this_,
  pageName,
  {
    expander,
    defaultParams = {},
    subTabs,
    pathName,
    historyKey
  }
) => {
  this_.settings = loadPageSettings({ pageName, pathName, historyKey });
  this_.defaultParams = {
    _limit: get(this_.settings, ['pagination', 'limit'], defaultParams._limit),
    _start: get(this_.settings, ['pagination', 'start'], defaultParams._start),
    _sort: defaultParams._sort,
    done: defaultParams.done,
    supportContractStatus: defaultParams.supportContractStatus || undefined,
    isInstallationCompleted: defaultParams.isInstallationCompleted || undefined
  };
  if (pathName && historyKey) {
    this_.settings.pathName = pathName;
    this_.settings.historyKey = historyKey;
  }

  /**
   * Saves pagination settings
   * @param {string} key - a pagination param name
   * @param {any} value - a new value of the pagination param
   */
  this_.savePaginationChanges = (key, value) => {
    if (!this_.settings.pagination) {
      this_.settings.pagination = {};
    }
    if (key === 'limit') {
      if (Number.isInteger(this_.settings.pagination?.start)) {
        this_.settings.pagination.start -= this_.settings.pagination.start % value;
      } else {
        this_.settings.pagination.start = 0;
      }
    }
    this_.settings.pagination[key] = value;
    this_.settings.savedExpanded = undefined;
    if (expander) {
      this_.setState({ expanded: undefined });
    }
    savePageSettings(pageName, this_.settings);
  };

  let expanded;
  if (expander) {
    expanded = this_.settings.savedExpanded;
    this_.saveExpanderChanges = (all) => {
      this_.settings.savedExpanded = all;
      this_.setState({ expanded: all });
      savePageSettings(pageName, this_.settings);
    };
  }

  let activeTab;
  if (subTabs) {
    // eslint-disable-next-line prefer-destructuring
    activeTab = this_.settings.activeTab;

    /**
     * Changes active tab and reset settings that were set by previous tab
     * @param {string} tabName - current tab name
     */
    this_.changeSubTab = (tabName) => {
      this_.settings = { activeTab: tabName };
      this_.setState({ expanded: undefined, pagination: undefined, activeTab: tabName });
      savePageSettings(pageName, this_.settings);
    };
  }
  this_.state = { ...this_.state, expanded, activeTab };
};
