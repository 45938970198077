import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import PropTypes from 'prop-types';
import { multiselectConfig as style } from '../../../constants';
import i18n from '../../../i18n';

/**
 *
 * @param {object[]} data
 * @param {string} field
 * @returns {string}
 */
const transformData = (data, field) => data.map(({ [field]: value }) => value).join(',');

const MultiFilter = (props) => {
  const {
    defaultSelectedOptions,
    onChange: handleSubmit,
    submitFieldName,
    placeholder,
    extraUpdate,
    fieldName,
    disabled,
    options,
    title,
    allParams
  } = props;
  const multiselectRef = useRef();
  const [selectedList, setSelectedList] = useState([]);
  const transformedOptions = useMemo(() => options
    .map((data) => ({ ...data, label: i18n.exists(data[fieldName]) ? i18n.t(data[fieldName]) : data[fieldName] }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    .sort((a, b) => a.label.localeCompare(b.label)), [options, fieldName]);

  const handleSelect = (data) => {
    setSelectedList(data);
    let submitData = { [submitFieldName]: transformData(data, fieldName) };

    if (extraUpdate) {
      const filteredOptions = extraUpdate(transformData(data, 'type'));
      submitData = { ...submitData, ...filteredOptions };
    }
    handleSubmit(submitData);
  };

  useEffect(() => {
    const searchBox = multiselectRef.current.searchBox.current;
    const onFocusOut = () => {
      multiselectRef.current.setState((prev) => ({
        ...prev,
        inputValue: ''
      }));
    };

    if (fieldName === 'version') {
      searchBox.addEventListener('focusout', onFocusOut);
    }

    return () => searchBox.removeEventListener('focusout', onFocusOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fieldName === 'version' && !allParams.firmware) {
      multiselectRef.current.setState((prev) => ({
        ...prev,
        options: transformedOptions
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allParams.firmware]);

  useEffect(() => {
    const selected = options.filter(({ [fieldName]: value }) => defaultSelectedOptions?.split(',').includes(value))
      .map((data) => ({ ...data, label: i18n.exists(data[fieldName]) ? i18n.t(data[fieldName]) : data[fieldName] }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setSelectedList(selected);
  }, [options, defaultSelectedOptions]); // eslint-disable-line

  return (
    <>
      {title && <span>{`${title}:`}</span>}
      <Multiselect
        id={title}
        options={transformedOptions}
        placeholder={placeholder}
        ref={multiselectRef}
        showCheckbox
        keepSearchTerm
        emptyRecordMsg={i18n.t('noOptionsAvailable')}
        style={style}
        onSelect={handleSelect}
        onRemove={handleSelect}
        displayValue="label"
        selectedValues={selectedList}
        disable={disabled}
      />
    </>
  );
};

MultiFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  submitFieldName: PropTypes.string.isRequired,
  allParams: PropTypes.instanceOf(Object).isRequired,
  placeholder: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  extraUpdate: PropTypes.func,
  disabled: PropTypes.bool,
  defaultSelectedOptions: PropTypes.string
};

export default MultiFilter;
