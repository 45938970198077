import moment from 'moment-timezone';
import { DATEPICKER_MASK } from '../constant';

/**
 * Convert whatever value the user has entered into the value
 * that you want stored in the Redux store for the [FROM_DATE] field.
 * @param {string} value current value
 * @param {string|number} previousValue previous value
 * @param {object} allValues all current form values
 * @param {object} previousAllValues previous all form values
 * @returns {string|number}
 */
const normalizeFromDate = (value) => {
  const timestampFromStr = Number(value);
  if (Number.isInteger(timestampFromStr) && moment(timestampFromStr).isValid()) {
    return timestampFromStr.valueOf();
  }

  const dateStrictFromValue = moment(value, DATEPICKER_MASK, true);
  if (dateStrictFromValue.isValid()) {
    return dateStrictFromValue.valueOf();
  }

  return value;
};

/**
 * Convert whatever value the user has entered into the value
 * that you want stored in the Redux store for the [TO_DATE] field.
 * @param {string} value current value
 * @param {string|number} previousValue previous value
 * @param {object} allValues all current form values
 * @param {object} previousAllValues previous all form values
 * @returns {string|number}
 */
const normalizeToDate = (value) => {
  const today = moment();

  const timestampFromStr = Number(value);
  if (Number.isInteger(timestampFromStr) && moment(timestampFromStr).isValid()) {
    if (moment(timestampFromStr).isSame(today, 'day')) {
      return today.valueOf();
    }
    return timestampFromStr.valueOf();
  }

  const dateStrictFromValue = moment(value, DATEPICKER_MASK, true);
  if (dateStrictFromValue.isValid()) {
    if (dateStrictFromValue.isSame(today, 'day')) {
      return today.valueOf();
    }
    return dateStrictFromValue.valueOf();
  }

  return value;
};

export { normalizeFromDate, normalizeToDate };
