/**
 * Parses query string from location
 * @param queryString {String}
 * @returns {Object}
 */
const queryURLParser = (queryString) => {
  const queries = new URLSearchParams(queryString);

  return [...queries.entries()].reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
};

export default queryURLParser;
