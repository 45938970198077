import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip } from '../../../components';
import { addNotification } from '../../NotificationGenerator/slice';
import { updateHouseSettings } from '../slice';
import { houseSettingsSelector } from '../selectors';
import { getSignInUserRoleTypeSelector } from '../../../redux-store/selectors/signIn';
import { isInvitedUserHasReadOnlyPermissionSelector, getUserDevicesUser_IdSelector, isPvEmployeeInstallHasReadOnlyAccessSelector } from '../../../redux-store/selectors/userDevices';
import { READ_ONLY_ROLES } from '../../../constants';

import i18n from '../../../i18n';

import '../index.scss';

const LoadManagement = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserDevicesUser_IdSelector);
  const { houseFuse, kWp, loadManagement, offsetWatt } = useSelector(houseSettingsSelector);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);

  const [loadManagementValue, setLoadManagementValue] = useState(null);
  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);

  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessSelector);

  const submitHandler = (e) => {
    if (!houseFuse && !loadManagementValue) {
      dispatch(addNotification({ type: 'error', text: i18n.t('loadManagementError') }));
    } else if (houseFuse) {
      setLoadManagementValue(e.target.checked);
      dispatch(updateHouseSettings({
        loadManagement: e.target.checked,
        houseFuse,
        offsetWatt,
        kWp,
        myRoleType,
        userId
      }));
    } else {
      setLoadManagementValue(false);
    }
  };

  return (
    <div className="load-management">
      <label
        htmlFor="loadManagement"
        className="m-checkbox m-checkbox--air m-checkbox--state-warning load-management-label"
      >
        <input
          name="loadManagement"
          id="loadManagement"
          type="checkbox"
          checked={loadManagementValue === null ? loadManagement : loadManagementValue}
          onChange={submitHandler}
          disabled={isReadOnlyRole || isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess}
        />
        {i18n.t('loadManagementLabel')}
        <span />
      </label>
      <Tooltip title={i18n.t('loadManagementTooltip')} placement="right" />
    </div>
  );
};

export default LoadManagement;
