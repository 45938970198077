import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { COLOR_PICKER_MODAL_ID, openModalWindow } from '../../ModalWindow/slice';

const ColorComponent = (props) => {
  const { input: { value }, deviceColor, formId, fieldName = '', disabled = false, device } = props;
  const dispatch = useDispatch();

  const openColorPickerModal = () => (disabled ? null : dispatch(openModalWindow({
    modalID: COLOR_PICKER_MODAL_ID,
    data: { fieldName, color: value || deviceColor, formId, device }
  })));

  return (
    <div
      className="open-color-picker"
      onClick={openColorPickerModal}
      style={{
        backgroundColor: value || deviceColor,
        cursor: disabled ? 'default' : 'pointer'
      }}
      title={value || deviceColor}
    />
  );
};

ColorComponent.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  deviceColor: PropTypes.string,
  disabled: PropTypes.bool,
  device: PropTypes.instanceOf(Object),
  fieldName: PropTypes.string,
  formId: PropTypes.string.isRequired
};

export default ColorComponent;
