import React from 'react';
import PropTypes from 'prop-types';

import ModalWindow from '../../../ModalWindow';
import { CustomDatePicker } from '../../../../components';
import { DATE_PICKER_MODAL_MODAL_ID } from '../../../ModalWindow/slice';

import './index.scss';

/**
 * Renders calendar picker
 * @memberof module:UsersTable
 */
const CalendarPickerModal = (props) => {
  const { handleChange, from, maxDetail } = props;

  return (
    <div className="calendarModal">
      <ModalWindow modalID={DATE_PICKER_MODAL_MODAL_ID}>
        <div className="modal-body">
          <CustomDatePicker
            value={from}
            handleChange={handleChange}
            maxDetail={maxDetail}
          />
        </div>
      </ModalWindow>
    </div>
  );
};

CalendarPickerModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  from: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.number,
    PropTypes.string
  ]),
  maxDetail: PropTypes.string
};

export default CalendarPickerModal;
