export const dataKeys = [
  { name: 'consumption' },
  { name: 'production' },
  { name: 'import', sum: true },
  { name: 'self_consumption', sum: true },
  { name: 'battery_charging' },
  { name: 'battery_discharging' },
  { name: 'x_battery_discharging', sum: true },
  { name: 'x_consumption', sum: true },
  { name: 'x_production', sum: true },
  { name: 'battery' }
];
