import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change } from 'redux-form';

import Tooltip from '../../Tooltip';
import { CustomSelect } from '../CustomSelect';
import { deviceTagsSelector } from './selectors';

import i18n from '../../../i18n';

/**
 * Render TagField
 * @function TagField
 */
export const TagField = (props) => {
  const { deviceType, formID } = props;
  const dispatch = useDispatch();

  const tags = useSelector(deviceTagsSelector);
  const newTagCreatedCB = (newOption) => dispatch(change(formID, 'createNewTag', newOption));

  return (!['input-devices'].includes(deviceType) ? (
    <div className="form-group m-form__group input-field">
      <div className="flags-select-label">
        {`${i18n.t('tagFieldLabel')}`}
        <div className="switch-tooltip tooltipInSelect">
          <Tooltip title={i18n.t('tagFieldTooltip')} placement="right" />
        </div>
      </div>
      <Field
        name="tag"
        component={CustomSelect}
        placeholder={i18n.t('tagFieldPlaceholder')}
        options={tags}
        className="m-input"
        creatable
        newOptionCreatedCB={newTagCreatedCB}
        maxLength={25}
      />
    </div>
  ) : null);
};

TagField.propTypes = {
  deviceType: PropTypes.string.isRequired,
  formID: PropTypes.string.isRequired
};
