import { createSelector } from '@reduxjs/toolkit';
import { getSignInUserRoleType } from './signIn';

const getSettingProfileOemsList = (state) => state?.settingProfile?.oemsList;
const getSettingProfileIsLoading = (state) => state?.settingProfile?.isLoading;
const getSettingProfileInitialData = (state) => state?.settingProfile?.initialData;
const getSettingProfileInitialDataCompany = (state) => state?.settingProfile?.initialData?.company;
const getSettingProfileInitialDataConnectedOem = (state) => state?.settingProfile?.initialData?.connectedOem;
const getInvitedUserPermission = (state) => state?.settingProfile?.initialData?.permission;
const getIsInstallationCompleted = (state) => state?.settingProfile?.initialData?.gateway?.isInstallationCompleted;

export const getSettingProfileInitialDataConnectedOemSelector = createSelector([getSettingProfileInitialDataConnectedOem], (connectedOem) => connectedOem);
export const getSettingProfileInitialDataCompanySelector = createSelector([getSettingProfileInitialDataCompany], (company) => company);
export const getSettingProfileIsLoadingSelector = createSelector([getSettingProfileIsLoading], (isLoading) => !!isLoading);
export const getSettingProfileOemsListSelector = createSelector([getSettingProfileOemsList], (oemsList) => oemsList || []);
export const getSettingProfileInitialDataUserSelector = createSelector([getSettingProfileInitialData], (initialData) => initialData);

export const isInvitedUserHasReadOnlyPermissionSettingSelector = createSelector(
  [getSignInUserRoleType, getInvitedUserPermission],
  (myRoleType, permission) => (['viewer', 'pv_installer', 'oem'].includes(myRoleType) ? permission === 'read' : false)
);
export const isPvEmployeeInstallHasReadOnlyAccessSettingSelector = createSelector(
  [getSignInUserRoleType, getIsInstallationCompleted],
  (myRoleType, isInstallationCompleted) => (myRoleType === 'pv_installer_employee_read_install' ? !!isInstallationCompleted : false)
);

export {
  getSettingProfileInitialData
};
