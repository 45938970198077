import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';
import './index.scss';
import { ConvertValue } from '../../utils';
import carChargerIcon from '../../../../assets/images/svg/car-charging-on.svg';
import heatpumpIcon from '../../../../assets/images/svg/heatpump.svg';
import waterHeaterIcon from '../../../../assets/images/svg/water-heater-overview-icon.svg';
import v2xIcon from '../../../../assets/images/svg/v2x.svg';

const Widget = (props) => {
  const { deviceType, widgetData } = props;

  const widgetIcon = {
    carCharger: carChargerIcon,
    heatpump: heatpumpIcon,
    waterHeater: waterHeaterIcon,
    v2x: v2xIcon
  };

  const widgetTitle = {
    carCharger: 'carChargers',
    heatpump: 'heatpumps',
    waterHeater: 'waterHeaters',
    v2x: 'v2xChargers'
  };

  const { last7Days, today, total, charged, discharged } = widgetData || {};
  const { last7Days: chargedLast7Days, today: chargedToday } = charged || {};
  const { last7Days: dischargedLast7Days, today: dischargedToday } = discharged || {};

  return (
    <div className="widget-wrapper">
      <div className="widget-header">
        <div className="left-side">
          <img className="widget-icon" src={widgetIcon[deviceType]} alt="widget icon" />
          <div className="widget-name">{i18n.t(widgetTitle[deviceType])}</div>
        </div>
        <div className="right-side">
          <div className="widget-total">
            {i18n.t('total')}
            :
          </div>
          <div className="value">{total}</div>
        </div>
      </div>
      {deviceType === 'v2x' ? (
        <div className="widget-content-v2x">
          <div className="widget-content-column">
            <div className="widget-column-title">{i18n.t('charged')}</div>
            <div className="widget-content-row">
              <div className="widget-label">{i18n.t('today')}</div>
              {Number.isFinite(chargedToday) ? ConvertValue(chargedToday) : ConvertValue(0)}
            </div>
            <div className="widget-content-row">
              <div className="widget-label">{i18n.t('last7Days')}</div>
              {Number.isFinite(chargedLast7Days) ? ConvertValue(chargedLast7Days) : ConvertValue(0)}
            </div>
          </div>
          <div className="vertical-line" />
          <div className="widget-content-column">
            <div className="widget-column-title">{i18n.t('discharged')}</div>
            <div className="widget-content-row">
              <div className="widget-label">{i18n.t('today')}</div>
              {Number.isFinite(dischargedToday) ? ConvertValue(dischargedToday) : ConvertValue(0)}
            </div>
            <div className="widget-content-row">
              <div className="widget-label">{i18n.t('last7Days')}</div>
              {Number.isFinite(dischargedLast7Days) ? ConvertValue(dischargedLast7Days) : ConvertValue(0)}
            </div>
          </div>
        </div>
      ) : (
        <div className="widget-content">
          <div className="widget-content-row">
            <div className="widget-label">{i18n.t('totalEnergyToday')}</div>
            {Number.isFinite(today) ? ConvertValue(today) : ConvertValue(0)}
          </div>
          <div className="widget-content-row">
            <div className="widget-label">{i18n.t('totalEnergyLast7Days')}</div>
            {Number.isFinite(last7Days) ? ConvertValue(last7Days) : ConvertValue(0)}
          </div>
        </div>
      )}
    </div>
  );
};

Widget.propTypes = {
  deviceType: PropTypes.string.isRequired,
  widgetData: PropTypes.instanceOf(Object)
};

export default Widget;
