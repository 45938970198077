/* eslint-disable space-before-function-paren */
import { replace } from 'react-router-redux';
import axios from 'axios';

import { api, checkOnMaintenanceError } from '.';
import { HEALTH_CHECK_API } from '../config';

const setupInterceptors = (store) => {
  const { dispatch } = store;

  api.interceptors.response.use(
    (config) => config.data,
    async (error) => {
      if (checkOnMaintenanceError(error?.response?.status)) {
        const healthCheckResponse = await axios.get(`${HEALTH_CHECK_API}/health-check`);
        if (healthCheckResponse?.data?.emergency) {
          dispatch(replace({ pathname: '/maintenance', state: { error: `${error?.response?.status}` } }));
        }
      }
      return Promise.reject(error);
    }
  );
};

export { setupInterceptors };
