import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { DATA_LIST_ID, EMPLOYEE_LIST_ID } from '../../../containers/Monitoring/constants';
import { addChoosedCompany, employeeSendRequest, monitoringSendApplyWithFields } from '../../../containers/Monitoring/slice';
import { ERROR_MONITORING_TICKETS_URL, USERS_EMPLOYEE_URL } from '../../../api/apiUrls';
import { DropDownList } from '../../UIKit';
import i18n from '../../../i18n';
import {
  getMonitoringDataSelector,
  getChoosedCompanySelector,
  getMonitoringEmployeesSelector
} from '../../../redux-store/selectors/monitoring';
import { transformResponse } from '../../../utils/transformResponse';

const RenderAssignedStatus = (props, disabled) => {
  const { row, original } = props;
  const dispatch = useDispatch();
  const allData = useSelector(getMonitoringDataSelector);
  const currentCompany = useSelector(getChoosedCompanySelector);
  const currentEmployees = useSelector(getMonitoringEmployeesSelector);

  const [open, setOpen] = useState(false);
  const assignedUser = row.assigned ? `${row.assigned?.first_name} ${row.assigned?.last_name}` : i18n.t('noAssign');

  useEffect(() => {
    if (open) {
      dispatch(addChoosedCompany(
        {
          choosedCompany: [original.company._id],
          choosedUser: [original.user._id],
          choosedStatus: [original.status]
        }
      ));

      dispatch(employeeSendRequest(
        {
          companyID: original.company._id,
          listID: EMPLOYEE_LIST_ID,
          dataList: {
            listURL: USERS_EMPLOYEE_URL,
            params: {
              _limit: 50,
              _start: 0,
              _sort: 'first_name:asc',
              company: original.company._id
            },
            transformResponse
          },
          field: 'employees'
        }
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const formatListOfEmployee = (choosedCompany, choosedEmployees) => {
    const choosedList = [{ key: 'aaaabbbbccccddddeeeeffff', value: 'noUser' }]; // default key from back-end part for set noUser

    if (choosedCompany.length === 1) {
      const allEmployeesOfCheckUser = Object.entries(choosedEmployees).reduce(
        (val, el) => (el[0] === choosedCompany[0] ? el[1].data : val),
        ''
      );

      if (allEmployeesOfCheckUser) {
        // add chosen Company Admin in options list
        const company = allData.find((el) => el?.company?._id && el.company._id === choosedCompany[0])?.company;

        if (company?.admin?._id && company?.name) {
          choosedList.push({
            key: company.admin._id,
            value: `Admin ${company.name}`
          });
        }

        // add all users of chosen field in options list
        const assignedField = allEmployeesOfCheckUser.map((el) => ({
          key: el._id,
          value: `${el.first_name} ${el.last_name}`
        }));
        choosedList.push(...assignedField);
      }
    }

    return choosedList;
  };

  const i18TitleValue = assignedUser.length > 16 ? `${assignedUser.slice(0, 16)}...` : assignedUser;
  const menuItems = currentCompany && currentEmployees && formatListOfEmployee(currentCompany, currentEmployees);

  return (
    <DropDownList
      request={monitoringSendApplyWithFields}
      i18TitleValue={i18TitleValue}
      DATA_LIST_URL={ERROR_MONITORING_TICKETS_URL}
      DATA_LIST_ID={DATA_LIST_ID}
      dropDownType="assigned"
      menuItems={menuItems}
      original={original}
      setOpen={setOpen}
      open={open}
      disabled={disabled}
    />
  );
};

RenderAssignedStatus.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired,
  row: PropTypes.instanceOf(Object).isRequired
};

export default RenderAssignedStatus;
