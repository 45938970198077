import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import Chart from './components/Chart';
import { dataListSendRequest } from '../DataList/slice';
import { getPredictionData } from './selectors/index';
import { HelmetConfig, PredictionError, Preloader } from '../../components';
import { DATA_LIST_ID } from './constants';
import { getSignInUserGatewayTimeZone } from '../../redux-store/selectors/signIn';
import { getDataListPredictionChartLoading } from '../../redux-store/selectors/dataList';

import './style.scss';

const PredictionChart = (props) => {
  const { ranges, expected } = props;
  const dispatch = useDispatch();

  const timezone = useSelector((state) => getSignInUserGatewayTimeZone(state));
  const isLoading = useSelector((state) => getDataListPredictionChartLoading(state));

  useEffect(() => {
    moment.tz.setDefault(timezone);

    return () => {
      moment.tz.setDefault();
    };
  }, [timezone]);

  useEffect(() => {
    dispatch(dataListSendRequest({
      listID: DATA_LIST_ID,
      dataList: { listURL: 'prediction', method: 'GET' },
      showNotification: false
    }));
  }, [dispatch]);

  if (isLoading) {
    return (
      <HelmetConfig>
        <Preloader />
      </HelmetConfig>
    );
  }

  if (expected.length === 0 && ranges.length === 0) {
    return (
      <HelmetConfig>
        <PredictionError />
      </HelmetConfig>
    );
  }

  return (
    <HelmetConfig>
      <div className="prediction-chart-height">
        <Chart expected={expected} ranges={ranges} timezone={timezone} />
      </div>
    </HelmetConfig>
  );
};

PredictionChart.propTypes = {
  ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  expected: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired
};

const mapStateToProps = (state) => ({
  ...getPredictionData(state)
});

export default connect(mapStateToProps)(PredictionChart);
