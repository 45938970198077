import {
  RenderHeaderWithTooltip,
  RenderUserName,
  RenderGateway,
  RenderButtons,
  RenderStatus,
  RenderSignal,
  RenderError,
  RenderDate,
  RenderPlant,
  RenderAccess
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';
import { EditColumns } from '../../components';
import { APP_ID, HELION_APP_ID } from '../../config';

const columns = (myRoleType, { columns: additionalColumns, width, queries }) => ([
  {
    Header: i18n.t('SMID'),
    id: 'sm_id',
    accessor: (d) => (d.gateway?.sm_id || undefined),
    Cell: (row) => RenderGateway(row),
    minWidth: width.sm_id,
    className: 'sm_id',
    show: additionalColumns.sm_id
  },
  {
    Header: i18n.t('fName'),
    id: 'last_name',
    Cell: RenderUserName,
    minWidth: width.last_name
  },
  {
    Header: i18n.t('userSettingNotesLabel'),
    id: 'note',
    accessor: 'note',
    minWidth: width.note,
    filterable: false,
    show: additionalColumns.note
  },
  {
    Header: i18n.t('city'),
    id: 'city',
    accessor: 'city',
    minWidth: width.city,
    show: additionalColumns.city
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('signal'),
      tooltipText: i18n.t('signalTooltip'),
      placement: 'right-start'
    }),
    id: 'signal',
    accessor: (d) => (d.gateway?.signal || 'not connected'),
    minWidth: width.signal,
    Cell: (row) => RenderSignal(row),
    filterable: false,
    sortable: true
  },
  {
    Header: i18n.t('plantLabel'),
    id: 'plant',
    minWidth: width.plant,
    className: '',
    Cell: RenderPlant,
    show: additionalColumns.plant
  },
  {
    Header: i18n.t('error'),
    id: 'hasErrors',
    accessor: 'error',
    minWidth: width.hasErrors,
    Cell: (row) => RenderError(row),
    filterable: false,
    sortable: true
  },
  {
    Header: i18n.t('installer'),
    id: 'installer',
    accessor: (d) => (d.company ? d.company.name : i18n.t('noComp')),
    minWidth: width.installer,
    show: (myRoleType === 'root' || myRoleType === 'solar_admin' || myRoleType === 'viewer' || myRoleType === 'property_management' || myRoleType === 'oem')
  },
  {
    Header: i18n.t('oem'),
    id: 'connectedOem',
    accessor: (d) => (d.connectedOem ? d.connectedOem.name : i18n.t('NoOEM')),
    minWidth: width.oem,
    show: additionalColumns.oem
  },
  {
    Header: i18n.t('License'),
    id: 'license',
    accessor: 'license.name',
    minWidth: width.license,
    filterable: false,
    show: additionalColumns.license
  },
  {
    Header: i18n.t('appVersion'),
    id: 'helionAppVersion',
    accessor: 'helionAppVersion',
    minWidth: 100,
    filterable: false,
    show: additionalColumns.license && (APP_ID === HELION_APP_ID)

  },
  {
    Header: i18n.t('access'),
    id: 'access',
    Cell: (row) => RenderAccess(row),
    minWidth: 100,
    filterable: false,
    sortable: false,
    show: additionalColumns.access
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: width.status,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: width.last_login,
    className: 'last_login-td',
    Cell: (row) => RenderDate(row),
    show: additionalColumns.last_login
  },
  {
    Header: i18n.t('installationDate'),
    accessor: 'installationDate',
    minWidth: width.installationDate,
    className: 'installation_date-td',
    Cell: (row) => RenderDate({ value: row.original?.createdAt }),
    show: additionalColumns.installationDate
  },
  {
    Header: i18n.t('installationFinishedDate'),
    accessor: 'installationFinishedDate',
    minWidth: width.installationFinishedDate,
    className: 'installation_date-td',
    Cell: (row) => RenderDate({ value: row.original?.gateway?.installationFinishedDate }),
    show: additionalColumns.installationFinishedDate && queries.isInstallationCompleted !== false
  },
  {
    Header: i18n.t('lastConnection'),
    accessor: 'lastStreamUpdate',
    minWidth: width.lastStreamUpdate,
    className: 'last-update-td',
    Cell: (row) => RenderDate({ value: row.original?.gateway?.lastStreamUpdate }),
    show: additionalColumns.lastStreamUpdate
  },
  {
    Header: i18n.t('kWPeakOutputLabel'),
    accessor: 'settings.kWp',
    id: 'kWp',
    minWidth: width.kWp,
    className: 'last-update-td',
    show: additionalColumns.kWp,
    Cell: ({ value }) => Number.isFinite(value) && `${Math.round(value * 100) / 100} ${i18n.t('kWp')}`
  },
  {
    Header: EditColumns,
    accessor: 'buttons',
    width: width.buttons,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        devices: { preLink: '/users/' },
        editUser: { preLink: '/setting-profile/solar-managers-users/' },
        remove: (myRoleType === 'root' || myRoleType === 'solar_admin')
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
