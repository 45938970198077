import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Multiselect } from 'multiselect-react-dropdown';

import { sendPropFromField } from '../slice';
import { waitForTheCustomerNewStatus } from '../constants';
import { getSignInUserRoleTypeSelector } from '../../../redux-store/selectors/signIn';
import { multiselectConfig } from '../../../constants';

import i18n from '../../../i18n';

import '../index.scss';

const style = {
  ...multiselectConfig,
  multiselectContainer: {
    ...multiselectConfig.multiselectContainer,
    width: '230px',
    maxWidth: undefined
  }
};

/**
 * Renders multi filter of pv installer, assign, work status fields for Monitoring page
 * @memberof module:Monitoring
 */
const ExpanderMultiFilter = (props) => {
  const {
    choosingMultiList = {},
    onChangeField,
    defaultValue,
    monitoring = {},
    queryName,
    fieldName,
    id
  } = props;
  const dispatch = useDispatch();

  const [translatedMultiList, setTranslatedMultiList] = useState([]);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);

  const handleSelect = (selectedList) => {
    dispatch(sendPropFromField({ fieldName: [fieldName], selections: selectedList }));
    onChangeField({ [queryName]: selectedList?.map((dataPoint) => dataPoint.id || dataPoint._id).join(',') || undefined }, true);
  };

  useEffect(() => {
    if (choosingMultiList?.options) {
      const getFilteredListOfOptions = ['root', 'solar_admin', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem', 'oem_employee', 'oem_employee_read_only'].includes(myRoleType)
        ? choosingMultiList.options
        : choosingMultiList.options.filter(({ name }) => name !== waitForTheCustomerNewStatus);

      setTranslatedMultiList(getFilteredListOfOptions.map((el) => {
        const copiedMultiSelect = { ...el };
        copiedMultiSelect.name = i18n.exists(el.name) ? i18n.t(el.name) : el.name;
        return copiedMultiSelect;
      }));
    }

    localStorage.setItem('monitoringFilter', JSON.stringify(monitoring));
  }, [choosingMultiList, fieldName, monitoring, myRoleType]);

  return (
    <Multiselect
      id={id}
      options={translatedMultiList}
      placeholder={defaultValue}
      showCheckbox
      emptyRecordMsg={i18n.t('noOptionsAvailable')}
      style={style}
      onSelect={handleSelect}
      onRemove={handleSelect}
      displayValue="name"
      selectedValues={monitoring?.selectedFields?.[fieldName]?.value || []}
    />
  );
};

ExpanderMultiFilter.propTypes = {
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  choosingMultiList: PropTypes.instanceOf(Object),
  onChangeField: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  monitoring: PropTypes.instanceOf(Object),
  queryName: PropTypes.string
};

export default ExpanderMultiFilter;
