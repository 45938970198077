import React from 'react';
import PropTypes from 'prop-types';

const PaginationList = (props) => {
  const { currentPage, goToAndSave, pagesAmount } = props;

  const generalClass = 'm-datatable__pager-link';
  const disableClass = `${generalClass}--disabled`;

  const disabledPrevLink = currentPage > 1 ? '' : disableClass;
  const disabledNextLink = currentPage < (pagesAmount) ? '' : disableClass;

  // eslint-disable-next-line arrow-body-style
  const renderButtonsBetween = () => {
    return (
      <>
        {
          (currentPage === 1)
            ? (
              <li>
                <a className={`${generalClass} ${generalClass}-number ${generalClass}--active curr-page`}>
                  {currentPage}
                </a>
              </li>
            )
            : (
              <li>
                <a
                  className={`${generalClass} ${generalClass}-number`}
                  onClick={goToAndSave.bind(null, 1)}
                >
                  1
                </a>
              </li>
            )
        }

        {(currentPage >= 5) ? (
          <li>
            <a className={generalClass}>
              <i className="la la-ellipsis-h" />
            </a>
          </li>
        ) : undefined}

        {currentPage >= 4 ? (
          <li>
            <a
              className={generalClass}
              onClick={goToAndSave.bind(null, currentPage - 2)}
            >
              {currentPage - 2}
            </a>
          </li>
        ) : undefined}

        {currentPage >= 3 ? (
          <li>
            <a
              className={generalClass}
              onClick={goToAndSave.bind(null, currentPage - 1)}
            >
              {currentPage - 1}
            </a>
          </li>
        ) : undefined}

        {currentPage !== 1 ? (
          <li>
            <a className={`${generalClass} ${generalClass}-number ${generalClass}--active curr-page`}>
              {currentPage}
            </a>
          </li>
        ) : undefined}

        {currentPage < (pagesAmount - 1) ? (
          <li>
            <a
              className={`${generalClass} ${generalClass}-number`}
              onClick={goToAndSave.bind(null, currentPage + 1)}
            >
              {currentPage + 1}
            </a>
          </li>
        ) : undefined}

        {currentPage < (pagesAmount - 2) ? (
          <li>
            <a
              className={`${generalClass} ${generalClass}-number`}
              onClick={goToAndSave.bind(null, currentPage + 2)}
            >
              {currentPage + 2}
            </a>
          </li>
        ) : undefined}

        {currentPage < (pagesAmount - 3) ? (
          <li>
            <a className={`${generalClass} ${generalClass}--more-next`}>
              <i className="la la-ellipsis-h" />
            </a>
          </li>
        ) : undefined}

        {currentPage !== pagesAmount ? (
          <li>
            <a
              className={`${generalClass} ${generalClass}-number`}
              onClick={goToAndSave.bind(null, pagesAmount)}
            >
              {pagesAmount}
            </a>
          </li>
        ) : undefined}
      </>
    );
  };

  return (
    <ul className="m-datatable__pager-nav">
      <li>
        <a
          className={`${generalClass} ${generalClass}--first ${disabledPrevLink}`}
          onClick={!disabledPrevLink ? goToAndSave.bind(null, 1) : null}
        >
          <i className="la la-angle-double-left" />
        </a>
      </li>
      <li>
        <a
          className={`${generalClass} ${generalClass}--prev ${disabledPrevLink}`}
          onClick={!disabledPrevLink ? goToAndSave.bind(null, currentPage - 1) : null}
        >
          <i className="la la-angle-left" />
        </a>
      </li>
      {renderButtonsBetween()}
      <li>
        <a
          className={`${generalClass} ${generalClass}--next ${disabledNextLink}`}
          onClick={!disabledNextLink ? goToAndSave.bind(null, currentPage + 1) : null}
        >
          <i className="la la-angle-right" />
        </a>
      </li>
      <li>
        <a
          className={`${generalClass} ${generalClass}--last ${disabledNextLink}`}
          onClick={!disabledNextLink ? goToAndSave.bind(null, pagesAmount) : null}
        >
          <i className="la la-angle-double-right" />
        </a>
      </li>
    </ul>
  );
};
PaginationList.propTypes = {
  pagesAmount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  goToAndSave: PropTypes.func.isRequired
};

export default PaginationList;
