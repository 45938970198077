import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { changeScaleType } from '../slice';
import { chartChangeRange, getRange } from '../utils';
import { queryURLFormat } from '../../../utils';
import { getLoadManagementDashboardChartSelector } from '../../../redux-store/selectors/loadManagementDashboard';

import i18n from '../../../i18n';

const LoadManagementChartControls = (props) => {
  const {
    replaceFromProps,
    openDatePicker,
    realTimeData,
    intervalStr,
    scaleType,
    subType,
    location,
    zoom
  } = props;
  const dispatch = useDispatch();

  const chart = useSelector(getLoadManagementDashboardChartSelector);

  const disableForwardBtn = moment().diff(moment(chart?.to)) <= 0 || realTimeData;
  /**
   * @param {number | string} value next scaleType
   */
  const chartControlsHandler = (value) => {
    const { from, to, newSubType } = getRange(value, { ...chart, scaleType: scaleType?.type }, subType || scaleType?.type);
    if (typeof value === 'number' || value === 'back' || value === 'forward') {
      chartChangeRange(from, to, replaceFromProps, location.pathname, newSubType);
    } else {
      replaceFromProps(location.pathname + queryURLFormat({ type: value }));
    }

    dispatch(changeScaleType({ data: { type: value } }));
  };

  return (
    <div className="container-fluid chart-controls-wrapper">
      <div className="row justify-content-between">
        <div className="col-auto">
          <button
            onClick={chartControlsHandler.bind(null, 't')}
            className={
              [subType, scaleType?.type].includes('t')
                ? 'btn-today navig-btn-dashb navig-btn-dashb-act'
                : 'btn-today navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('today')}
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'h')}
            className={
              subType !== 'd' && [subType, scaleType?.type].includes('h') ? 'navig-btn-dashb navig-btn-dashb-act' : 'navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('h')}
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'd')}
            className={
              [subType, scaleType?.type].includes('d') ? 'navig-btn-dashb navig-btn-dashb-act' : 'navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('d')}
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'w')}
            className={
              [subType, scaleType?.type].includes('w') ? 'navig-btn-dashb navig-btn-dashb-act' : 'navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('w')}
          </button>
        </div>
        <div className="col-auto mr-auto d-flex align-items-start">
          {zoom && (
            <>
              <button
                onClick={chartControlsHandler.bind(null, 0.5)}
                type="button"
                className="navig-btn-dashb btn-plus-zoom-dashb"
                disabled={scaleType?.type === 'h'}
              >
                +
              </button>
              <button
                onClick={chartControlsHandler.bind(null, 2)}
                type="button"
                className="navig-btn-dashb btn-min-zoom-dashb"
                disabled={scaleType?.type === 'w'}
              >
                -
              </button>
            </>
          )}
          {realTimeData && (
            <div className="liveDataStatus">
              <div className="recIco" />
            </div>
          )}
        </div>
        <div className="col-auto d-flex align-items-center">
          <button
            onClick={openDatePicker}
            type="button"
            className="navig-btn-dashb btn-calendar-dashb"
          >
            <i className="flaticon-event-calendar-symbol fa" />
          </button>
          <span className="date-dashb">{intervalStr}</span>
          <button
            onClick={chartControlsHandler.bind(null, 'back')}
            type="button"
            className="navig-btn-dashb btn-plus-zoom-dashb"
          >
            <i className="la-angle-left la" />
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'forward')}
            disabled={disableForwardBtn}
            style={{
              cursor: disableForwardBtn ? 'not-allowed' : 'pointer',
              opacity: disableForwardBtn ? '0.5' : '1',
              cursorEvents: disableForwardBtn ? 'none' : 'auto'
            }}
            type="button"
            className="navig-btn-dashb btn-min-zoom-dashb"
          >
            <i className="la-angle-right la" />
          </button>
        </div>
      </div>
    </div>
  );
};

LoadManagementChartControls.propTypes = {
  scaleType: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object).isRequired,
  replaceFromProps: PropTypes.func.isRequired,
  openDatePicker: PropTypes.func.isRequired,
  zoom: PropTypes.bool.isRequired,
  intervalStr: PropTypes.string,
  realTimeData: PropTypes.bool,
  subType: PropTypes.string
};

export default LoadManagementChartControls;
