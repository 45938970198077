import React, { useMemo } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { DataListV2, DataListPagination, DataListFilterV2 } from '../DataList';
import { PrimaryButton } from '../../components/UIKit';
import { DeletePropMgmtWindow, AddPropMgmtWindow } from './modals';
import { Footer, SearchField, TopBar, Pagination, Breadcrumbs } from '../../components';

import { openModalWindow, modalWindowSend, ADD_DATA_MODAL_ID, REMOVE_DATA_MODAL_ID } from '../ModalWindow/slice';
import { dataListReloadData } from '../DataList/slice';

import i18n from '../../i18n';
import columns from './columns';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';

import '../Users/index.scss';
import { USERS_PROP_MGMT_URL } from '../../api/apiUrls';
import { ConfirmDeleteUserWindow } from '../../modals';
import { transformResponse } from '../../utils/transformResponse';

const dataListID = 'propMgmts';
const listURL = USERS_PROP_MGMT_URL;

/**
 * Renders page with table of supports.
 * @memberof module:SupportsTable
 */
const PropMgmts = (props) => {
  const { myRoleType } = props;
  const dispatch = useDispatch();

  const { [dataListID]: pageSettings } = useSelector(getPagesSettingsSelector);
  const columnsPropMgmts = useMemo(() => columns(myRoleType, pageSettings), [myRoleType, pageSettings]);
  const defaultParams = pageSettings.queries;

  const sendAddUser = (fields) => dispatch(modalWindowSend({
    modalID: ADD_DATA_MODAL_ID,
    requestConfig: {
      method: 'post',
      url: '/users/property-management',
      data: {
        ...fields,
        'rep-password': undefined
      }
    },
    cb: () => dataListReloadData({ listID: dataListID })
  }));

  const sendRemoveUser = ({ id }) => dispatch(modalWindowSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `/../user/${id}`
    },
    cb: () => dataListReloadData({ listID: dataListID })
  }));

  const onClickHandler = () => dispatch(openModalWindow({ modalID: ADD_DATA_MODAL_ID, data: null }));

  return (
    <div className="main-table-container">
      <AddPropMgmtWindow onSubmit={sendAddUser} />
      <DeletePropMgmtWindow onSubmit={sendRemoveUser} />
      <ConfirmDeleteUserWindow userRole={i18n.t('propMgmt').toLowerCase()} />

      <TopBar updateButton showNavigation />
      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('propMgmt') }]} />

        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
          <div className="col-xl-12">
            <div className="row align-items-center">
              <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10 order-1 mr-auto">
                <DataListFilterV2
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSettings.queries.search,
                    searchByFields: 'email,first_name,last_name,company,street,city,zip,phone',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={dataListID}
                  listURL={listURL}
                  transformResponse={transformResponse}
                />
              </div>
              {myRoleType !== 'viewer' ? (
                <div className="col-auto order-2 btn-sm-us">
                  <PrimaryButton onClickHandler={onClickHandler} i18nLabel="addPropMgmt" isLargeButton />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
          <DataListV2
            listID={dataListID}
            listURL={listURL}
            params={defaultParams}
            transformResponse={transformResponse}
            Component={ReactTable}
            componentProps={{
              defaultPageSize: 0,
              className: '-highlight m-datatable__table',
              columns: columnsPropMgmts,
              manual: true
            }}
          />
          <DataListPagination
            Component={Pagination}
            listID={dataListID}
            defaultPageSize={10}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

PropMgmts.propTypes = {
  myRoleType: PropTypes.string.isRequired
};

export default PropMgmts;
