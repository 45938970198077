import { modalWindowSend, DATA_EXPORT_SUB_METER_MODAL_ID } from '../../../ModalWindow/slice';

const checkEndUser = (roleType) => roleType === 'end_user';

const getUserGateway = (state = {}) => {
  const { signIn = {}, userDevices = {} } = state;

  if (checkEndUser(signIn.user?.role?.type)) {
    return signIn.user.gateway._id;
  }

  return userDevices.user?.gateway?._id || null;
};

export const sendSubMeterData = (fields = {}) => (dispatch, getState) => {
  const { fromDate, toDate } = fields;
  const from = new Date(fromDate).toISOString();
  const to = new Date(toDate).toISOString();

  const gatewayId = getUserGateway(getState());

  dispatch(modalWindowSend({
    modalID: DATA_EXPORT_SUB_METER_MODAL_ID,
    requestConfig: {
      method: 'post',
      url: '/data/export-data/sub-meter',
      data: {
        from,
        to,
        gatewayIds: [gatewayId]
      }
    }
  }));
};
