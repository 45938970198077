import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  isSupportContractFlagAllowedToBeChanged: true,
  isInstallationCompleted: undefined,
  userSubscription: undefined,
  supportContract: undefined,
  devicesList: undefined,
  lastSensorsData: [],
  userId: undefined,
  load: undefined,
  ips: undefined,
  chargers: undefined,
  user: {}
};

const userDevicesSlice = createSlice({
  name: 'userDevices',
  initialState,
  reducers: {
    getIsSupportContractFlagAllowedToBeChanged: epicEmptyFunction,
    devicesGetTypesAndNames: epicEmptyFunction,
    changePrioritySuccess: epicEmptyFunction,
    cancelChangePriority: epicEmptyFunction,
    fetchLastSensorsData: epicEmptyFunction,
    updateHouseSettings: epicEmptyFunction,
    userDataSendRequest: epicEmptyFunction,
    cancelUserInfo: epicEmptyFunction,
    changePriority: epicEmptyFunction,
    getUserInfo: epicEmptyFunction,
    scanIP: epicEmptyFunction,
    scanChargers: epicEmptyFunction,

    changeInstallationFinishedRequest: (state, action) => {
      state.userId = action.payload.userId;
      state.isInstallationCompleted = action.payload.isInstallationCompleted;
      state.userId = action.payload.userId;
    },
    receiveUserInfo: (state, action) => {
      state.user = action.payload.data;
    },
    setFirmwareVersion: (state, action) => {
      if (state.user) {
        state.user.firmware = action.payload.data;
      }
    },
    devicesSaveTypesAndNames: (state, action) => {
      state.devicesList = action.payload.data;
    },
    receiveIsSupportContractFlagAllowedToBeChanged: (state, action) => {
      state.isSupportContractFlagAllowedToBeChanged = action.payload.data;
    },
    getListOfIP: (state) => {
      state.load = true;
    },
    getListOfChargers: (state) => {
      state.load = true;
    },
    saveIP: (state, action) => {
      state.ips = action.payload.ips;
      state.load = false;
    },
    saveChargers: (state, action) => {
      state.chargers = action.payload.chargers;
      state.load = false;
    },
    removeIP: (state) => {
      state.ips = [];
    },
    removeChargers: (state) => {
      state.chargers = [];
    },
    changeSupportContractsRequest: (state, action) => {
      state.userId = action.payload.userId;
      state.supportContract = action.payload.supportContractFlag;
    },
    lastSensorsDataReceived: (state, action) => {
      state.lastSensorsData = action.payload.data;
    },
    clearUserData: (state) => {
      state.isSupportContractFlagAllowedToBeChanged = true;
      state.user = {};
    }
  }
});

export const {
  getUserInfo,
  receiveUserInfo,
  cancelUserInfo,
  clearUserData,
  userDataSendRequest,

  devicesSaveTypesAndNames,
  devicesGetTypesAndNames,

  receiveIsSupportContractFlagAllowedToBeChanged,
  getIsSupportContractFlagAllowedToBeChanged,
  changeSupportContractsRequest,
  changeInstallationFinishedRequest,

  changePrioritySuccess,
  cancelChangePriority,
  changePriority,

  updateHouseSettings,

  fetchLastSensorsData,
  lastSensorsDataReceived,

  getListOfIP,
  removeIP,
  saveIP,
  scanIP,
  saveChargers,
  getListOfChargers,
  removeChargers,
  scanChargers,
  setFirmwareVersion
} = userDevicesSlice.actions;

export default userDevicesSlice.reducer;
