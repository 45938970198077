import { createSelector } from '@reduxjs/toolkit';

import {
  getSignInUser
} from '../../redux-store/selectors/signIn';
import {
  getUserDevicesUser
} from '../../redux-store/selectors/userDevices';
import {
  houseFuseDefaultValue,
  loadManagementValue,
  rolesWithCustomLogo
} from './constants';

const getSupportContracts = (state, ownProps) => ownProps.user?.flags?.includes('supportContract');
const getRentedPVSystemFlag = (_, ownProps) => ownProps.user?.flags?.includes('rentedPvSystem');
const getIsInstallationCompleted = (state, ownProps) => ownProps?.user?.gateway?.isInstallationCompleted;

export const houseFuseSelector = createSelector(
  [getSignInUser, getUserDevicesUser],
  (user, userDevices) => (user?.role?.type === 'end_user'
    ? user?.houseSettings?.houseFuse
    : userDevices?.houseSettings?.houseFuse) || houseFuseDefaultValue
);

export const loadManagementSelector = createSelector(
  [getSignInUser, getUserDevicesUser],
  (user, userDevices) => (user?.role?.type === 'end_user'
    ? user?.houseSettings?.loadManagement
    : userDevices?.houseSettings?.loadManagement) || loadManagementValue
);

export const houseSettingsSelector = createSelector(
  [getSignInUser, getUserDevicesUser],
  (user, userDevices) => (user?.role?.type === 'end_user'
    ? user?.houseSettings
    : userDevices?.houseSettings) || {}
);

export const supportEmailSubjectSelector = createSelector(
  [getUserDevicesUser, getSignInUser],
  (userDevice, user) => {
    const rolesForShowingCompany = ['pv_installer', 'pv_installer_employee', 'oem', 'oem_employee'];
    return `SM-Web-Case ${userDevice?.gateway?.sm_id} - ${userDevice?.first_name} ${userDevice?.last_name} ${rolesForShowingCompany.includes(user?.role?.type)
      ? `- ${userDevice?.company?.name}`
      : ''}`;
  });

// User selectors
export const visibleWebSelector = createSelector(
  [getSignInUser],
  (user) => (rolesWithCustomLogo.includes(user?.role?.type)
    ? user?.my_company?.visible_web || user?.oem?.visible_web || user?.employerOem?.visible_web
    : user?.company?.visible_web)
);

// Other selectors
export const initialValuesSelector = createSelector(
  [getSupportContracts, getIsInstallationCompleted, getRentedPVSystemFlag],
  (supportContracts, isInstallationCompleted, rentedPVSystemFlag) => ({
    support_contracts: supportContracts,
    isInstallationCompleted,
    rentedPVSystemFlag
  })
);
