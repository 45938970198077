import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import i18n from '../../../i18n';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsRemoveDataDataSelector } from '../../../redux-store/selectors/modals';
import { REMOVE_DATA_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import '../index.scss';

const DeleteAdminWindow = (props) => {
  const { onSubmit } = props;
  const dispatch = useDispatch();

  const data = useSelector(getModalsRemoveDataDataSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: REMOVE_DATA_MODAL_ID }));

  return (
    <ModalWindow modalID={REMOVE_DATA_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('delAdmin')}</h5>
      </div>
      <div>
        <div className="modal-body">
          <div className="text-confirmation">{i18n.t('confirmDelAdmin')}</div>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton onClickHandler={onSubmit.bind(null, { id: data?.id || data?._id })} type="delete" />
        </div>
      </div>
    </ModalWindow>
  );
};

DeleteAdminWindow.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default DeleteAdminWindow;
