import { createSelector } from '@reduxjs/toolkit';

const getMonitoring = (state) => state.monitoring;
const getMonitoringEmployeesForFilter = (state) => state.monitoring?.employeesForFilter;
const getChoosedCompany = (state) => state.monitoring?.choosedCompany?.value;
const getMonitoringSelectedRows = (state) => state.monitoring?.selectedRows;
const getMonitoringInstallers = (state) => state.monitoring?.installers;
const getMonitoringEmployees = (state) => state.monitoring?.employees;
const getMonitoringErrors = (state) => state.monitoring?.errors;
const getMonitoringData = (state) => state.monitoring?.data;

const getMonitoringSelectedFields = (state) => state.monitoring?.selectedFields;
const getMonitoringSelectedFieldsAssignField = (state) => state.monitoring?.selectedFields?.assignField;
const getMonitoringSelectedFieldsPriorityField = (state) => state.monitoring?.selectedFields?.priorityField;
const getMonitoringSelectedFieldsWorkStatusField = (state) => state.monitoring?.selectedFields?.workStatusField;
const getMonitoringSelectedFieldsPVInstallerFilter = (state) => state.monitoring?.selectedFields?.PVInstallerFilter;

export const getMonitoringSelectedFieldsPVInstallerFilterSelector = createSelector([getMonitoringSelectedFieldsPVInstallerFilter], (PVInstallerFilter) => PVInstallerFilter);
export const getMonitoringSelectedFieldsWorkStatusFieldSelector = createSelector([getMonitoringSelectedFieldsWorkStatusField], (workStatusField) => workStatusField || {});
export const getMonitoringSelectedFieldsPriorityFieldSelector = createSelector([getMonitoringSelectedFieldsPriorityField], (priorityField) => priorityField || {});
export const getMonitoringSelectedFieldsAssignFieldSelector = createSelector([getMonitoringSelectedFieldsAssignField], (assignField) => assignField || {});
export const getMonitoringSelectedFieldsSelector = createSelector([getMonitoringSelectedFields], (selectedFields) => selectedFields || {});

export const getMonitoringEmployeesForFilterSelector = createSelector([getMonitoringEmployeesForFilter], (employeesForFilter) => employeesForFilter || {});
export const getMonitoringSelectedRowsSelector = createSelector([getMonitoringSelectedRows], (selectedRows) => selectedRows || []);
export const getMonitoringInstallersSelector = createSelector([getMonitoringInstallers], (installers) => installers);
export const getMonitoringEmployeesSelector = createSelector([getMonitoringEmployees], (employees) => employees || {});
export const getMonitoringErrorsSelector = createSelector([getMonitoringErrors], (errors) => errors || {});
export const getMonitoringSelector = createSelector([getMonitoring], (monitoring) => monitoring);
export const getChoosedCompanySelector = createSelector([getChoosedCompany], (value) => value || []);
export const getMonitoringDataSelector = createSelector([getMonitoringData], (data) => data || []);
