import moment from 'moment-timezone';
import { days366, fifteenMinutes, ONE_DAY_MS } from '../constants';

/**
 * Returns range parameters by scaleType
 * @param scaleType
 * @param chart
 * @param isReset
 * @memberof module:Dashboard
 * @returns {{limitsReceived: *, expectedScaleMS: *, from: *, to: *}}
 */
export default function getRangeByScaleType(scaleType, chart) {
  let from;
  let to;
  let expectedScaleMS;
  let limitsReceived;
  let isToday = false;
  const now = moment().valueOf();
  const prevTo = chart?.to > moment(now).valueOf() ? moment(now).valueOf() : chart?.to;
  const prevRange = {
    from: chart?.from,
    to: prevTo,
    middle: prevTo - ((prevTo - (chart?.from || 0)) / 2),
    scaleType: chart?.scaleType,
    realTimeData: chart?.realTimeData
  };

  const { chartType = 'daily' } = chart || {};

  switch (scaleType) {
    case 'y': {
      from = moment(prevRange.from || now).startOf('year');
      to = moment(prevRange.from || now).endOf('year');
      expectedScaleMS = to.valueOf() - from.valueOf();
      break;
    }
    case 'm': {
      from = moment(prevRange.middle || now).startOf('month');
      to = moment(prevRange.middle || now).endOf('month');
      expectedScaleMS = to.valueOf() - from.valueOf();
      break;
    }
    case 'w': {
      if (prevRange.scaleType === 't' || prevRange.realTimeData) {
        from = moment(now).subtract(1, 'week');
        to = moment(now);
      } else {
        from = moment(prevRange.middle || now).subtract(1, 'week');
        to = moment(prevRange.middle || now);
      }
      expectedScaleMS = to.valueOf() - from.valueOf();
      break;
    }
    case 'd': {
      if (prevRange.scaleType === 't' || prevRange.realTimeData) {
        from = moment(now).subtract(1, 'day');
        to = moment(now);
      } else if (moment(prevRange.middle || now).endOf('day') > now) {
        from = moment(prevRange.middle || now).subtract(1, 'day');
        to = moment(prevRange.middle || now);
      } else {
        from = moment(prevRange.middle || now).startOf('day');
        to = moment(prevRange.middle || now).endOf('day');
      }
      expectedScaleMS = to.valueOf() - from.valueOf();
      break;
    }
    case 'h': {
      if (prevRange.scaleType === 't' || prevRange.realTimeData) {
        from = moment(now).subtract(1, 'hour');
        to = moment(now);
      } else {
        from = moment(prevRange.middle || now).subtract(1, 'hour');
        to = moment(prevRange.middle || now);
      }
      expectedScaleMS = to.valueOf() - from.valueOf();
      break;
    }
    case 't': {
      from = moment(now).startOf('day');
      to = moment(now);
      expectedScaleMS = ONE_DAY_MS;
      isToday = true;
      break;
    }
    default: {
      from = chart.from ? moment(chart.from) : moment().startOf('day');
      to = chart.to ? moment(chart.to) : moment();
      const oldScaleMS = chart.scaleMS ? chart.scaleMS : to.diff(from);

      if (scaleType === 'back') {
        if (chartType === 'monthly') {
          from = from.subtract(1, 'year').startOf('year');
          to = from.clone().endOf('year');
        } else if (chartType === 'daily') {
          from = from.subtract(1, 'month').startOf('month');
          to = from.clone().endOf('month');
        } else {
          from.subtract(Math.round(oldScaleMS), 'ms');
          to = moment(from).add(oldScaleMS, 'ms');
        }
        expectedScaleMS = oldScaleMS;
      } else if (scaleType === 'forward') {
        if (chartType === 'monthly') {
          from = from.add(1, 'year').startOf('year');
          to = from.clone().endOf('year');
        } else if (chartType === 'daily') {
          from = from.add(1, 'month').startOf('month');
          to = from.clone().endOf('month');
        } else {
          to.add(Math.round(oldScaleMS), 'ms');
          from = moment(to).subtract(oldScaleMS, 'ms');
        }
        expectedScaleMS = oldScaleMS;
      } else if (typeof scaleType === 'number') {
        let newScale = oldScaleMS * scaleType;
        if (newScale < fifteenMinutes) {
          // 15 хвилин
          newScale = fifteenMinutes;
          limitsReceived = { minZoom: true };
        } else if (newScale > days366) {
          // 366 днів
          newScale = days366;
          limitsReceived = { maxZoom: true };
        }
        const offset = (oldScaleMS - newScale) / 2;
        from.add(offset, 'ms');
        to.subtract(offset, 'ms');
        expectedScaleMS = newScale;
      }
    }
  }

  return { from, to, expectedScaleMS, limitsReceived, isToday };
}

export const getScaleRange = ({ from, to, type }, chartData) => {
  if (from && to) {
    return {
      from: moment(+from),
      to: moment(+to),
      expectedScaleMS: to.valueOf() - from.valueOf()
    };
  }

  return getRangeByScaleType(type || 't', chartData);
};
