import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { monitoringSendApplyWithFields } from '../../../containers/Monitoring/slice';
import { DATA_LIST_ID, WORK_STATUS_ARR } from '../../../containers/Monitoring/constants';
import { ERROR_MONITORING_TICKETS_URL } from '../../../api/apiUrls';
import { DropDownList } from '../../UIKit';
import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for status of user
 * @memberof module:ReactTable
 * @param  {string} value - work status of user
 */
const RenderWorkStatus = (props) => {
  const { value, original } = props;
  const [open, setOpen] = useState(false);

  return (
    <DropDownList
      request={monitoringSendApplyWithFields}
      disabledKey="wait for the customer / new"
      i18TitleValue={i18n.t(value).length > 16 ? `${i18n.t(value).slice(0, 16)}...` : i18n.t(value)}
      DATA_LIST_URL={ERROR_MONITORING_TICKETS_URL}
      DATA_LIST_ID={DATA_LIST_ID}
      menuItems={WORK_STATUS_ARR}
      dropDownType="status"
      original={original}
      titleValue={value}
      setOpen={setOpen}
      open={open}
    />
  );
};

RenderWorkStatus.propTypes = {
  value: PropTypes.string.isRequired,
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderWorkStatus;
