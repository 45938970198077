import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { ThemeProvider, createTheme } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import { TableDefaultSettings } from './components/ReactTable/components';
import store, { history, persistor } from './redux-store/store';
import App from './App';
import NotificationList from './containers/NotificationGenerator';
import { setupInterceptors } from './api/AxiosInterceptor';
import { themeMui } from './themeMui';
import 'react-table/react-table.css';
import './components/ReactTable/index.scss';

import './index.css';
import './assets/styles/style.scss';
import './i18n';

const theme = createTheme(themeMui);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <>
            <TableDefaultSettings />
            <App />
            <NotificationList />
          </>
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>
);

setupInterceptors(store);
