import React, { Suspense, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

import { Preloader } from '../../../components';
import { signInCheckJWT, signInInvalidJWT, signInGoBackIfSucc } from '../../SignIn/slice';
import { getSignInUserSelector, getSignInVerifyingJWTSelector } from '../../../redux-store/selectors/signIn';
import { getJwtFromCookies, updateCookies, updateJwt } from '../../../api';

export default (Component, allowedRoles, properties, restrictionsByFields = []) => {
  /**
   * Checks if user is authorized. If he is authorized and his role type has permission to visit current page, it renders the page. Otherwise it does redirect him to login page.
   * @memberof module:PermissionChecker
   */
  const ProtectedRoute = (props) => {
    const { match, location } = props;
    const dispatch = useDispatch();

    const user = useSelector(getSignInUserSelector);
    const verifyingJWT = useSelector(getSignInVerifyingJWTSelector);
    const tokenFromUrl = new URLSearchParams(location.search).get('token');

    useEffect(() => {
      const jwt = getJwtFromCookies();

      if (jwt) {
        if (verifyingJWT) {
          dispatch(signInCheckJWT());
        }
      } else {
        dispatch(signInInvalidJWT());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (tokenFromUrl) {
      updateCookies(tokenFromUrl, { expires: moment().add(30, 'days').toDate() });
      updateJwt(tokenFromUrl);

      return <Redirect to="/" />;
    }

    if (verifyingJWT) {
      return <Preloader />;
    }

    if (!user) {
      dispatch(signInGoBackIfSucc({ needGoBackAfterLogin: true }));
      return <Redirect push to="/login" />;
    }

    const { _id, role, my_company, tabs } = user;
    const userRoleType = role?.type;
    const matchPath = match?.path;

    switch (userRoleType) {
      case 'viewer': {
        if (
          (matchPath.startsWith('/oem') && !tabs?.oem)
          || (matchPath === '/sub-users' && !tabs?.sub_users)
          || (matchPath.startsWith('/pv-installers') && !tabs?.installers)
        ) {
          return <Redirect to="/" />;
        }
        break;
      }

      case 'pv_installer': {
        if (my_company?.company_abbreviation && matchPath === '/employees') {
          return <Redirect to="/" />;
        }
        break;
      }

      default:
        break;
    }

    if (!allowedRoles || allowedRoles.includes(userRoleType)) {
      return (
        <Suspense fallback={<Preloader />}>
          <Component
            {...props}
            {...properties}
            user={user}
            verifyingJWT={verifyingJWT}
            myRoleType={userRoleType}
            myUserID={_id}
          />
        </Suspense>
      );
    }

    if (restrictionsByFields.length) {
      const isRestricted = restrictionsByFields.some(({ field, value }) => user[field] !== value);

      if (isRestricted) {
        return <Redirect to="/" />;
      }
    }

    return <Redirect to="/" />;
  };

  ProtectedRoute.propTypes = {
    match: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired
  };

  return withTranslation()(ProtectedRoute);
};
