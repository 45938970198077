/**
 * Render function for React-Table. User name
 * @memberof module:ReactTable
 */
const RenderUserName = ({ original: { first_name, last_name, status, email } }) => (
  (status === 'pending user confirmation')
    ? email
    : `${first_name} ${last_name}`
);

export default RenderUserName;
