import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment-timezone';
import addHighchartsMore from 'highcharts/highcharts-more';

import './style.scss';
import { calcTickInterval, formatEnergy, xAxisFormatter } from '../utils';

addHighchartsMore(Highcharts);

const PredictionChart = ({ expected = [], ranges = [], timezone }) => {
  const min = expected[0][0];
  const max = expected[expected.length - 1][0];

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    Highcharts.charts[0].reflow(false);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="dashboardApp">
      <div className="chartFlexItem">
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: null,
            legend: { enabled: false },
            credits: { enabled: false },
            accessibility: { enabled: false },
            chart: {
              pinchType: 'none',
              marginRight: 0,
              backgroundColor: '#25272d',
              resetZoomButton: {
                theme: {
                  display: 'none'
                }
              }
            },
            plotOptions: {
              series: {
                allowPointSelect: false,
                states: {
                  hover: {
                    enabled: false
                  }
                },
                getExtremesFromAll: true,
                enableMouseTracking: false
              }
            },
            noData: {
              style: {
                fontWeight: 'normal',
                fontSize: '20px',
                color: 'rgba(0, 0, 0, .5)'
              }
            },
            time: {
              getTimezoneOffset(timestamp) {
                const timezoneOffset = -moment.tz(timestamp, timezone).utcOffset();

                return timezoneOffset;
              }
            },
            labels: {
              style: {
                color: '#b6b6b6'
              }
            },
            xAxis: {
              id: 'timeAxis',
              gridLineColor: '#353535',
              lineWidth: 0,
              min,
              max,
              tickWidth: 0,
              tickInterval: calcTickInterval(windowWidth),
              gridLineWidth: 1,
              dateTimeLabelFormats: {
                hour: '%H:%M<br/>%Y-%m-%d'
              },
              labels: {
                formatter: xAxisFormatter,
                rotation: 0,
                useHTML: true,
                style: {
                  width: '70px',
                  color: '#b6b6b6',
                  fontSize: '8pt',
                  textAlign: 'center'
                }
              }
            },
            yAxis: {
              title: null,
              gridLineColor: '#353535',
              tickInterval: 1000,
              labels: {
                style: {
                  color: '#b6b6b6',
                  fontSize: '10pt'
                },
                formatter: formatEnergy
              }
            },
            series: [
              {
                type: 'spline',
                lineColor: 'rgba(255, 184, 34, 1)',
                lineWidth: 1,
                data: expected
              },
              {
                type: 'areasplinerange',
                fillColor: 'rgba(255, 184, 34, .35)',
                lineWidth: 0,
                data: ranges
              }
            ]
          }}
          className="chartParent"
          containerProps={{ style: { height: '100%', width: '100%', position: 'absolute', left: 0, right: 0 } }}
        />
      </div>
    </div>
  );
};

PredictionChart.propTypes = {
  expected: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  timezone: PropTypes.string.isRequired
};

export default PredictionChart;
