import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../api_helper';
import i18n from '../../../i18n';

export const InstalledSMApi = createApi({
  tagTypes: ['InstalledSM', 'firmwareList', 'firmware'],
  reducerPath: 'InstalledSMApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    startSoftwareUpdate: builder.mutation({
      query: ({ data }) => ({ url: '/firmware/latest', method: 'put', data })
    }),
    rebootGateways: builder.mutation({
      refetchOnMountOrArgChange: false,
      query: ({ data }) => ({ url: '/account/gateways/reboot', method: 'post', data })
    }),
    getFirmwareList: builder.query(({
      providesTags: ['firmwareList'],
      query: () => ({ url: '/firmware/list', method: 'get' }),
      transformResponse: (data) => [
        { label: i18n.t('doNotChanges'), value: undefined },
        ...data.map(({ _id, version }) => ({ label: `${_id} (${version})`, value: _id }))
      ]
    }))
  })
});

export const {
  useStartSoftwareUpdateMutation,
  useRebootGatewaysMutation,
  useGetFirmwareListQuery
} = InstalledSMApi;
