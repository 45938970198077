import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  usersSensors: [],
  devicesTypesList: undefined,
  sensorsConfigurationFile: undefined
};

const devicesTableSlice = createSlice({
  name: 'devicesTable',
  initialState,
  reducers: {
    getSensorsConfigurationFile: epicEmptyFunction,
    usersSensorsRequest: epicEmptyFunction,
    setDeviceIdRtu: epicEmptyFunction,
    getZohoRedirectRequest: epicEmptyFunction,

    receiveSensorsConfigurationFile: (state, action) => {
      state.sensorsConfigurationFile = {
        data: action.payload.data,
        createdAt: new Date()
      };
    },
    usersSensorsReceived: (state, action) => {
      state.usersSensors = action.payload.data;
    },
    saveDevicesTypesList: (state, action) => {
      state.devicesTypesList = action.payload.data;
    }
  }
});

export const {
  receiveSensorsConfigurationFile,
  getSensorsConfigurationFile,
  usersSensorsReceived,
  saveDevicesTypesList,
  usersSensorsRequest,
  setDeviceIdRtu,
  getZohoRedirectRequest
} = devicesTableSlice.actions;

export default devicesTableSlice.reducer;
