import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Header, Value } from '../Cell';

const Row = ({ data = [] }) => (
  <tr className="m-datatable__row">
    {data.map(({ label, value, type = null }) => (
      <Fragment key={uuidv4()}>
        <Header label={label} />
        <Value value={value} type={type} />
      </Fragment>
    ))}
  </tr>
);

Row.propTypes = {
  data: PropTypes.instanceOf(Object)
};

export default Row;
