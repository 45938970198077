/**
 * Create array with statuses of chosen field by checkbox
 * @param {Array} data Array with objects. Object contain all info about field
 * @param {Array} arrayOfChoosedFields id of chosen field
 * @param {String} choosedEl name of key which we should get from object
 * @returns {Array}
 */
const parseStatusOfTicket = (data, arrayOfChoosedFields, choosedEl) => Array.from(new Set(data.map((el) => {
  if (arrayOfChoosedFields.find((id) => id === el._id)) {
    return el[choosedEl];
  }
  return null;
}).filter(Boolean)));

export default parseStatusOfTicket;
