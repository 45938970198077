import i18n from '../../../i18n';

/**
 * get title for add devices btn in devices table
 */
const getAddTitle = (type) => {
  const deviceTypes = {
    devices: 'addDevice',
    inverters: 'addInverter',
    'smart-meter': 'addSmartMeterBtn',
    'sub-meter': 'addSubMetersBtn',
    cars: 'addCar',
    'input-devices': 'addInputDevice',
    loadguards: 'addLoadGuard'
  };

  if (deviceTypes?.[type]) {
    return i18n.t(deviceTypes[type]);
  }
  throw new Error('unknown devices');
};

export default getAddTitle;
