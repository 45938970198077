import React from 'react';
import PropTypes from 'prop-types';

const Status = ({ status }) => (
  <span className="m-badge gener-sett-soft-select m-badge--metal m-badge--wide td-info-status">
    {status}
  </span>
);

Status.propTypes = {
  status: PropTypes.string.isRequired
};

export default Status;
