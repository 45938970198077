import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  formData: null,
  isDisabledOauth2EditModal: true
};

const sensorsOauth2Slice = createSlice({
  name: 'sensorsOauth2',
  initialState,
  reducers: {
    requestAccessToken: epicEmptyFunction,
    receiveFormData: (state, action) => {
      state.formData = action.payload.data;
    },
    sensorsOauth2AllowedToDisabledEditModal: (state, action) => {
      state.isDisabledOauth2EditModal = action.payload.data;
    },
    clearSensorsOauth2FormData: (state) => {
      state.formData = null;
    }
  }
});

export const {
  sensorsOauth2AllowedToDisabledEditModal,
  clearSensorsOauth2FormData,
  requestAccessToken,
  receiveFormData
} = sensorsOauth2Slice.actions;

export default sensorsOauth2Slice.reducer;
