import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { CustomTextField, CustomSelect } from '../../../components/ReduxFormFields';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';

import { required, gateways as gatewaysValidator } from '../../validation';
import { ADD_GATEWAY_FORM_ID, MAX_CHARS_COUNT } from '../constants';

import { fetchHardwareListRequest } from '../slice';

import { getInstalledSMDefaultLicenseIdSelector, getInstalledSMHardwaresListSelector } from '../../../redux-store/selectors/installedSM';
import { getModalsIsLoadingSelector, getModalsSelector } from '../../../redux-store/selectors/modals';
import { closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';
import '../index.css';

const selector = formValueSelector(ADD_GATEWAY_FORM_ID);

/**
 * Renders add gateway modal window for InstalledSM page
 * @memberof module:InstalledSM
 */
const AddGatewayWindow = (props) => {
  const { initialize, handleSubmit } = props;
  const dispatch = useDispatch();

  const modals = useSelector(getModalsSelector);
  const isLoading = useSelector(getModalsIsLoadingSelector);
  const gateways = useSelector((state) => selector(state, 'gateways'));
  const hardware = useSelector((state) => selector(state, 'hardware'));
  const licensesList = useSelector((state) => state.dataList?.licenses?.data) || [];
  const hardwaresList = useSelector(getInstalledSMHardwaresListSelector);
  const defaultLicenseId = useSelector(getInstalledSMDefaultLicenseIdSelector);
  const initialLicense = useSelector((state) => selector(state, 'initialLicense'));

  const modal = modals?.[ADD_GATEWAY_FORM_ID];
  const hardwaresOptions = hardwaresList.map((value) => ({ value, label: value }));
  const licenseOptions = licensesList.map((value) => ({ label: value.title, value: value._id }));

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_GATEWAY_FORM_ID }));

  useEffect(() => {
    if (modal?.opened) {
      dispatch(fetchHardwareListRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchHardwareListRequest, modal?.opened]);

  useEffect(() => {
    if (modal?.opened && hardwaresList.length && defaultLicenseId) {
      initialize({
        hardware: hardware || hardwaresList[0],
        initialLicense: initialLicense || defaultLicenseId,
        gateways: gateways ?? ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLicenseId, gateways, hardware, hardwaresList, initialLicense, modal?.opened]);

  useEffect(() => {
    if (!modal?.opened) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={ADD_GATEWAY_FORM_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addGateway')}</h5>
      </div>
      {isLoading ? (
        <div className="add-gateway-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body modal-body-gateway">
            <form
              noValidate
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-gateway"
              id={ADD_GATEWAY_FORM_ID}
            >
              <div className="form-group m-form__group input-field">
                <div className="flags-select-label">{`${i18n.t('hardwareLabel')} *`}</div>
                <Field
                  name="hardware"
                  component={CustomSelect}
                  placeholder={i18n.t('selectHardware')}
                  options={hardwaresOptions}
                  isSearchable={false}
                  validate={[required]}
                  className="m-input"
                  creatable
                  props={{
                    inputProps: {
                      autoComplete: 'new-password', // to disable autocomplete, "off" does not work
                      autoCorrect: 'off',
                      spellCheck: false
                    }
                  }}
                />
              </div>
              <div className="form-group m-form__group input-field">
                <div className="flags-select-label">{`${i18n.t('License')} *`}</div>
                <Field
                  name="initialLicense"
                  component={CustomSelect}
                  options={licenseOptions}
                  isSearchable={false}
                  validate={[required]}
                  className="m-input"
                  props={{
                    inputProps: {
                      autoComplete: 'new-password' // to disable autocomplete, "off" does not work
                    }
                  }}
                />
              </div>
              <div className="form-group m-form__group">
                <div className="flags-select-label">{`${i18n.t('nameOrSmid')} *`}</div>
                <Field
                  name="gateways"
                  placeholder={i18n.t('nameOrSmidPlaceholder')}
                  component={CustomTextField}
                  className="name-or-smid-note"
                  maxLength={MAX_CHARS_COUNT}
                  validate={[required, gatewaysValidator]}
                  props={{
                    inputProps: {
                      autoComplete: 'new-password', // to disable autocomplete, "off" does not work
                      autoCorrect: 'off',
                      spellCheck: false
                    }
                  }}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={ADD_GATEWAY_FORM_ID} isSubmit type="save" />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

AddGatewayWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired
};

const form = reduxForm({
  form: ADD_GATEWAY_FORM_ID,
  enableReinitialize: true
})(AddGatewayWindow);

export default form;
