const normalizeActiveDevices = (activity) => {
  activity.sort((a, b) => {
    const diffX = a.x - b.x;
    if (diffX !== 0) return diffX;
    const diffX2 = a.x2 - b.x2;
    if (diffX2 !== 0) return diffX2;
    return a.y - b.y;
  });
};

export default normalizeActiveDevices;
