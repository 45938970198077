import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import shortid from 'shortid';

import { getSignInUserSelector } from '../../../redux-store/selectors/signIn';
import { tabsByRoles } from '../utils';

import i18n from '../../../i18n';

/**
 * Renders tabs (relative to user role who is viewing) for TopBar.
 * @memberof module:TopBar
 * @param {Object} param Must contain next Object: { user: { role: { type: 'string' } }, ... }
 */
const MainTabs = () => {
  const user = useSelector(getSignInUserSelector);

  if (!user?.role) return null;

  const isDocumentation = window.location.pathname.split('/').some((elem) => elem.includes('documentation'));

  const userTabs = tabsByRoles({ user });

  return (
    <ul className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right">
      {userTabs.map(({ allowedRoles, label, link, componentProps }) => {
        const isAllowedByRole = allowedRoles.includes(user.role.type);

        if (isAllowedByRole && label === 'employees' && user?.my_company?.company_abbreviation) {
          return null;
        }

        return isAllowedByRole ? (
          <li key={shortid.generate()} className="nav-item m-tabs__item">
            <NavLink
              className={`nav-link m-tabs__link ${isDocumentation && label === 'SMUs' ? 'active show' : ''}`}
              activeClassName="active show"
              to={link}
              {...componentProps}
            >
              {i18n.exists(label) ? i18n.t(label) : label}
            </NavLink>
          </li>
        ) : null;
      })}
    </ul>
  );
};

export default MainTabs;
