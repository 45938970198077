import { createSelector } from '@reduxjs/toolkit';

// const initialState = [
//   Example:
//   {
//     id: 'De72fVJVa',
//     type: 'success',
//     text: 'Changes ,were saved.'
//   }
// ];

const getNotifications = (state) => state.notifications;

export const getNotificationsSelector = createSelector([getNotifications], (notifications) => notifications);
