import { USERS_PV_INSTALLER_USERS_URL, PV_INSTALLER_URL } from '../../api/apiUrls';

/**
 * AddPVInstallerToOEMWindow form ids
 */
export const addPvInstallerToOEMForm = 'addPvInstallerToOEMForm';

export const addPvInstallerToOEM = 'addPvInstallerToOEM';

export const GET_INSTALLERS_PARAMS = {
  _limit: 10000,
  _start: 0,
  _sort: 'my_company:asc'
};

const GET_ALL_CONNECTED_INSTALLERS_PARAMS = {
  _limit: 10000,
  _start: 0,
  _sort: 'first_name:asc'
};

export const GET_OEM_EMPLOYEES_PARAMS = {
  _limit: 10000,
  _start: 0,
  _sort: 'first_name:asc'
};

const GET_INSTALLERS_URL = {
  default: USERS_PV_INSTALLER_USERS_URL
};

export const COMPANY_DATALIST_ID = 'current_company';

export const getConnectedInstallersRequestData = (params) => {
  const {
    myRoleType,
    companyOemId,
    defaultRoleParams
  } = params;

  const connectedInstallersRequest = {
    default: {
      GET_CONNECTED_INSTALLERS_URL: PV_INSTALLER_URL,
      GET_CONNECTED_INSTALLERS_PARAMS: { ...GET_ALL_CONNECTED_INSTALLERS_PARAMS, ...defaultRoleParams, connectedOemUser: companyOemId }
    }
  };

  const URL = GET_INSTALLERS_URL[myRoleType] || GET_INSTALLERS_URL.default;

  const {
    GET_CONNECTED_INSTALLERS_URL,
    GET_CONNECTED_INSTALLERS_PARAMS
  } = connectedInstallersRequest?.[myRoleType] || connectedInstallersRequest.default;

  return {
    GET_CONNECTED_INSTALLERS_URL,
    GET_CONNECTED_INSTALLERS_PARAMS,
    URL
  };
};
