import { omit } from 'lodash';

/**
 * @name omitFakeField
 * @type {Function}
 * @description Will delete the redundant field "fakeField" if it exists, otherwise, return all fields
 */
const omitFakeField = (fields, type) => {
  const updatedFields = JSON.parse(JSON.stringify(fields));
  if (type === 'loadguards') {
    updatedFields.color = undefined;
  }

  if (updatedFields?.data?.fakeField) {
    const newFields = { ...updatedFields, data: { ...omit(updatedFields?.data, ['fakeField']) } };
    return newFields;
  }

  if (updatedFields?.data?.support_url) {
    updatedFields.data.support_url = undefined;
  }

  if (updatedFields?.data?.waterHeaterType) {
    updatedFields.data.waterHeaterType = undefined;
  }

  if (updatedFields?.isCarExists) {
    updatedFields.isCarExists = undefined;
  }

  return updatedFields;
};

export default omitFakeField;
