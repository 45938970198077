import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { useDispatch, useSelector } from 'react-redux';

import columns from './columns';
import { openModalWindow, modalWindowSend, ADD_DATA_MODAL_ID, REMOVE_DATA_MODAL_ID } from '../ModalWindow/slice';
import { DataListPagination, DataListFilterV2, DataListV2 } from '../DataList';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/slice';
import { Footer, SearchField, TopBar, Pagination, Breadcrumbs } from '../../components';
import { PrimaryButton } from '../../components/UIKit';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { AddAdminWindow, DeleteAdminWindow } from './modals';
import { USERS_SOLAR_ADMIN_URL, ADMIN_URL } from '../../api/apiUrls';
import { ConfirmDeleteUserWindow } from '../../modals';
import { countryLabels } from '../../components/ReduxFormFields/CountrySelector/constants';

import '../Users/index.scss';
import { transformResponse } from '../../utils/transformResponse';

const countriesLength = Object.values(countryLabels).length;

const dataListID = 'admins';
const listURL = ADMIN_URL;

const Admins = (props) => {
  const { myRoleType } = props;
  const dispatch = useDispatch();

  const { [dataListID]: pageSettings } = useSelector(getPagesSettingsSelector);

  const adminColumns = useMemo(() => columns(myRoleType, pageSettings), [myRoleType, pageSettings]);
  const defaultParams = pageSettings.queries;

  const sendAddUser = (fields) => {
    const copyFields = { ...fields };

    // we don't need to send all countries if we have selected all countries
    if (copyFields.authorizedCountries) {
      copyFields.authorizedCountries = copyFields.authorizedCountries?.length === countriesLength
        ? ''
        : copyFields.authorizedCountries.join(',');
    }

    dispatch(modalWindowSend({
      modalID: ADD_DATA_MODAL_ID,
      requestConfig: {
        method: 'post',
        url: USERS_SOLAR_ADMIN_URL,
        data: copyFields
      },
      cb: () => dataListReloadData({ listID: dataListID })
    }
    ));
  };

  const sendRemoveUser = ({ id }) => dispatch(modalWindowSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `/user/${id}`
    },
    cb: () => dataListReloadData({ listID: dataListID })
  }
  ));

  const onClickHandler = () => dispatch(openModalWindow({ modalID: ADD_DATA_MODAL_ID, data: null }));

  return (
    <div className="main-table-container">
      <AddAdminWindow onSubmit={sendAddUser} />
      <DeleteAdminWindow onSubmit={sendRemoveUser} />
      <ConfirmDeleteUserWindow userRole={i18n.t('Administrator').toLowerCase()} />

      <TopBar updateButton showNavigation />

      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('admins') }]} />

        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
          <div className="col-xl-12">
            <div className="row align-items-center">
              <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-8 order-1 mr-auto">
                <DataListFilterV2
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSettings.queries.search,
                    searchByFields: 'email,first_name,last_name,company,street,city,zip,phone',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={dataListID}
                  listURL={listURL}
                  transformResponse={transformResponse}
                />
              </div>
              <div className="col-auto order-2 btn-sm-us">
                <PrimaryButton onClickHandler={onClickHandler} i18nLabel="AddAdminbtn" isLargeButton />
              </div>
            </div>
          </div>
        </div>
        <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
          <DataListV2
            listID={dataListID}
            listURL={listURL}
            params={defaultParams}
            transformResponse={transformResponse}
            Component={ReactTable}
            componentProps={{
              defaultPageSize: 0,
              className: '-highlight m-datatable__table',
              columns: adminColumns,
              manual: true
            }}
          />
          <DataListPagination
            Component={Pagination}
            listID={dataListID}
            defaultPageSize={10}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

Admins.propTypes = {
  myRoleType: PropTypes.string.isRequired
};

export default Admins;
