import { from, of } from 'rxjs';
import { catchError, map, mergeMap, groupBy, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { omitBy } from 'lodash';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import {
  dataListReceived,
  dataListGotError,
  subUserDataReceived,
  dataListSendRequest,
  getSubUserData,
  dataListReloadData,
  getSubUserReloadData
} from './slice';

function dataListSendRequestEpic(action$) {
  return action$.pipe(
    ofType(dataListSendRequest),
    map((action) => action.payload),
    groupBy((payload) => payload.listID),
    mergeMap((group) => group.pipe(
      // eslint-disable-next-line arrow-body-style
      switchMap(({ listID, dataList: { listURL, params, headers, transformResponse }, showNotification }) => {
        return from(api.get(listURL, { params, headers })).pipe(
          catchError(handleErrorDetailed),
          mergeMap((result) => {
            if (!result.error) {
              const parsedResult = transformResponse ? transformResponse(result) : { data: result };
              return of(
                dataListReceived({ listID, listURL, parsedResult })
              );
            }

            if (showNotification) {
              return of(
                dataListGotError({ listID, listURL, errorMessage: result.message }),
                addNotification({ type: 'error', text: result.message })
              );
            }

            return of(
              dataListGotError({ listID, listURL, errorMessage: result.message })
            );
          }
          )
        );
      }
      )
    )
    )
  );
}

function dataListReloadDataEpic(action$, state$) {
  return action$.pipe(
    ofType(dataListReloadData),
    map((action) => action.payload),
    mergeMap(({ listID }) => {
      const { dataList, pagesSettings } = state$.value;
      const { queries } = pagesSettings[listID] || {}; // re 2 new logic saving page setting & requests params;
      const { listURL, params: paramsFromListId, transformResponse } = dataList[listID]; // old logic remove after add page setting to all pages
      const params = omitBy(queries || paramsFromListId, (value) => value === ''); // remove empty strings params as double check for request

      return from(api.get(listURL, { params })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error) {
            const parsedResult = transformResponse ? transformResponse(result) : { data: result };
            return of(
              dataListReceived({ listID, listURL, parsedResult })
            );
          }
          return of(
            dataListGotError({ listID, listURL, errorMessage: result.message }),
            addNotification({ type: 'error', text: result.message })
          );
        }
        )
      );
    })
  );
}

function getSubUserDataEpic(action$) {
  return action$.pipe(
    ofType(getSubUserData),
    map((action) => action.payload),
    groupBy((payload) => payload.listID),
    mergeMap(
      (group) => group.pipe(
        // eslint-disable-next-line arrow-body-style
        switchMap(({ listID, dataList: { listURL, params, parentIdOfSubUsers, transformResponse } }) => {
          return from(api.get(listURL, { params })).pipe(
            catchError(handleErrorDetailed),
            mergeMap((result) => {
              if (!result.error) {
                const parsedResult = transformResponse ? transformResponse(result) : { data: result };
                return of(
                  subUserDataReceived({ listID, parentIdOfSubUsers, listURL, parsedResult })
                );
              }

              return of(
                dataListGotError({ listID, listURL, errorMessage: result.message }),
                addNotification({ type: 'error', text: result.message })
              );
            }
            )
          );
        }
        )
      )
    )
  );
}

function getSubUserReloadDataEpic(action$, state$) {
  return action$.pipe(
    ofType(getSubUserReloadData),
    map((action) => action.payload),
    mergeMap(({ listID, parentIdOfSubUsers }) => {
      const { dataList } = state$.value;
      const { listURL, params, transformResponse } = dataList[listID][parentIdOfSubUsers];

      return from(api.get(listURL, { params })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error) {
            const parsedResult = transformResponse ? transformResponse(result) : { data: result };
            return of(
              subUserDataReceived({ listID, parentIdOfSubUsers, listURL, parsedResult })
            );
          }
          return of(
            dataListGotError({ listID, listURL, errorMessage: result.message }),
            addNotification({ type: 'error', text: result.message })
          );
        }
        )
      );
    })
  );
}

export default combineEpics(
  getSubUserReloadDataEpic,
  dataListSendRequestEpic,
  dataListReloadDataEpic,
  getSubUserDataEpic
);
