import createRange from './createRange';
import normalizeActiveDevices from './normalizeActiveDevices';
import setColorFromStore from './setColorFromStore';

const parseData = (input, minX, maxX, chart_settings) => {
  const activity = [];

  let devices = input?.map((device, indexOfDevice) => {
    const { id, color } = device;

    const hasState = device?.activity && Array.isArray(device.activity) && device.activity.find((deviceMeasurement) => deviceMeasurement.state);

    const isTag = 'tag' in device;
    const name = isTag ? device?.tag : device.type;
    const deviceColor = color || setColorFromStore(id, chart_settings);
    if (!device.activity || device.activity.length === 0) {
      activity.push(
        createRange(
          minX,
          maxX,
          indexOfDevice,
          undefined,
          undefined,
          true
        ));
    } else {
      let prevX = minX;
      device.activity.forEach(({ x1, x2, state }) => {
        if (x1 - 2 > prevX) {
          activity.push(
            createRange(
              prevX,
              x1,
              indexOfDevice,
              undefined,
              undefined,
              true
            ));
        }
        activity.push(
          createRange(
            x1,
            x2,
            indexOfDevice,
            deviceColor,
            state
          ));
        prevX = x2;
      });
      if (prevX + 100000 < maxX) { // 100000: usual delay between chunks
        activity.push(
          createRange(
            prevX,
            maxX,
            indexOfDevice,
            undefined,
            undefined,
            true
          ));
      }
    }
    return { name, id, color: deviceColor, isTag, y: indexOfDevice, hasState: !!hasState };
  }) || [];

  normalizeActiveDevices(activity);

  devices = devices.filter((device) => device !== null);
  return { devices, activity };
};

export default parseData;
