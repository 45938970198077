import { createSelector } from '@reduxjs/toolkit';

const getAddDeviceForm = (state) => state.form?.addDeviceForm;
const getEditDeviceForm = (state) => state.form?.editDeviceForm;
const getFormValues = (state) => state.form.notificationForm?.values;
const getNoteText = (state) => state.form?.monitoringNoteForm?.values?.note;

export const formValuesSelector = createSelector([getFormValues], (values) => values || {});
export const noteTextSelector = createSelector([getNoteText], (note) => note);

export {
  getAddDeviceForm,
  getEditDeviceForm
};
