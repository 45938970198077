import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, groupBy } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import {
  monitoringSendApplyWithFields,
  monitoringInstallersReceived,
  monitoringReplaceSelected,
  clearPropsFromFields,
  addChoosedCompany,
  employeeReceived,
  errorInfoReceived,
  employeeSendRequest,
  monitoringGetInstallers,
  getErrorInfo
} from './slice';
import { dataListReloadData } from '../DataList/slice';

/**
 * Gets list for filter of versions
 * @memberof module:InstalledSM
 */
function monitoringGetInstallersEpic($action) {
  return $action.pipe(
    ofType(monitoringGetInstallers),
    map((action) => action.payload),
    // eslint-disable-next-line arrow-body-style
    switchMap(({ url, params }) => {
      return from(api.get(url, { params })).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          if (!result.error) {
            if (Array.isArray(result.list)) {
              return monitoringInstallersReceived({ installers: result.list });
            }
            return addNotification({ type: 'error', text: result });
          }

          return addNotification({ type: 'error', text: result.message });
        })
      );
    })
  );
}

function employeeSendRequestEpic(action$) {
  return action$.pipe(
    ofType(employeeSendRequest),
    map((action) => action.payload),
    groupBy((payload) => payload.listID),
    mergeMap((group) => group.pipe(
      switchMap(
        // eslint-disable-next-line arrow-body-style
        ({ companyID, listID, field, dataList: { listURL, params, transformResponse } }) => {
          return from(api.get(listURL, { params })).pipe(
            catchError(handleErrorDetailed),
            mergeMap((result) => {
              if (!result.error) {
                const parsedResult = transformResponse ? transformResponse(result) : { data: result };
                return of(
                  employeeReceived({ companyID, listID, listURL, parsedResult, field })
                );
              }

              return of(
                addNotification({ type: 'error', text: result.message })
              );
            }
            )
          );
        }
      )
    )
    )
  );
}

function changeFieldsEpic($action) {
  return $action.pipe(
    ofType(monitoringSendApplyWithFields),
    map((action) => action.payload),
    groupBy((payload) => payload.listID),
    mergeMap((group) => group.pipe(
      // eslint-disable-next-line arrow-body-style
      switchMap(({ url, listID, config }) => {
        return from(api.put(url, config)).pipe(
          catchError(handleErrorDetailed),
          mergeMap((result) => {
            if (result && !result.error) {
              return of(
                dataListReloadData({ listID }),
                monitoringReplaceSelected({ newSelected: [] }),
                clearPropsFromFields(),
                addChoosedCompany({ choosedCompany: [] })
              );
            }

            return of(
              addNotification({ type: 'error', text: result.message })
            );
          }
          )
        );
      })))
  );
}

function getErrorInfoEpic($action) {
  return $action.pipe(
    ofType(getErrorInfo),
    map((action) => action.payload),
    switchMap(({ url, taskId }) => from(api.get(url)).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result)) {
            return errorInfoReceived({ errorsList: result, taskId });
          }
          return addNotification({ type: 'error', text: result });
        }

        return addNotification({ type: 'error', text: result.message });
      })
    ))
  );
}

export default combineEpics(
  employeeSendRequestEpic,
  monitoringGetInstallersEpic,
  changeFieldsEpic,
  getErrorInfoEpic
);
