/**
 * "From date" - date field input name
 */
export const FROM_DATE = 'fromDate';

/**
 * "To date" - date field input name
 */
export const TO_DATE = 'toDate';

/**
 * Datepicker mask
 */
export const DATEPICKER_MASK = 'DD/MM/YYYY';
