import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { signInCheckJWT, signInInvalidJWT } from '../../SignIn/slice';
import { getSignInUserSelector, getSignInVerifyingJWTSelector } from '../../../redux-store/selectors/signIn';
import { getJwtFromCookies } from '../../../api';

import '../index.scss';

export default (Component, allowedRoles) => {
  /**
   * Authorization checker created for testing authorization from iOS app
   * @memberof module:PermissionChecker
   */
  const CheckAuthorizationApp = () => {
    const dispatch = useDispatch();

    const user = useSelector(getSignInUserSelector);
    const verifyingJWT = useSelector(getSignInVerifyingJWTSelector);

    const jwt = getJwtFromCookies();

    useEffect(() => {
      if (jwt) {
        if (verifyingJWT) {
          dispatch(signInCheckJWT());
        }
      } else {
        dispatch(signInInvalidJWT());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verifyingJWT) {
      return (
        <div className="check-authorization-wrapper">
          <div className="check-authorization-content">
            Cookies:
            {document.cookie}
          </div>
          Loading... Authorization is being verified.
        </div>
      );
    }
    if (user) {
      if (!allowedRoles || allowedRoles.includes(user.role.type)) {
        return (
          <div className="check-authorization-wrapper">
            <div className="check-authorization-content">
              Cookies:
              {document.cookie}
            </div>
            {`Authorized successfull. Role type: ${user.role.type}`}
          </div>
        );
      }
      return (
        <div className="check-authorization-wrapper">
          <div className="check-authorization-content">
            Cookies:
            {document.cookie}
          </div>
          {`Authorization failed. Current user (${user.role.type}) has no permissions for this page.`}
        </div>
      );
    }
    if (jwt) {
      return (
        <div className="check-authorization-wrapper">
          <div className="check-authorization-content">
            Cookies:
            {document.cookie}
          </div>
          Authorization failed. Token is invalid.
        </div>
      );
    }
    return (
      <div className="check-authorization-wrapper">
        <div className="check-authorization-content">
          Cookies:
          {document.cookie}
        </div>
        Authorization failed. Not found jwt token in cookies.
      </div>
    );
  };

  return withTranslation()(CheckAuthorizationApp);
};
