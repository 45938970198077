import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsIsLoadingSelector, getModalsRemoveDataSelector } from '../../../redux-store/selectors/modals';
import { REMOVE_DATA_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

import '../index.scss';

/**
 * Modal window for confirm deleting supports.
 * @memberof module:SupportsTable
 */
const DeletePropMgmtWindow = (props) => {
  const { onSubmit } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(getModalsIsLoadingSelector);
  const modal = useSelector(getModalsRemoveDataSelector);

  const data = modal ? modal.data : {};

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: REMOVE_DATA_MODAL_ID }));

  return (
    <ModalWindow modalID={REMOVE_DATA_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('delPropMgmt')}</h5>
      </div>
      {isLoading ? (
        <div className="user-delete-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <div className="text-confirmation">{i18n.t('confirmDelPropMgmt')}</div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton onClickHandler={onSubmit.bind(null, { id: data?.id || data?._id })} type="delete" />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

DeletePropMgmtWindow.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default DeletePropMgmtWindow;
