import {
  RenderEmail,
  RenderDate,
  RenderStatus,
  RenderButtons,
  RenderFullName,
  RenderAccess
} from '../../components/ReactTable/renderFunctions';

import i18n from '../../i18n';

const columns = (myRoleType, { width }) => ([
  {
    width: 35
  },
  {
    Header: i18n.t('fName'),
    accessor: 'full_name',
    minWidth: width.full_name,
    className: '',
    Cell: (row) => RenderFullName(row)
  },
  {
    Header: i18n.t('mail'),
    accessor: 'email',
    minWidth: width.email,
    className: 'email-text-td',
    Cell: (row) => RenderEmail(row)
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: width.status,
    Cell: (row) => RenderStatus(row),
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('access'),
    id: 'access',
    minWidth: width.access,
    Cell: (row) => RenderAccess(row),
    sortable: false
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: width.last_login,
    className: 'last_login-td',
    Cell: (row) => RenderDate(row)
  },
  {
    Header: i18n.t('city'),
    accessor: 'city',
    minWidth: width.city,
    className: 'city-td'
  },
  {
    Header: '',
    accessor: 'buttons',
    width: width.buttons,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        devices: false,
        edit: { preLink: '/setting-profile/employee/' },
        remove: (myRoleType === 'pv_installer')
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
