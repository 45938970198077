import { from, of, startWith, endWith } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../../api_helper';
import { closeModalWindow, DELETE_FILES_MODAL_ID, UPLOAD_MODAL_ID } from '../../ModalWindow/slice';
import i18n from '../../../i18n';

import { addNotification } from '../../NotificationGenerator/slice';
import { api } from '../../../api';
import { download } from '../helpers';
import {
  setUploadFilesLoading,
  setDocumentationFilesLoading,
  setDocumentationFiles,
  getDocumentationFiles,
  setFilesForUpload,
  setSelectedFiles,
  downloadFiles,
  skipAction,
  uploadFiles,
  deleteFiles
} from './slice';
import { USERS_DOCUMENTATION_FILES_URL, USERS_DOCUMENTATION_URL } from '../../../api/apiUrls';

function fetchFiles($action) {
  return $action.pipe(
    ofType(getDocumentationFiles),
    map((action) => action.payload),
    // eslint-disable-next-line arrow-body-style
    switchMap(({ userId }) => {
      return from(api.get(`${USERS_DOCUMENTATION_FILES_URL}/${userId}`)).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (result?.error) {
            return of(addNotification({ type: 'error', text: result.error.message || result.error }));
          }

          return of(setDocumentationFiles({ files: result }));
        }),
        startWith(setDocumentationFilesLoading({ filesLoading: true })),
        endWith(setDocumentationFilesLoading({ filesLoading: false }))
      );
    })
  );
}

function uploadFilesEpic($action, $state) {
  return $action.pipe(
    ofType(uploadFiles),
    map((action) => action.payload),
    switchMap(({ userId, files }) => {
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file));

      return from(api.post(`${USERS_DOCUMENTATION_URL}/${userId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (result?.error) {
            return of(addNotification({ type: 'error', text: result.error.message || result?.error }));
          }

          return of(
            setDocumentationFiles({ files: [...$state.value.documentation.files, ...result] }),
            setFilesForUpload({ filesForUpload: [] }),
            addNotification({ type: 'success', text: i18n.t('uploadSuccess') }),
            closeModalWindow({ modalID: UPLOAD_MODAL_ID })
          );
        }),
        startWith(setUploadFilesLoading({ isUploadLoading: true })),
        endWith(setUploadFilesLoading({ isUploadLoading: false }))
      );
    })
  );
}

function downloadFilesEpic($action) {
  return $action.pipe(
    ofType(downloadFiles),
    map((action) => action.payload),
    switchMap(({ filesIds }) => from(api.get(`${USERS_DOCUMENTATION_FILES_URL}?filesId=${filesIds.join(',')}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return addNotification({ type: 'error', text: result.error.message || result.error });
        }

        result.forEach((url) => download(url));
        return of(skipAction());
      })
    ))
  );
}

function deleteFilesEpic($action, $state) {
  return $action.pipe(
    ofType(deleteFiles),
    map((action) => action.payload),

    switchMap(({ filesIds }) => from(api.delete(`${USERS_DOCUMENTATION_FILES_URL}?filesId=${filesIds.join(',')}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return of(
            addNotification({ type: 'error', text: result.error.message || result.error })
          );
        }

        const { deleteFilesModal = {} } = $state.value.modals;
        const { selectedFiles, files } = $state.value.documentation;

        const updatedFiles = files?.filter((file) => !filesIds.includes(file._id));
        const isNeedToCloseModal = deleteFilesModal.data?.ids?.every((id) => filesIds.includes(id));
        const updatedSelectedFiles = selectedFiles?.filter((file) => !filesIds.includes(file));

        return of(
          setDocumentationFiles({ files: updatedFiles }),
          isNeedToCloseModal ? closeModalWindow({ modalID: DELETE_FILES_MODAL_ID }) : skipAction(),
          addNotification({ type: 'success', text: i18n.t('deleteSuccess') }),
          setSelectedFiles({ selectedFiles: updatedSelectedFiles })
        );
      })
    ))
  );
}

export default combineEpics(fetchFiles, uploadFilesEpic, downloadFilesEpic, deleteFilesEpic);
