import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../containers/ModalWindow';
import { PrimaryButton } from '../../components/UIKit';
import { DATA_EXPORT_INFO_POPUP, closeModalWindow } from '../../containers/ModalWindow/slice';
import { ReactComponent as WarningIcon } from '../../assets/images/svg/warning.svg';

import i18n from '../../i18n';
import './index.scss';
import { getModalsDataExportInfoPopUpSelector } from '../../redux-store/selectors/modals';

const DataExportInfoPopUp = () => {
  const dispatch = useDispatch();
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: DATA_EXPORT_INFO_POPUP }));
  const modal = useSelector(getModalsDataExportInfoPopUpSelector);

  return (
    <ModalWindow modalID={DATA_EXPORT_INFO_POPUP} customClass="dataExportInfoPopUp">
      <div className="modal-header">
        <h5 className="modal-title">
          <WarningIcon />
          <span className="ml-3">{i18n.t('creatingNewUserNoteTitle')}</span>
        </h5>
      </div>
      <div className="modal-body">
        <div className="text-confirmation">
          {i18n.t(modal?.data?.descriptionLabelKey)}
        </div>
      </div>
      <div className="modal-footer">
        <PrimaryButton onClickHandler={handleOnClose} i18nLabel="ok" />
      </div>
    </ModalWindow>
  );
};

export default DataExportInfoPopUp;
