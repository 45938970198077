import { createSelector } from '@reduxjs/toolkit';

import { getChartUser } from '../../redux-store/selectors/loadManagementDashboard';
import { getSettingProfileInitialData } from '../../redux-store/selectors/settingProfile';
import { getSignInUser } from '../../redux-store/selectors/signIn';

export const logoSelector = createSelector(
  [getSignInUser, getSettingProfileInitialData, getChartUser],
  (user, initialData, dashboardUser) => {
    switch (user?.role?.type) {
      case 'oem':
      case 'pv_installer':
        return user?.company_logo;
      case 'solar_admin':
      case 'root':
      case 'property_management':
      case 'viewer':
        return initialData?.oem_company_logo
          || initialData?.company_logo
          || dashboardUser?.oem_company_logo
          || dashboardUser?.company_logo;
      default:
        if (user?.oem_company_logo) {
          return user?.oem_company_logo;
        }

        if (user?.company_logo) {
          return user?.company_logo;
        }

        break;
    }
    return undefined;
  }
);
