import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  goToAfterLogin: '',
  loading: false,
  url: ''
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    redirectToPaymentPage: epicEmptyFunction,

    getPaymentUrlRequest: (state) => {
      state.loading = true;

      return state;
    },
    setPaymentUrlSuccess: (state, action) => {
      state.loading = false;
      state.url = action.payload.url;
    },
    setPaymentUrlFailure: (state) => {
      state.loading = false;
    },
    goToAfterLogin: (state, action) => {
      state.goToAfterLogin = action.payload.pathName;
    }
  }
});

export const {
  redirectToPaymentPage,
  getPaymentUrlRequest,
  setPaymentUrlSuccess,
  setPaymentUrlFailure
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
