import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import carIcon from '../../../../../assets/images/svg/car-icon.svg';

const CarTooltip = (props) => {
  const { chargedForLast24Hours, SOC } = props;

  return (
    <>
      <img src={carIcon} alt="Car Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('batterySoc')}</span>
        <span>{i18n.t('charged')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {SOC || defaultValue}
          %
        </span>
        <span>
          {chargedForLast24Hours || 0}
          {i18n.t('kwh')}
        </span>
      </div>
    </>
  );
};

CarTooltip.propTypes = {
  SOC: PropTypes.number,
  chargedForLast24Hours: PropTypes.number
};

export default CarTooltip;
