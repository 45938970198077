const replace = {
  changeSMID: '3S ID ändern',
  reqSMID: '3S ID *',
  SMID: '3S ID',
  SMIDTolltip: 'Die 3S ID ist eine eindeutige Kennung Ihres Gerätes. Sie finden diese ID auf dem Gerät unterhalb des QR-Codes.',
  enableSmId: '3S ID',
  smidLabel: '3S ID',
  confirmDeletingDesc: 'Bestätigen Sie das Löschen aller Daten dieses Gateways, indem Sie die 3S -ID eingeben. Nach Bestätigung kann dieser Vorgang nicht rückgängig gemacht werden.',
  confirmDeletingError: 'Die ID stimmt nicht mit der 3S-ID überein. Das Gateway wird nicht gelöscht.'
};

export default replace;
