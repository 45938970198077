import additionalFields from './additionalFields';

/**
 * Check if need to display field for some devices by role and returns the result of  the checking.
 * @param {string} deviceName
 * @param {string} fieldName
 * @param {string} myRoleType
 * @returns {boolean}
 */
const displayField = (deviceName, fieldName, myRoleType) => {
  if (!additionalFields?.[fieldName]) {
    return false;
  }

  if (['ctaTypeRTU', 'ctaType', 'ctaTypeCarel'].includes(fieldName)) {
    return true;
  }

  const additionalPermissionsForDisplayingDevices = [
    {
      deviceName: 'CTA heat pump',
      roles: {
        root: true,
        solar_admin: true,
        pv_installer: true,
        pv_installer_employee: true,
        property_management: true,
        oem: true,
        viewer: true,
        pv_installer_employee_read_only: false,
        oem_employee: true,
        oem_employee_read_only: false,
        end_user: !['switchOnDelay', 'switchOffDelay', 'minimalOnDuration'].includes(fieldName)
      }
    },
    {
      deviceName: 'CTA heat pump RTU',
      roles: {
        root: true,
        solar_admin: true,
        pv_installer: true,
        pv_installer_employee: true,
        property_management: true,
        oem: true,
        viewer: true,
        pv_installer_employee_read_only: false,
        oem_employee: true,
        oem_employee_read_only: false,
        end_user: !['switchOnDelay', 'switchOffDelay', 'minimalOnDuration'].includes(fieldName)
      }
    },
    {
      deviceName: 'CTA carel',
      roles: {
        root: true,
        solar_admin: true,
        pv_installer: true,
        pv_installer_employee: true,
        property_management: true,
        oem: true,
        viewer: true,
        pv_installer_employee_read_only: false,
        oem_employee: true,
        oem_employee_read_only: false,
        end_user: !['switchOnDelay', 'switchOffDelay', 'minimalOnDuration'].includes(fieldName)
      }
    }
  ];
  const targetDevice = additionalPermissionsForDisplayingDevices.find((device) => device.deviceName === deviceName);
  // Display the field if the device (deviceName) does not have additional rules for the device.
  if (typeof targetDevice === 'undefined') {
    return true;
  }
  const renderDeviceField = targetDevice?.roles?.[myRoleType];
  // If field does not have special permission, than display the field, else display if the permission is true.
  return typeof renderDeviceField !== 'boolean' ? true : renderDeviceField;
};

export default displayField;
