import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ label }) => (
  <td className="m-datatable__cell table-tariff-header">
    <span className="tarif-header">
      {label}
      :
      {' '}
    </span>
  </td>
);

Header.propTypes = {
  label: PropTypes.string.isRequired
};

export default Header;
