import PropTypes from 'prop-types';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const RenderPhoneNumber = (props) => {
  const { phone } = props?.original || {};
  return formatPhoneNumberIntl(phone) || phone || '';
};

RenderPhoneNumber.propTypes = {
  original: PropTypes.instanceOf(Object)
};

export default RenderPhoneNumber;
