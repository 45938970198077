import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, take, concatMap } from 'rxjs/operators';

import { push } from 'react-router-redux';
import { handleError } from '../../api_helper';
import { api, deleteOldJwt } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import i18n from '../../i18n';
import { changeEmailVerify } from './slice';
import { EMAIL_VERIFY_URL } from '../../api/apiUrls';

function verifyEmailSendEpic(action$) {
  return action$.pipe(
    ofType(changeEmailVerify),
    map((action) => action.payload),
    take(1),
    concatMap(({ verifyCode }) => (
      from(api.post(EMAIL_VERIFY_URL, { code: verifyCode })).pipe(
        catchError(handleError),
        mergeMap((result) => {
          deleteOldJwt();

          if (result.ok) {
            return of(
              push('/'),
              addNotification({ type: 'success', text: i18n.t('newEmailConfirmed') })
            );
          }

          return of(
            push('/'),
            addNotification({ type: 'error', text: result })
          );
        })
      )
    ))
  );
}

export default combineEpics(
  verifyEmailSendEpic
);
