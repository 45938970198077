import React from 'react';
import i18n from '../../i18n';

import './index.scss';

const PowerFlowDetailsInfo = () => (
  <div className="container power-flow-details-info">
    {i18n.t('descriptionForPowerFlowScreen')}
  </div>
);

export default PowerFlowDetailsInfo;
