import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip, Expander } from '../../../components';
import ExpanderSide from './ExpanderSide';
import { monitoringSendApplyWithFields, clearPropsFromFields } from '../slice';
import { DATA_LIST_ID, PRIORITY_ARR, WORK_STATUS_ARR } from '../constants';
import i18n from '../../../i18n';
import {
  getMonitoringEmployeesSelector,
  getChoosedCompanySelector,
  getMonitoringSelectedRowsSelector,
  getMonitoringSelectedFieldsWorkStatusFieldSelector,
  getMonitoringSelectedFieldsPriorityFieldSelector,
  getMonitoringDataSelector,
  getMonitoringSelectedFieldsAssignFieldSelector
} from '../../../redux-store/selectors/monitoring';

import '../index.scss';
import { ERROR_MONITORING_TICKETS_URL } from '../../../api/apiUrls';

/**
 * Renders changer side with apply button for Monitoring page
 * @memberof module:Monitoring
 */
const Changer = () => {
  const dispatch = useDispatch();

  const allData = useSelector(getMonitoringDataSelector);
  const choosedCompany = useSelector(getChoosedCompanySelector);
  const choosedEmployees = useSelector(getMonitoringEmployeesSelector);
  const selectedTickets = useSelector(getMonitoringSelectedRowsSelector);
  const assign = useSelector(getMonitoringSelectedFieldsAssignFieldSelector);
  const priority = useSelector(getMonitoringSelectedFieldsPriorityFieldSelector);
  const workStatus = useSelector(getMonitoringSelectedFieldsWorkStatusFieldSelector);

  const [arrayToChange, setArrayToChange] = useState([]);
  const [choosedList, setChoosedList] = useState([]);
  const [objToChange, setObjToChange] = useState({});

  const handleSubmit = () => {
    dispatch(monitoringSendApplyWithFields({
      url: ERROR_MONITORING_TICKETS_URL,
      listID: DATA_LIST_ID,
      config: objToChange
    }));
  };

  /**
   * Format list of employee to send props for ExpanderSides component
   * @param {array} choosedCompany array of companies id which checked in table
   * @param {object} choosedEmployees object with checked company info where we can take all employees
   */
  const formatListOfEmployee = (choosedCompanyNew, choosedEmployeesNew) => {
    setChoosedList([{ key: 'aaaabbbbccccddddeeeeffff', value: 'noUser' }]); // default key from back-end part for set noUser
    if (choosedCompanyNew.length === 1) {
      const allEmployeesOfCheckUser = Object.entries(choosedEmployeesNew).reduce(
        (val, el) => (el[0] === choosedCompanyNew[0] ? el[1].data : val),
        ''
      );

      if (allEmployeesOfCheckUser) {
        // add chosen Company Admin in options list
        const company = allData.find((el) => el?.company?._id && el.company._id === choosedCompanyNew[0])?.company;

        if (company?.admin?._id && company?.name) {
          setChoosedList((prev) => ([
            ...prev,
            {
              key: company.admin._id,
              value: `Admin ${company.name}`
            }
          ]));
        }

        // add all users of chosen field in options list
        const assignedField = allEmployeesOfCheckUser.map((el) => ({
          key: el._id,
          value: `${el.first_name} ${el.last_name}`
        }));
        setChoosedList((prev) => ([
          ...prev,
          ...assignedField
        ]));
      }
    }
  };

  /**
   * Set choosed item from field to state.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFields = () => {
    const arr = [
      { assigned: assign?.value },
      { status: workStatus?.value },
      { priority: priority?.value }
    ];
    const arrFiltered = arr.filter((el) => Object.values(el).filter(Boolean).length);
    const data = arrFiltered.reduce((obj, item) => ({ ...obj, ...item }), {});

    const objToChangeNew = {};
    objToChangeNew.targets = selectedTickets;
    objToChangeNew.values = data;

    setArrayToChange(arrFiltered);
    setObjToChange(objToChangeNew);
  };

  useEffect(() => {
    setFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assign, priority, workStatus]);

  useEffect(() => {
    if (!selectedTickets.length) {
      dispatch(clearPropsFromFields());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTickets.length]);

  useEffect(() => {
    if (choosedCompany) {
      formatListOfEmployee(choosedCompany, choosedEmployees);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosedCompany, choosedEmployees]);

  return (
    <Expander expanded={Boolean(selectedTickets.length)}>
      <div className="row apply-monitoring-container px-4 mx-0">
        <div className="col-xl-3 col-l-4 col-md-4 col-12 m--margin-top-15">
          <div className="row align-items-center">
            <span>
              {i18n.t('assigned')}
              :
            </span>
            <ExpanderSide
              id="monitoring_expander_filter-reg"
              defaultValue={i18n.t('selectAssign')}
              onChangeField={setFields}
              choosingList={choosedList}
              fieldName="assignField"
              listID={DATA_LIST_ID}
            />
          </div>
        </div>
        <div className="col-xl-3 col-l-4 col-md-4 col-12 m--margin-top-15">
          <div className="row align-items-center">
            <span>
              {i18n.t('priority')}
              :
            </span>
            <ExpanderSide
              id="monitoring_expander_filter-reg"
              defaultValue={i18n.t('selectPriority')}
              choosingList={PRIORITY_ARR}
              onChangeField={setFields}
              fieldName="priorityField"
              listID={DATA_LIST_ID}
            />
          </div>
        </div>
        <div className="col-xl-3 col-l-4 col-md-4 col-12 m--margin-top-15">
          <div className="row align-items-center">
            <span>
              {i18n.t('workStatusChangerLabel')}
              :
            </span>
            <ExpanderSide
              id="monitoring_expander_filter-reg"
              defaultValue={i18n.t('workStatusChangerPlaceholder')}
              choosingList={WORK_STATUS_ARR}
              onChangeField={setFields}
              fieldName="workStatusField"
              listID={DATA_LIST_ID}
            />
          </div>
        </div>
        <div className="col-xl-3 col-12 m--margin-top-15">
          <div className="row justify-content-end align-items-center">
            <Tooltip
              title={i18n.t('monitoringToolTip')}
              placement="right-start"
              style={{ fontSize: 20 }}
            />
            <button
              type="button"
              className="btn m-btn--pill m-btn--air btn-monitoring"
              onClick={handleSubmit}
              disabled={arrayToChange.length < 1}
            >
              <i className="fa fa-paper-plane mr-2" />
              {i18n.t('apply')}
            </button>
          </div>
        </div>
      </div>
    </Expander>
  );
};

export default Changer;
