import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders native text input for redux form
 * @memberof module:ReduxFormFields
 */
export const PureTextInput = (props) => {
  const { input: { value, ...newInput }, ...custom } = props;

  return (
    <input {...newInput} {...custom} />
  );
};

PureTextInput.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  custom: PropTypes.instanceOf(Object)
};
