import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  needGoBackAfterLogin: undefined,
  verifyingJWT: true,
  user: undefined
};

const epicEmptyFunction = (state) => state;

const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    cancelSignIn: epicEmptyFunction,
    signInCheckJWT: epicEmptyFunction,
    signIn: epicEmptyFunction,
    logOut: () => ({}),
    logOutAndGoTo: () => ({}),
    signInUpdateUser: (state, action) => {
      state.user = action.payload.user;
      state.verifyingJWT = undefined;
      state.needGoBackAfterLogin = undefined;
    },
    signInValidJWT: (state, action) => {
      state.user = action.payload.user;
      state.verifyingJWT = undefined;
      state.needGoBackAfterLogin = undefined;
    },
    receiveSignIn: (state, action) => {
      state.user = action.payload.user;
      state.verifyingJWT = undefined;
      state.needGoBackAfterLogin = undefined;
    },
    signInInvalidJWT: (state) => ({ needGoBackAfterLogin: state.needGoBackAfterLogin }),
    settingPrGetUserSucc: (state, action) => {
      if (action.payload.myself) {
        state.user = action.payload.user;
      }
    },
    setFirmwareVersion: (state, action) => {
      if (state.user) {
        state.user.firmware = action.payload.data;
      }
    },
    signInGoBackIfSucc: (state, action) => {
      state.needGoBackAfterLogin = action.payload.needGoBackAfterLogin;
      // TODO: need research why was added this condition
      // if (state.userDevices) {
      //   state.needGoBackAfterLogin = action.payload.needGoBackAfterLogin;
      // }
    }
  }
});

export const {
  signInGoBackIfSucc,
  signInInvalidJWT,
  signInUpdateUser,
  signInCheckJWT,
  receiveSignIn,
  cancelSignIn,
  signIn,
  logOut,
  setFirmwareVersion
} = signInSlice.actions;

export default signInSlice.reducer;
