import devices from './devices';
import inverters from './inverters';
import smartMeter from './smartMeter';
import subMeters from './subMeters';
import cars from './cars';
import inputDevices from './inputDevices';
import loadGuard from './loadGuard';

/**
 * Creates columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 * @param {string|number} userId ID of user being viewed
 * @param {string} type Devices type
 * @param {number} total Devices amount
 */
const columns = (myRoleType, userId, type, total, isReadOnly, widthSetting = {}, columnsSetting = {}) => {
  const columnsTypes = {
    devices: devices(myRoleType, userId, total, isReadOnly, widthSetting[type], columnsSetting),
    inverters: inverters(myRoleType, isReadOnly, widthSetting[type], columnsSetting),
    'smart-meter': smartMeter(myRoleType, isReadOnly, widthSetting[type], columnsSetting),
    'sub-meter': subMeters(myRoleType, isReadOnly, widthSetting['smart-meter'], columnsSetting),
    cars: cars(myRoleType, isReadOnly, widthSetting.cars, columnsSetting),
    'input-devices': inputDevices(myRoleType, isReadOnly, widthSetting[type], columnsSetting),
    loadguards: loadGuard(myRoleType, isReadOnly, widthSetting[type], userId)
  };
  return columnsTypes[type];
};

export default columns;
