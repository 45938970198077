import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for gateway errors
 * @memberof module:ReactTable
 * @param  {object} original - props of the gateway
 */
const RenderError = ({ original: { gateway, virtualSensorErrorActivity } }) => {
  const errorCode = Number.isFinite(virtualSensorErrorActivity) ? virtualSensorErrorActivity : gateway?.virtualSensorErrorActivity;

  return (
    [0, 1].includes(errorCode) ? (
      <span className={`error_status_yes ${(errorCode === 0) ? 'error' : 'warning'}`}>{i18n.t('yes')}</span>
    ) : (
      <span className="error_status_no">{i18n.t('no')}</span>
    )
  );
};

RenderError.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderError;
