import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { dataListSendRequest } from '../slice';
import { setPagesSettingQueries } from '../../PagesSettings/slice';
import { getPagesSettingsSelector } from '../../../redux-store/selectors/pagesSettings';
import { filterOnUndefined } from '../utils';

const DataListFilterV2 = (props) => {
  const { listID, Component, componentProps = {}, listURL, transformResponse } = props;
  const dispatch = useDispatch();

  const pages = useSelector(getPagesSettingsSelector);

  const currentPage = pages[listID];
  const { queries } = currentPage;

  const addFilters = (filters) => {
    const data = {
      transformResponse,
      listURL,
      params: { ...filterOnUndefined(queries), ...filterOnUndefined(filters), _start: 0 }
    };
    dispatch(dataListSendRequest({ listID, dataList: data }));
    dispatch(setPagesSettingQueries({ page: listID, data: { ...queries, ...filters } }));
  };

  return (
    <Fragment key={listID}>
      <Component
        onChange={addFilters}
        {...componentProps}
        allParams={queries}
      />
    </Fragment>
  );
};

DataListFilterV2.propTypes = {
  Component: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired,
  listID: PropTypes.string.isRequired,
  listURL: PropTypes.string.isRequired,
  componentProps: PropTypes.instanceOf(Object)
};

export default DataListFilterV2;
