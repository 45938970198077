import {
  RenderSignal,
  RenderError,
  RenderStatus,
  RenderButtons,
  RenderHeaderWithTooltip,
  RenderGateway,
  RenderDate,
  RenderUserName,
  RenderAccess
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';

const columnsEndUser = () => ([
  {
    Header: i18n.t('SMID'),
    id: 'sm_id',
    accessor: (d) => (d.gateway?.sm_id || undefined),
    Cell: RenderGateway,
    minWidth: 180,
    className: 'sm_id'
  },
  {
    Header: i18n.t('fName'),
    id: 'last_name',
    Cell: RenderUserName,
    minWidth: 180
  },
  {
    Header: RenderHeaderWithTooltip({
      headerText: i18n.t('signal'),
      tooltipText: i18n.t('signalTooltip'),
      placement: 'right-start'
    }),
    id: 'signal',
    accessor: (d) => (d.gateway?.signal || 'not connected'),
    minWidth: 170,
    Cell: RenderSignal,
    filterable: false,
    sortable: true
  },
  {
    Header: i18n.t('error'),
    id: 'hasErrors',
    accessor: 'error',
    minWidth: 100,
    Cell: RenderError,
    filterable: false,
    sortable: true
  },
  {
    Header: i18n.t('accStatus'),
    accessor: 'status',
    minWidth: 169,
    Cell: RenderStatus,
    className: 'acc_status-td'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: 107,
    className: 'last_login-td',
    Cell: RenderDate
  },
  {
    Header: i18n.t('access'),
    id: 'access',
    Cell: (row) => RenderAccess(row),
    minWidth: 100,
    filterable: false,
    sortable: false,
    show: true
  },
  {
    Header: '',
    accessor: 'buttons',
    width: 120,
    className: 'action-buttons',
    Cell: (row) => RenderButtons(
      row.original,
      {
        devices: { preLink: '/users/' },
        editUser: { preLink: '/setting-profile/solar-managers-users/' },
        delete: true
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columnsEndUser;
