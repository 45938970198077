import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, groupBy, mergeMap, switchMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import { receiveNotificationInfo, sendRequest } from './slice';

function SendRequestEpic(action$) {
  return action$.pipe(
    ofType(sendRequest),
    map((action) => action.payload),
    groupBy((payload) => payload.listID),
    mergeMap((group) => group.pipe(
      switchMap(({ data }) => (
        from(api.post('/emailnotification/update-notification', { ...data })).pipe(
          catchError(handleErrorDetailed),
          mergeMap((result) => {
            if (!result.error) {
              return of(
                receiveNotificationInfo({ data: result })
              );
            }

            return of(
              addNotification({ type: 'error', text: result.message })
            );
          })
        )
      ))
    ))
  );
}

export default combineEpics(
  SendRequestEpic
);
