import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetUserFromQueryQuery } from '../containers/Authorization/services';
import { addNotification } from '../containers/NotificationGenerator/slice';

export const useFetchUser = (userID, mySelf) => {
  const dispatch = useDispatch();
  const { data, isLoading, error, refetch } = useGetUserFromQueryQuery({ userID, mySelf }, { skip: !userID && !mySelf });

  useEffect(() => {
    if (error) {
      dispatch(addNotification({ type: 'error', text: error.message }));
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, isLoading, refetch, error, skip: !userID && !mySelf };
};
