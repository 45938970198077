import React from 'react';
import PropTypes from 'prop-types';

const Email = ({ email }) => (
  <span className="table-mail-hove-ef company-detail-td email-text-td td-info-email">
    <a href={`mailto:${email}`}>{email}</a>
  </span>
);

Email.propTypes = {
  email: PropTypes.string.isRequired
};

export default Email;
