import React from 'react';
import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';

export const Expander = ({ children, expanded, orientation, timeout }) => (
  <Collapse in={expanded} orientation={orientation} timeout={timeout}>
    {children}
  </Collapse>
);

Expander.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  orientation: PropTypes.string,
  timeout: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
