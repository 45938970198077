import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const TabContentContainer = ({ className = '', children }) => (
  <div className={`tabs-cont align-items-center ${className ?? ''}`}>
    <div className="col-auto mr-auto">
      <div className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links tab-content-container">
        <div className="nav-item m-tabs__item">
          <div className="nav-link m-tabs__link">
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

TabContentContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};

export default TabContentContainer;
