import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import i18n from '../../../i18n';
import Tooltip from '../../Tooltip';

/**
 * Render function for React-Table. Column for device errors.
 * @memberof module:ReactTable
 * @param  {object} original - props of the company details
 */
const RenderDevicesError = ({ original }) => (
  Array.isArray(original.errorCodes)
    ? (
      <div className="errors-container">
        {original.errorCodes.map(({ errorCode, errorLabel, errorDescription }) => (
          <span
            key={shortid.generate()}
            className={`error_status_yes device-activity-code-${original.deviceActivity || 0}`}
          >
            <Tooltip
              disabled={!errorLabel}
              title={(
                <>
                  <p className="error-title">
                    {errorCode}
                    :
                    {' '}
                    {errorLabel || i18n.t('unknownError')}
                  </p>
                  <p className="error-subtitle">{errorDescription}</p>
                </>
              )}
              customClasses={{ tooltip: 'error-tooltip_wrapper' }}
            >
              <span className="error_wrapper">{errorCode}</span>
            </Tooltip>
          </span>
        ))}
      </div>
    )
    : <span className="error_status_no">{i18n.t('no')}</span>
);

RenderDevicesError.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderDevicesError;
