import { createSelector } from '@reduxjs/toolkit';
import { getSignInUserRoleType } from './signIn';

const getUserDevicesIsSupportContractFlagAllowedToBeChanged = (state) => state.userDevices?.isSupportContractFlagAllowedToBeChanged;
const getUserDevicesLastSensorsData = (state) => state.userDevices?.lastSensorsData;
const getUserDevicesDevicesList = (state) => state.userDevices?.devicesList;
const getUserDevicesLoad = (state) => state.userDevices?.load;
const getUserDevicesUser = (state) => state.userDevices?.user;
const getInvitedUserPermissionForSelectedUser = (state) => state.userDevices?.user?.permission;
const getIsInstallationCompleted = (state) => state.userDevices?.user?.gateway?.isInstallationCompleted;
const getUserDevicesIps = (state) => state.userDevices?.ips;
const getUserDevicesChargers = (state) => state.userDevices?.chargers;

export const getUserDevicesIsSupportContractFlagAllowedToBeChangedSelector = createSelector([getUserDevicesIsSupportContractFlagAllowedToBeChanged], (allowedToBeChanged) => allowedToBeChanged);
export const getUserDevicesLastSensorsDataSelector = createSelector([getUserDevicesLastSensorsData], (lastSensorsData) => lastSensorsData);
export const getUserDevicesUserGatewaySensorsSelector = createSelector([getUserDevicesUser], (user) => user?.gateway?.sensors || []);
export const getUserDevicesDevicesListSelector = createSelector([getUserDevicesDevicesList], (devicesList) => devicesList);
export const getUserDevicesUserGateway_IdSelector = createSelector([getUserDevicesUser], (user) => user?.gateway?._id);
export const getUserDevicesUser_IdSelector = createSelector([getUserDevicesUser], (user) => user?._id);
export const getUserDevicesUserSelector = createSelector([getUserDevicesUser], (user) => user);
export const getUserDevicesLoadSelector = createSelector([getUserDevicesLoad], (load) => load);
export const getUserDevicesIpsSelector = createSelector([getUserDevicesIps], (ips) => ips || []);
export const getUserDevicesChargersSelector = createSelector([getUserDevicesChargers], (chargers) => chargers || []);
export const isInvitedUserHasReadOnlyPermissionSelector = createSelector(
  [getSignInUserRoleType, getInvitedUserPermissionForSelectedUser],
  (myRoleType, permission) => (['viewer', 'pv_installer', 'oem'].includes(myRoleType) ? permission === 'read' : false)
);
export const isPvEmployeeInstallHasReadOnlyAccessSelector = createSelector(
  [getSignInUserRoleType, getIsInstallationCompleted],
  (myRoleType, isInstallationCompleted) => (myRoleType === 'pv_installer_employee_read_install' ? !!isInstallationCompleted : false)
);

export {
  getUserDevicesLoad,
  getUserDevicesUser
};
