import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import dayjs from 'dayjs';

export const useAdditionalValuesForFields = ({ data, formID }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.chargingMode === 6) {
      dispatch(change(formID, 'data.minimumChargeQuantityTargetDateTime', data.minimumChargeQuantityTargetDateTime || dayjs().add(1, 'day').set('hour', 6).set('minute', 0)));
      dispatch(change(formID, 'data.minimumChargeQuantityTargetAmount', data.minimumChargeQuantityTargetAmount || 20));
    }

    if (data?.chargingMode === 7) {
      dispatch(change(formID, 'data.chargingTargetSocDateTime', data.chargingTargetSocDateTime || dayjs().add(1, 'day').set('hour', 6).set('minute', 0)));
      dispatch(change(formID, 'data.chargingTargetSoc', data.chargingTargetSoc || 80));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.chargingMode]);
};
