/* eslint-disable arrow-body-style */
import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  notAttachedEndUsers: [],
  pvInstallers: [],
  oems: [],
  isNotAttachedUsersLoading: false,
  isOemsListForViewerLoading: false,
  isPvInstallersListForViewerLoading: false
};

const viewerDetailsSlice = createSlice({
  name: 'viewerDetails',
  initialState,
  reducers: {
    getPVInstallersForViewerAllList: epicEmptyFunction,
    getNotAttachedToViewerEndUsers: epicEmptyFunction,
    getOEMsListForViewer: epicEmptyFunction,

    setNotAttachedToViewerEndUsers: (state, action) => {
      state.notAttachedEndUsers = action.payload.endUsers;
    },
    setOEMsForViewerList: (state, action) => {
      state.oems = action.payload.oems;
    },
    setPVInstallersForViewerList: (state, action) => {
      state.pvInstallers = action.payload.pvInstallers;
    },
    setLoading: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const {
  getPVInstallersForViewerAllList,
  getNotAttachedToViewerEndUsers,
  setNotAttachedToViewerEndUsers,
  setPVInstallersForViewerList,
  setOEMsForViewerList,
  getOEMsListForViewer,
  setLoading
} = viewerDetailsSlice.actions;

export default viewerDetailsSlice.reducer;
