import { createSelector } from '@reduxjs/toolkit';

const getDevicesTableUsersSensors = (state) => state.devicesTable?.usersSensors;
const getDevicesTableDevicesTypesList = (state) => state.devicesTable?.devicesTypesList;
const getDevicesTableSensorsConfigurationFile = (state) => state.devicesTable?.sensorsConfigurationFile;

export const sensorsConfigurationFileSelector = createSelector(
  [getDevicesTableSensorsConfigurationFile],
  (data) => data?.data
);

export const allDevicesTypesList = createSelector(
  [getDevicesTableDevicesTypesList],
  (types) => types
);

export {
  getDevicesTableSensorsConfigurationFile,
  getDevicesTableDevicesTypesList,
  getDevicesTableUsersSensors
};
