import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

/**
 * Component for displaying notifications
 * @memberof module:UIKit
 * @param {Object} notification
 * @param {string} notification.type Type of notification (success, error, ...)
 * @param {string} notification.text Text of notification
 * @param {function} notification.onClick Callback function when click on notification
 */
const StatusMessage = ({ type, text, onClick }) => (
  <div className={`toast toast-${type}`} aria-live="polite" role="status" onClick={onClick}>
    <div className="toast-message">
      {typeof text === 'object' ? i18n.t('error') : text}
    </div>
  </div>
);

StatusMessage.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func.isRequired
};

export default StatusMessage;
