import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DropDownList } from '../../../components/UIKit';

import i18n from '../../../i18n';

const ExportDropDownList = (props) => {
  const { menuItems } = props;
  const [open, setOpen] = useState(false);

  return (
    <DropDownList
      i18TitleValue={i18n.t('export')}
      dropDownType="export"
      menuItems={menuItems}
      setOpen={setOpen}
      open={open}
    />
  );
};

ExportDropDownList.propTypes = {
  menuItems: PropTypes.instanceOf(Array).isRequired
};

export default ExportDropDownList;
