import { createSelector } from '@reduxjs/toolkit';
import { ADD_CAR_MODAL, EDIT_CAR_MODAL } from '../../containers/DevicesTable/Tabs/CarsTab/constants';

const getModalsIsLoading = (state) => state.modals?.isLoading;
const getModals = (state) => state.modals;
const getModalsAddData = (state) => state.modals?.addData;
const getModalsEditRow = (state) => state.modals?.editRow;
const getModalsEditNote = (state) => state.modals.editNote;
const getModalsSearchIP = (state) => state.modals?.searchIP;
const getModalSearchChargers = (state) => state.modals?.searchChargers;
const getSkiSearchModal = (state) => state.modals?.skiSearch;
const getModalsAddDevice = (state) => state.modals?.addDevice;
const getModalsChangeSMID = (state) => state.modals?.changeSMID;
const getModalsDeleteData = (state) => state.modals?.deleteData;
const getModalsRemoveData = (state) => state.modals?.removeData;
const getModalsColorPicker = (state) => state.modals?.colorPicker;
const getModalsChangeEmail = (state) => state.modals?.changeEmail;
const getModalsAddEmployee = (state) => state.modals?.addEmployee;
const getEditUserAccessModal = (state) => state.modals?.editUserAccess;
const getModalsCloudStatus = (state) => state.modals?.CloudStatus;
const getModalsDownloadFile = (state) => state.modals?.downloadFile;
const getModalsChangeColumn = (state) => state.modals?.changeColumn;
const getModalsInviteUserModal = (state) => state.modals?.inviteUserModal;
const getModalsDataExportModal = (state) => state.modals?.dataExportModal;
const getModalsRemoveGateway = (state) => state.modals?.removeGateway;
const getModalsDeleteFilesModal = (state) => state.modals?.deleteFilesModal;
const getModalsInviteSubUser = (state) => state.modals?.inviteSubUser;
const getModalsRemoveSubUser = (state) => state.modals?.removeSubUser;
const getModalRemoveCarCharger = (state) => state.modals?.removeCarCharger;
const getModalsChangePassword = (state) => state.modals?.changePassword;
const getModalsGeneralSettings = (state) => state.modals?.generalSettings;
const getModalsChooseLicenseWindow = (state) => state.modals?.chooseLicenseWindow;
const getModalsDashboardSettings = (state) => state.modals?.dashboardSettings;
const getModalsDeleteInvitedUser = (state) => state.modals?.deleteInvitedUser;
const getModalsConfirmationWindow = (state) => state.modals?.confirmationWindow;
const getModalsAddPvInstallerToOEM = (state) => state.modals?.addPvInstallerToOEM || {};
const getModalsConfirmRemoveGateway = (state) => state.modals?.confirmRemoveGateway;
const getModalsConfirmRemoveUser = (state) => state.modals?.confirmRemoveUser;
const getModalsRemoveFirmwareUpdate = (state) => state.modals?.removeFirmwareUpdate;
const getModalsAddEndUsersToViewer = (state) => state.modals?.addEndUsersToViewer;
const getModalsAddCarChargers = (state) => state.modals?.addCarChargers;
const getModalsDataExportInfoPopUp = (state) => state.modals?.dataExportInfoPopUp;

export const getModalsIsLoadingSelector = createSelector([getModalsIsLoading], (isLoading) => !!isLoading);
export const getModalsSelector = createSelector([getModals], (modals) => modals || {});

export const getModalsAddDataSelector = createSelector([getModalsAddData], (modal) => modal);
export const getModalsEditNoteSelector = createSelector([getModalsEditNote], (modal) => modal);
export const getModalsSearchIPSelector = createSelector([getModalsSearchIP], (modal) => modal || {});
export const getModalSearchChargersSelector = createSelector([getModalSearchChargers], (modal) => modal || {});
export const getSkiSearchModalSelector = createSelector([getSkiSearchModal], (modal) => modal || {});
export const getModalsChangeSMIDSelector = createSelector([getModalsChangeSMID], (modal) => modal);
export const getModalsDeleteDataSelector = createSelector([getModalsDeleteData], (modal) => modal || {});
export const getModalsRemoveDataSelector = createSelector([getModalsRemoveData], (modal) => modal);
export const getModalsColorPickerSelector = createSelector([getModalsColorPicker], (modal) => modal || {});
export const getModalsChangeEmailSelector = createSelector([getModalsChangeEmail], (modal) => modal || {});
export const getModalsAddEmployeeSelector = createSelector([getModalsAddEmployee], (modal) => modal);
export const getEditUserAccessModalSelector = createSelector([getEditUserAccessModal], (modal) => modal);
export const getModalsCloudStatusSelector = createSelector([getModalsCloudStatus], (modal) => modal || {});
export const getModalsRemoveGatewaySelector = createSelector([getModalsRemoveGateway], (modal) => modal);
export const getModalsChangePasswordSelector = createSelector([getModalsChangePassword], (modal) => modal);
export const getModalsDataExportModalSelector = createSelector([getModalsDataExportModal], (modal) => modal || {});
export const getModalsGeneralSettingsSelector = createSelector([getModalsGeneralSettings], (modal) => modal);
export const getModalsInviteUserModalSelector = createSelector([getModalsInviteUserModal], (modal) => modal);
export const getModalsDashboardSettingsSelector = createSelector([getModalsDashboardSettings], (modal) => modal);
export const getModalsDeleteInvitedUserSelector = createSelector([getModalsDeleteInvitedUser], (modal) => modal);
export const getModalsAddPvInstallerToOEMSelector = createSelector([getModalsAddPvInstallerToOEM], (modal) => modal);
export const getModalsChooseLicenseWindowSelector = createSelector([getModalsChooseLicenseWindow], (modal) => modal || {});
export const getModalsConfirmRemoveGatewaySelector = createSelector([getModalsConfirmRemoveGateway], (modal) => modal || {});
export const getModalsConfirmRemoveUserSelector = createSelector([getModalsConfirmRemoveUser], (modal) => modal || {});
export const getModalsRemoveFirmwareUpdateSelector = createSelector([getModalsRemoveFirmwareUpdate], (modal) => modal);
export const getModalsAddEndUsersToViewerSelector = createSelector([getModalsAddEndUsersToViewer], (modal) => modal || {});
export const getModalsAddCarChargersSelector = createSelector([getModalsAddCarChargers], (modal) => modal);
export const getModalsDataExportInfoPopUpSelector = createSelector([getModalsDataExportInfoPopUp], (modal) => modal);

export const getModalsConfirmationWindowDataSelector = createSelector([getModalsConfirmationWindow], (modal) => modal?.data || {});
export const getModalsDeleteFilesModalDataIdsSelector = createSelector([getModalsDeleteFilesModal], (modal) => modal?.data?.ids || []);
export const getModalsInviteSubUserDataSelector = createSelector([getModalsInviteSubUser], (modal) => modal?.data || {});
export const getModalsDownloadFileDataIDSelector = createSelector([getModalsDownloadFile], (modal) => modal?.data?.id);
export const getModalsChangeColumnOpenedSelector = createSelector([getModalsChangeColumn], (modal) => modal?.opened);
export const getModalsRemoveSubUserDataSelector = createSelector([getModalsRemoveSubUser], (modal) => modal?.data);
export const getModalRemoveCarChargerDataSelector = createSelector([getModalRemoveCarCharger], (modal) => modal?.data);
export const getModalsRemoveDataDataSelector = createSelector([getModalsRemoveData], (modal) => modal?.data || {});
export const getModalsAddDeviceOpenedSelector = createSelector([getModalsAddDevice], (modal) => modal?.opened);
export const getModalsAddDeviceDataSelector = createSelector([getModalsAddDevice], (modal) => modal?.data || {});
export const getModalsEditRowDataIdSelector = createSelector([getModalsEditRow], (modal) => modal?.data?.id);
export const getModalsEditRowDataSelector = createSelector([getModalsEditRow], (modal) => modal?.data || {});
export const getModalAddCar = createSelector([getModals], (modal) => modal?.[ADD_CAR_MODAL]);
export const getModalEditCar = createSelector([getModals], (modal) => modal?.[EDIT_CAR_MODAL]);

export {
  getModals
};
