import React from 'react';
import PropTypes from 'prop-types';
import ReactFlagsSelect from 'react-flags-select';

import { countries, countryLabelsBack, countryLabels, thermondoCountryLabels, thermondoCountriesCode } from './constants';

import i18n from '../../../i18n';
import './index.scss';

/**
 * Renders languageSelector for redux form
 * @memberof module:ReduxFormFields
 */
export const LanguageSelector = ({
  input,
  label,
  id,
  buttonClassName = '',
  isThermondoUser = false,
  meta: { touched, error },
  ...custom
}) => {
  const labels = isThermondoUser ? thermondoCountryLabels : countryLabels;
  const countriesCode = isThermondoUser ? thermondoCountriesCode : countries;

  return (
    <>
      <ReactFlagsSelect
        {...input}
        {...custom}
        selectButtonClassName={`${buttonClassName} ${custom.disabled ? `${buttonClassName}_disabled` : ''}`}
        rfsKey={id}
        onSelect={(country) => input.onChange(labels[country])}
        countries={countriesCode}
        customLabels={labels}
        selected={countryLabelsBack[input.value] || 'US'}
        selectedSize={18}
        searchPlaceholder={`${i18n.t('search')}`}
      />
      {touched && error && (
        <div className="inputCustomWarning">
          {i18n.t(error)}
        </div>
      )}
    </>
  );
};

LanguageSelector.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  buttonClassName: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  isThermondoUser: PropTypes.bool
};
