import { from, of } from 'rxjs';
import { catchError, switchMap, mergeMap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { push } from 'react-router-redux';

import {
  redirectToPaymentPage,
  setPaymentUrlSuccess,
  setPaymentUrlFailure,
  getPaymentUrlRequest
} from './slice';
import { handleErrorDetailed } from '../../api_helper';
import { addNotification } from '../NotificationGenerator/slice';
import { getJwtFromCookies, api } from '../../api';

import i18n from '../../i18n';

function getPaymentUrlRequestEpic(action$) {
  return action$.pipe(
    ofType(getPaymentUrlRequest),
    switchMap(({ payload }) => {
      const userPath = payload?.userId ? `/${payload.userId}` : '';

      return from(api.get(`/payments/web-url${userPath}`)).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error && result?.webViewUrl) {
            return of(setPaymentUrlSuccess({ url: result.webViewUrl }), redirectToPaymentPage({ endUserId: payload?.userId, url: result.webViewUrl }));
          }
          return of(
            setPaymentUrlFailure(),
            push('/payments'),
            addNotification({ type: 'error', text: result.message || i18n.t('error') })
          );
        })
      );
    })
  );
}

function redirectToPaymentPageEpic(action$) {
  return action$.pipe(
    ofType(redirectToPaymentPage),
    map((action) => action.payload),
    switchMap(({ endUserId, url }) => {
      const jwt = getJwtFromCookies();

      if (!jwt) {
        return of(addNotification({ type: 'error', text: i18n.t('error') }));
      }

      const userIdPath = endUserId ? `/${endUserId}` : '';
      const currentLocation = encodeURIComponent(window.location.href);
      window.location.href = `${url}/${jwt}${userIdPath}?app=web&redirectUrl=${currentLocation}`;
      return of(addNotification({ type: 'success', text: i18n.t('success') }));
    })
  );
}

export default combineEpics(
  getPaymentUrlRequestEpic,
  redirectToPaymentPageEpic
);
