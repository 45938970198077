import React from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

import i18n from '../../../i18n';

import './index.scss';

/**
 * Renders multiple select for redux form
 * @memberof module:ReduxFormFields
 * @param  {Object} props - React component properties
 * @param  {Object[]} props.options - list of options for multiple select
 * @param  {string} props.label - label text
 * @param  {string} props.placeholder - placeholder for select
 * @param  {Object} props.input
 * @param  {string} props.input.name - multiple select component name
 * @param  {function} props.input.onChange - on change handler
 * @param  {Object[]|string} props.input.value - current value
 * @param  {Object} props.meta
 * @param  {boolean} props.meta.invalid
 * @param  {boolean} props.meta.touched
 * @param  {boolean} props.meta.error
 */
export const MultipleSelect = (props) => {
  const empty = [];
  const {
    options = [],
    label = '',
    placeholder = '',
    input: {
      name,
      onChange,
      value: currentValue = empty
    },
    meta: {
      invalid,
      touched,
      error
    },
    required,
    renderValue,
    disabled,
    allSelectedKey = ''
  } = props;

  const handleChange = ({ target }) => {
    const { value: selected } = target;

    if (allSelectedKey && selected.includes(allSelectedKey)) {
      onChange(selected.length === options.length ? [] : options.map(({ value: val } = {}) => val).filter((item) => item !== allSelectedKey));
      return;
    }

    onChange(selected);
  };

  const renderValueHandle = (selected) => {
    if (!selected?.length) {
      return <span className="affected-device">{i18n.exists(placeholder) ? i18n.t(placeholder) : placeholder}</span>;
    }

    return (
      <>
        {selected.map((_id) => {
          const labelValue = options.find(({ value }) => value === _id)?.label || '';
          return (
            <span key={_id} className="affected-device">
              {i18n.exists(labelValue) ? i18n.t(labelValue) : labelValue}
            </span>
          );
        })}
      </>
    );
  };

  return (
    <div className="multiple-select-container">
      <FormControl classes={{ root: 'form-control-multiple-select' }}>
        {label ? (
          <div id="multiple-checkbox-label" className="input-label-multiple-select">
            {i18n.exists(label) ? i18n.t(label) : label}
            {required ? ' *' : null}
          </div>
        ) : null}
        <Select
          disabled={disabled}
          name={name}
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={currentValue || empty}
          onChange={handleChange}
          input={<Input {...{ notched: undefined }} />}
          displayEmpty
          classes={{ icon: 'multiple-select-icon' }}
          renderValue={renderValue || renderValueHandle}
          MenuProps={{
            autoFocus: false,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            classes: {
              paper: 'multiple-select-menu'
            }
          }}
        >
          {options.map(({ value, id }) => {
            const optionValue = options.find((item) => item.value === value)?.label || '';

            return (
              <MenuItem
                key={value}
                value={value}
                className="multiple-select-menu-item"
              >
                <div className="labeled-checkbox-container">
                  <Checkbox
                    checked={id === allSelectedKey ? currentValue.length === (options.length - 1) : (currentValue || empty)?.indexOf(value) > -1}
                    className="multiple-select-checkbox"
                  />
                  <ListItemText
                    primary={i18n.exists(optionValue) ? i18n.t(optionValue) : optionValue}
                    classes={{
                      root: 'multiple-select-label',
                      primary: 'multiple-select-label-primary'
                    }}
                  />
                </div>
              </MenuItem>
            );
          }

          )}
        </Select>
        {(invalid && touched && error) ? (
          <FormHelperText
            error={!!error}
            classes={{
              root: 'multiple-select-helper-text',
              error: 'validation-error'
            }}
          >
            {i18n.t(error)}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
};

MultipleSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Array),
  options: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  required: PropTypes.bool,
  renderValue: PropTypes.func,
  disabled: PropTypes.bool,
  allSelectedKey: PropTypes.string
};
