import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { openModalWindow } from '../../../ModalWindow/slice';
import { PrimaryButton } from '../../../../components/UIKit';
import { ReactComponent as CloseIco } from '../../../../assets/images/svg/close.svg';
import { UPLOAD_MODAL_ID, allowedRolesForUpdateAndDeleteFiles } from '../../constants';
import { downloadFiles } from '../../store/slice';

import i18n from '../../../../i18n';

import './index.scss';

const DocumentationTableHeader = (props) => {
  const { filesCount, selectedRowsIds, resetSelectedRows, deleteFiles, myRoleType, isReadOnlyPermission } = props;
  const dispatch = useDispatch();
  const isAllowedToUpdateAndDeleteFiles = allowedRolesForUpdateAndDeleteFiles.includes(myRoleType) && !isReadOnlyPermission;

  const handleDownloadFiles = () => dispatch(downloadFiles({ filesIds: selectedRowsIds }));
  const uploadFilesHAndler = () => dispatch(openModalWindow({ modalID: UPLOAD_MODAL_ID }));

  return (
    <div className="documentation-header-container">
      <div className="documentation-header">
        <h2 className="documentation-header-title">
          {i18n.t('uploadedFiles')}
        </h2>
        {(filesCount > 0) ? (
          <div className="documentation-header-subtitle">
            {filesCount}
            {' '}
            {i18n.t('items')}
          </div>
        ) : null}
      </div>
      {selectedRowsIds.length ? (
        <div className="documentation-header-selected-items">
          <p className="count">
            <span>{selectedRowsIds.length}</span>
            {' '}
            <span>{i18n.t('Selected')}</span>
          </p>
          <div className="btn-upd-dashb">
            <button
              type="button"
              className="close-btn"
              onClick={resetSelectedRows}
            >
              <CloseIco />
            </button>
          </div>
          <button
            type="button"
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn filter"
            onClick={handleDownloadFiles}
          >
            {i18n.t('download')}
          </button>
          {isAllowedToUpdateAndDeleteFiles && (
            <button
              type="button"
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn delete-btn"
              onClick={deleteFiles}
            >
              {i18n.t('delete')}
            </button>
          )}
        </div>
      ) : (isAllowedToUpdateAndDeleteFiles && (
        <PrimaryButton
          onClickHandler={uploadFilesHAndler}
          i18nLabel="uploadFiles"
          type="upload"
          isLargeButton
          customButtonClass="upload-files-btn"
        />
      ))}
    </div>
  );
};

DocumentationTableHeader.propTypes = {
  filesCount: PropTypes.number.isRequired,
  selectedRowsIds: PropTypes.instanceOf(Array).isRequired,
  resetSelectedRows: PropTypes.func.isRequired,
  deleteFiles: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  isReadOnlyPermission: PropTypes.bool.isRequired
};

export default DocumentationTableHeader;
