import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hexToRgb } from '@mui/material';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { change } from 'redux-form';

import ModalWindow from '../../containers/ModalWindow';
import { getModalsColorPickerSelector } from '../../redux-store/selectors/modals';
import { RGBToHex } from './utils';

import { ReactComponent as CloseIco } from '../../assets/images/svg/close.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/svg/checkbox-icon.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/svg/ArrowDown.svg';
import { getArrayOfUserColors, getDashboardUserChart_SettingsColors } from '../../containers/DevicesTable/modals/selectors';
import { addNotification } from '../../containers/NotificationGenerator/slice';
import { getAllDataSensorsSelector } from './selectors';
import { COLOR_PICKER_MODAL_ID, closeModalWindow } from '../../containers/ModalWindow/slice';

import i18n from '../../i18n';

import './index.scss';
import { getDashboardUserChart_SettingsSelector } from '../../redux-store/selectors/dashboard';

const ColorPickerModalWindow = () => {
  const dispatch = useDispatch();

  const getAllDataSensors = useSelector(getAllDataSensorsSelector);

  const modal = useSelector(getModalsColorPickerSelector);
  const arrayOfUserColors = useSelector(getArrayOfUserColors);
  const arrayOfDashboardUserColors = useSelector(getDashboardUserChart_SettingsColors);
  const { color: colorData, formId, fieldName, device } = modal?.data || {};
  const colorArrRandom = hexToRgb(colorData || '#fff').match(/\d+/g);
  const initialColorState = { hex: colorData || '#fff', rgb: { r: colorArrRandom[0], g: colorArrRandom[1], b: colorArrRandom[2] } };
  const chartSetting = useSelector(getDashboardUserChart_SettingsSelector);

  const [isHex, setIsHex] = useState(true); // true -> hex, false -> rgb
  const [colorState, setColorState] = useState(initialColorState);

  const setRgbHAndler = (e) => setColorState((prev) => {
    const newRgb = { ...prev.rgb, [e.target.dataset.type]: e.target.value };
    return { hex: RGBToHex(newRgb), rgb: newRgb };
  });

  const colorSelectHandler = (colorHex) => {
    const colorArr = hexToRgb(colorHex).match(/\d+/g);

    setColorState({ hex: colorHex, rgb: { r: colorArr[0], g: colorArr[1], b: colorArr[2] } });
  };

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: COLOR_PICKER_MODAL_ID }));
  const changeColorByTag = (currentDevice) => {
    const tagId = currentDevice?.tag?._id;
    const { batteriesSoc, devicesCurrentPower, temperatures, invertersCurrentPower } = chartSetting;
    const batteries = batteriesSoc.map((item) => ({ ...item, name: `batteriesSoc.${item._id}-color` }));
    const temperaturesData = temperatures.map((item) => ({ ...item, name: `temperatures.${item._id}-color` }));
    const currentPower = devicesCurrentPower.map((item) => ({ ...item, name: `devicesCurrentPower.${item._id}-color` }));
    const inverters = invertersCurrentPower.map((item) => ({ ...item, name: `invertersCurrentPower.${item._id}-color` }));
    const allDevices = [...batteries, ...currentPower, ...temperaturesData, ...inverters];
    const allDevicesWithSameTag = allDevices.filter((item) => item?.tag?._id === tagId);

    for (let i = 0; i < allDevicesWithSameTag.length; i += 1) {
      dispatch(change(formId, allDevicesWithSameTag[i].name, colorState.hex));
    }
  };

  const saveHandler = () => {
    if (arrayOfUserColors.length || arrayOfDashboardUserColors.length) {
      if (colorState.hex !== colorData && (getAllDataSensors.includes(colorState.hex) || arrayOfUserColors.includes(colorState.hex) || arrayOfDashboardUserColors.includes(colorState.hex))) {
        dispatch(addNotification({ type: 'error', text: i18n.t('colorAlreadyExist') }));
      } else {
        const splitFieldName = fieldName?.split('.');

        if (!['temperatures', 'devicesCurrentPower'].includes(splitFieldName?.[0]) || !(device?.tag?.color)) {
          dispatch(change(formId, fieldName, colorState.hex));
        } else {
          changeColorByTag(device);
        }
      }
    }
    handleOnClose();
  };

  useEffect(() => {
    setColorState(initialColorState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorData]);

  return (
    <ModalWindow modalID={COLOR_PICKER_MODAL_ID} customOnClose={handleOnClose} isModalOver>
      <div className="color-picker-modal">
        <div className="color-picker-header">
          <CloseIco className="close-icon" onClick={handleOnClose} />
          {i18n.t('colorsHeader')}
          <CheckIcon className="check-icon" onClick={saveHandler} />
        </div>
        <div className="color-picker-area">
          <HexColorPicker color={colorState.hex} onChange={colorSelectHandler} />
        </div>
        <div className="color-picker-controllers">
          <button type="button" value={isHex} onClick={() => setIsHex((prev) => !prev)}>
            <span>{isHex ? 'Hex' : 'RGB'}</span>
            <ArrowDown />
          </button>
          {isHex ? (
            <div className="hex-controller">
              <span>#</span>
              <HexColorInput color={colorState.hex} onChange={colorSelectHandler} />
            </div>
          ) : (
            <div className="rgb-controller">
              <input maxLength={2} value={colorState.rgb.r} data-type="r" onChange={setRgbHAndler} />
              <input maxLength={2} value={colorState.rgb.g} data-type="g" onChange={setRgbHAndler} />
              <input maxLength={2} value={colorState.rgb.b} data-type="b" onChange={setRgbHAndler} />
            </div>
          )}
        </div>
      </div>
    </ModalWindow>
  );
};

export default ColorPickerModalWindow;
