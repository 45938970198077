import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { children = null } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="error-handler-parent">
          <h1>Oops! Something went wrong...</h1>
        </div>
      );
    }

    return children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ErrorHandler;
