import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import routes from './routes';
import { ErrorHandler } from './components';
import { changeLanguageTo, getLanguageFromStore } from './i18n';
import { getJwtFromCookies, updateJwt } from './api';
import { CkwLicenseRestrictionsModal, UpgradeToUnlockPopUp, PermissionDenyAIT } from './modals';
import { UPGRADE_TO_UNLOCK_POPUP, CKW_LICENSE_RESTRICTIONS_MODAL } from './containers/ModalWindow/slice';

const App = () => {
  const jwt = getJwtFromCookies();
  updateJwt(jwt);

  useEffect(() => {
    changeLanguageTo(
      getLanguageFromStore()
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorHandler>
      <Switch>
        {routes.map((route) => <Route {...route} key={route.key} />)}
      </Switch>
      <UpgradeToUnlockPopUp modalID={UPGRADE_TO_UNLOCK_POPUP} />
      <CkwLicenseRestrictionsModal modalID={CKW_LICENSE_RESTRICTIONS_MODAL} />
      <PermissionDenyAIT />
    </ErrorHandler>
  );
};

export default App;
