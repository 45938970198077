import { of } from 'rxjs';
import FormData from 'form-data';
import { api } from '../api';

/**
 * Destructurises response data recieved from back-end. Return Object or string.
 * @param {Object} error Response from back-end
 * @returns {Object|String} Object with successfull data; or string if response has error
 */
// eslint-disable-next-line arrow-body-style
export const handleError = (error) => {
  return (
    error.response?.data
      ? of(`${error.response.data.message}`)
      : of(`${error?.message || error}`)
  );
};

/**
 * Destructurises response data recieved from back-end. Always return Object.
 * @param {Object} error Response from back-end
 * @returns {Object} Object.response.data if successfull; or Object {error: boolean, message: string}
 */
// eslint-disable-next-line arrow-body-style
export const handleErrorDetailed = (error) => {
  return (error.response?.data
    ? of({
      error: true,
      status: error.response?.status,
      message: `${error.response.data.message}`
    })
    : of({
      error: true,
      status: error.response?.status,
      message: `${error?.message || error}`
    }));
};

/**
 * Trims all string children in Object.
 * @param {Object} fields Trimmed string
 * @returns {Object} Object with trimmed strings
 */
export const trimAllFields = (fields) => (
  Object.keys(fields).reduce((prev, key) => {
    const value = fields[key];
    return {
      ...prev,
      [`${key}`]: typeof value === 'string' ? value.trim() : value
    };
  }, {})
);

/**
 * Transforms data object to form data
 * @param data
 * @returns {FormData}
 */
export const transformToFormData = (data) => {
  const formData = new FormData();
  const fieldsKeys = Object.keys(data);
  fieldsKeys.forEach((fieldID) => {
    formData.append(fieldID, data[fieldID]);
  });
  return formData;
};

// eslint-disable-next-line space-before-function-paren
export const axiosBaseQuery = async ({ url, method, data, params, headers }) => {
  try {
    const result = await api({ url, method, data, params, headers });
    return { data: result };
  } catch (axiosError) {
    const { response = {} } = axiosError || {};

    return { error: { status: axiosError.code, message: response.data?.message || axiosError.message } };
  }
};
