import moment from 'moment-timezone';

/**
 * Validates if the value is the same as today date or before today date
 * @param {number} value timestamp
 * @returns {undefined|string}
 */
const dataPickerValidator = (value) => moment(value).startOf('day').isSameOrBefore(moment().startOf('day'));

export default dataPickerValidator;
