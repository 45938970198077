import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  supportContracts: undefined,
  totalEnergy: undefined,
  consumption: undefined,
  monitoring: undefined,
  production: undefined,
  autarchy: undefined,
  plants: undefined,
  status: undefined
};

const epicEmptyFunction = (state) => state;

const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    requestOverviewData: epicEmptyFunction,
    receiveOverviewData: (state, action) => action.payload
  }
});

export const {
  requestOverviewData,
  receiveOverviewData
} = overviewSlice.actions;

export default overviewSlice.reducer;
