import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../api_helper';
import { DEVICE_STATISTICS_URL } from '../../../api/apiUrls';

function parsePredictionData({ keys = [], values = [] }) {
  const rangesData = [];

  const timestampIndex = keys.indexOf('timestamp');
  const expectedIndex = keys.indexOf('expected');
  const lowerIndex = keys.indexOf('lower');
  const upperIndex = keys.indexOf('upper');

  const expectedData = values.map((arr = []) => {
    const timestamp = arr[timestampIndex];
    const expected = arr[expectedIndex];
    const lower = arr[lowerIndex];
    const upper = arr[upperIndex];

    rangesData.push([timestamp, lower / 1000, upper / 1000]);
    return [timestamp, expected / 1000];
  });

  return { ranges: rangesData, expected: expectedData };
}

function parseDeviceData({ keys = [], values = [] }, key) {
  const timestampIndex = keys.indexOf('timestamp');
  const dataIndex = keys.indexOf(key);

  if (dataIndex === -1) {
    throw new Error('Data not found');
  }

  return values.map((arr = []) => {
    const timestamp = arr[timestampIndex];
    const data = arr[dataIndex];
    return [timestamp, data / 1000];
  });
}

export const flexDashboardApi = createApi({
  reducerPath: 'flexDashboardApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['data'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getFlexDashboardData: builder.query({
      providesTags: ['data'],
      query: () => ({ url: DEVICE_STATISTICS_URL, method: 'get' }),
      transformResponse: ({ minY, maxY, prediction = {}, curves = {} }) => {
        const predictionData = parsePredictionData(prediction);

        const pv = parseDeviceData(curves, 'TOTAL_PV');
        const batteryCharging = parseDeviceData(curves, 'CHARGING');
        const battery = parseDeviceData(curves, 'BATTERY');
        const heatPump = parseDeviceData(curves, 'HEAT_PUMP');
        const waterHeaters = parseDeviceData(curves, 'WATER_HEATERS');

        return {
          prediction: predictionData,
          pv,
          batteryCharging,
          battery,
          heatPump,
          waterHeaters,
          minY: minY / 1000,
          maxY: maxY / 1000
        };
      }
    })
  })
});

export const { useGetFlexDashboardDataQuery } = flexDashboardApi;
