/* eslint-disable arrow-body-style */
import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  // [listID] : {
  //   listURL: string,
  //   total: number,
  //   loading: bool,
  //   data: array,
  //   errorMessage: string,
  //   transformResponse: function,
  //   params: Object
  // }
};

const dataListSlice = createSlice({
  name: 'dataList',
  initialState,
  reducers: {
    dataListReloadData: epicEmptyFunction,
    getSubUserReloadData: epicEmptyFunction,

    dataListSendRequest: {
      reducer(state, action) {
        const { listID, dataList: { params, listURL, transformResponse, headers } } = action?.payload || {};

        state[listID] = {
          ...state[listID],
          listURL,
          loading: true,
          headers,
          params: {
            ...params,
            isInstallationCompleted: (listID === 'sm_users' && params.isInstallationCompleted !== undefined) ? params.isInstallationCompleted : undefined,
            supportContractFlag: (listID === 'sm_users' && params.supportContractFlag !== undefined) ? params.supportContractFlag : undefined
          },
          transformResponse,
          errorMessage: undefined
        };
      },
      prepare: (data) => ({ payload: { ...data, showNotification: data.showNotification ?? true } })
    },
    getSubUserData: (state, action) => {
      const { listID, dataList: { params, listURL, parentIdOfSubUsers, transformResponse } } = action?.payload || {};

      state[listID] = {
        ...state[listID],
        [parentIdOfSubUsers]: {
          listURL,
          loading: true,
          params,
          transformResponse,
          errorMessage: undefined
        }
      };
    },
    dataListReceived: (state, action) => {
      const { listID, listURL, parsedResult: { data, total, ...rest } } = action.payload;

      state[listID] = {
        ...state[listID],
        listURL,
        ...rest,
        data,
        total,
        errorMessage: undefined,
        loading: false
      };
    },
    subUserDataReceived: (state, action) => {
      const { parentIdOfSubUsers, listID, listURL, parsedResult: { data, total } } = action.payload;

      state[listID] = {
        ...state[listID],
        [parentIdOfSubUsers]: {
          ...state[listID][parentIdOfSubUsers],
          listURL,
          data,
          total,
          errorMessage: undefined,
          loading: false
        }
      };
    },
    dataListGotError: (state, action) => {
      const { listID, listURL, errorMessage } = action.payload;

      state[listID] = {
        ...state[listID],
        data: [],
        total: 0,
        listURL,
        errorMessage,
        loading: false
      };
    },
    dataListClean: (state, action) => {
      state[action.payload.listID] = undefined;
    },
    dataListUpdateRow: (state, action) => {
      const { listID, rowID, updates } = action.payload;

      state[listID].data[rowID] = {
        ...state[listID].data[rowID],
        ...updates
      };

      return state;
    }
  }
});

export const {
  getSubUserReloadData,
  dataListSendRequest,
  subUserDataReceived,
  dataListReloadData,
  dataListUpdateRow,
  dataListReceived,
  dataListGotError,
  getSubUserData,
  dataListClean
} = dataListSlice.actions;

export default dataListSlice.reducer;
