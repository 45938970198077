import i18n from '../../../i18n';

const renderDeviceName = (el) => {
  if (!el.sensor) {
    return el?.gateway ? i18n.t('Gateway') : i18n.t('unknownSensor');
  }
  return `${el.sensor.device_group} | ${el.sensor.device_type}`;
};

export default renderDeviceName;
