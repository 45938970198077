import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { omitBy } from 'lodash';
import { dataListSendRequest } from '../slice';
import { setPagesSettingQueries, setPagesSettingColumnsWidth } from '../../PagesSettings/slice';
import { getPagesSettingsSelector } from '../../../redux-store/selectors/pagesSettings';
import { getDataListSelector } from '../../../redux-store/selectors/dataList';

const removeEmptyValuesFromQueries = (values) => omitBy(values, (value) => value === '');

const DataListV2 = (props) => {
  const {
    listID,
    listURL = undefined,
    Component = undefined,
    componentProps = {},
    transformResponse = undefined,
    params: paramsFromProps = {},
    savePageColumnWidth: savePageColumnWidthFromProps = undefined
  } = props;
  const dispatch = useDispatch();

  const { [listID]: dataList } = useSelector(getDataListSelector);
  const { [listID]: settings = { queries: paramsFromProps || {} } } = useSelector(getPagesSettingsSelector);
  const settingRef = useRef([]);

  const [id, desc] = settings.queries._sort.split(':');

  const handleSort = (sorting) => {
    const [{ id: sortId, desc: isDesc }] = sorting;
    const _sort = `${sortId}:${isDesc ? 'desc' : 'asc'}`;
    const params = {
      listURL,
      params: removeEmptyValuesFromQueries({ ...settings.queries, _sort }),
      transformResponse
    };

    dispatch(dataListSendRequest({ listID, dataList: params }));
    dispatch(setPagesSettingQueries({ page: listID, data: { _sort } }));
  };

  const onResizedChange = (all) => {
    settingRef.current = all;

    if (componentProps.onResizedChange) {
      componentProps.onResizedChange(all);
    }
  };

  const savePageColumnWidth = () => {
    if (savePageColumnWidthFromProps) {
      savePageColumnWidthFromProps(settingRef);
      return;
    }

    const transformedData = settingRef.current.reduce((acc, { id: sortId, value }) => ({ ...acc, [sortId]: value }), {});
    dispatch(setPagesSettingColumnsWidth({ page: listID, data: transformedData }));
  };

  useEffect(() => {
    if (listID && listURL) {
      const params = {
        listURL,
        params: removeEmptyValuesFromQueries({ ...paramsFromProps, ...settings.queries }),
        transformResponse
      };
      dispatch(dataListSendRequest({ listID, dataList: params }));
    }
  }, [listID, listURL]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('beforeunload', savePageColumnWidth);

    return () => {
      savePageColumnWidth();
      window.removeEventListener('beforeunload', savePageColumnWidth);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Component
      onSortedChange={handleSort}
      data={dataList?.data}
      sorted={[{ id, desc: desc === 'desc' }]}
      loading={dataList?.loading}
      {...componentProps}
      onResizedChange={onResizedChange}
    />
  );
};

DataListV2.propTypes = {
  listID: PropTypes.string.isRequired,
  Component: PropTypes.func,
  componentProps: PropTypes.instanceOf(Object),
  listURL: PropTypes.string,
  transformResponse: PropTypes.func,
  params: PropTypes.instanceOf(Object),
  savePageColumnWidth: PropTypes.func
};

export default DataListV2;
