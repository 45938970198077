const replace = {
  changeSMID: 'Cambia l\'ID 3S OEM',
  reqSMID: '3S ID *',
  SMID: '3S ID',
  SMIDTolltip: 'Il 3S ID è un identificatore unico del tuo dispositivo. Questo ID si trova sul dispositivo sotto il codice QR.',
  enableSmId: '3S ID',
  smidLabel: '3S ID',
  confirmDeletingDesc: 'Conferma la cancellazione di tutti i dati di questo gateway inserendo l\'ID 3S. Dopo la conferma, questo processo non può essere invertito.',
  confirmDeletingError: 'L\'ID non corrisponde all\'ID 3S. Il gateway non è stato cancellato.'
};

export default replace;
