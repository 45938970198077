import { sixtyDays, tenDays, hours25 } from '../constants';

export default function getChartType(expectedScaleMS) {
  if (expectedScaleMS > sixtyDays) { // 60 days
    return 'monthly';
  }
  if (expectedScaleMS > tenDays) { // 10 days
    return 'daily';
  }
  if (expectedScaleMS > hours25) { // 7 days
    return 'week';
  }

  return 'default';
}
