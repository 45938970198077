import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from '@mui/material';
import RadioInput from './components/RadioInput';
import i18n from '../../../i18n';
import './index.scss';

export const RadioButtons = (props) => {
  const { input, variants, InputElement = null, defaultValue, ...custom } = props;

  useEffect(() => {
    if (!input.value && typeof defaultValue !== 'undefined') {
      input.onChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    input.onChange(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const Input = InputElement || RadioInput;

  return (
    variants?.map((variant) => {
      const _label = typeof variant.label === 'string' && i18n.exists(variant.label) ? i18n.t(variant.label) : variant.label;

      return (
        <Input
          {...variant}
          label={_label}
          callback={input.onChange.bind(null, variant.value)}
          name={input.name}
          selected={variant.value === input.value}
          key={`radio_${input.name}_${variant.value}`}
          {...custom}
        />
      );
    })
  );
};

export const renderRadioGroup = ({ input, meta: { touched, error }, ...rest }) => (
  <>
    <RadioGroup
      value={input.value}
      onChange={(_event, value) => input.onChange(value)}
      {...input}
      {...rest}
    />
    {touched && error && (
      <div className="inputCustomWarning m-0 p-2">
        {i18n.t(error)}
      </div>
    )}
  </>
);

RadioButtons.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  variants: PropTypes.instanceOf(Array).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  InputElement: PropTypes.func
};
