import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { QrReader } from 'react-qr-reader';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../containers/ModalWindow';
import { getModalsSelector } from '../../redux-store/selectors/modals';
import { CancelButton } from '../../components/UIKit';
import { QR_CODE_SCANNER_MODAL_ID, closeModalWindow } from '../../containers/ModalWindow/slice';

import i18n from '../../i18n';

import './index.scss';

const QRCodeScannerModalWindow = (props) => {
  const { scanQR } = props;
  const dispatch = useDispatch();

  const QRCompRef = useRef();
  const modals = useSelector(getModalsSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: QR_CODE_SCANNER_MODAL_ID }));

  const handleError = (err) => {
    console.error(err);
    handleOnClose();
  };

  const modal = modals?.[QR_CODE_SCANNER_MODAL_ID];

  return (
    <ModalWindow isModalOver modalID={QR_CODE_SCANNER_MODAL_ID}>
      <div className="modal-header">
        <CancelButton onClickHandler={handleOnClose} customButtonClass="QR-code-header-button" />
        <h5 className="modal-title">{i18n.t('QRLink')}</h5>
      </div>
      <div className="modal-body">
        {modal?.opened ? (
          <QrReader
            ref={QRCompRef}
            delay={300}
            onError={handleError}
            onScan={scanQR}
            className="QRCodeScanner"
            style={{ width: '100%', marginBottom: '30px' }}
          />
        ) : null}
      </div>
    </ModalWindow>
  );
};

QRCodeScannerModalWindow.propTypes = {
  scanQR: PropTypes.func.isRequired
};

export default QRCodeScannerModalWindow;
