import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for status of user
 * @memberof module:ReactTable
 * @param  {string} value - status of user
 */
const RenderStatus = ({ value }) => (
  (value === 'active')
    ? i18n.t(value)
    : <span className="error_status_yes">{i18n.t(value)}</span>
);

export const DotStatus = ({ value }) => ((['active', 'connected'].includes(value))
  ? <span className="m-badge m-badge--success m-badge--dot ml-4" />
  : <span className="m-badge m-badge--danger m-badge--dot ml-4" />
);

RenderStatus.propTypes = {
  value: PropTypes.string.isRequired
};

DotStatus.propTypes = {
  value: PropTypes.string.isRequired
};

export default RenderStatus;
