import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import { handleErrorDetailed } from '../../api_helper';
import { addNotification } from '../NotificationGenerator/slice';
import { openModalWindow } from '../ModalWindow/slice';
import { receiveFormData, requestAccessToken } from './slice';
import { api } from '../../api';
import i18n from '../../i18n';
import { SENSORS_AUTH_OAUTH2_URL } from '../../api/apiUrls';
import fieldsForDevices from '../DevicesTable/utils/fieldsForDevices';

function requestAccessTokenEpic(action$) {
  return action$.pipe(
    ofType(requestAccessToken),
    map((action) => action.payload),
    switchMap(({ body, currentModalId, currentModalData }) => from(
      api.post(SENSORS_AUTH_OAUTH2_URL, { sensor: { device_group: currentModalData?.device_group }, body }))
      .pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          const tokenString = result && !result?.error && typeof result === 'object' ? JSON.stringify(result) : '';
          // for some devices we have to use generatedToken instead of tokenString
          let tokenFieldName = 'tokenString';

          if (fieldsForDevices[currentModalData?.device_group]?.includes('generatedToken')) {
            tokenFieldName = 'generatedToken';
          }

          if (fieldsForDevices[currentModalData?.device_group]?.includes('tokenStringHC')) {
            tokenFieldName = 'tokenStringHC';
          }

          const newModalData = {
            ...currentModalData,
            data: {
              ...currentModalData.data,
              [tokenFieldName]: tokenString
            }
          };

          if (tokenString) {
            return of(
              openModalWindow({ modalID: currentModalId, data: newModalData }),
              receiveFormData({ data: newModalData })
            );
          }

          return of(
            openModalWindow({ modalID: currentModalId, data: newModalData }),
            receiveFormData({ data: newModalData }),
            addNotification({ type: 'error', text: result?.message || i18n.t('error') })
          );
        })
      ))
  );
}

export default combineEpics(requestAccessTokenEpic);
