import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import { api } from '../../api';
import { handleErrorDetailed } from '../../api_helper';
import { addNotification } from '../NotificationGenerator/slice';
import { oAuthSignIn } from './actions';
import { V1_AUTH_OAUTH_LOGIN_URL } from '../../api/apiUrls';

function oAuthSignInEpic(action$) {
  return action$.pipe(
    ofType(oAuthSignIn),
    map((action) => action.payload),
    switchMap(({ data }) => (
      from(api.post(V1_AUTH_OAUTH_LOGIN_URL, { ...data })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error) {
            window.location.href = result;
            return result;
          }

          return of(
            addNotification({ type: 'error', text: result.message })
          );
        })
      )
    ))
  );
}

export default combineEpics(oAuthSignInEpic);
