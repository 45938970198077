import { useRef, useEffect } from 'react';

const useHorizontalScroll = () => {
  const elRef = useRef();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();

        el.scrollBy({
          left: e.deltaY < 0 ? -30 : 30
        });
      };
      el.addEventListener('wheel', onWheel, { passive: false });
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, [elRef]);
  return elRef;
};

export default useHorizontalScroll;
