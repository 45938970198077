/**
 * Return color for background, relative to name
 * @param {string} name - user name.
 */
const getBackgroundAvatarColor = (userName) => {
  const colors = ['#f8ad40', '#7191ad', '#f5753c', '#33517d', '#337d40', '#7d7133', '#7d3340', '#33467d'];

  const number = (userName.charCodeAt(0) + userName.length) % colors.length;
  return colors[number];
};

export default getBackgroundAvatarColor;
